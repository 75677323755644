import { select, putResolve, takeEvery, all } from 'redux-saga/effects';

import {
  CHANGE_LICENSE,
  INITIATE_INVOICES_FETCH,
  fetchInvoices,
} from 'src/customer/redux/actions';
import { PAY_EXTRA } from 'src/payment/redux/actions';

export function* handleInvoicesLoad({ payload: { prevPage } }) {
  const {
    customer: {
      invoices: { curPage },
    },
    router: { location },
  } = yield select((state) => state);
  if (process.env.BROWSER) {
    if (curPage && curPage === prevPage) {
      return;
    }
  }
  const params = {
    page: curPage || location.query.page,
    size: 12,
  };

  const selectedCustomerId = yield select(
    (state) => state.admin.selectedCustomer.customerInfo.id
  );
  const role = yield select(
    (state) => state.customer.user?.currentLoggedUser?.role
  );
  if (selectedCustomerId && role === 'EXPOINT_ADMIN') {
    params.selectedCustomerId = selectedCustomerId;
  }
  
  yield putResolve(fetchInvoices(params));
}

export function* handlePaymentSuccessfull(props) {
  yield putResolve(fetchInvoices({ page: 1, size: 12 }));
}

export default function* () {
  yield all([
    takeEvery(CHANGE_LICENSE.SUCCESS, handlePaymentSuccessfull),
    takeEvery(PAY_EXTRA.SUCCESS, handlePaymentSuccessfull),
    takeEvery(INITIATE_INVOICES_FETCH, handleInvoicesLoad),
  ]);
}
