import { createApiAction, createAsyncActionRequestTypes } from 'src/api';
import queryString from 'query-string';

export const FETCH_JOBS_STATS =
  createAsyncActionRequestTypes('FETCH_JOBS_STATS');

export const fetchJobsStats = ({ ...params } = {}) =>
  createApiAction(
    {
      url:
        '/customer/insights/jobs?' +
        queryString.stringify({ ...params }, { skipEmptyString: true }),
      params,
    },
    FETCH_JOBS_STATS
  );

export const FETCH_CANDIDATES_SENIORITY_TRENDS = createAsyncActionRequestTypes(
  'FETCH_CANDIDATES_SENIORITY_TRENDS'
);

export const fetchCandidatesSeniorityTrends = ({ ...params } = {}) =>
  createApiAction(
    {
      url:
        `/customer/insights/seniority-trends?` +
        queryString.stringify({ ...params }, { skipEmptyString: true }),
      params,
    },
    FETCH_CANDIDATES_SENIORITY_TRENDS
  );

export const FETCH_JOB_SENIORITY_TRENDS = createAsyncActionRequestTypes(
  'FETCH_JOB_SENIORITY_TRENDS'
);

export const fetchJobSeniorityTrends = ({ ...params } = {}) =>
  createApiAction(
    {
      url:
        `/customer/insights/seniority-trends?` +
        queryString.stringify({ ...params }, { skipEmptyString: true }),
      params,
    },
    FETCH_JOB_SENIORITY_TRENDS
  );

export const DOWNLOAD_STATISTICS = createAsyncActionRequestTypes(
  'DOWNLOAD_STATISTICS'
);

export const downloadStatistics = ({ ...params } = {}) =>
  createApiAction(
    {
      method: 'GET',
      url:
        `/customer/insights/report?` +
        queryString.stringify({ ...params }, { skipEmptyString: true }),
      params,
      headers: {
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob',
    },
    DOWNLOAD_STATISTICS
  );

export const CLEAR_JOB_CANDIDATES_SENIORITY = 'CLEAR_JOB_CANDIDATES_SENIORITY';

export const clearJobCandidatesSeniority = (data) => ({
  type: CLEAR_JOB_CANDIDATES_SENIORITY,
  payload: data,
});

export const CLEAR_DESTRIBUTIONS = 'CLEAR_DESTRIBUTIONS';

export const clearDestributions = (data) => ({
  type: CLEAR_DESTRIBUTIONS,
  payload: data,
});

export const SET_SELECTED_SEARCH = 'SET_SELECTED_SEARCH';

export const setSelectedSearch = (field, value) => ({
  type: SET_SELECTED_SEARCH,
  payload: {
    field,
    value,
  },
});

export const SET_DEFAULT_STATS = 'SET_DEFAULT_STATS';

export const setDefaultStats = (data) => ({
  type: SET_DEFAULT_STATS,
  payload: data,
});
