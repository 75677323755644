import React, { useRef } from 'react';
// import moment from 'moment';
import clsx from 'clsx';
import { makeInitials, truncate } from 'src/utils/transformString';
import expointLogo from 'src/img/expoint-logo.jpg';
import useStyles from './styles';
import JobDesc from '../JobDesc';

const JobItem = ({
  hideJobDesc = false,
  job,
  handleClick,
  color,
  rowReverse = false,
  small = false,
}) => {
  const classes = useStyles({ color: color });
  const ref = useRef(null);
  const makeDate = (timestamp) => {
    // const today = moment().format('DD.MM.YYYY'),
    //   yesterday = moment().subtract(1, 'days').format('DD.MM.YYYY'),
    //   date = moment(timestamp).format('DD.MM.YYYY');
    const currentDate = new Date();

    const today = currentDate.toLocaleDateString('he-IL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    const yesterdayFormatted = yesterday.toLocaleDateString('he-IL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const date = new Date(timestamp).toLocaleDateString('he-IL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return date === today
      ? 'Today'
      : date === yesterdayFormatted
      ? 'Yesterday'
      : date;
  };

  return (
    <div
      className={clsx(
        classes.jobItem,
        rowReverse && classes.rowReverse,
        small && classes.smallJobItem
      )}
      ref={ref}
      onClick={(e) => handleClick(ref)}
      onKeyDown={(e) => e.keyCode === 13 && handleClick(ref)}
      tabIndex='0'
    >
      <div
        className={clsx(classes.border, job.clicked && classes.activeBorder)}
      />
      <div className={classes.date}>
        {job.createdAt ? makeDate(job.createdAt) : ''}
      </div>
      <div className={classes.row}>
        {job.companyLogo || !job.showCompanyName ? (
          <img
            src={job.showCompanyName ? job.companyLogo : expointLogo}
            alt='Company Logo'
            className={clsx(classes.image, small && classes.smallImage)}
            loading='lazy'
          />
        ) : (
          <div
            style={{ backgroundColor: color }}
            className={clsx(classes.image, small && classes.smallImage)}
          >
            {makeInitials(job.companyName)}
          </div>
        )}
        <div className={classes.infoCol}>
          <h2>
            <span className={classes.companyNameSpan}>
              {job.showCompanyName ? job.companyName : 'Expoint'}
            </span>
            <span style={{ direction: 'ltr' }} className={classes.titleSpan}>
              {' ' + truncate(job.title, 60) + ' '}
            </span>

            <span className={classes.locationSpan}>
              {job.country
                ? job.country +
                  (job.state ? ', ' + job.state : '') +
                  (job.city ? ', ' + job.city : '')
                : ''}
            </span>
          </h2>
        </div>
      </div>
      <div>
        {!hideJobDesc && (
          <div
            className={clsx(
              classes.jobDesc,
              job.clicked && classes.jobDescActive
            )}
          >
            <div className={classes.mobileDescWrapper}>
              <JobDesc
                showLogo={false}
                shortDesc
                {...job}
                isDerectLink={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobItem;
