import { all, takeEvery } from 'redux-saga/effects';

import {
  INITIATE_CVS_UPLOAD,
  INITIATE_CHECK_KEYS,
  KEYS_REQUEST_TIMED_OUT,
} from '../../../candidates/redux/actions';

import jobs from 'src/customerJobs/redux/sagas';
import onboarding from './onboarding';
import user from './user';
import invoices from './invoices';
import {
  handleCvsUpload,
  handleCheckKeys,
  handleKeysRequestTimedOut,
} from '../../../candidates/redux/sagas';

export default function* (store) {
  yield takeEvery(INITIATE_CHECK_KEYS, handleCheckKeys);
  yield takeEvery(INITIATE_CVS_UPLOAD, handleCvsUpload);
  yield takeEvery(KEYS_REQUEST_TIMED_OUT, handleKeysRequestTimedOut);
  yield all([onboarding(store), jobs(), user(), invoices()]);
}
