import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '310px',
    marginTop: '20px',
    '& fieldset': {
      borderRadius: 6,
    },
  },
  errorText: {
    color: 'red',
    fontSize: '14px',
    marginTop: 0,
    marginLeft: 0,
    fontWeight: 'normal',
    textAlign: 'left',
  },
}));

export default ({
  name,
  errorName = name,
  handleChange,
  handleBlur,
  values,
  value,
  label,
  touched,
  errors,
  error,
  className,
  intl,
  labelId,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <TextField
      name={name}
      label={
        labelId
          ? intl.formatMessage({
              id: labelId,
              defaultMessage: 'Input label',
            })
          : label
      }
      onChange={handleChange}
      onBlur={handleBlur}
      value={value === undefined ? (values ? values[name] || '' : null) : value}
      error={error || (errors && touched[name] && !!errors[name])}
      variant='outlined'
      className={clsx(classes.textField, className)}
      helperText={
        error || (errors?.[name] && touched[name])
          ? intl.formatMessage({
              id: `errors.${errorName}.${errors[name]}`,
              defaultMessage: `errors.${errorName}.${errors[name]}`,
            })
          : ''
      }
      FormHelperTextProps={{
        className: classes.errorText,
      }}
      autoComplete='new-password'
      {...rest}
    />
  );
};
