import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ width, height, color, strokeWidth }) => (
  <SvgIcon
    viewBox='0 0 22 19'
    style={{
      height: height || 19,
      width: width || 22,
    }}
  >
    <g id='heart' transform='translate(0.75 0.75)'>
      <path
        d='M17.5375 1.4653C16.5998 0.527106 15.3277 -8.07435e-16 14.0012 0C12.6747 4.03717e-16 11.4026 0.527106 10.4648 1.4653L9.50117 2.42893L8.53754 1.4653C6.58446 -0.487784 3.41789 -0.487784 1.46481 1.4653C-0.48827 3.41838 -0.48827 6.58494 1.46481 8.53802L2.42845 9.50166L9.50117 16.5744L16.5739 9.50166L17.5375 8.53802C18.4757 7.60027 19.0028 6.32815 19.0028 5.00166C19.0028 3.67517 18.4757 2.40305 17.5375 1.4653Z'
        transform='translate(0.4988266 -0.001659656)'
        id='Path'
        fill='none'
        stroke={color || '#FF6E7E'}
        strokeWidth={strokeWidth || '1.5'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </SvgIcon>
);
