import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, shadows }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: palette.background.paper,
    boxShadow: shadows[5],
    width: '100vw',
    height: '100vh',
    borderRadius: '6px',
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    color: palette.primary.dark,
  },
  header: {
    position: 'relative',
    height: 250,
  },
  imageTop: {
    objectFit: 'cover',
    objectPosition: 'bottom',
    marginLeft: -1,
    marginTop: -1,
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sloganContainer: {
    position: 'relative',
    height: 160,
    width: '100%',
  },
  imageText: {
    position: 'absolute',
    top: 0,
    height: 160,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: 32,
    padding: '0 30px',
    fontWeight: 'bold',
    '@media screen and (max-width: 400px)': {
      '&': {
        fontSize: 26,
      },
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  title: {
    color: palette.primary.main,
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 30,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  listItem: {
    flex: 1,
    minWidth: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 18,
    lineHeight: '30px',
    padding: '0 10%',
    '& p': {
      textAlign: 'center',
    },
  },
  icon: {
    width: 150,
    marginTop: 10,
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: 35,
    left: 35,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  button: {
    minHeight: '40px',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
    cursor: 'pointer',
    userSelect: 'none',
    color: palette.text.white,
    '&:hover': {
      boxShadow: '-3px 3px 7px 0 rgba(0,0,0,0.22)',
    },
    backgroundColor: palette.primary.main,
    width: '90%',
    maxWidth: 310,
    borderRadius: '6px',
    fontSize: 20,
  },
  dividerDiv: {
    width: '100%',
    maxWidth: 310,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  dividerSide: {
    height: '20px',
    width: '40%',
    borderBottom: `1px ${palette.divider} solid`,
  },
  dividerText: {
    color: palette.text.secondary,
    fontSize: '24px',
    fontWeight: 'bold',
    height: '40px',
  },
  socialDiv: {
    width: '90%',
    maxWidth: 310,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  blueText: {
    color: palette.primary.main,
  },
  '@media screen and (min-width: 600px)': {
    paper: {
      width: '80vw',
      minWidth: '300px',
      maxWidth: '600px',
      height: 'auto',
      maxHeight: '95%',
    },
    header: {
      height: 100,
    },
    '@media screen and (min-height: 700px)': {
      header: {
        height: 200,
      },
    },
    '@media screen and (min-height: 760px)': {
      header: {
        height: 250,
      },
    },
  },
  '@media screen and (min-width: 1400px)': {
    button: {
      height: 48,
    },
  },
}));
