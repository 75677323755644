import {
  takeEvery,
  takeLatest,
  take,
  put,
  all,
  select,
  putResolve,
} from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import { navigateIntl } from 'src/utils/history';
import {
  setToken,
  CONNECT_USER,
  RENEW_TOKEN,
  SET_TOKEN,
  REMOVE_TOKEN,
} from 'src/onboarding/redux/actions';
import { setItem, getItem, removeItem } from 'src/utils/localStorage';
import { toggleModal } from 'src/onboarding/redux/actions';
import { REG_FROM_POPUP } from 'src/user/constants';
import {
  CV_DATA,
  LOGIN_FROM_CV_BUILDER,
  SHOW_CV_MODAL,
  fetchCvSaga,
} from 'src/cv/redux/sagas';
import { getMe, FETCH_USER } from 'src/user/redux/actions';
import { toggleCVModal } from 'src/user/redux/actions/profile';
import { redirect } from 'src/router/redux/actions';
import { replaceLangInPath, allLanguages } from 'src/intl/utils';
import { setLanguage } from 'src/intl/redux/actions';

import { LOG_OUT, LOGIN_USER, REGISTER_USER, updateMe } from '../actions';

export const LOCAL_STORE_TOKEN = 'expoint@user:token';

function* initUser() {
  const {
    router: {
      location: { pathname, query },
    },
    onboarding: { token, isCustomer },
  } = yield select((state) => state);

  // if (!isCustomer && pathname.includes('/customer/dashboard')) {
  //   yield put(redirect('/'));
  // }

  if (query.showRegModal === '1') {
    yield put(toggleModal(true, 'isRegModalOpen'));
  }
  if (query.showLogin === '1') {
    yield put(toggleModal(true, 'isLoginClientOpen'));
  }
  if (query.show_update_modal !== '1') return;
  if (token) {
    yield put(replace(pathname));
    const { profile } = yield select((state) => state.user);
    if (profile.jobTitle === '') {
      yield put(toggleCVModal(true, null, true));
    }
  } else {
    yield put(toggleModal(true, 'isLoginClientOpen'));
    yield setItem(SHOW_CV_MODAL, true);
  }
  // yield updateUser();
  // const token = yield getItem(LOCAL_STORE_TOKEN);
  // if (token) {
  //   yield put(setToken(token));
  //   yield getMeSaga();
  // }
}

export function* loginSuccessSaga({ data: { token, redirectBackUrl = '/' } }) {
  const loginFromCvBuilder = yield getItem(LOGIN_FROM_CV_BUILDER);
  const { language } = yield select((state) => state.locale);

  if (token) {
    yield setItem(LOCAL_STORE_TOKEN, token);
    yield put(setToken(token));
    yield updateUser();
    yield all([putResolve(getMe()), fetchCvSaga()]);
    const { profile } = yield select((state) => state.user);

    // if (profile?.defaultLanguage && profile?.defaultLanguage !== language) {
    //  yield put(setLanguage(profile.defaultLanguage));
    // }
  }
  yield removeItem(LOGIN_FROM_CV_BUILDER);
}

function* logoutSaga() {
  yield removeItem(LOCAL_STORE_TOKEN);
  yield removeItem(CV_DATA);
}

function* connectSuccessSaga({ data, params: { type } }) {
  const { t } = data;

  if (type === 'user') {
    yield loginSuccessSaga({ data: { token: t } });
  }
}

function* renewTokenSaga({ token }) {
  const isUser = yield getItem(LOCAL_STORE_TOKEN);

  if (isUser) {
    yield setItem(LOCAL_STORE_TOKEN, token);
    yield put(setToken(token));
  }
}

function* closeModalSaga() {
  yield put(toggleModal(false));
}

function* updateUser() {
  const isFromPopup = yield getItem(REG_FROM_POPUP);

  if (isFromPopup) {
    yield put(updateMe({ promotionPopup: true }));
    yield removeItem(REG_FROM_POPUP);
  }
}

function* jobTitleModalSaga() {
  const showCvModal = yield getItem(SHOW_CV_MODAL);
  if (showCvModal) {
    yield take([FETCH_USER.SUCCESS]);
    const { profile } = yield select((state) => state.user);
    if (profile.jobTitle === '') {
      yield put(toggleCVModal(true, null, true));
    }
    yield removeItem(SHOW_CV_MODAL);
  }
}

export default function* () {
  return yield all([
    initUser(),
    // yield takeEvery(REGISTER_CUSTOMER.SUCCESS, successRegSaga),
    takeEvery(LOGIN_USER.SUCCESS, loginSuccessSaga),
    takeEvery(REMOVE_TOKEN.SUCCESS, logoutSaga),
    takeLatest(CONNECT_USER.SUCCESS, connectSuccessSaga),
    takeLatest(RENEW_TOKEN, renewTokenSaga),
    takeLatest(REGISTER_USER.SUCCESS, closeModalSaga),
    takeEvery(SET_TOKEN, jobTitleModalSaga),
  ]);
}
