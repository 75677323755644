import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  container: {
    width: '100%',
    // backgroundColor: '#F3F3F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  sliderTitle: {
    alignSelf: 'center',
    width: '83%',
    fontWeight: 600,
    fontSize: 20,
  },
  suggestionLink: {
    alignSelf: 'center',
  },
  jobItemContainer: {
    maxWidth: 400,
    margin: '10px',
  },

  jobsSliderContainer: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  suggestionLinkSpan: {
    alignSelf: 'center',
    color: 'blue',
    fontWeight: 500,
    textAlign: 'center',
    textDecoration: 'underline',
  },
  carousel: {
    overflow: 'hidden',
    '& button': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
    '& div': {
      '& button': {
        width: '8.5%',
        color: '#0243E5',
        '&:hover:enabled': {
          transform: 'scale(1.1)',
          color: '#0243E5',
          boxShadow: 'none',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        '&:focus:enabled': {
          color: '#0243E5',
          boxShadow: 'none',
          backgroundColor: 'rgba(0,0,0,0)',
        },
        '&:disabled': {
          cursor: 'auto',
          color: '#d1d1d1',
          boxShadow: 'none',
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
    },
  },
}));
