import queryString from 'query-string';
import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const GET_RECOMMENDED_JOBS = createAsyncActionRequestTypes(
  'GET_RECOMMENDED_JOBS'
);

export const fetchSimilarJobs = (job, langCode = 'en') => {
  // send only required fields
  const { id, title, country, city } = job;
  return createApiAction(
    {
      method: 'POST',
      url: '/jobs/similarJobs',
      headers: {
        'Content-Type': 'application/json',
      },
      body: { job: { id, title, country, city }, langCode },
    },
    GET_RECOMMENDED_JOBS
  );
};

export const GET_JOBS = createAsyncActionRequestTypes('GET_JOBS');

export const getJobsAction = ({ page = 0, size = 50, ...params } = {}) => {
  return createApiAction(
    {
      method: 'POST',
      url:
        params?.saved || params?.type === 'recommended'
          ? '/user/saved-jobs'
          : '/jobs',
      body: {
        ...params,
        distance: params.distance ? params.distance + 'km' : '',
        // in UI we keep start pages from 1 so we remove that one before
        // it's sent to the server
        page: page > 0 ? page - 1 : page,
        size,
        captchaToken: params?.captchaToken,
        ...params,
      },
      headers: {
        'Content-type': 'application/json',
        ...(process.env.BROWSER ? {} : { ssrId: process.env.SSR_ID }),
      },
    },
    GET_JOBS
  );
};

export const INITIATE_JOBS_FETCH = 'INITIATE_JOBS_FETCH';

export const initiateJobsFetch = (params) => ({
  type: INITIATE_JOBS_FETCH,
  params,
});

export const GET_SAVED_JOBS = createAsyncActionRequestTypes('GET_SAVED_JOBS');

export const getSavedJobs = (params) =>
  createApiAction(
    {
      url:
        '/user/saved-jobs?' +
        queryString.stringify(params, { skipEmptyString: true }),
      params,
    },
    GET_SAVED_JOBS
  );

export const SAVE_JOB_IN_FAVORITE = createAsyncActionRequestTypes(
  'SAVE_JOB_IN_FAVORITE'
);

export const saveJob = (jobId) =>
  createApiAction(
    {
      url: '/user/save-job?jobId=:jobId',
      params: { jobId },
    },
    SAVE_JOB_IN_FAVORITE
  );

export const DELETE_SAVED_JOB =
  createAsyncActionRequestTypes('DELETE_SAVED_JOB');

export const deleteSavedJob = (jobId) =>
  createApiAction(
    {
      method: 'DELETE',
      url: '/user/save-job?jobId=:jobId',
      params: { jobId },
    },
    DELETE_SAVED_JOB
  );

export const APPLY_FOR_JOB = createAsyncActionRequestTypes('APPLY_FOR_JOB');

export const applyForJob = (jobId) =>
  createApiAction(
    {
      method: 'POST',
      url: '/jobs/:jobId/apply',
      params: {
        jobId,
      },
    },
    APPLY_FOR_JOB
  );

export const JOB_TITLE_AUTOCOMPLETE = createAsyncActionRequestTypes(
  'JOB_TITLE_AUTOCOMPLETE'
);

export const autocompleteJobsByField = (field, value, langCode = 'en') =>
  createApiAction(
    {
      method: 'GET',
      url: '/jobs/autocomplete?field=:field&value=:value&langCode=:langCode',
      params: {
        field,
        value,
        langCode,
      },
    },
    JOB_TITLE_AUTOCOMPLETE
  );

export const CHANGE_COMPANY_VALUE = 'CHANGE_COMPANY_VALUE';

export const changeCompanyValue = (value) => ({
  type: CHANGE_COMPANY_VALUE,
  value,
});

export const SET_JOB_CATEGORY = 'SET_JOB_CATEGORY';

export const setJobCategory = (value) => ({
  type: SET_JOB_CATEGORY,
  value,
});

export const SET_SEO_INFO = 'SET_SEO_INFO';

export const setSeoInfo = (value) => ({
  type: SET_SEO_INFO,
  value,
});

export const JOB_COMPANY_AUTOCOMPLETE = createAsyncActionRequestTypes(
  'JOB_COMPANY_AUTOCOMPLETE'
);

export const jobCompanyAutocomplete = (company_name) =>
  createApiAction(
    {
      url: `/jobs/autocomplete-company?name=${company_name}`,
      params: {
        name: company_name,
      },
    },
    JOB_COMPANY_AUTOCOMPLETE
  );

export const GET_DEFAULT_COMPANIES = createAsyncActionRequestTypes(
  'GET_DEFAULT_COMPANIES'
);

export const getDefaultCompanies = () =>
  createApiAction(
    {
      url: `/jobs/autocomplete?field=companyName`,
    },
    GET_DEFAULT_COMPANIES
  );

export const GET_COUNTRIES_AUTOCOMPLETE = createAsyncActionRequestTypes(
  'GET_COUNTRIES_AUTOCOMPLETE'
);

export const getCountriesAutocomplete = () =>
  createApiAction(
    {
      url: `/jobs/autocomplete-country`,
    },
    GET_COUNTRIES_AUTOCOMPLETE
  );

export const GET_CITIES_AUTOCOMPLETE = createAsyncActionRequestTypes(
  'GET_CITIES_AUTOCOMPLETE'
);

export const getJobTitlesCategories = (title = '') => {
  return createApiAction(
    {
      url: `/jobs/autocomplete-job-categories?title=:title`,
      params: {
        title,
      },
    },
    GET_JOB_TITLES_CATEGORIES
  );
};

export const GET_JOB_TITLES_CATEGORIES = createAsyncActionRequestTypes(
  'GET_JOB_TITLES_CATEGORIES'
);

export const getJobTitlesAutocomplete = (category) =>
  createApiAction(
    {
      url: `/jobs/autocomplete-job-titles?category=:category`,
      params: {
        category,
      },
    },
    GET_JOB_TITLES_AUTOCOMPLETE
  );

export const GET_JOB_TITLES_AUTOCOMPLETE = createAsyncActionRequestTypes(
  'GET_JOB_TITLES_AUTOCOMPLETE'
);

export const getCitiesAutocomplete = (params) =>
  createApiAction(
    {
      url: `/jobs/autocomplete-cities?country=:country&state=:state`,
      params,
    },
    GET_CITIES_AUTOCOMPLETE
  );

export const AUTOCOMPLETE_INITITATED = 'AUTOCOMPLETE_INITITATED';

export const initiateAutocomplete = (field, value, langCode = 'en') => ({
  type: AUTOCOMPLETE_INITITATED,
  field,
  value,
  langCode,
});

export const SET_CURRENT_JOB_INDEX = 'SET_CURRENT_JOB_INDEX';

export const setCurrentJobIndex = (idx) => ({
  type: SET_CURRENT_JOB_INDEX,
  idx,
});

export const GET_HIGHLIGHTS = createAsyncActionRequestTypes('GET_HIGHLIGHTS');

export const getHighlights = (companyName) =>
  createApiAction(
    {
      url: `/customer/highlight?companyName=${companyName}`,
      params: {
        companyName,
      },
    },
    GET_HIGHLIGHTS
  );

export const GET_OVERVIEW = createAsyncActionRequestTypes('GET_OVERVIEW');

export const getOverview = (companyName) =>
  createApiAction(
    {
      url: `/jobs/company-details?companyName=${companyName}`,
      params: {
        companyName,
      },
    },
    GET_OVERVIEW
  );

export const SET_REQUEST_PAGE = 'SET_REQUEST_PAGE';

export const setRequestPage = (page) => ({
  type: SET_REQUEST_PAGE,
  page,
});

export const SET_QUERY = 'SET_QUERY';

export const setQuery = (query) => ({
  type: SET_QUERY,
  query,
});

export const GET_JOB = createAsyncActionRequestTypes('GET_JOB');

export const getJob = (jobId, langCode = 'en', validRequest = true) =>
  createApiAction(
    {
      url: `/jobs/${jobId}/?langCode=${langCode}&validRequest=${validRequest}`,
    },
    GET_JOB
  );

export const DELETE_JOB_SAVED_SCREEN = 'DELETE_JOB_SAVED_SCREEN';

export const deleteJobSavedScreen = (jobId) => ({
  type: DELETE_JOB_SAVED_SCREEN,
  jobId,
});

export const TOGGLE_ALERT_MODAL = 'TOGGLE_ALERT_MODAL';

export const toggleAlertModal = (isOpen) => ({
  type: TOGGLE_ALERT_MODAL,
  isOpen,
});

export const TOGGLE_CVBANNER_MODAL = 'TOGGLE_CVBANNER_MODAL';

export const toggleCvBanner = (isOpen) => ({
  type: TOGGLE_CVBANNER_MODAL,
  isOpen,
});
