import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const USER_JOB_TITLES_AUTOCOMPLETE = createAsyncActionRequestTypes(
  'USER_JOB_TITLES_AUTOCOMPLETE'
);

export const fetchUserJobTitlesAutocomplete = (string) => {
  return createApiAction(
    {
      url: '/user/job-titles-autocomplete?string=:string',
      params: {
        string,
      },
    },
    USER_JOB_TITLES_AUTOCOMPLETE
  );
};

export const UPLOAD_AVATAR = createAsyncActionRequestTypes('UPLOAD_AVATAR');

export const uploadAvatar = (d, headers) =>
  createApiAction(
    {
      url: '/user/avatar',
      method: 'POST',
      body: d,
      headers,
    },
    UPLOAD_AVATAR
  );

export const FETCH_USER = createAsyncActionRequestTypes('FETCH_USER');

export const getMe = () =>
  createApiAction(
    {
      url: '/user',
    },
    FETCH_USER
  );

export const UPDATE_USER = createAsyncActionRequestTypes('UPDATE_USER');

export const updateMe = (body) =>
  createApiAction(
    {
      method: 'PATCH',
      url: '/user',
      body,
      headers: {
        'Content-type': 'application/json',
      },
    },
    UPDATE_USER
  );

export const UPDATE_USER_SETTING = createAsyncActionRequestTypes(
  'UPDATE_USER_SETTING'
);

export const updateUserSettings = (body) =>
  createApiAction(
    {
      method: 'POST',
      url: '/user/settings',
      body,
      headers: {
        'Content-type': 'application/json',
      },
    },
    UPDATE_USER_SETTING
  );

export const UNSUBSCRIBE = createAsyncActionRequestTypes('UNSUBSCRIBE');

export const unsubscribe = (token) =>
  createApiAction(
    {
      url: '/user/unsubscribe?token=:token',
      params: {
        token,
      },
    },
    UNSUBSCRIBE
  );
