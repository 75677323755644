import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ isActive }) => (
  <SvgIcon
    viewBox="0 0 28 14"
    style={{
      height: '14px',
      width: '28px',
      marginLeft: '-7px',
    }}
  >
    <g id="Group-27" transform="matrix(-1 0 0 1 26.66016 0.6601563)">
      <path
        d="M5.04 0C6.55878 0 7.79 1.23122 7.79 2.75C7.79 4.26878 6.55878 5.5 5.04 5.5C3.52122 5.5 2.29 4.26878 2.29 2.75C2.29 1.23122 3.52122 0 5.04 0ZM14.39 2.75C14.39 1.23122 13.1588 0 11.64 0C10.1212 0 8.89 1.23122 8.89 2.75C8.89 4.26878 10.1212 5.5 11.64 5.5C13.1588 5.5 14.39 4.26878 14.39 2.75ZM20.99 2.75C20.99 1.23122 19.7588 0 18.24 0C16.7212 0 15.49 1.23122 15.49 2.75C15.49 4.26878 16.7212 5.5 18.24 5.5C19.7588 5.5 20.99 4.26878 20.99 2.75ZM6.27638 6.6599C5.82451 7.17477 5.53606 7.83303 5.48735 8.57497L5.48 8.8L5.48 11.246L0 11.272L0 8.75L0.00524762 8.60074C0.0781361 7.56777 0.90168 6.74205 1.9337 6.66576L2.09 6.66L6.27638 6.6599ZM20.9393 6.66525L20.79 6.66L15.29 6.66L15.1337 6.66576C14.1017 6.74205 13.2781 7.56777 13.2052 8.60074L13.2 8.75L13.2 11.275L22.88 11.275L22.88 8.75L22.8742 8.5937C22.7979 7.56168 21.9722 6.73814 20.9393 6.66525ZM12.6129 6.70986C12.2001 7.19996 11.9367 7.81618 11.8881 8.51431L11.88 8.75L11.88 11.275L6.8 11.275L6.8 8.8L6.80525 8.65074C6.87814 7.61777 7.70168 6.79205 8.7337 6.71576L8.89 6.71L12.6129 6.70986Z"
        transform="translate(0.4599994 -1.638348E-08)"
        id="Shape"
        fill={isActive ? '#82BCFF' : '#FFF'}
        fillRule="evenodd"
        stroke="none"
      />
    </g>
  </SvgIcon>
);
