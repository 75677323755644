import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import { togglePrivacyPopup } from 'src/about/redux/actions';
import { toggleContactModal } from 'src/about/redux/actions';
// import { getArticlesNames } from 'src/articles/redux/actions';

import Footer from './Footer';

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state, props) => ({
      // footerArticles: state.articles.footerArticles,
      query: queryString.parse(props.location.search),
      queryString: queryString,
      isHebrew: state.locale.language === 'he',
      langCode: state.locale.language,
    }),
    { togglePrivacyPopup, toggleContactModal /*, getArticlesNames*/ },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      onPrivacyPopupClose: () => {
        if (stateProps.query.terms || stateProps.query.privacy)
          parentProps.history.push('/about');
      },
    })
  )
)(Footer);
