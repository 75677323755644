import { REDIRECT } from './actions';

export default (routerReducer) => (state, action) => {
  const newState = routerReducer(state, action);

  switch (action.type) {
    case REDIRECT:
      return {
        ...newState,
        redirect: {
          to: action.to,
          status: action.status,
        },
      };
    default:
      return newState;
  }
};
