import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const LOGIN_USER = createAsyncActionRequestTypes('LOGIN_USER');
export const REGISTER_USER = createAsyncActionRequestTypes('REGISTER_USER');
export const CHECK_USER_EMAIL =
  createAsyncActionRequestTypes('CHECK_USER_EMAIL');
export const DELETE_USER = createAsyncActionRequestTypes('DELETE_USER');

export const register = (d) =>
  createApiAction(
    {
      url: '/user/register',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    REGISTER_USER
  );

export const login = (d) =>
  createApiAction(
    {
      url: '/user/login',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    LOGIN_USER
  );

export const checkEmail = (d) =>
  createApiAction(
    {
      url: '/user/checkEmail',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    CHECK_USER_EMAIL
  );

export const deleteMe = () =>
  createApiAction(
    {
      url: '/user',
      method: 'DELETE',
    },
    DELETE_USER
  );

export const LOG_OUT = 'LOG_OUT';

export const logOut = (data) => ({
  type: LOG_OUT,
  payload: data,
});
