import { put, putResolve, select, takeEvery } from 'redux-saga/effects';

import queryString from 'query-string';

import { fetchMyJobs, getNextJobId } from 'src/customerJobs/redux/actions';

import { JOBS_LIST_ROUTE_NAME } from 'src/customer/constants';

export function* getMyJobs() {
  const {
    location: { search },
  } = yield select((state) => state.router);

  const selectedCustomerId = yield select(
    (state) => state.admin.selectedCustomer.customerInfo.id
  );
  // const selectedCustomerName = yield select(
  //   (state) => state.admin.selectedCustomer.customerInfo.name
  // );
  const selectedCustomerCompanyId = yield select(
    (state) => state.admin.selectedCustomer.customerInfo.company_id
  );

  const params = {
    ...queryString.parse(search),
  };

  if (selectedCustomerId) {
    params.selectedCustomerId = selectedCustomerId;
  }
  
  if (selectedCustomerCompanyId) {
    params.selectedCustomerId = selectedCustomerCompanyId;
    params.type = 'external';
  }
  return yield putResolve(
    // fetchMyJobs({
    //   ...queryString.parse(search),
    //   // selectedCustomerId,
    //   selectedCustomerId: selectedCustomerCompanyId || selectedCustomerId,
    //   // selectedCustomerName,
    // })
    fetchMyJobs(params)
  );
}

function* routerChangedSaga({ payload: { location } }) {
  if (location.pathname === JOBS_LIST_ROUTE_NAME && !location.search) {
    const {
      location: { search },
    } = yield select((state) => state.router);

    yield put(fetchMyJobs(queryString.parse(search)));
  }
}

export default function* () {
  yield takeEvery('@@router/LOCATION_CHANGE', routerChangedSaga);
}

export function* getNextJobIdSaga() {
  return yield putResolve(getNextJobId());
}
