import { connect } from 'react-redux';

import { toggleHeaderModal } from 'src/layout/redux/actions';
import { toggleModal, logout } from 'src/onboarding/redux/actions';

import Buttons from './Buttons';

export default connect(
  (state) => ({
    user: state.user.profile,
  }),
  { logout, toggleModal, toggleHeaderModal }
)(Buttons);
