import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ isActive, color, size = 18 }) => (
  <SvgIcon
    viewBox='0 0 18 18'
    style={{
      height: size,
      width: size,
    }}
  >
    <g id='new' transform='translate(0.6601563 0.6601563)'>
      <path
        d='M13.4268 0.636024C12.4035 -0.252392 10.8522 -0.210083 9.87918 0.762938L9.1685 1.47361L13.5053 5.81039L14.216 5.09971L14.3429 4.96354C15.2312 3.94025 15.1889 2.38899 14.216 1.41599L13.5629 0.762941L13.4268 0.636024ZM5.09972 14.216L12.5719 6.74377L8.23512 2.40699L0.762932 9.87917L0.622864 10.0308C0.222289 10.5005 0 11.0993 0 11.721L0 14.9789L3.25786 14.9789L3.46412 14.9707C4.07945 14.9219 4.66006 14.6556 5.09972 14.216Z'
        transform='translate(0.4258005 0.7809117)'
        id='Shape'
        fill={color ? color : isActive ? '#82BCFF' : '#FFF'}
        fillRule='evenodd'
        stroke='none'
      />
    </g>
  </SvgIcon>
);
