import { takeEvery, all, put } from 'redux-saga/effects';

import { CONNECT_USER, LOG_OUT, removeToken } from '../actions';
// TODO: remove onboarding saga for logout and move it to customer/users saga with
// their own logic in it
function* logoutConnected() {
  if (process.env.BROWSER) {
    yield put(removeToken());
    // window.FB.getLoginStatus((response) => {
    //   response.status === 'connected' && window.FB.logout();
    // });
  }
}

export default function* () {
  yield all([
    takeEvery(LOG_OUT, logoutConnected),
    takeEvery(CONNECT_USER.SUCCESS, function* () {
      window.location.reload();
    }),
  ]);
}
