import React from 'react';
import { Route } from 'react-router-dom';

const NestedRouter = ({ children = [], component: Comp, ...rest }) => (
  <Route
    {...rest}
    render={(routeProps) => (
      <Comp {...routeProps}>
        {children.map((r, i) => (
          <NestedRouter key={i} {...r} />
        ))}
      </Comp>
    )}
  />
);

export default NestedRouter;
