import React from 'react';
import { call, cancel, cancelled } from 'redux-saga/effects';

import { runSaga } from 'src/store/sagasMiddleware';

export default (saga) => (Component) => {
  class RouteResolveComponent extends React.PureComponent {
    static displayName = `ResolveRoute(${
      Component.displayName || Component.name
    })`;
    // to be done w functional class
    saga;

    state = {
      // only show it on FE
      isResolved: !process.env.BROWSER,
    };

    static fetchSaga = saga;

    componentDidMount() {
      const _this = this;

      this.saga = runSaga(function* () {
        yield call(saga, _this.props);

        const isCancelled = yield cancelled();

        if (!isCancelled)
          _this.setState((state) => ({ ...state, isResolved: true }));
      });
    }

    componentWillUnmount() {
      const localSaga = this.saga;

      if (localSaga) {
        runSaga(function* () {
          yield cancel(localSaga);
        });
      }
    }

    render() {
      return (
        <Component {...this.props} isSagaRunning={!this.state.isResolved} />
      );
    }
  }

  return RouteResolveComponent;
};
