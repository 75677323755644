import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ isActive, color, height = 22, width = 18, size = 15 }) => (
  <SvgIcon
    viewBox='0 0 18 22'
    style={{
      height,
      width,
    }}
  >
    <g id='surface1'>
      <path
        d='m8.03,18.85c-0.07,0 -0.07,0 -0.16,0c-0.07,0 -7.78,-1.79 -7.78,-10.14l0,-4.67c0,-0.31 0.16,-0.62 0.48,-0.71l7.14,-3.12c0.25,-0.07 0.48,-0.07 0.64,0l7.13,3.12c0.32,0.08 0.48,0.4 0.48,0.71l0,4.67c0,8.35 -7.7,10.14 -7.77,10.14c-0.09,0 -0.09,0 -0.16,0zm0,-8.56c-2.3,0 -3.97,1.01 -3.97,2.33c0,1.33 1.66,2.34 3.97,2.34c2.29,0 3.96,-1.01 3.96,-2.34c0,-1.32 -1.66,-2.33 -3.96,-2.33zm0,-6.24c-1.35,0 -2.39,1.01 -2.39,2.34c0,1.32 1.04,2.33 2.39,2.33c1.34,0 2.38,-1.01 2.38,-2.33c0,-1.33 -1.04,-2.34 -2.38,-2.34zm0,0'
        fill={color ? color : isActive ? '#82BCFF' : '#FFF'}
        fillRule='evenodd'
        stroke='none'
      />
    </g>
  </SvgIcon>
);
