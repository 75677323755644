import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: '35vw',
    minWidth: '300px',
    maxWidth: '600px',
    height: 'auto',
    maxHeight: '90vh',
    borderRadius: '6px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  fullWidth: {
    width: '90vw',
    minWidth: '200px',
    maxWidth: '90vw',
  },
  autoWidth: {
    width: 'auto',
    maxWidth: 'none',
    maxHeight: '90vh',
  },
  loginModal: {
    overflowY: 'auto',
  },
  '@media screen and (min-height: 700px)': {
    loginModal: {
      overflowY: 'hidden',
    },
  },
}));

const TransitionsModal = ({
  open = false,
  setOpen,
  children,
  isLogin,
  fullWidth,
  autoWidth,
  disableBackdropClick,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      closeAfterTransition
      onClose={() => {
        !disableBackdropClick && handleClose();
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          className={clsx(
            classes.paper,
            isLogin && classes.loginModal,
            fullWidth && classes.fullWidth,
            autoWidth && classes.autoWidth
          )}
        >
          {children}
        </div>
      </Fade>
    </Modal>
  );
};
export default TransitionsModal;
