import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => {
  return {
    container: {
      minHeight: '80px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: palette.primary.dark,
      marginTop: -1,
      borderBottom: `1px ${palette.divider} solid`,
    },
    smallScreenContainer: {
      width: '100%',
      height: 80,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 15px',
      borderBottom: `1px solid ${palette.divider}`,
    },
    logoBar: {
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '170px',
      backgroundColor: palette.primary.dark,
      marginBottom: '-2px',
      cursor: 'pointer',
      boxSizing: 'border-box',
      '@media screen and (min-width: 700px)': {
        '&': {
          width: 220,
          justifyContent: 'flex-start',
          paddingLeft: 48,
        },
      },
      '@media screen and (min-width: 1001px)': {
        '&': {
          width: 259,
        },
      },
    },
    innerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flex: 1,
      width: '100%',
      paddingRight: '30px',
    },
    navItem: {
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      cursor: 'pointer',
      marginRight: 25,
      marginLeft: 15,
      padding: '0 10px',
      '&:hover $navHovered': {
        backgroundColor: '#F8F9FA',
      },
    },
    navHovered: {},
    rightRow: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    divider: {
      width: 1,
      height: 32,
      backgroundColor: palette.text.disabled,
    },
    navItemBorder: {
      borderBottom: `2px ${palette.primary.main} solid`,
      color: palette.primary.main,
      fontWeight: '600',
      boxSizing: 'border-box',
    },
    '@media (max-width: 945px)': {
      innerContainer: {
        width: '95%',
      },
    },
    '@media screen and (max-width: 560px)': {
      logoBar: {
        width: '100%',
      },
      innerContainer: {
        padding: 0,
      },
      rightRow: {
        flex: 1,
        justifyContent: 'flex-end',
        marginRight: 30,
      },
    },
  };
});
