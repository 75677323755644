import { connect } from 'react-redux';

import { initiateAutocomplete } from 'src/jobs/redux/actions';

import Autocomplete from './Autocomplete';

export default connect(
  (state, { name }) => ({
    langCode: state.locale.language,
    autocomplete: {
      isLoading: state.jobs.autocomplete.isLoading,
      result: state.jobs.autocomplete?.result?.[name],
    },
  }),
  { initiateAutocomplete }
)(Autocomplete);
