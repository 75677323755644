import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import ArrowLeft from 'src/img/icons/ArrowLeft';
import ArrowRight from 'src/img/icons/ArrowRight';
import { togglePrivacyPopup } from 'src/about/redux/actions';

import useStyles from './styles';
import { forUsers, forCustomers } from './constants';

const PrivacyPopup = ({
  isOpen,
  togglePrivacyPopup,
  modalType,
  isCustomer,
  onClose = () => {},
  isRtl,
  langCode,
}) => {
  const classes = useStyles();
  const [goBackToTerms, setGoBackToTerms] = useState(false);
  const [curModalType, setCurModalType] = useState(modalType);
  useEffect(() => {
    setCurModalType(modalType);
  }, [modalType]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        togglePrivacyPopup(null);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.card}>
          <div className={classes.cardHeader}>
            <div
              className={classes.modalButton}
              onClick={() => {
                if (goBackToTerms) {
                  togglePrivacyPopup(isCustomer, 'terms');
                  setGoBackToTerms(false);
                } else {
                  togglePrivacyPopup(null);
                  onClose();
                }
              }}
            >
              <div className={classes.arrow}>
                {isRtl ? <ArrowRight /> : <ArrowLeft />}
              </div>
              <FormattedMessage id="btns.back" defaultMessage="Back" />
            </div>
            <p>
              <FormattedMessage
                id={
                  curModalType ? `privacyTerms.title.${curModalType}` : 'noKey'
                }
                defaultMessage="privacyTerms.title.terms"
              />
            </p>
            <div
              className={classes.modalButton}
              onClick={() => {
                setCurModalType(
                  curModalType === 'terms' ? 'privacyPolicy' : 'terms'
                );
              }}
            >
              <FormattedMessage
                id="privacyTerms.opposite"
                defaultMessage="Go to {opposite}"
                values={{
                  opposite: (
                    <FormattedMessage
                      id={
                        curModalType === 'terms'
                          ? 'privacy.privacyPolicy'
                          : 'terms.termsOfUse'
                      }
                      defaultMessage="Go to {opposite}"
                    />
                  ),
                }}
              />
            </div>
          </div>
          <div className={classes.cardBody}>
            {curModalType && (
              <div
                dangerouslySetInnerHTML={{
                  __html: isCustomer
                    ? forCustomers[langCode][curModalType]
                    : forUsers[langCode][curModalType],
                }}
              />
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default connect(
  (state) => ({
    isOpen: !!state.about.isPrivacyPopupOpen,
    modalType: state.about.privacyModalType,
    isRtl: state.locale.dir === 'rtl',
    langCode: state.locale.language,
    isCustomer: state.about.isPrivacyPopupOpen === 'customer',
  }),
  { togglePrivacyPopup }
)(PrivacyPopup);
