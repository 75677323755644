import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const FETCH_TOP_DATA = createAsyncActionRequestTypes('FETCH_TOP_DATA');

export const fetchTopData = (attribute = 'companies') =>
  createApiAction(
    {
      url: '/top/:attribute',
      params: {
        attribute,
      },
    },
    FETCH_TOP_DATA
  );
