import {
  select,
  putResolve,
  all,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import queryString from 'query-string';
import { LOCATION_CHANGE } from 'connected-react-router';
import { redirect } from 'src/router/redux/actions';
import { replaceLangInPath, allLanguages } from 'src/intl/utils';
import { JOBS_PAGE_ARTICLES } from 'src/articles/constants';
import {
  getCardArticles,
  getFullArticles,
  setArticlesMeta,
  getAnalytics,
  INITIATE_ARTICLES_FETCH,
  INITIATE_UNIQUE_TOP_FETCH,
  autocompleteArticlesByField,
  AUTOCOMPLETE_INITITATED_ARTICLES,
  updateAutocompleteResults,
  updateAutocompleteLoading,
  setCurrentArticle,
  GET_ANALYTICS,
} from './actions';
import { navigateIntl } from 'src/utils/history';

// Worker saga:
export function* handleArticlesLoad() {
  const {
    articles: { meta }, // contains all prev values
    locale: { language = 'en' },
    router: { location },
  } = yield select((state) => state);
  const curPage = location.query?.page || 1;
  const curSide = location.pathname.includes('/customer') ? 'customer' : 'user';

  if (
    curPage == meta.curPage &&
    language == meta.curLangCode &&
    curSide == meta.curSide
  ) {
    return;
  }
  yield putResolve(
    getCardArticles({
      type: 'full',
      langCode: language,
      page: curPage,
      side: curSide,
      size: 11,
    })
  );

  yield put(setArticlesMeta({ curLangCode: language, curPage, curSide }));
}

export function* handleGetAnalytics({ payload }) {
  yield putResolve(getAnalytics(payload));
}

export function* ensureArticles({
  idOrArray,
  isCustomerLogged = false,
  downgrade = false,
}) {
  const { language, location, isLoading, article } = yield select((state) => ({
    language: state.locale.language,
    location: state.router.location,
    isLoading: state.articles.loading,
    article: state.articles.selected[0],
  }));

  if (article?.id == idOrArray)
    // id aleadry has been fetched!
    return;

  const curSide = location.pathname.includes('/customer') ? 'customer' : 'user';

  const ids = [].concat(idOrArray);
  const result = yield putResolve(
    getFullArticles({ articleId: ids, side: curSide, downgrade })
  );
  if (isLoading) return;

  if (!result || result.length === 0) {
    // Case I: got a connection error. Case II: article/s with that/those id/s was/were not found
    if (!location.pathname.includes('articles')) {
      // Do not redirect to notFound on pages like CVBuilder or Jobs!
      return;
    }
    yield put(
      redirect(
        replaceLangInPath(
          '/not-found',
          language !== allLanguages.en.language && language
        )
      )
    );
    return;
  }
  if (language !== result[0].langCode) {
    // an article was found, but had a different langCode
    const isCustomer = location.pathname.includes('/customer');
    // if error has been found
    if (result[0].error && result[0].url) {
      if (process.env.BROWSER) {
        navigateIntl(result[0].url);
      } else yield put(redirect(result[0].url, 301));
    } else {
      yield put(
        redirect(
          replaceLangInPath(
            !isCustomer
              ? `/articles`
              : isCustomerLogged
              ? `/customer/dashboard/articles`
              : `/customer/articles`,
            language !== allLanguages.en.language && language
          )
        )
      );
    }
  }
}

function* handleAutocomplete({ payload }) {
  const result = yield putResolve(autocompleteArticlesByField(payload));
  yield putResolve(
    updateAutocompleteResults(result?.list?.map((item) => item.title) || [])
  );
  yield put(updateAutocompleteLoading(false));
}

export function* getArticleSaga() {
  const {
    locale: { language },
    router: {
      location: { pathname, query, search },
    },
    articles: { currentArticle },
  } = yield select((state) => state);

  const searchParams = queryString.parse(search);

  // find the correct article
  let article = JOBS_PAGE_ARTICLES[language].find((article) => {
    if (Object.keys(article.query).length == Object.keys(searchParams).length) {
      if (
        Object.entries(searchParams).sort().toString() ===
        Object.entries(article.query).sort().toString()
      ) {
        return true;
      }
    }
    return false;
  });

  if (article && article.id) {
    if (!currentArticle || currentArticle?.id != article.id) {
      return yield putResolve(
        getFullArticles({
          articleId: [article.id],
          side: 'user',
          downgrade: true,
        })
      );
    }
  } else {
    return yield put(setCurrentArticle(null));
  }
}
function* analyticsFetchFail({ error: { status, error, redirectURL } = {} }) {
  switch (status) {
    case 301:
      if (redirectURL) return yield put(redirect(redirectURL, 301));
    default:
      break;
  }
}

// Watcher saga:
export default function* watchArticles(store) {
  yield all([
    takeLatest(AUTOCOMPLETE_INITITATED_ARTICLES, handleAutocomplete),
    takeEvery(INITIATE_ARTICLES_FETCH, handleArticlesLoad),
    takeEvery(INITIATE_UNIQUE_TOP_FETCH, handleGetAnalytics),
    takeLatest(GET_ANALYTICS.FAILURE, analyticsFetchFail),
    takeLatest(
      LOCATION_CHANGE,
      ({
        payload: {
          location: { pathname = '' },
        },
      }) => {
        if (pathname.includes('/articles')) {
          if (
            pathname.endsWith('/articles') ||
            pathname.endsWith('/articles/')
          ) {
            // articles page
            return handleArticlesLoad();
          } else {
            let parts = pathname.split('/');
            // article page
            return ensureArticles({
              idOrArray: parts[parts.length - 1],
              isCustomerLogged: pathname.includes('customer/dashboard'),
              downgrade: false,
            });
          }
        }
      }
    ),
  ]);
}
