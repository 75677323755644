import queryString from 'query-string';

import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

const BASE_URL = '/customer/user';
const HEADERS = {
  'Content-Type': 'application/json',
};

export const UPDATE_CUSTOMER_USERS = 'UPDATE_CUSTOMER_USERS';
export const CLEAR_CUSTOMER_USERS = 'CLEAR_CUSTOMER_USERS';
export const GET_CUSTOMER_USERS =
  createAsyncActionRequestTypes('GET_CUSTOMER_USERS');
export const GET_MANAGERS = createAsyncActionRequestTypes('GET_MANAGERS');
export const GET_CUSTOMER_FILTERED_USERS = createAsyncActionRequestTypes(
  'GET_CUSTOMER_FILTERED_USERS'
);
export const ADD_CUSTOMER_USER =
  createAsyncActionRequestTypes('ADD_CUSTOMER_USER');
export const EDIT_CUSTOMER_USER =
  createAsyncActionRequestTypes('EDIT_CUSTOMER_USER');
export const DELETE_CUSTOMER_USER = createAsyncActionRequestTypes(
  'DELETE_CUSTOMER_USER'
);

export const clearCustomerUsers = () => ({
  type: CLEAR_CUSTOMER_USERS,
});

export const updateCustomerUsers = (data) => ({
  type: UPDATE_CUSTOMER_USERS,
  payload: data,
});

export const getUsers = (
  { page = 1, ...params } = {},
  action = GET_CUSTOMER_USERS
) =>
  createApiAction(
    {
      url: `${BASE_URL}?${queryString.stringify(
        {
          ...params,
          page: page - 1,
        },
        { skipEmptyString: true }
      )}`,
      headers: HEADERS,
    },
    action
  );
  
export const FETCH_EMAILS = createAsyncActionRequestTypes('FETCH_EMAILS');

export const fetchEmails = (params) =>
  createApiAction(
    {
      url: `/customer/emails?${queryString.stringify(
        {
          ...params,
        },
        { skipEmptyString: true }
      )}`,
    },
    FETCH_EMAILS
  );

export const getManagers = (params) =>
  createApiAction(
    {
      url: `/customer/user/by-role?${queryString.stringify(params)}`,
      params,
    },
    GET_MANAGERS
  );

export const getFilteredUsers = (params) =>
  getUsers(params, GET_CUSTOMER_FILTERED_USERS);

export const crudAction = (params, method, action, url = BASE_URL) =>
  createApiAction(
    {
      method,
      url,
      headers: HEADERS,
      body: params,
    },
    action
  );

export const addUser = (params) =>
  crudAction(params, 'POST', ADD_CUSTOMER_USER);
export const updateUser = (params) =>
  crudAction(params, 'PATCH', EDIT_CUSTOMER_USER);
export const deleteUser = (params) =>
  crudAction(
    params,
    'DELETE',
    DELETE_CUSTOMER_USER,
    `${BASE_URL}/${params.id}`
  );

export const TOGGLE_USERS_MODAL = 'TOGGLE_USERS_MODAL';

export const toggleModal = (isOpen, modalType, itemId) => ({
  type: TOGGLE_USERS_MODAL,
  isOpen,
  modalType,
  itemId,
});

export const TOGGLE_INFO_MODAL = 'TOGGLE_INFO_MODAL';

export const toggleInfoModal = (isOpen) => ({
  type: TOGGLE_INFO_MODAL,
  isOpen,
});

export const TOGGLE_PASSWORD_MODAL = 'TOGGLE_PASSWORD_MODAL';

export const togglePasswordModal = (isOpen) => ({
  type: TOGGLE_PASSWORD_MODAL,
  isOpen,
});

export const CHANGE_PASSWORD_USER = createAsyncActionRequestTypes(
  'CHANGE_PASSWORD_USER'
);

export const changePasswordUser = (body) =>
  createApiAction(
    {
      method: 'PATCH',
      url: '/customer/user/me',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    },
    CHANGE_PASSWORD_USER
  );
