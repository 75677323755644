import React from 'react';

import RegistrationForm from 'src/onboarding/components/RegistrationForm';

import FirstForm from './Forms/FirstForm';
import SecondForm from './Forms/SecondForm';
import ThirdForm from './Forms/ThirdForm';
import FourthForm from './Forms/FourthForm';

const steps = [
  {
    fields: ['email', 'password', 'password2'],
    title: 'registrationForm.user.0.title',
  },
  {
    fields: ['firstName', 'lastName', 'jobTitle', 'experienceTitle'],
    title: 'registrationForm.user.1.title',
  },
  {
    fields: ['cv'],
    title: 'registrationForm.user.2.title',
  },
  {
    fields: [],
    title: 'registrationForm.user.3.title',
  },
];

export default (props) => {
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <FirstForm {...props} />;
      case 1:
        return <SecondForm {...props} />;
      case 2:
        return <ThirdForm {...props} />;
      case 3:
        return <FourthForm {...props} />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <RegistrationForm
      {...props}
      getStepContent={getStepContent}
      steps={steps}
      showSocialLogin
      type={'client'}
    />
  );
};
