import createReducer from 'src/utils/createReducer';

import {
  POST_NOTE,
  TOGGLE_LANDING_MODAL,
  TOGGLE_PRIVACY_POPUP,
  TOGGLE_CONTACT_MODAL,
} from './actions';

const INITIAL_STATE = {
  loading: false,
  isPrivacyPopupOpen: null,
  privacyModalType: null,
  landingModalOpen: false,
  contactModalOpen: false,
};

export default createReducer(INITIAL_STATE, {
  [POST_NOTE.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [POST_NOTE.SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [POST_NOTE.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [TOGGLE_PRIVACY_POPUP]: (state, { isOpen, modalType }) => ({
    ...state,
    isPrivacyPopupOpen: isOpen,
    privacyModalType: isOpen ? modalType : null,
  }),
  [TOGGLE_LANDING_MODAL]: (state, { isOpen }) => ({
    ...state,
    landingModalOpen: isOpen,
  }),
  [TOGGLE_CONTACT_MODAL]: (state, { isOpen }) => ({
    ...state,
    contactModalOpen: isOpen,
  }),
});
