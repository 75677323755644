import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { isRoot } from 'src/intl/utils';
import { unsubscribe } from 'src/user/redux/actions';

export default (enqueueSnackbar) => {
  const {
    router: {
      location: { pathname, query },
    },
    locale: { messages },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isRoot(pathname) && query.unsubscribe) {
      dispatch(unsubscribe(query.unsubscribe))
        .then((res) => {
          if (res.success) {
            enqueueSnackbar(messages['snackbar.success.unsubscribe'], {
              variant: 'success',
            });
            return history.replace(pathname);
          }
        })
        .catch((err) => {
          enqueueSnackbar(messages['snackbar.error.errorOccured'], {
            variant: 'error',
          });
          return history.replace(pathname);
        });
    }
  }, []);
};
