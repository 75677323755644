import createReducer from 'src/utils/createReducer';

import { FETCH_TOP_DATA } from 'src/top/redux/actions';

const INITIAL_STATE = {
  countries: {
    data: [],
    isLoading: false,
  },
  companies: {
    data: [],
    isLoading: false,
  },
};

export default createReducer(INITIAL_STATE, {
  [FETCH_TOP_DATA.REQUEST]: (state, { params: { attribute } }) => ({
    ...state,
    [attribute]: {
      ...state[attribute],
      data: [],
      isLoading: true,
    },
  }),
  [FETCH_TOP_DATA.FAILURE]: (state, { params: { attribute } }) => ({
    ...state,
    [attribute]: {
      ...state[attribute],
      isLoading: false,
    },
  }),
  [FETCH_TOP_DATA.SUCCESS]: (
    state,
    { params: { attribute }, data: { d } }
  ) => ({
    ...state,
    [attribute]: {
      ...state[attribute],
      data: d,
      isLoading: false,
    },
  }),
});
