import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, shadows }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: palette.background.paper,
    boxShadow: shadows[5],
    width: '35vw',
    minWidth: '400px',
    maxWidth: '600px',
    height: 'auto',
    maxHeight: '90vh',
    borderRadius: '6px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '40px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  checkboxDiv: {
    width: '310px',
    marginTop: '20px',
  },
  checkbox: {
    paddingLeft: 0,
  },
  textField: {
    width: '100%',
    maxWidth: 310,
  },
  select: {
    marginTop: 20,
  },
  cancelButton: {
    width: '310px',
    marginTop: '20px',
    height: '47px',
    borderRadius: '6px',
    border: 'none',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: palette.background.default,
    '&:hover': {
      boxShadow: '-3px 3px 7px 0 rgba(0,0,0,0.1)',
      backgroundColor: '#E2E6EA',
    },
  },
}));
