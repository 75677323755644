import {
  takeEvery,
  put,
  all,
  takeLatest,
  putResolve,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { getNavigationUrl, navigateIntl } from 'src/utils/history';
import {
  LOCAL_STORE_TOKEN,
  CUSTOMER_DASHBOARD_ROUTE,
} from 'src/customer/constants';
import { setItem, getItem, removeItem } from 'src/utils/localStorage';
import {
  toggleModal,
  setToken,
  CONNECT_USER,
  RENEW_TOKEN,
  saveExtraRedirect,
} from 'src/onboarding/redux/actions';
import { CLEAR_CUSTOMER_DATA } from 'src/customer/redux/actions/user';
import { redirect } from 'src/router/redux/actions';
import { initiateJobsFetch } from 'src/jobs/redux/actions';
import { clearCandidates } from 'src/candidates/redux/actions';
import { clearDepartments } from 'src/department/redux/actions';
import { clearCustomerUsers } from 'src/users/redux/actions';
import {
  clearCustomerJobs,
  clearViewedJob,
} from 'src/customerJobs/redux/actions';

import {
  REGISTER_CUSTOMER,
  LOGIN_CUSTOMER,
  LOG_OUT,
  toggleStatusMemo,
  clearCvPreview,
  clearInvoices,
  clearUser,
} from '../actions';

const REDIRECT_TO_DASHBOARD_URLS = [
  '/',
  '/my-profile',
  // '/customer',
  // '/customer/',
  '/articles',
  '/cv-builder',
];

function* goToDashboard() {
  yield put(push(getNavigationUrl(CUSTOMER_DASHBOARD_ROUTE)));
}

function* goToExtraRedirect(extraRedirect) {
  yield put(push(getNavigationUrl(extraRedirect)));
}

function* successRegSaga() {
  yield put(toggleModal(false));
  // yield showSuccessMessage()
}

export function* loginCustomerSaga({ data }) {
  yield setItem(LOCAL_STORE_TOKEN, data.token);
  yield put(setToken(data.token));

  const {
    onboarding: { extraRedirect },
    locale: { language },
  } = yield select();
  if (extraRedirect) {
    yield goToExtraRedirect(extraRedirect);
  } else {
    yield goToDashboard();
  }
  // if (data?.defaultLanguage && data?.defaultLanguage !== language) {
  //   yield put(setLanguage(data.defaultLanguage));
  // }
  if (data.status !== 'Active') {
    yield put(toggleStatusMemo(true));
  }
}

function* initCustomer() {
  if (process.env.BROWSER) return;

  const {
    onboarding: { isCustomer },
    router: {
      location: { pathname },
    },
  } = yield select();

  if (
    isCustomer &&
    !pathname.includes('/customer/dashboard') &&
    !pathname.includes('/not-found')
  ) {
    yield put(
      redirect(
        pathname.includes('/articles')
          ? `/customer/dashboard/articles${pathname.split('/articles')[1]}`
          : '/customer/dashboard/jobs-statistics'
      )
    );
  }
}

function* logoutSaga({ payload }) {
  yield removeItem(LOCAL_STORE_TOKEN);
  if (payload.extraRedirect) {
    yield put(saveExtraRedirect(payload.extraRedirect));
  }
  if (payload.side === 'customer') {
    yield put(push(getNavigationUrl('/customer')));
  } else {
    yield put(push(getNavigationUrl('/')));
    yield putResolve(initiateJobsFetch({}));
  }
}

function* clearCustomerSaga() {
  yield removeItem(LOCAL_STORE_TOKEN);
  yield put(clearCandidates());
  yield put(clearCvPreview());
  yield put(clearInvoices());
  yield put(clearUser());
  yield put(clearCustomerJobs());
  yield put(clearViewedJob());
  yield put(clearDepartments());
  yield put(clearCustomerUsers());
  setTimeout(() => {
    navigateIntl('/customer');
  }, 1500);
}

function* connectSuccessSaga({ data, params: { type } }) {
  const { t } = data;

  if (type === 'customer') {
    yield loginCustomerSaga({ data: { token: t } });
  }
}

function* renewTokenSaga({ token }) {
  const isCustomer = yield getItem(LOCAL_STORE_TOKEN);

  if (isCustomer) {
    yield setItem(LOCAL_STORE_TOKEN, token);
    yield put(setToken(token));
  }
}

function* successFetchSaga() {
  if (
    REDIRECT_TO_DASHBOARD_URLS.some(
      (e) => e.search(window.location.pathname) > -1
    )
  )
    yield goToDashboard();
}

export default function* () {
  yield initCustomer();

  yield all([
    takeEvery(REGISTER_CUSTOMER.SUCCESS, successRegSaga),
    takeEvery(LOGIN_CUSTOMER.SUCCESS, loginCustomerSaga),
    takeEvery(LOG_OUT, logoutSaga),
    takeEvery(CLEAR_CUSTOMER_DATA, clearCustomerSaga),
    takeLatest(CONNECT_USER.SUCCESS, connectSuccessSaga),
    takeLatest(RENEW_TOKEN, renewTokenSaga),
    // takeEvery(FETCH_CUSTOMER.SUCCESS, successFetchSaga),
  ]);
}
