import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ height, width, color }) => (
  <SvgIcon
    viewBox="0 0 612 612"
    style={{
      height: '28px',
      width: '28px',
      color: '#0041E5',
    }}
  >
    <g>
      <g id="menu">
        <g>
          <path d="M0,95.625v38.25h612v-38.25H0z M0,325.125h612v-38.25H0V325.125z M0,516.375h612v-38.25H0V516.375z" />
        </g>
      </g>
    </g>
  </SvgIcon>
);
