import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const UPLOAD_CV = createAsyncActionRequestTypes('UPLOAD_CV');

export const uploadCV = (body, headers) =>
  createApiAction(
    {
      method: 'POST',
      url: '/cv',
      body,
      headers,
    },
    UPLOAD_CV
  );

export const FETCH_USER_CV = createAsyncActionRequestTypes('FETCH_USER_CV');

export const fetchCV = () =>
  createApiAction(
    {
      url: '/cv',
    },
    FETCH_USER_CV
  );

export const DELETE_USER_CV = createAsyncActionRequestTypes('DELETE_USER_CV');

export const deleteUserCv = () =>
  createApiAction(
    {
      method: 'DELETE',
      url: '/cv',
    },
    DELETE_USER_CV
  );
