import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { all } from 'redux-saga/effects';
import {
  setCurrentJobIndex,
  initiateJobsFetch,
  toggleAlertModal,
} from 'src/jobs/redux/actions';
import { getJobsSaga } from 'src/jobs/redux/sagas';
import resolveRoute from 'src/utils/resolveRoute';
import { toggleModal } from 'src/onboarding/redux/actions';
import Layout from 'src/layout/components/Layout';
import wrapIntoLayout from 'src/utils/wrapIntoLayout';
import { getMeSaga } from 'src/user/redux/sagas/user';
import { trackJobAction } from 'src/analytics/redux/actions';
import { navigateIntl } from 'src/utils/history';
import { getArticleSaga } from 'src/articles/redux/sagas';
import Jobs from './Jobs';

export default compose(
  wrapIntoLayout(Layout),
  resolveRoute(function* () {
    yield all([getMeSaga(), getJobsSaga(), getArticleSaga()]);
  }),
  withSnackbar,
  withRouter,
  injectIntl,
  connect(
    (state, props) => ({
      jobs: state.jobs.jobs,
      isLogged: !!state.user.profile.confirmed,
      isCleanQuery: props.location.search === '',
      query: queryString.parse(props.location.search),
      resolvedOnSSR: state.jobs.jobs.resolvedOnSSR,
      dir: state.locale.dir,
      pathname: state.router.location.pathname,
      currentArticle: state.articles.currentArticle,
    }),
    {
      setCurrentJobIndexD: setCurrentJobIndex,
      toggleLoginModal: toggleModal,
      initiateJobsFetch,
      toggleAlertModal,
      trackJobAction,
    },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      goTo: (k, v) => {
        k === 'page' && window.scrollTo(0, 0);
        const params = {
          ...stateProps.query,
          [k]: v,
        };

        // if page requested = 1 => ignore it and remove the params
        if (params?.page === 1) delete params.page;

        dispatchProps.initiateJobsFetch(params);
        navigateIntl(
          `/?${queryString.stringify(params, { skipEmptyString: true })}`
        );
      },
    })
  )
)(Jobs);
