import { connect } from 'react-redux';

import FBLogin from './FBLogin';

export default connect((state) => ({
  ...state.locale,
  redirectBackUrl:
    state.router.location.query?.redirectUrl || state.router.location.pathname,
  templateName: state?.cvBuilder?.cvData?.templateName,
}))(FBLogin);
