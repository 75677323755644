import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ className, color, size, thickness }) => (
  <SvgIcon
    viewBox="0 0 12 12"
    style={{
      height: size || '12px',
      width: size || '12px',
    }}
    className={className}
  >
    <path
      d="M6 0L5.64645 5.64645L0 6"
      transform="matrix(-0.7071068 -0.7071067 -0.7071067 0.7071068 9.900391 5.657227)"
      id="Path"
      fill="none"
      stroke={color || '#0041E5'}
      strokeWidth={thickness || '2'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
