import React from 'react';

import Departments from 'src/img/icons/menu/Departments';
import Users from 'src/img/icons/menu/Users';
import Job from 'src/img/icons/menu/Job';
import Jobs from 'src/img/icons/menu/Jobs';
import Candidates from 'src/img/icons/menu/Candidates';
import Reports from 'src/img/icons/menu/Reports';
import License from 'src/img/icons/menu/License';
import Company from 'src/img/icons/menu/Company';
import Admin from 'src/img/icons/menu/Admin';
import Insights from 'src/img/icons/menu/Insights';

import { USER_ROLES_MAP } from 'src/utils/constants';

export default [
  {
    key: 'insights',
    path: '/customer/dashboard/jobs-statistics',
    // path: '/customer/dashboard/insights',
    label: 'links.insights',
    renderIcon: (isActive) => <Insights isActive={isActive} />,
    // conditionRoles: [USER_ROLES_MAP.expointAdmin],
  },
  {
    key: 'profile',
    path: '/customer/dashboard/details',
    label: 'links.company',
    renderIcon: (isActive) => <Company isActive={isActive} />,
    className: 'mt3',
  },
  {
    key: 'departments',
    path: '/customer/dashboard/departments',
    label: 'links.departments',
    renderIcon: (isActive) => <Departments isActive={isActive} />,
    className: 'mt2',
  },
  {
    key: 'users',
    path: '/customer/dashboard/users',
    label: 'links.users',
    renderIcon: (isActive) => <Users isActive={isActive} />,
    className: 'mt3',
  },
  {
    key: 'postJob',
    path: '/customer/dashboard/postjob',
    label: 'links.postJobs',
    renderIcon: (isActive) => <Job isActive={isActive} />,
    className: 'mt3',
    conditionRoles: [
      USER_ROLES_MAP.admin,
      USER_ROLES_MAP.departmentAdmin,
      USER_ROLES_MAP.editor,
      USER_ROLES_MAP.departmentEditor,
      USER_ROLES_MAP.expointAdmin,
    ],
  },
  {
    key: 'jobs',
    path: '/customer/dashboard/jobs',
    label: 'jobs.wordJobs',
    renderIcon: (isActive) => <Jobs isActive={isActive} />,
  },
  {
    key: 'candidates',
    label: 'links.candidates',
    path: '/customer/dashboard/my-candidates',
    renderIcon: (isActive) => <Candidates isActive={isActive} />,
    onlyStandard: true,
  },
  {
    path: '/customer/dashboard/my-candidates',
    label: 'links.myCandidates',
    subLink: true,
    key: 'my-candidates',
    renderIcon: (isActive) => <Candidates isActive={isActive} />,
  },
  {
    path: '/customer/dashboard/candidates',
    label: 'links.allSiteCandidates',
    subLink: true,
    onlyStandard: true,
    key: 'all-candidates',
    renderIcon: (isActive) => <Candidates isActive={isActive} />,
  },
  // {
  //   key: 'reports',
  //   path: '/customer/dashboard/reports',
  //   label: 'links.reports',
  //   renderIcon: (isActive) => <Reports isActive={isActive} />,
  //   className: 'mt3',
  //   conditionRoles: [
  //     'ADMIN',
  //     'DEPARTMENT_ADMIN',
  //     'EDITOR',
  //     'DEPARTMENT_EDITOR',
  //   ],
  // },
  {
    key: 'billing',
    path: '/customer/dashboard/billing',
    label: 'links.licenseAndBilling',
    renderIcon: (isActive) => <License isActive={isActive} />,
    conditionRoles: [USER_ROLES_MAP.admin, USER_ROLES_MAP.expointAdmin],
  },
  {
    key: 'admin',
    path: '/customer/dashboard/admin',
    label: 'links.admin',
    renderIcon: (isActive) => <Admin isActive={isActive} />,
    conditionRoles: [USER_ROLES_MAP.expointAdmin],
  },
];
