import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  inputContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  icon: {
    position: 'absolute',
    borderRadius: 6,
    right: 15,
    width: 25,
  },
  errorText: {
    fontSize: 14,
    marginLeft: 0,
  },
}));

export default ({ iconStyle = {}, containerClassName, ...props }) => {
  const classes = useStyles();
  const [isVisible, setVisibility] = useState(false);
  const dir = useSelector((state) => state.locale.dir);

  return (
    <div className={clsx(classes.inputContainer, containerClassName)}>
      <TextField
        {...props}
        type={isVisible ? 'text' : 'password'}
        inputProps={{
          ...props.inputProps,
          style: {
            paddingLeft: dir === 'ltr' ? 14 : 45,
            paddingRight: dir === 'ltr' ? 45 : 14,
          },
        }}
        FormHelperTextProps={{
          className: classes.errorText,
        }}
      />
      <div className={classes.icon} style={iconStyle}>
        {isVisible ? (
          <Visibility onClick={() => setVisibility(false)} />
        ) : (
          <VisibilityOff onClick={() => setVisibility(true)} />
        )}
      </div>
    </div>
  );
};
