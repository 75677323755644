import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import LanguageFlag from 'src/img/icons/LanguageFlag';
import { setLanguage } from 'src/intl/redux/actions';
import { allLanguages, langArr } from 'src/intl/constants';
import { replaceLangInPath } from 'src/intl/utils';
import queryString from 'query-string';
import { navigateIntl } from 'src/utils/history';

const useStyles = makeStyles(({ palette }) => ({
  label: {
    fontSize: 14,
    color: palette.primary.dark,
    marginTop: -2,
    marginRight: 7,
    marginLeft: 7,
  },
  control: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    border: `1px ${palette.divider} solid`,
    marginRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  flagsDropDown: {
    position: 'absolute',
    display: 'none',
    flexDirection: 'column',
    right: 8,
    zIndex: 100,
    top: -5,
    padding: 4,
    gap: 3,
    backgroundColor: '#e2e8f0',
    borderRadius: 4,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
    transform: 'translateY(50%)',
  },

  languageItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '1px 7px',
    transition: 'all 0.1s ease-in-out',
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 600,
    color: '#4E5C73',
    '&:hover': {
      backgroundColor: '#d0d9e6',
    },
  },
  selectedLang: {
    color: 'white',
    backgroundColor: '#94a3b8',
    '&:hover': {
      backgroundColor: '#94a3b8',
    },
  },
  label: {
    margin: 10,
  },
}));

const LanguageMenu = ({
  locale: { language },
  setLanguage,
  currentPathname,
  query = '',
  queryObject = queryString.parse(query) || {},
}) => {
  const classes = useStyles();

  const buildHrefUrl = (selectedLang) => {
    const path = currentPathname === '' ? '/' : currentPathname;
    const isHomePage =
      replaceLangInPath(path, '') === '/' || replaceLangInPath(path, '') === '';

    if (isHomePage) {
      // remove location when moving from lang to lang if language is exact as default
      if (!query) {
        return replaceLangInPath(
          path,
          selectedLang !== 'en' ? selectedLang : ''
        );
      } else {
        let tempQueryObject = { ...queryObject };
        // url contains query
        if (!queryObject.location && language !== selectedLang) {
          tempQueryObject.location = allLanguages[language].defaultLocation;
        } else if (
          tempQueryObject.location?.trim()?.toLowerCase() ===
          allLanguages[selectedLang].defaultLocation
        )
          delete tempQueryObject.location;

        return replaceLangInPath(
          `${path}${
            Object.keys(tempQueryObject).length > 0
              ? '?' + queryString.stringify(tempQueryObject)
              : ''
          }`,
          selectedLang !== 'en' ? selectedLang : ''
        );
      }
    }

    return replaceLangInPath(
      path + query,
      selectedLang !== 'en' ? selectedLang : ''
    );
  };

  const getHrefUrl = (selectedLang) => {
    let result = buildHrefUrl(selectedLang);

    // Validate URL structure
    if (!result.startsWith('/')) {
      result = `/${result}`;
    }

    return result;
  };

  const changeLanguageHandler = (e, language) => {
    e.preventDefault();
    e.stopPropagation();

    // set new language
    setLanguage(language);

    // redirect to new url
    navigateIntl(replaceLangInPath(getHrefUrl(language), '') || '/');
  };

  return (
    <div className={classes.flagsDropDown}>
      {langArr
        .sort((a, b) =>
          a.language === language
            ? -1
            : b.language === language
            ? 1
            : a.language.localeCompare(b.language)
        )
        .map((item) => (
          <a
            href={getHrefUrl(item.language)}
            className={clsx(
              classes.languageItem,
              item.language === language && classes.selectedLang
            )}
            onClick={(e) => changeLanguageHandler(e, item.language)}
            key={item.language}
          >
            <LanguageFlag size={30} type={item.language} />
            <div className={classes.label}>{item.langName}</div>
          </a>
        ))}
    </div>
  );
};

export default connect(
  (state, props) => ({
    locale: state.locale,
    currentPathname: state.router.location.pathname,
    query: state.router.location.search,
  }),
  { setLanguage }
)(LanguageMenu);
