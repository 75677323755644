import createReducer from 'src/utils/createReducer';
import { LOG_OUT } from 'src/customer/redux/actions';

import {
//   TOGGLE_COMPLETE_CUSTOMER_REG_MODAL,
  TOGGLE_CUSTOMER_MODAL_LOADING,
} from '../actions/test';

const INITIAL_STATE = {
  loading: false,
};

export default createReducer(INITIAL_STATE, {
  [LOG_OUT]: () => INITIAL_STATE,
//   [TOGGLE_COMPLETE_CUSTOMER_REG_MODAL]: (state, { isOpen }) => ({
//     ...state,
//     isCompleteRegModal: isOpen,
//   }),
  [TOGGLE_CUSTOMER_MODAL_LOADING]: (state, { isLoading }) => ({
    ...state,
    loading: isLoading,
  }),
});
