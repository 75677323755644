import React, { useState, useRef, useEffect } from 'react';
import { getNavigationUrl } from 'src/utils/history';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import makeSalary from 'src/utils/salary';
// import queryString from 'query-string';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import moment from 'moment';
import expointLogo from 'src/img/expoint-logo.jpg';

// classes
import useStyles from './styles';

// code splitting
import loadable from '@loadable/component';
const JobSlider = loadable(() => import('src/jobs/components/JobSlider'));
const CvBannerAd = loadable(() => import('../CvBannerAd/CvBannerAd'));
const AddCVModal = loadable(() => import('src/user/components/AddCVModal'));
const SharePopover = loadable(() => import('src/jobs/components/SharePopover'));
const Share = loadable(() => import('src/img/icons/Share'));
const Heart = loadable(() => import('src/img/icons/Heart'));
const Location = loadable(() => import('src/img/icons/Location'));
const Calendar = loadable(() => import('src/img/icons/Calendar'));
const Suitcase = loadable(() => import('src/img/icons/Suitcase'));

const JobDesc = ({
  hideJobLink = false,
  fromSalary,
  toSalary,
  currency,
  applyForJobD,
  saveJobD,
  deleteSavedJobD,
  isLogged,
  toggleLoginModal,
  isHidden,
  inModal,
  loading,
  saveLoading,
  toggleCVModal,
  isSharedJob,
  enqueueSnackbar,
  profile: { cv, country, city, jobTitle },
  savedJobs,
  similarJobs,
  appliedJobs,
  onCompanyNameClick = () => {},
  getHighlights,
  highlights,
  companyLinkEnabled,
  isRtl,
  langCode,
  intl,
  trackJobAction,
  query,
  shortDesc = false,
  isDerectLink = true,
  ...job
}) => {
  useEffect(() => {
    if (isDerectLink) {
      trackJobAction(job.id, {
        jobTitleSearch: '',
        companyNameSearch: '',
      });
    }
  }, [isDerectLink]);

  const isMobile = useMediaQuery('(max-width:900px)');
  const classes = useStyles();
  const [sharePopoverOpen, setSharePopoverOpen] = useState(false);
  const isApplied = appliedJobs ? appliedJobs.includes(job.id) : false;
  const applyDisabled = (job.source === 'expoint' && !isLogged) || isApplied;
  const canUserApply = cv && country && city && jobTitle;
  const anchor = useRef(null);
  const isSaved = savedJobs ? savedJobs.includes(job.id) : false;
  const rtlChars = /[\u0590-\u05FF]/gm;
  const isDescRtl = rtlChars.test(job.desc_html || job.text_summary);
  const showLogo = job.companyLogo || !job.showCompanyName;
  const makeDate = (timestamp) => {
    // const today = moment().format('DD.MM.YYYY'),
    //   yesterday = moment().subtract(1, 'days').format('DD.MM.YYYY'),
    //   date = moment(timestamp).format('DD.MM.YYYY');
    const currentDate = new Date();

    const today = currentDate.toLocaleDateString('he-IL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    const yesterdayFormatted = yesterday.toLocaleDateString('he-IL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const date = new Date(timestamp).toLocaleDateString('he-IL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return date === today
      ? 'Today'
      : date === yesterdayFormatted
      ? 'Yesterday'
      : date;
  };
  const goToJobPageHandler = () => {
    if (!isLogged) toggleLoginModal(true, 'isLoginClientOpen');
    else if (canUserApply) {
      // trackApplying();
      if (job.link) {
        // applying to NOT expoint job
        applyForJobD(job.id);
      }
    } else {
      toggleCVModal(true, onApply);
    }
  };
  const onSave = () => {
    if (saveLoading) return;
    return isSaved ? deleteSavedJobD(job.id) : saveJobD(job.id);
  };
  const onApply = () => {
    if (isApplied || loading) return;
    if (isLogged) {
      if (!job.link) {
        // this is expoint job post, go and apply!
        applyForJobD(job.id);
      }
      // trackApplying();
    } else {
      toggleLoginModal(true, 'isLoginClientOpen');
    }
  };
  const trackApplying = () => {
    if (isSharedJob)
      trackJobAction(job.id, {
        eventType: 'APPLIED',
      });
    else
      trackJobAction(job.id, {
        eventType: 'APPLIED',
        jobTitleSearch: query?.title,
        companyNameSearch: query?.companyName,
      });
  };

  const applyButton = (
    <div
      className={clsx(
        classes.applyButton,
        classes.button,
        (applyDisabled || loading) && classes.disabledButton
      )}
      tabIndex='0'
      onKeyDown={(e) =>
        e.keyCode === 13 &&
        (!isLogged || canUserApply ? onApply() : toggleCVModal(true, onApply))
      }
      onClick={() =>
        !isLogged || canUserApply ? onApply() : toggleCVModal(true, onApply)
      }
    >
      {loading ? (
        <FormattedMessage id='jobs.loading' defaultMessage='Loading...' />
      ) : isLogged ? (
        <FormattedMessage id='jobs.applyNow' defaultMessage='Apply now' />
      ) : (
        <FormattedMessage
          id='jobs.loginToApply'
          defaultMessage='Please log in to apply'
        />
      )}
    </div>
  );

  return (
    <>
      <div
        className={clsx(
          classes.containerColumn,
          isHidden && classes.dNone,
          isSharedJob && classes.heightNone
        )}
      >
        <div className={classes.container}>
          <picture
            className={clsx(
              inModal || shortDesc ? classes.imageModal : classes.imageTop
            )}
          >
            <source
              srcSet={require(`src/img/job_desc.webp`)}
              type='image/webp'
            />
            <img
              loading='lazy'
              alt={intl.formatMessage({
                id: 'helmet.about.jobDesc',
                defaultMessage:
                  'Limitless High-tech career opportunities - Expoint',
              })}
              className={clsx(
                inModal || shortDesc ? classes.imageModal : classes.imageTop
              )}
              src={require(`src/img/job_desc.jpg`)}
            />
          </picture>
          <div
            className={clsx(
              classes.content,
              isSharedJob && classes.heightNone,
              shortDesc && classes.shortDescContent
            )}
          >
            <div className={classes.descRow}>
              {!shortDesc && (
                <div className={classes.titleCol}>
                  {showLogo && job.companyLogo && isSharedJob ? (
                    <div className={classes.logoDiv}>
                      <img
                        src={
                          job.showCompanyName ? job.companyLogo : expointLogo
                        }
                        className={classes.companyLogo}
                        alt='Company logo'
                        loading='lazy'
                      />
                    </div>
                  ) : null}
                  <div className={classes.jobInfo}>
                    {isSharedJob ? (
                      <h1>
                        <span className={classes.companyName}>
                          {job.showCompanyName ||
                          job.showCompanyName == undefined
                            ? job.companyName
                            : 'Expoint'}
                          &nbsp;
                        </span>

                        <span className={classes.positionName}>
                          {job.title}&nbsp;
                        </span>
                        <span className={classes.additionalInfo}>
                          <div className={classes.jobInfoIcon}>
                            <Location height={20} />
                          </div>
                          {job.country
                            ? job.country +
                              (job.state ? ', ' + job.state : '') +
                              (job.city ? ', ' + job.city : '')
                            : ''}
                          &nbsp;
                        </span>
                        <span className={classes.jobId}>
                          <div className={classes.jobInfoIcon}>
                            <Suitcase height={'15px'} width={'20px'} />
                          </div>
                          {job.id}
                        </span>
                      </h1>
                    ) : (
                      <Link
                        target='_blank'
                        to={getNavigationUrl(`/job/${job.id}`)}
                      >
                        <span className={classes.companyName}>
                          {job.showCompanyName ||
                          job.showCompanyName == undefined
                            ? job.companyName
                            : 'Expoint'}
                        </span>
                        <span className={classes.positionName}>
                          {job.title}
                        </span>
                        <span className={classes.additionalInfo}>
                          <div className={classes.jobInfoIcon}>
                            <Location height={20} />
                          </div>
                          {job.country
                            ? job.country +
                              (job.state ? ', ' + job.state : '') +
                              (job.city ? ', ' + job.city : '')
                            : ''}
                        </span>
                        <span className={classes.jobId}>
                          <div className={classes.jobInfoIcon}>
                            <Suitcase height={'15px'} width={'20px'} />
                          </div>
                          {job.id}
                        </span>
                      </Link>
                    )}
                    <div className={classes.date}>
                      <div className={classes.jobInfoIcon}>
                        <Calendar size={18} />
                      </div>
                      {job.createdAt ? makeDate(job.createdAt) : ''}
                    </div>
                    {job?.seniority?.length > 0 && (
                      <div className={classes.senioritiesContainer}>
                        <div className={classes.jobSeniorities}>
                          {job?.seniority.map((seniority, index) => (
                            <p className={classes.seniorityItem} key={index}>
                              {intl.formatMessage({
                                id: `inputText.seniority.${seniority}`,
                              })}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                    {job.showSalary
                      ? React.createElement(
                          isSharedJob ? 'h3' : 'span',
                          {
                            className: classes.additionalInfo,
                          },
                          makeSalary(fromSalary, toSalary, currency)
                        )
                      : null}
                  </div>
                </div>
              )}
              <div
                className={clsx(
                  classes.buttons,
                  showLogo && classes.buttonsLogo
                )}
              >
                <div
                  ref={anchor}
                  className={clsx(
                    classes.shareButton,
                    classes.button,
                    showLogo && classes.buttonWithLogo,
                    sharePopoverOpen && classes.shareButtonPopoverOpen
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSharePopoverOpen(true);
                  }}
                  onKeyDown={(e) =>
                    e.keyCode === 13 && setSharePopoverOpen(true)
                  }
                  tabIndex='0'
                >
                  <Share
                    style={{ marginRight: isMobile ? 5 : 10 }}
                    size={isMobile ? 15 : 17}
                    color='#00a085'
                  />
                  <p>
                    <FormattedMessage id='jobs.share' defaultMessage='Share' />
                  </p>
                  <SharePopover
                    open={sharePopoverOpen}
                    anchorEl={anchor.current}
                    closeMenu={() => setSharePopoverOpen(false)}
                    jobId={job.id}
                    job={job}
                    enqueueSnackbar={enqueueSnackbar}
                    intl={intl}
                  />
                </div>
                {isLogged ? (
                  <div
                    className={clsx(
                      classes.saveButton,
                      classes.button,
                      saveLoading && classes.disabledButton
                    )}
                    onClick={onSave}
                    onKeyDown={(e) => e.keyCode === 13 && onSave()}
                    tabIndex='0'
                  >
                    {saveLoading ? (
                      <FormattedMessage
                        id='jobs.loading'
                        defaultMessage='Loading...'
                      />
                    ) : isSaved ? (
                      <FormattedMessage
                        id='jobs.unsave'
                        defaultMessage='Unsave'
                      />
                    ) : (
                      <>
                        <div className={classes.heartIcon}>
                          <Heart width={17} height={15} />
                        </div>
                        <FormattedMessage
                          id='btns.save'
                          defaultMessage='Save'
                        />
                      </>
                    )}
                  </div>
                ) : null}
                {(!hideJobLink || isSaved) &&
                  (job.source === 'expoint' ? (
                    applyButton
                  ) : (
                    <a
                      href={isLogged && canUserApply ? job.link : '#'}
                      rel={
                        isLogged && canUserApply ? 'noopener noreferrer' : ''
                      }
                      onClick={goToJobPageHandler}
                      target={isLogged && canUserApply ? '_blank' : ''}
                      className={clsx(
                        classes.button,
                        classes.applyButton,
                        classes.linkText,
                        !isLogged && classes.disabledButton
                      )}
                    >
                      {isLogged ? (
                        <FormattedMessage
                          id='jobs.goToJobPage'
                          defaultMessage='Go to job page'
                        />
                      ) : (
                        <FormattedMessage
                          id='jobs.loginForJobPage'
                          defaultMessage='Log in to apply'
                        />
                      )}
                    </a>
                  ))}
              </div>
            </div>
            <div
              className={clsx(
                classes.detailsRow,
                showLogo && classes.detailsWLogo
              )}
            >
              <div className={classes.tabsDiv}>
                <div>
                  <div className={classes.descLink}>
                    {!isSharedJob ? (
                      <div
                        className={clsx(
                          classes.expointJobDesc,
                          ((isDescRtl && !isRtl) || (!isDescRtl && isRtl)) &&
                            classes.dirRtl
                        )}
                      >
                        <b>
                          <FormattedMessage
                            id='jobPreviewModal.description'
                            defaultMessage='Description:'
                          />
                        </b>
                        <div className={classes.summaryContainer}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: job.text_summary,
                            }}
                          />
                          <div className={classes.summaryShadow} />
                        </div>
                        <Link
                          target={isMobile ? '' : '_blank'}
                          to={getNavigationUrl(`/job/${job.id}`)}
                          className={clsx(
                            classes.applyButton,
                            classes.viewMore,
                            classes.button,
                            classes.linkText
                          )}
                        >
                          <FormattedMessage
                            id='jobs.viewMore'
                            defaultMessage='View full job details'
                          />
                        </Link>
                      </div>
                    ) : (
                      <div
                        className={clsx(
                          classes.description,
                          ((isDescRtl && !isRtl) || (!isDescRtl && isRtl)) &&
                            classes.dirRtl
                        )}
                        dangerouslySetInnerHTML={{
                          __html: job.desc_html,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.adsWrapper}>
              {similarJobs && similarJobs.length > 0 && !isMobile && (
                <JobSlider hideLinkButton jobs={similarJobs} />
              )}
              {!shortDesc && (
                <div className={classes.cvBannerWrapper}>
                  <CvBannerAd intl={intl} />
                </div>
              )}
            </div>
          </div>
        </div>
        <AddCVModal />
      </div>
    </>
  );
};

export default JobDesc;
