import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { postNote, toggleContactModal } from 'src/about/redux/actions';
import resolveRoute from 'src/utils/resolveRoute';
import { getMeSaga } from 'src/user/redux/sagas/user';

import ContactModal from './ContactModal';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  email: Yup.string().required('required').email('invalid'),
  phone: Yup.string()
    .required('required')
    .matches(/^\+\d{8,}$/, 'invalid'),
  message: Yup.string().required('required'),
});

export default compose(
  resolveRoute(function* () {
    yield getMeSaga();
  }),
  withSnackbar,
  connect(
    (state, props) => ({
      loading: state.about.loading,
      messages: state.locale.messages,
      open: state.about.contactModalOpen,
    }),
    {
      postNote,
      toggleContactModal,
    }
  ),
  injectIntl,
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      email: '',
      phone: '',
      message: '',
    }),
    validationSchema,
    displayName: 'ContactForm',
    handleSubmit: async (
      val,
      {
        resetForm,
        props: { postNote, enqueueSnackbar, messages, toggleContactModal },
      }
    ) => {
      const res = await postNote(val);

      if (res.error || res.errors) {
        return enqueueSnackbar(messages['snackbar.error.errorOccured'], {
          variant: 'error',
        });
      }

      resetForm();
      toggleContactModal(false);

      return enqueueSnackbar(messages['snackbar.success.msgSent'], {
        variant: 'success',
      });
    },
  })
)(ContactModal);
