import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ isActive }) => (
  <SvgIcon
    viewBox="0 0 22 17"
    style={{
      height: '17px',
      width: '22px',
      marginLeft: '-1px',
    }}
  >
    <g id="user-plus" transform="translate(0.4765625 0.4765625)">
      <path
        d="M10.9581 3.8115C10.9581 1.70647 9.2516 0 7.14656 0C5.04153 0 3.33506 1.70647 3.33506 3.8115C3.33506 5.91653 5.04153 7.623 7.14656 7.623C9.2516 7.623 10.9581 5.91653 10.9581 3.8115ZM17.7779 3.56648L17.777 6.425L20.6366 6.4251L20.6366 7.9497L17.777 7.949L17.7779 10.8083L16.2533 10.8083L16.253 7.949L13.3947 7.9497L13.3947 6.4251L16.253 6.425L16.2533 3.56648L17.7779 3.56648ZM14.2882 13.5253C14.1861 11.5114 12.5209 9.9099 10.4816 9.9099L3.8115 9.9099L3.61536 9.91486C1.60148 10.0169 0 11.6821 0 13.7214L0 15.6271L14.2931 15.6271L14.2931 13.7214L14.2882 13.5253Z"
        id="Shape"
        fill={isActive ? '#82BCFF' : '#FFF'}
        fillRule="evenodd"
        stroke="none"
      />
    </g>
  </SvgIcon>
);
