import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { withFormik } from 'formik';
import { navigateIntl } from 'src/utils/history';
import * as Yup from 'yup';

import { login } from 'src/user/redux/actions';
import { toggleModal } from 'src/onboarding/redux/actions';
import { execute } from 'src/utils/captcha';
import { initiateJobsFetch } from 'src/jobs/redux/actions';
import { getItem } from 'src/utils/localStorage';
import { LOGIN_FROM_CV_BUILDER } from 'src/cv/redux/sagas';
import { dataLayerPush } from 'src/analytics/ga';
import { setRedirectUrl } from 'src/onboarding/redux/actions';

import Login from './Login';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('invalid').required('required'),
  password: Yup.string()
    .required('required')
    .min(8, 'minChars')
    .test('contains non-english chars', 'onlyEnglishAllowed', (value) =>
      /^[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]+$/.test(
        value
      )
    ),
});

export default compose(
  withSnackbar,
  withRouter,
  connect(
    (state) => ({
      loading: state.user.loading,
      cvData: state.cvBuilder.cvData,
      loginFromCvBuilder: getItem(LOGIN_FROM_CV_BUILDER),
      messages: state.locale.messages,
      langCode: state.locale.language,
      redirectUrl: state.onboarding.redirectUrl,
    }),
    {
      loginUser: login,
      toggleLoginModal: toggleModal,
      initiateJobsFetch,
      setRedirectUrl,
      navigateIntl,
    }
  ),
  injectIntl,
  withFormik({
    mapPropsToValues: ({ loginFromCvBuilder, cvData }) => ({
      email: loginFromCvBuilder ? cvData.email : '',
      password: '',
      remember: false,
    }),
    displayName: 'LoginUserForm',
    validationSchema,
    handleSubmit: async (
      val,
      {
        setFieldValue,
        props: {
          loginUser,
          enqueueSnackbar,
          setRedirectUrl,
          toggleLoginModal,
          initiateJobsFetch,
          redirectUrl,
          messages,
          navigateIntl,
        },
      }
    ) => {
      const captchaToken = await execute({
        action: 'submit',
      });

      const { error } = await loginUser({
        ...val,
        captchaToken,
      });

      if (error) {
        if (error === 'WRONG_CREDENTIALS') {
          setFieldValue('password', '');
          return enqueueSnackbar(messages['snackbar.error.loginFailed'], {
            variant: 'error',
          });
        }

        if (error === 'NOT_VERIFIED') {
          return enqueueSnackbar(messages['snackbar.error.unverifiedEmail'], {
            variant: 'error',
          });
        }

        return enqueueSnackbar(messages['snackbar.error.somethingWentWrong'], {
          variant: 'error',
        });
      }

      initiateJobsFetch({});
      toggleLoginModal(false);
      dataLayerPush('login_succeed', {
        method: 'email',
      });

      //check if there is redirectUrl
      if (redirectUrl) {
        navigateIntl(redirectUrl);
        //reset redirectUrl
        setRedirectUrl(null);
      }
    },
  })
)(Login);
