import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import clsx from 'clsx';
import { VariableSizeList as List } from 'react-window';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import FilterItem from '../JobFilters/FilterItem';
import CheckboxInput from 'src/input/components/CheckboxInput';
import useStyles from './styles';

import {
  DEFAULT_ITEM_HEIGHT,
  DEFAULT_SEARCH_HEIGHT,
  MAX_ITEM_SIZE_JOB_TITLE,
} from '../../utils/constants';
import {
  measureTextWidth,
  customSort,
  getWindowHeight,
} from '../../utils/functions';

const AsyncTypeahead = withAsync(Typeahead);

export default ({
  handleSubmit,
  intl,
  jobTitleQuery: selectedJobTitle = '',
  jobTitlesList = [],
  jobTitleCategory,
  fetchJobTitlesAutocomplete,
  dropdownMenu = false,
  openFilter,
  setOpenFilter,
}) => {
  const classes = useStyles();
  const listRef = useRef({});
  const rowHeights = useRef({});
  const anchorRef = useRef(null);
  const isMenuOpen = openFilter === 'jobTitle';
  // const [filteredJobTitles, setFilteredJobTitles] = useState(jobTitlesList);
  const [searchJobTitle, setSearchJobTitle] = useState('');
  const [textWidths, setTextWidths] = useState([]);
  const [windowHeight, setWindowHeight] = useState(DEFAULT_SEARCH_HEIGHT);

  const filteredJobTitles = useMemo(() => {
    return jobTitlesList
      .filter((title) =>
        title.toLowerCase().includes(searchJobTitle.toLowerCase())
      )
      .sort((a, b) => customSort(a, b, selectedJobTitle));
  }, [jobTitlesList, searchJobTitle, selectedJobTitle]);

  // useEffect(() => {
  //   const sortedTitles = jobTitlesList
  //     .filter((title) =>
  //       title.toLowerCase().includes(searchJobTitle.toLowerCase())
  //     )
  //     .sort((a, b) => customSort(a, b, selectedJobTitle));
  //   setFilteredJobTitles(sortedTitles);
  // }, [searchJobTitle, jobTitlesList, selectedJobTitle, jobTitleCategory]);

  const itemSize = useCallback(
    (index) =>
      textWidths[index] > MAX_ITEM_SIZE_JOB_TITLE
        ? DEFAULT_ITEM_HEIGHT * 1.5
        : DEFAULT_ITEM_HEIGHT,
    [textWidths]
  );

  useEffect(() => {
    const len = filteredJobTitles.length;
    if (len === 0) {
      setWindowHeight(DEFAULT_SEARCH_HEIGHT);
      return;
    }

    const height = getWindowHeight(rowHeights, len);

    height &&
      setWindowHeight(
        len === 1 ? height + 1 : Math.min(height, DEFAULT_SEARCH_HEIGHT)
      );
  }, [filteredJobTitles]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setTextWidths(filteredJobTitles.map((value) => measureTextWidth(value)));
    }
  }, [filteredJobTitles]);

  useEffect(() => {
    if (jobTitleCategory) fetchJobTitlesAutocomplete(jobTitleCategory);
  }, [jobTitleCategory]);

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const Row = ({ index, style }) => {
    const rowRef = useRef({});
    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [index, rowRef]);

    const jobTitle = filteredJobTitles[index];
    const isChecked = jobTitle.toLowerCase() === selectedJobTitle.toLowerCase();
    return (
      <div key={index} className={classes.menuItem} style={style} ref={rowRef}>
        <FormControlLabel
          control={
            <CheckboxInput
              value={isChecked}
              onChange={() => {
                if (isChecked) {
                  handleSubmit('');
                } else {
                  handleSubmit(jobTitle);
                  setOpenFilter(null);
                  setSearchJobTitle('');
                }
              }}
              name={jobTitle}
            />
          }
          label={jobTitle}
          className={classes.controlLabel}
        />
      </div>
    );
  };

  const dropdown = () => (
    <div
      className={clsx(
        classes.container,
        dropdownMenu && classes.dropdownContainer
      )}
    >
      <AsyncTypeahead
        id='jobTitle'
        labelKey='jobTitle'
        placeholder={intl.formatMessage({
          id: 'inputText.jobTitle',
          defaultMessage: 'Job title',
        })}
        isLoading={false}
        options={[]}
        defaultInputValue={''}
        useCache={false}
        defaultSelected={[searchJobTitle]}
        onSearch={(text) => {}}
        onInputChange={(text) => {
          setSearchJobTitle(text);
        }}
        minLength={1}
        inputProps={{
          className: clsx(
            classes.autocomplete,
            !jobTitleCategory && classes.hidden
          ),
        }}
        renderMenu={() => null}
      />
      <div
        className={clsx(
          classes.menu,
          !jobTitleCategory && classes.roundedCorners
        )}
      >
        {jobTitleCategory && filteredJobTitles?.length > 0 ? (
          <List
            height={windowHeight}
            itemCount={filteredJobTitles.length}
            itemSize={itemSize}
            width={'100%'}
            className={classes.menu}
            ref={listRef}
          >
            {Row}
          </List>
        ) : !jobTitleCategory ? (
          <p className={classes.noResults}>
            {intl.formatMessage({
              id: 'inputText.selectCategory',
              defaultMessage: 'No category selected',
            })}
          </p>
        ) : (
          <p className={classes.noResults}>
            {intl.formatMessage({
              id: 'errors.autocomplete.listIsEmpty',
              defaultMessage: 'No results found',
            })}
          </p>
        )}
      </div>
    </div>
  );

  return (
    <>
      <FilterItem
        handleClick={() => {
          setOpenFilter(isMenuOpen ? null : 'jobTitle');
        }}
        anchorRef={anchorRef}
        open={isMenuOpen}
        placeholder={intl.formatMessage({
          id: 'inputText.jobTitle',
          defaultMessage: 'Job title',
        })}
        value={selectedJobTitle ? '(1)' : ''}
        showResetButton={selectedJobTitle}
        resetFilter={(e) => {
          setSearchJobTitle('');
          handleSubmit('');
          e.stopPropagation();
        }}
        dropdown={dropdownMenu && dropdown()}
      />
      {!dropdownMenu && (
        <Popper
          open={isMenuOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 100,
          }}
          placement='bottom-start'
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
            },
            arrow: {
              enabled: false,
              element: anchorRef,
            },
            hide: {
              enabled: false,
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={{ enter: 300, exit: 0 }}>
              <Paper className={classes.paper}>
                <ClickAwayListener
                  onClickAway={() => {
                    if (isMenuOpen) {
                      setOpenFilter(null);
                      setSearchJobTitle('');
                    }
                  }}
                >
                  {dropdown()}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};
