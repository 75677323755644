import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

import { REG_FROM_POPUP } from 'src/user/constants';
import { setItem } from 'src/utils/localStorage';
import { toggleLandingModal } from 'src/about/redux/actions';
import { toggleModal } from 'src/onboarding/redux/actions';
import GoogleLogin from 'src/onboarding/components/GoogleLogin';
import FBLogin from 'src/onboarding/components/FBLogin';
import LILogin from 'src/onboarding/components/LILogin';
import LandingModal1 from 'src/img/icons/LandingModal1';
import LandingModal2 from 'src/img/icons/LandingModal2';
import Close from 'src/img/icons/Close';

import useStyles from './styles';

const LandingModal = ({ open, toggleLandingModal, toggleOnboardingModal }) => {
  const classes = useStyles();
  const closeModal = () => {
    toggleLandingModal(false);
  };
  const isBigScreen = useMediaQuery('(min-width:1400px)');
  const elementSize = isBigScreen ? 'medium' : 'small';
  const signUp = () => {
    closeModal();
    toggleOnboardingModal(true, 'isRegModalOpen');
  };
  const setLocalKey = () => {
    setItem(REG_FROM_POPUP, true);
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={(_, reason) => reason !== 'backdropClick' && closeModal()}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <div
              className={classes.closeIcon}
              tabIndex='0'
              onClick={closeModal}
              onKeyDown={(e) => e.keyCode === 13 && closeModal()}
            >
              <Close />
            </div>
            <picture className={classes.imageTop}>
              <source
                srcSet={require(`src/img/landing_modal.webp`)}
                type='image/webp'
              />
              <img
                src={require(`src/img/landing_modal.png`)}
                alt=''
                className={classes.imageTop}
              />
            </picture>
          </div>
          <div className={classes.content}>
            <div className={classes.title}>
              <FormattedMessage
                id='mdlLanding.title'
                defaultMessage='Try our Algorithm'
              />
            </div>
            <div className={classes.row}>
              <div className={classes.listItem}>
                <div className={classes.icon}>
                  <LandingModal1 />
                </div>
                <p>
                  <FormattedMessage
                    id='mdlLanding.list1'
                    defaultMessage='A variety of jobs from the leading companies from all over the world'
                  />
                </p>
              </div>
              <div className={classes.listItem}>
                <div className={classes.icon}>
                  <LandingModal2 />
                </div>
                <p>
                  <FormattedMessage
                    id='mdlLanding.list2'
                    defaultMessage='The best jobs for the best employees'
                  />
                </p>
              </div>
            </div>
            <button className={classes.button} onClick={signUp} type='submit'>
              <FormattedMessage id='btns.signUp' defaultMessage='Sign up' />
            </button>
            <div className={classes.dividerDiv}>
              <div className={classes.dividerSide}></div>
              <div className={classes.dividerText}>
                <FormattedMessage id='singleWords.or' defaultMessage='Or' />
              </div>
              <div className={classes.dividerSide}></div>
            </div>
            <div className={classes.socialDiv}>
              <FBLogin size={elementSize} setLocalKey={setLocalKey} />
              <LILogin size={elementSize} setLocalKey={setLocalKey} />
              <GoogleLogin size={elementSize} setLocalKey={setLocalKey} />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default connect(
  (state) => ({
    open: state.about.landingModalOpen,
  }),
  {
    toggleLandingModal,
    toggleOnboardingModal: toggleModal,
  }
)(LandingModal);
