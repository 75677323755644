import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import EyeInput from 'src/input/components/EyeInput';
import TextInput from 'src/input/components/TextInput';
import PhoneNumberInput from 'src/input/components/PhoneNumberInput';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '0 20px',
  },
  textField: {
    width: '100%',
    maxWidth: 310,
    marginTop: 20,
    '& fieldset': {
      borderRadius: 6,
    },
    '&:first-child': {
      marginTop: 0,
    },
  },
  errorText: {
    marginLeft: 0,
  },
  countryDropdown: {
    bottom: 33,
    maxHeight: 198,
  },
}));

export default ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  intl,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TextInput
        name="email"
        label={intl.formatMessage({
          id: 'inputText.email',
          defaultMessage: 'Email',
        })}
        type="email"
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        touched={touched}
        errors={errors}
        className={classes.textField}
        error={errors.email === 'alreadyExists'}
        intl={intl}
      />
      <EyeInput
        name="password"
        label={intl.formatMessage({
          id: 'inputText.password',
          defaultMessage: 'Password',
        })}
        type="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        autoComplete="new-password"
        inputProps={{ autoComplete: 'new-password' }}
        error={!!errors.password && touched.password}
        helperText={
          errors.password && touched.password
            ? intl.formatMessage({
                id: `errors.password.${errors.password}`,
                defaultMessage: 'Error',
              })
            : ''
        }
        className={classes.textField}
        containerClassName={classes.textField}
        FormHelperTextProps={{
          className: classes.errorText,
        }}
        iconStyle={{ top: '12px' }}
      />
      <EyeInput
        name="password2"
        label={intl.formatMessage({
          id: 'inputText.password2',
          defaultMessage: 'Password Verify',
        })}
        type="password"
        value={values.password2}
        onChange={handleChange}
        onBlur={handleBlur}
        variant="outlined"
        autoComplete="new-password"
        inputProps={{ autoComplete: 'new-password' }}
        error={!!errors.password2 && touched.password2}
        helperText={
          errors.password2 && touched.password2
            ? intl.formatMessage({
                id: `errors.password.${errors.password2}`,
                defaultMessage: 'Error',
              })
            : ''
        }
        className={classes.textField}
        containerClassName={classes.textField}
        iconStyle={{ top: '12px' }}
        FormHelperTextProps={{
          className: classes.errorText,
        }}
      />
      <PhoneNumberInput
        name="phone"
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        value={values.phone}
        className={classes.textField}
        error={errors.phone}
        touched={touched.phone}
        dropdownClass={classes.countryDropdown}
        intl={intl}
      />
    </div>
  );
};
