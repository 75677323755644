import loadable from '@loadable/component';

import { loadableConfig } from 'src/router/config';
import { langRegex } from 'src/intl/utils';

const UserSettings = loadable(
  () => import('src/user/components/Settings'),
  loadableConfig.client
);

const routes = [
  {
    path: `${langRegex}/user/settings`,
    exact: true,
    component: UserSettings,
  },
];

export default routes;
