import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ size }) => (
  <SvgIcon
    viewBox='0 0 22 22'
    style={{
      height: size || '25px',
      width: size || '25px',
    }}
  >
    <defs>
      <filter id='filter-1'>
        <feColorMatrix
          in='SourceGraphic'
          type='matrix'
          values='0 0 0 0 0.071094 0 0 0 0 0.334683 0 0 0 0 1.000000 0 0 0 1.000000 0'
        ></feColorMatrix>
      </filter>
      <filter id='filter-2'>
        <feColorMatrix
          in='SourceGraphic'
          type='matrix'
          values='0 0 0 0 0.071094 0 0 0 0 0.334683 0 0 0 0 1.000000 0 0 0 1.000000 0'
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id='Design'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <g id='HP-01' transform='translate(-1613.000000, -646.000000)'>
        <g id='Group-19'>
          <g transform='translate(1614.000000, 641.000000)'>
            <g id='Group-18'>
              <g>
                <g
                  id='bell'
                  transform='translate(0.000000, 6.000000)'
                  stroke='#0041E5'
                  strokeWidth='1.5'
                >
                  <path
                    d='M20,15.0000001 L0,15.0000001 C1.65685425,15.0000001 3,13.6568542 3,12.0000001 L3,7.0000001 C3,3.13400679 6.13400679,1.04308128e-07 10,1.04308128e-07 C13.8659932,1.04308128e-07 17,3.13400679 17,7.0000001 L17,12.0000001 C17,13.6568542 18.3431458,15.0000001 20,15.0000001 L20,15.0000001 Z M11.73,19 C11.3722022,19.6168043 10.7130686,19.9964563 10,19.9964563 C9.28693139,19.9964563 8.62779776,19.6168043 8.27,19'
                    id='Shape'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
