import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default () => (
  <SvgIcon
    viewBox="0 0 18 22"
    style={{
      height: '22px',
      width: '18px',
    }}
  >
    <g id="map-marker-2-copy" transform="translate(0.75 0.75)">
      <path
        d="M3 6C4.65685 6 6 4.65685 6 3C6 1.34315 4.65685 0 3 0C1.34315 0 0 1.34315 0 3C0 4.65685 1.34315 6 3 6Z"
        transform="translate(5 5)"
        id="Oval"
        fill="none"
        stroke="#0041E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 19.7C13.3 15 16 11 16 8C16 3.58172 12.4183 4.44089e-16 8 0C3.58172 -2.22045e-16 8.88178e-16 3.58172 0 8C0 11 2.7 14.9 8 19.7L8 19.7Z"
        id="Path"
        fill="none"
        stroke="#0041E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
);
