import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { ThemeProvider } from '@material-ui/styles';
import {
  createTheme,
  StylesProvider,
  jssPreset,
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: 14,
        height: 'auto',
      },
    },
    MuiInputLabel: {
      outlined: {
        zIndex: 1,
        transform: 'translate(14px, 16px) scale(1)',
        pointerEvents: 'none',
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '4px !important',
      },
    },
  },
  palette: {
    primary: {
      main: '#0041E5',
      dark: '#20285C',
      light: '#F1F7FF',
      medium: '#82BCFF',
      darkHovered: '#29366C',
    },
    secondary: {
      light: '#FFEEEE',
      main: '#FF6E7E',
    },
    background: {
      paper: '#FFF',
      default: '#EDEDF1',
      light: '#FAFAFB',
      blue: '#D9EBFF',
      dark: '#14193C',
    },
    text: {
      primary: '#4E5C73',
      secondary: '#20285C',
      disabled: '#9A9DAE',
      white: '#fff',
    },
    divider: '#D6D8DF',
  },
  typography: {
    fontFamily: `"Assistant", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export default ({ children, dir }) => (
  <StylesProvider jss={jss}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider
        theme={{
          ...theme,
          ...(dir === 'rtl' && {
            direction: 'rtl',
          }),
        }}
      >
        {children}
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  </StylesProvider>
);
