import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import userIcon from 'src/img/userIcon.jpg';
import More from 'src/img/icons/More';
import Profile from 'src/img/icons/Profile';
import AdobeIcon from 'src/img/icons/AdobeIcon';
import LogOut from 'src/img/icons/LogOut';
import Settings from 'src/img/icons/Settings';
import Heart from 'src/img/icons/Heart';
import Apply from 'src/img/icons/Apply';
import Recommended from 'src/img/icons/Recommended';
import { getNavigationUrl } from 'src/utils/history';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    paddingRight: '15px !important',
    margin: '0 !important',
    '&:focus $icon': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  profileContainer: {
    display: 'flex',
    height: '50px',
    backgroundColor: theme.palette.primary.light,
    alignItems: 'center',
    borderRadius: '6px',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: '600',
    minWidth: '100px',
    maxWidth: '300px',
    width: 'auto',
    justifyContent: 'center',
    padding: '10px 10px',
    boxSizing: 'border-box',
  },
  image: {
    width: '32px',
    height: '32px',
    borderRadius: '16px',
  },
  icon: {
    height: 50,
    borderRadius: '6px',
    width: '20px',
    marginRight: '-8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  username: {
    marginRight: '10px',
    marginBottom: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 200,
  },
  contactPerson: {
    fontWeight: 'normal',
    margin: 0,
  },
  profile: {
    objectFit: 'cover',
  },
  paper: {
    opacity: 1,
    minWidth: 183,
  },
  menuItem: {
    width: '100%',
    display: 'flex',
    height: 40,
    alignItems: 'center',
    '& p': {
      margin: 0,
      lineHeight: '16px',
      marginLeft: '15px',
      color: theme.palette.primary.main,
    },
  },
  appliedMenuItem: {
    '& p': {
      marginLeft: 12,
    },
  },
  logOutItem: {
    height: 52,
  },
  recommendedItem: {
    '& p': {
      marginLeft: 13,
    },
  },
}));

export default ({
  user,
  userName,
  imgId = userIcon,
  logOut,
  isCustomer,
  editPassAccess,
  customerUser,
  companyName,
  toggleInfoModal,
  togglePasswordModal,
  initiateJobsFetch,
  dir,
}) => {
  const classes = useStyles({ isCustomer });
  const anchor = useRef(null);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const showRecommended = user.recommendedJobsCount !== 0;

  return (
    <>
      <div
        className={classes.container}
        onClick={() => setProfileOpen(true)}
        tabIndex='0'
        onKeyDown={(e) => e.keyCode === 13 && setProfileOpen(true)}
      >
        <div className={classes.profileContainer}>
          <div>
            <p className={classes.username}>{companyName || userName}</p>
            {companyName ? (
              <p className={classes.contactPerson}>{customerUser}</p>
            ) : null}
          </div>
          {imgId ? (
            <div>
              <img
                src={imgId}
                alt='User'
                className={`${classes.profile} ${classes.image}`}
              />
            </div>
          ) : null}
        </div>
        <div ref={anchor} className={classes.icon}>
          <More />
        </div>
      </div>
      <Popper
        open={isProfileOpen}
        anchorEl={anchor.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 100,
        }}
        placement={dir === 'rtl' ? 'bottom-end' : 'bottom-start'}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={() => setProfileOpen(false)}>
                <MenuList
                  autoFocusItem={isProfileOpen}
                  id='menu-list-grow'
                  onKeyDown={(event) => {
                    if (event.key === 'Tab') {
                      event.preventDefault();
                    }
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      isCustomer && toggleInfoModal(true);
                      setProfileOpen(false);
                    }}
                  >
                    <Link
                      className={classes.menuItem}
                      to={isCustomer ? '#' : getNavigationUrl('/my-profile')}
                    >
                      <Profile />
                      <p>
                        <FormattedMessage
                          id='profileMenu.profile'
                          defaultMessage='Profile'
                        />
                      </p>
                    </Link>
                  </MenuItem>
                  {!isCustomer ? (
                    <MenuItem
                      onClick={() => {
                        setProfileOpen(false);
                      }}
                    >
                      <Link
                        className={classes.menuItem}
                        to={getNavigationUrl('/cv-builder/library')}
                      >
                        <AdobeIcon width={15} color='#0041E5' />
                        <p>
                          <FormattedMessage
                            id='cvBuilder.myCvLibrary'
                            defaultMessage='My CV library'
                          />
                        </p>
                      </Link>
                    </MenuItem>
                  ) : null}
                  {!isCustomer ? (
                    <MenuItem
                      onClick={() => {
                        initiateJobsFetch({ saved: 1 });
                        setProfileOpen(false);
                      }}
                    >
                      <Link
                        className={classes.menuItem}
                        to={getNavigationUrl('/?saved=1')}
                      >
                        <Heart height={16} width={15} color='#0041E5' />
                        <p>
                          <FormattedMessage
                            id='jobs.savedJobs'
                            defaultMessage='Saved jobs'
                          />
                        </p>
                      </Link>
                    </MenuItem>
                  ) : null}
                  {!isCustomer ? (
                    <MenuItem
                      onClick={() => {
                        initiateJobsFetch({ applied: 1 });
                        setProfileOpen(false);
                      }}
                    >
                      <Link
                        className={clsx(
                          classes.menuItem,
                          classes.appliedMenuItem
                        )}
                        to={getNavigationUrl('/?applied=1')}
                      >
                        <Apply />
                        <p>
                          <FormattedMessage
                            id='userDetails.appliedJobs'
                            defaultMessage='Applied jobs'
                          />
                        </p>
                      </Link>
                    </MenuItem>
                  ) : null}
                  {showRecommended && !isCustomer ? (
                    <MenuItem
                      onClick={() => {
                        initiateJobsFetch({ recommended: 1 });
                        setProfileOpen(false);
                      }}
                    >
                      <Link
                        className={clsx(
                          classes.menuItem,
                          classes.recommendedItem
                        )}
                        to={getNavigationUrl('/?recommended=1')}
                      >
                        <Recommended />
                        <p>
                          <FormattedMessage
                            id='jobs.recommendedJobs'
                            defaultMessage='Recommended jobs'
                          />
                        </p>
                      </Link>
                    </MenuItem>
                  ) : null}
                  {isCustomer ? (
                    editPassAccess ? (
                      <MenuItem
                        className={classes.menuItem}
                        onClick={() => {
                          togglePasswordModal(true);
                        }}
                      >
                        <Settings />
                        <p>
                          <FormattedMessage
                            id='profileMenu.changePassword'
                            defaultMessage='Change password'
                          />
                        </p>
                      </MenuItem>
                    ) : null
                  ) : (
                    <MenuItem>
                      <Link
                        className={classes.menuItem}
                        to={getNavigationUrl('/user/settings')}
                      >
                        <Settings />
                        <p>
                          <FormattedMessage
                            id='profileMenu.settings'
                            defaultMessage='Settings'
                          />
                        </p>
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() =>
                      logOut({ side: isCustomer ? 'customer' : 'user' })
                    }
                    className={clsx(classes.menuItem, classes.logOutItem)}
                  >
                    <LogOut />
                    <p>
                      <FormattedMessage
                        id='profileMenu.logOut'
                        defaultMessage='Log out'
                      />
                    </p>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
