import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color }) => (
  <SvgIcon
    viewBox="0 0 22 21"
    style={{
      height: '21px',
      width: '22px',
    }}
  >
    <g id="upload-cloud" transform="translate(0.75 0.75)">
      <path
        d="M19.1795 13.0359C19.8795 11.8359 20.1795 10.5359 19.8795 9.13593C19.2795 7.13593 17.4795 5.63593 15.4795 5.63593L14.2795 5.63593C13.5795 2.63593 11.0795 0.43593 8.07949 0.0359301C5.07949 -0.26407 2.17949 1.33593 0.779487 4.03593C-0.420513 6.53593 -0.220513 10.5359 1.27949 12.8359M9.97949 11.2359L9.97949 19.0359"
        transform="translate(0.02051324 0.9640699)"
        id="Shape"
        fill="none"
        stroke={color || '#0041E5'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4L4 0L0 4"
        transform="translate(6 11)"
        id="Path"
        fill="none"
        stroke={color || '#0041E5'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
);
