import createReducer from 'src/utils/createReducer';
import { LOG_OUT } from 'src/onboarding/redux/actions';

import {
  CREATE_OR_EDIT_DEPARTMENT,
  FETCH_CUSTOMER_DEPARTMENTS,
  DELETE_CUSTOMER_DEPARTMENT,
  CLEAR_DEPARTMENTS,
} from './actions';

const INITIAL_STATE = {
  list: [],
  isLoading: false,
};

export default createReducer(INITIAL_STATE, {
  [CREATE_OR_EDIT_DEPARTMENT.REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [CREATE_OR_EDIT_DEPARTMENT.SUCCESS]: (state, { data, body }) => ({
    ...state,
    isLoading: false,
    list: body.id
      ? state.list.map((e) => (e.id === data.id ? data : e))
      : [...state.list, data],
  }),
  [CREATE_OR_EDIT_DEPARTMENT.FAILURE]: (state, { data, body }) => ({
    ...state,
    isLoading: false,
  }),
  [FETCH_CUSTOMER_DEPARTMENTS.REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_CUSTOMER_DEPARTMENTS.SUCCESS]: (state, { data }) => ({
    ...state,
    list: data,
    isLoading: false,
  }),
  [FETCH_CUSTOMER_DEPARTMENTS.FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [DELETE_CUSTOMER_DEPARTMENT.SUCCESS]: (
    state,
    { params: { departmentId } }
  ) => ({
    ...state,
    list: state.list.filter((e) => e.id !== departmentId),
    isLoading: false,
  }),
  [LOG_OUT]: () => INITIAL_STATE,
  [CLEAR_DEPARTMENTS]: () => INITIAL_STATE,
});
