import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => {
  return {
    LoginEmployerBtn: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    extraButtonsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      gap: 10,
      height: 60,
    },
    flexEnd: {
      justifyContent: 'end',
    },
    container: {
      minHeight: 60,
      // height: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: palette.primary.dark,
      marginTop: -1,
      borderBottom: `1px solid ${palette.divider}`,
      boxSizing: 'border-box',
      boxShadow: '0 5px 10px 1px rgba(32,40,92,0.08)',
      '@media (max-width: 1124px)': {
        '&': {
          display: 'none',
        },
      },
    },
    aTag: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 94,
      height: 60,
    },
    thinTab: {
      width: 60,
    },
    wideTab: {
      width: 94,
    },
    smallScreenContainer: {
      width: '100%',
      height: 55,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      boxShadow: '0 5px 10px 1px rgba(32,40,92,0.15)',
      '@media (min-width: 1124px)': {
        '&': {
          display: 'none',
        },
      },
    },
    smallScreenInnerContainer: {
      width: '83%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxSizing: 'border-box',
      '& a': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    logoBar: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: 164,
      height: 40,
      borderRadius: 6,
      justifyContent: 'center',
      // marginLeft: -10,
      // marginRight: 20,
      '&:focus': {
        backgroundColor: palette.primary.light,
      },
    },
    divider: {
      height: 32,
      width: 1,
      backgroundColor: palette.text.disabled,
      margin: 'auto',
      '@media (max-width: 270px)': {
        '&': {
          display: 'none',
        },
      },
    },
    loginButton: {
      fontSize: '16px',
      fontWeight: '600',
      backgroundColor: '#fff',
      color: palette.text.primary,
      border: 'none',
      textTransform: 'none',
      padding: '0 6px',
      textAlign: 'center',
      borderRadius: 6,
      '@media (max-width: 1124px)': {
        '&': {
          border: `1px ${palette.primary.main} solid`,
          width: '80%',
          maxWidth: 210,
          height: 48,
          color: palette.primary.main,
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    innerContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: 1840,
      minHeight: '60px',
      height: 'auto',
      padding: '0 15px',
      // width: '83%',
    },
    navItem: {
      height: 60,
      minWidth: 50,
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: `2px ${palette.text.white} solid`,
      fontSize: '16px',
      cursor: 'pointer',
      // marginRight: 15,
      // marginLeft: 15,
      whiteSpace: 'nowrap',
      userSelect: 'none',
      boxSizing: 'border-box',
      color: '#20285C',
      '& a': {
        color: '#20285C',
      },
      '&:hover $navHovered': {
        backgroundColor: '#F8F9FA',
      },
      '&:focus $navHovered': {
        backgroundColor: '#F8F9FA',
      },
      '@media (max-width: 1300px)': {
        minWidth: 50,
      },
    },
    jobsNavItem: {
      marginLeft: 0,
    },
    payNavItem: {
      width: 94,
      margin: 0,
    },
    cvNavItem: {
      width: 90,
    },
    cvNavItemRtl: {
      width: 108,
    },
    aboutNavItem: {
      marginRight: 0,
    },
    navHovered: {
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: '2px 10px 0',
      borderRadius: 6,
    },
    navRow: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      minHeight: 60,
      marginLeft: 15,
      maxWidth: 700,
      paddingRight: 35,
      // gap: 15,
    },
    rightRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      width: '100%',
      height: 60,
    },
    navItemBorder: {
      borderBottom: `2px ${palette.primary.main} solid`,
      color: palette.primary.main,
      fontWeight: '600',
    },
    activeNavItem: {
      color: palette.primary.main + ' !important',
      fontWeight: '600',
    },
    '@media (max-width: 945px)': {
      innerContainer: {
        width: '95%',
      },
    },
  };
});
