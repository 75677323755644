// TODO use both commonJS and esm modules in webkack and share these two modules
import { states } from 'src/Dashboards/AutoCompleteConst';

export const allLanguages = {
  en: {
    language: 'en',
    langName: 'English',
    defaultLocation: 'united states',
    dir: 'ltr',
  },
  he: {
    language: 'he',
    langName: 'עברית',
    defaultLocation: 'israel',
    dir: 'rtl',
  },
};

const langs = {
  en: require('./translations/en'),
  he: require('./translations/he'),
};

function createLangRegex(allLanguages) {
  const langCodesArr = Object.keys(allLanguages);
  let str = '/(';
  const lastIndex = langCodesArr.length - 1;
  for (let i = 0; i < lastIndex; i++) {
    str = str + langCodesArr[i] + '|';
  }
  str = str + langCodesArr[lastIndex] + ')?';
  return str;
}

export const langRegex = createLangRegex(allLanguages);

export const availableLanguages = Object.keys(langs);

export const isRoot = (urlPath) =>
  /^\/([he|ru|en]{2})?\/?([\?].*)?$/i.test(urlPath);

export const getLangFromPath = (urlPath) => {
  const [_, firstPart] = urlPath.split('/');
  const [lang] = firstPart.match(/^\/?\b([he|ru|en]{2})\b/i) || [];

  return lang;
};

export const getDirFromLang = (langCode) => {
  return allLanguages[langCode] ? allLanguages[langCode].dir : 'ltr';
};

export const replaceLangInPath = (urlPath, newLang) => {
  const hasLangInPath = getLangFromPath(urlPath);

  // if urlPath has language in it we just replace it with the new one
  if (hasLangInPath) {
    if (newLang) return urlPath.replace(hasLangInPath, newLang);
    // or with an empty string if we need to remove the lang from the urlPath
    if (!newLang) return urlPath.replace(`/${hasLangInPath}`, '');
  }

  // if no lang in url and new lang specified we just concat the new lang with the path
  if (!hasLangInPath && newLang)
    return `/${newLang}${
      urlPath.startsWith('/')
        ? urlPath === '/'
          ? '' // ignore the path to avoid closing slash
          : urlPath
        : `/${urlPath}}`
    }`;

  // in any other way we return the passed urlPath
  return urlPath;
};
export const capFirstLetters = (string, firstLetterOnly = false) => {
  if (firstLetterOnly) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  let words = string
    ?.split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  return words?.join(' ');
};
export const getMessagesForLang = (lang) => langs[lang];

export const buildLangRouteName = (routeName) =>
  // new RegExp(`^\/([he|ru|en]{2})${routeName.replace('/', '/')}$`);
  new RegExp(`^\/([he|ru|en]))${routeName.replace('/', '/')}$`);

export const parseLocationString = (location) => {
  let city, country, state;

  if (location && typeof location === 'string') {
    const splits = location
      .toLowerCase()
      .split(',')
      .map((item) => item.trim());

    country = splits[0];

    if (country !== 'united states') {
      city = splits[1];
    } else {
      // possible state field in location string
      if (splits.length > 2) {
        state = splits[1];
        city = splits[2];
      } else if (
        states.find((obj) => obj.value.toLowerCase() === splits[1]) !==
        undefined
      ) {
        state = splits[1];
      } else city = splits[1];
    }
  }
  return { country, state, city };
};
