import React, { useState, useRef, useEffect } from 'react';
import useStyles from './styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

// filters
import CompanyDropdown from '../CompanyDropdown';
import CountryDropdown from '../CountryDropdown';
import JobCategoriesDropdown from '../JobCategoriesDropdown';
import StateDropdown from '../StateDropdown';
import CityDropdown from '../CityDropdown';
import JobTypeDropdown from '../JobTypeDropdown';
import JobTitleDropdown from '../JobTitleDropdown';
import { navigateIntl } from 'src/utils/history';
import { setJobCategory } from 'src/jobs/redux/actions';
import FilterItem from '../JobFilters/FilterItem';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Filters from 'src/img/icons/Filters';
import Close from 'src/img/icons/Close';

const FILTER_WIDTH = 195;
const filtersArray = [
  <CompanyDropdown />,
  <JobTypeDropdown />,
  <JobCategoriesDropdown />,
  <JobTitleDropdown />,
  <CountryDropdown />,
  <StateDropdown />,
  <CityDropdown />,
];

const JobFilters = ({ intl, handleReset }) => {
  const classes = useStyles();

  // refs
  const moreFiltersRef = useRef(null);
  const filtersRef = useRef(null);

  // states
  const [showMoreFiltersBtn, setShowMoreFiltersBtn] = useState(false);
  const [moreFiltersOpen, setMoreFiltersOpen] = useState(false);
  const [numberOfFilters, setNumberOfFilters] = useState(filtersArray.length);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(null);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  useEffect(() => {
    function handleResize() {
      setWindowWidth(typeof window !== 'undefined' ? window.innerWidth : 0);
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  useEffect(() => {
    if (showFiltersModal && windowWidth > 650) setShowFiltersModal(false);

    const containerWidth = filtersRef.current?.clientWidth;

    setNumberOfFilters(Math.floor(containerWidth / FILTER_WIDTH) - 1);

    if (containerWidth < FILTER_WIDTH * 8) setShowMoreFiltersBtn(true);
    else setShowMoreFiltersBtn(false);
  }, [windowWidth]);

  const filtersClickHandler = () => {
    setShowFiltersModal(true);
  };

  return (
    <div className={classes.container}>
      <div ref={filtersRef} className={classes.filtersContainer}>
        {!showFiltersModal &&
          filtersArray.slice(0, numberOfFilters).map((component, index) => (
            <React.Fragment key={index}>
              {React.cloneElement(component, {
                openFilter,
                setOpenFilter,
              })}
            </React.Fragment>
          ))}
        {showMoreFiltersBtn && (
          <>
            <FilterItem
              handleClick={() => setMoreFiltersOpen(!moreFiltersOpen)}
              anchorRef={moreFiltersRef}
              open={moreFiltersOpen}
              placeholder={intl.formatMessage({
                id: 'btns.moreFilters',
                defaultMessage: 'More filters',
              })}
              showResetButton={false}
            />
            <Popper
              open={moreFiltersOpen}
              anchorEl={moreFiltersRef.current}
              role={undefined}
              transition
              disablePortal
              style={{
                zIndex: 100,
              }}
              placement='bottom-end'
              modifiers={{
                flip: {
                  enabled: false,
                },
                preventOverflow: {
                  enabled: true,
                },
                arrow: {
                  enabled: false,
                  element: moreFiltersRef,
                },
                hide: {
                  enabled: false,
                },
              }}
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps} timeout={{ enter: 300, exit: 0 }}>
                  <Paper className={classes.paper}>
                    <ClickAwayListener
                      onClickAway={() => setMoreFiltersOpen(false)}
                    >
                      <div className={classes.moreFiltersContainer}>
                        {filtersArray
                          .slice(
                            Math.abs(filtersArray.length - numberOfFilters) * -1
                          )
                          .map((component, index) => (
                            <React.Fragment key={index}>
                              {React.cloneElement(component, {
                                dropdownMenu: true,
                                openFilter,
                                setOpenFilter,
                              })}
                            </React.Fragment>
                          ))}
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}
      </div>
      <button
        onClick={filtersClickHandler}
        size='medium'
        className={classes.filtersButton}
        variant='Outlined'
      >
        <Filters color={'#2F64EA'} />
        <p>
          <FormattedMessage id='btns.filters' defaultMessage='Filters' />
        </p>
      </button>
      {showFiltersModal && (
        <div className={classes.filtersModalContainer}>
          <div className={classes.modalHeadersContainer}>
            <button onClick={handleReset} className={classes.resetFiltersBtn}>
              <FormattedMessage id='btns.resetOnly' defaultMessage='Reset' />
            </button>
            <p>
              <FormattedMessage id='btns.filters' defaultMessage='Filters' />
            </p>
            <button
              onClick={() => {
                setOpenFilter(null);
                setShowFiltersModal(false);
              }}
              className={classes.closeModalBtn}
            >
              <Close size={25} color={'#636363'} />
            </button>
          </div>
          {filtersArray.map((component, index) => (
            <React.Fragment key={index}>
              {React.cloneElement(component, {
                dropdownMenu: true,
                openFilter,
                setOpenFilter,
              })}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      isCleanQuery: state?.router?.location?.search === '',
      selectedCategory: state.jobs.autocomplete.jobCategory,
    }),
    { setJobCategory },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      handleReset: async () => {
        // save on redux the category selected
        navigateIntl('/');
        dispatchProps.setJobCategory('');
      },
    })
  )
)(JobFilters);
