import createReducer from 'src/utils/createReducer';
import {
  GET_JOBS,
  JOB_TITLE_AUTOCOMPLETE,
  GET_SAVED_JOBS,
  DELETE_SAVED_JOB,
  SAVE_JOB_IN_FAVORITE,
  SET_CURRENT_JOB_INDEX,
  GET_HIGHLIGHTS,
  GET_OVERVIEW,
  APPLY_FOR_JOB,
  SET_REQUEST_PAGE,
  SET_QUERY,
  JOB_COMPANY_AUTOCOMPLETE,
  GET_JOB,
  INITIATE_JOBS_FETCH,
  GET_DEFAULT_COMPANIES,
  GET_COUNTRIES_AUTOCOMPLETE,
  GET_CITIES_AUTOCOMPLETE,
  DELETE_JOB_SAVED_SCREEN,
  CHANGE_COMPANY_VALUE,
  TOGGLE_ALERT_MODAL,
  GET_RECOMMENDED_JOBS,
  TOGGLE_CVBANNER_MODAL,
  GET_JOB_TITLES_CATEGORIES,
  GET_JOB_TITLES_AUTOCOMPLETE,
  SET_JOB_CATEGORY,
  SET_SEO_INFO,
} from 'src/jobs/redux/actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { isRoot } from 'src/intl/utils';

const INITIAL_STATE = {
  jobs: {
    list: [],
    loading: false,
    pages: 0,
    currentJobIndex: 0,
    requestPage: null,
    currentJob: {},
    total: null,
    savedJobs: [],
    appliedJobs: [],
    highlights: {},
    overview: {},
    seoInfo: null,
  },
  autocomplete: {
    categories: {
      loading: false,
    },
    isLoading: false,
    result: {},
    defaultCompanies: [],
    countries: [],
    cities: [],
    companyValue: '',
    jobCategory: '',
    jobTitles: [],
  },
  query: null,
  applyLoading: false,
  alertModalOpen: false,
  cvBannerOpen: true,
  pageMetaData: {},
};

export default createReducer(INITIAL_STATE, {
  [GET_RECOMMENDED_JOBS.SUCCESS]: (state, { data: { jobId, similarJobs } }) => {
    if (jobId) {
      const foundIndex = state.jobs?.list.findIndex((job) => job.id === jobId);
      if (foundIndex) {
        return {
          ...state,
          jobs: {
            ...state.jobs,
            list: state.jobs.list.map((job, index) =>
              index === foundIndex ? { ...job, similarJobs } : job
            ),
          },
          currentJob: { ...state.jobs.list[foundIndex], similarJobs },
        };
      }
    } else
      return {
        ...state,
        jobs: {
          ...state.jobs,
          similarJobs,
        },
      };
  },

  [GET_JOBS.REQUEST]: (state, data) => ({
    ...state,
    jobs: {
      ...state.jobs,
      loading: true,
      resolvedOnSSR: false,
    },
    query: data?.body?.url,
  }),
  [GET_JOBS.SUCCESS]: (
    state,
    { data: { d, pages, total, savedJobs, ...rest }, body: { saved } }
  ) => ({
    ...state,
    jobs: {
      ...state.jobs,
      ...rest,
      total,
      pages,
      loading: false,
      list: d,
      resolvedOnSSR: !process.env.BROWSER,
      currentJobIndex: 0,
      savedJobs: saved ? d.map((item) => item.id) : savedJobs,
      // currentJob: {},
    },
  }),
  [GET_JOBS.FAILURE]: (state) => ({
    ...state,
    jobs: {
      ...state.jobs,
      loading: false,
      resolvedOnSSR: false,
    },
  }),
  [LOCATION_CHANGE]: (
    state,
    {
      payload: {
        location: { pathname },
        isFirstRendering,
      },
    }
  ) => ({
    ...state,
    jobs: {
      ...state.jobs,
      resolvedOnSSR:
        !process.env.BROWSER || (isFirstRendering && isRoot(pathname)),
    },
  }),
  [INITIATE_JOBS_FETCH]: (state) => ({
    ...state,
    jobs: {
      ...state.jobs,
      loading: true,
    },
  }),
  [GET_JOB.REQUEST]: (state) => ({
    ...state,
    jobs: {
      ...state.jobs,
      loading: true,
    },
  }),
  [GET_JOB.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      jobs: {
        ...state.jobs,
        currentJob: data,
        savedJobs: data.saved
          ? state.jobs.savedJobs.concat(data.id)
          : state.jobs.savedJobs,
        loading: false,
      },
    };
  },
  [GET_JOB.FAILURE]: (state) => ({
    ...state,
    jobs: {
      ...state.jobs,
      loading: false,
    },
  }),
  [GET_SAVED_JOBS.SUCCESS]: (
    state,
    { data: { d, pages, total, ...rest } }
  ) => ({
    ...state,
    jobs: {
      ...state.jobs,
      ...rest,
      total,
      pages,
      loading: false,
      list: d,
      currentJobIndex: 0,
    },
  }),
  [CHANGE_COMPANY_VALUE]: (state, { value }) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      companyValue: value,
    },
  }),
  [SET_JOB_CATEGORY]: (state, { value }) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      jobCategory: value,
    },
  }),
  [SET_SEO_INFO]: (state, { value }) => {
    return {
      ...state,
      jobs: {
        ...state.jobs,
        seoInfo: value,
      },
    };
  },
  [JOB_TITLE_AUTOCOMPLETE.REQUEST]: (state) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      isLoading: true,
    },
  }),
  [JOB_TITLE_AUTOCOMPLETE.FAILURE]: (state) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      isLoading: false,
    },
  }),
  [JOB_TITLE_AUTOCOMPLETE.SUCCESS]: (
    state,
    { params: { field, value }, data: { d } }
  ) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      isLoading: false,
      result:
        field === 'companyName' && value !== state.autocomplete.companyValue
          ? state.autocomplete.result
          : { [field]: d.slice(0, 5) },
    },
  }),
  [JOB_COMPANY_AUTOCOMPLETE.SUCCESS]: (state, { params: { name }, data }) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      isLoading: false,
      result: {
        companyName: data.d,
      },
    },
  }),
  [GET_COUNTRIES_AUTOCOMPLETE.REQUEST]: (state) => ({
    ...state,
  }),
  [GET_COUNTRIES_AUTOCOMPLETE.SUCCESS]: (state, { data: { countries } }) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      countries,
    },
  }),
  [GET_JOB_TITLES_CATEGORIES.REQUEST]: (state) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      categories: {
        loading: true,
      },
    },
  }),
  [GET_JOB_TITLES_CATEGORIES.SUCCESS]: (state, { data }) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      categories: {
        loading: false,
        ...data,
      },
    },
  }),
  [GET_JOB_TITLES_AUTOCOMPLETE.REQUEST]: (state) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      jobTitles: [],
    },
  }),
  [GET_JOB_TITLES_AUTOCOMPLETE.SUCCESS]: (state, { data: { jobTitles } }) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      jobTitles,
    },
  }),
  [GET_CITIES_AUTOCOMPLETE.REQUEST]: (state) => ({
    ...state,
  }),
  [GET_CITIES_AUTOCOMPLETE.SUCCESS]: (state, { data: { cities } }) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      cities,
    },
  }),
  [GET_DEFAULT_COMPANIES.REQUEST]: (state) => ({
    ...state,
  }),
  [GET_DEFAULT_COMPANIES.SUCCESS]: (state, { data }) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      defaultCompanies: data.d.map((item) => item.companyName),
    },
  }),
  [DELETE_SAVED_JOB.REQUEST]: (state) => ({
    ...state,
    saveLoading: true,
  }),
  [DELETE_SAVED_JOB.SUCCESS]: (state, { params: { jobId } }) => ({
    ...state,
    saveLoading: false,
    jobs: {
      ...state.jobs,
      savedJobs: state.jobs.savedJobs.filter((item) => item !== jobId),
    },
  }),
  [DELETE_SAVED_JOB.FAILURE]: (state) => ({
    ...state,
    saveLoading: false,
  }),
  [DELETE_JOB_SAVED_SCREEN]: (state, { jobId }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      list: state.jobs.list.filter((e) => e.id !== jobId),
      total: state.jobs.total - 1,
      currentJobIndex: 0,
    },
  }),
  [SAVE_JOB_IN_FAVORITE.REQUEST]: (state) => ({
    ...state,
    saveLoading: true,
  }),
  [SAVE_JOB_IN_FAVORITE.SUCCESS]: (state, { params: { jobId } }) => ({
    ...state,
    saveLoading: false,
    jobs: {
      ...state.jobs,
      savedJobs: state.jobs.savedJobs.concat(jobId),
    },
  }),
  [SAVE_JOB_IN_FAVORITE.FAILURE]: (state) => ({
    ...state,
    saveLoading: false,
  }),
  [SET_CURRENT_JOB_INDEX]: (state, { idx }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      currentJobIndex: idx,
      currentJob: state.jobs.list.length >= idx ? state.jobs.list[idx] : null,
    },
  }),
  [APPLY_FOR_JOB.REQUEST]: (state) => ({
    ...state,
    applyLoading: true,
  }),
  [APPLY_FOR_JOB.SUCCESS]: (state, { params: { jobId } }) => ({
    ...state,
    applyLoading: false,
    jobs: {
      ...state.jobs,
      appliedJobs: state.jobs.appliedJobs.concat(jobId),
    },
  }),
  [APPLY_FOR_JOB.FAILURE]: (state, { error: { error }, params }) => ({
    ...state,
    applyLoading: false,
  }),
  [GET_HIGHLIGHTS.SUCCESS]: (state, { data, params }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      highlights: {
        ...state.jobs.highlights,
        [params.companyName]: data?.desc || [],
      },
    },
  }),
  [GET_OVERVIEW.SUCCESS]: (state, { data, params }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      overview: {
        ...state.jobs.overview,
        [params.companyName]: data?.d?.info || [],
      },
    },
  }),
  [SET_REQUEST_PAGE]: (state, { page }) => ({
    ...state,
    jobs: {
      ...state.jobs,
      requestPage: page,
    },
  }),
  [SET_QUERY]: (state, { query }) => ({
    ...state,
    query,
  }),
  [TOGGLE_ALERT_MODAL]: (state, { isOpen }) => ({
    ...state,
    alertModalOpen: isOpen,
  }),
  [TOGGLE_CVBANNER_MODAL]: (state, { isOpen }) => ({
    ...state,
    cvBannerOpen: isOpen,
  }),
});
