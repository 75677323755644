import React, { useCallback, useState } from 'react';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
// import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

const AsyncTypeahead = withAsync(Typeahead);

export default function AutocompleteAPI({
  initiateAutocomplete,
  autocomplete,
  langCode,
  autocomplete: { isLoading, result = [] },
  label = 'Autocomplete',
  onChange = () => {},
  name,
  value,
  clickSearch = () => {},
  renderInput,
  ...props
}) {
  const [enterPressed, setEnterPressed] = useState(false);

  const onChangeValue = ([selected]) => {
    enterPressed && setEnterPressed(false);
    if (!selected) return;

    const val = selected[name] || selected || '';

    onChange(name)(val, selected);
  };

  const debouncedInitiate = debounce((text, langCode) => {
    initiateAutocomplete(name, text, langCode);
  }, 500);

  const onChangeInputValue = useCallback(
    (text) => {
      enterPressed && setEnterPressed(false);
      onChange(name)(text);
      debouncedInitiate(text, langCode);
    },
    [langCode]
  );

  return (
    <AsyncTypeahead
      id={name}
      labelKey={name}
      isLoading={false}
      options={result}
      placeholder={label}
      defaultInputValue={''}
      selected={[value]}
      onSearch={onChangeInputValue}
      onChange={onChangeValue}
      minLength={1}
      onInputChange={onChangeInputValue}
      useCache={false}
      filterBy={(option) => option}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          clickSearch();
          setEnterPressed(true);
        }
      }}
      open={enterPressed ? false : undefined}
      renderInput={renderInput && renderInput(onChangeInputValue)}
      {...props}
    />
  );
}
