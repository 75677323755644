import createReducer from 'src/utils/createReducer';
import { GET_AUTOCOMPLETE, TOGGLE_CONFIRMATION } from 'src/input/redux/actions';

const INITIAL_STATE = {
  autocomplete: {},
  isConfirmationOpen: false,
  actions: [],
  confirmationTitle: '',
};

export default createReducer(INITIAL_STATE, {
  [GET_AUTOCOMPLETE.SUCCESS]: (state, { data }) => ({
    ...state,
    autocomplete: { ...state.autocomplete, ...data },
  }),
  [TOGGLE_CONFIRMATION]: (state, { isOpen, actions, title }) => ({
    ...state,
    isConfirmationOpen: isOpen,
    actions: isOpen ? actions : [],
    confirmationTitle: isOpen ? title : '',
  }),
});
