import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { logOut, deleteMe } from 'src/user/redux/actions';
import { toggleInfoModal, togglePasswordModal } from 'src/users/redux/actions';
import { initiateJobsFetch } from 'src/jobs/redux/actions';

import ProfileMenu from './ProfileMenu';

export default compose(
  connect(
    (state) => ({
      user: state.user.profile,
      imgId: state.user.profile.imgId || state.customer.user.logo,
      userName:
        (state.user.profile?.firstName || '') +
        ' ' +
        (state.user.profile?.lastName || ''),
      companyName:
        state.admin.selectedCustomer.customerInfo.name ||
        state.customer.user.name,
      customerUser: state.customer.user.currentLoggedUser?.fullName,
      dir: state.locale.dir,
    }),
    {
      logOut,
      deleteMe,
      toggleInfoModal,
      togglePasswordModal,
      initiateJobsFetch,
    }
  ),
  withRouter
)(ProfileMenu);
