import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = '#0041E5', size = 14, strokeWidth = 1, style }) => (
  <SvgIcon
    viewBox='0 0 14 14'
    style={{
      height: size,
      width: size,
      ...style,
    }}
  >
    <g id='arrow-out-square' transform='translate(0.5 0.5)'>
      <path
        d='M8.6265 10.0643L12.2209 6.46988L8.6265 2.8755M11.9333 6.46988L3.59438 6.46988M4.31325 0L0 0L0 12.9398L4.31325 12.9398'
        id='Shape'
        fill='none'
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </g>
  </SvgIcon>
);
