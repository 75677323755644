import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ isActive }) => {
  const color = isActive ? '#82BCFF' : '#FFF';
  return (
    <SvgIcon
      viewBox="0 0 128 160"
      style={{
        height: '24px',
        marginLeft: -5,
      }}
    >
      <g>
        <path
          fill={color}
          d="M83.387,33.785c-0.909,0-1.646-0.734-1.646-1.639v-9.764c0-3.917-3.2-7.103-7.133-7.103H53.392    c-3.933,0-7.133,3.187-7.133,7.103v9.764c0,0.905-0.737,1.639-1.646,1.639c-0.909,0-1.646-0.734-1.646-1.639v-9.764    C42.967,16.657,47.644,12,53.392,12h21.216c5.748,0,10.425,4.657,10.425,10.381v9.764C85.033,33.051,84.296,33.785,83.387,33.785z    "
        />
        <g>
          <rect
            x="56.434"
            y="75.127"
            width="15.617"
            height="13.053"
            fill={color}
          />
          <path
            fill={color}
            d="M75.343,89.819c0,0.905-0.737,1.639-1.646,1.639H54.788c-0.909,0-1.646-0.734-1.646-1.639v-6.046L7.573,68.025     l-0.087,42.941c0,2.774,2.241,5.034,4.996,5.034h103.035c2.755,0,4.996-2.26,4.996-5.037V68.016L75.343,83.75V89.819z"
          />
          <path
            fill={color}
            d="M121.004,30.515H6.996C4.257,30.515,2,32.827,2,35.552v27.077l51.142,17.657v-6.798v-0.001     c0-0.905,0.737-1.639,1.646-1.639h18.909c0.909,0,1.646,0.734,1.646,1.639v0.001v6.775L126,62.632v-27.08     C126.375,33.375,124.312,30.515,121.004,30.515z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
