import { createApiAction, createAsyncActionRequestTypes } from 'src/api';
import queryString from 'query-string';

export const FETCH_CUSTOMERS_ALL = createAsyncActionRequestTypes(
  'FETCH_CUSTOMERS_ALL'
);

export const fetchAllCustomers = ({ page = 1, ...params } = {}) =>
  createApiAction(
    {
      url:
        '/customer/allCustomers?' +
        queryString.stringify(
          { ...params, page: page - 1 },
          { skipEmptyString: true }
        ),
      params,
    },
    FETCH_CUSTOMERS_ALL
  );

export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER';

export const setSelectedCustomer = (customer) => ({
  type: SET_SELECTED_CUSTOMER,
  customer,
});

export const CLEAR_SELECTED_CUSTOMER = 'CLEAR_SELECTED_CUSTOMER';

export const clearSelectedCustomer = (data) => ({
  type: CLEAR_SELECTED_CUSTOMER,
  payload: data,
});

export const CLEAR_CUSTOMERS_AUTOCOMPLETE = 'CLEAR_CUSTOMERS_AUTOCOMPLETE';

export const clearCustomersAutocomplete = (data) => ({
  type: CLEAR_CUSTOMERS_AUTOCOMPLETE,
  payload: data,
});

export const FETCH_CUSTOMERS_AUTOCOMPLETE_RESULTS =
  createAsyncActionRequestTypes('FETCH_CUSTOMERS_AUTOCOMPLETE_RESULTS');

export const fetchAutocompleteCustomers = (field, value) =>
  createApiAction(
    {
      url: `/customer/autocomplete?field=${field}&value=${value}`,
      params: {
        field,
        value,
      },
    },
    FETCH_CUSTOMERS_AUTOCOMPLETE_RESULTS
  );

export const FETCH_SELECTED_CUSTOMER_DEPARTMENTS =
  createAsyncActionRequestTypes('FETCH_SELECTED_CUSTOMER_DEPARTMENTS');

export const fetchSelectedDepartments = (customerID) =>
  createApiAction(
    {
      url: '/departments/:customerID',
      params: {
        customerID,
      },
    },
    FETCH_SELECTED_CUSTOMER_DEPARTMENTS
  );

export const FETCH_SELECTED_CUSTOMER = createAsyncActionRequestTypes(
  'FETCH_SELECTED_CUSTOMER'
);

export const fetchSelectedCustomer = (selectedCustomerId) =>
  createApiAction(
    {
      url: '/customer',
      headers: { selectedCustomerId },
    },
    FETCH_SELECTED_CUSTOMER
  );
