export const REG_FROM_POPUP = 'REG_FROM_POPUP';

export const TYPES_OF_EMPLOYMENT = [
  {
    type: 'employee',
    labelId: 'userDetails.employee',
  },
  {
    type: 'contractor',
    labelId: 'userDetails.contractor',
  },
];

export const USER_PAGES_ARRAY = [
  'saved', // ?saved=X user saved jobs page
  'applied', // ?applied=X user applied jobs page
  'recommended', // ?recommended=1 user recommended jobs page
];
