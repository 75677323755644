export const jobsPageArticles = {
  en: [
    67, // High tech jobs worldwide
    66, // High-tech jobs for beginners
    89, // High-tech jobs for Students
  ],
  he: [
    // 6, // High-tech jobs in Israel
    10, // High-tech jobs for those with no experience
    28, // High-tech jobs for Students
  ],
};

export const JOBS_PAGE_ARTICLES = {
  en: [
    { query: { title: 'student' }, id: 133 },
    { query: { title: 'student', location: 'united states' }, id: 133 },
    { query: { title: 'junior' }, id: 132 },
    { query: { title: 'junior', location: 'united states' }, id: 132 },
    { query: {}, id: 130 },
  ],
  he: [
    { query: { title: 'student' }, id: 128 },
    { query: { title: 'student', location: 'israel' }, id: 128 },
    { query: { title: 'junior' }, id: 129 },
    { query: { title: 'junior', location: 'israel' }, id: 129 },
    { query: {}, id: 6 },
  ],
};

export const cvBuilderPageArticles = {
  en: [
    12, // How to create a successful CV
  ],
  he: [
    1, // Building a CV
  ],
};
export const CV_TEMPLATE_PAGE_ARTICLES = {
  en: [134],
  he: [131],
};
