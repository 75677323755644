import React from 'react';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Switch } from 'react-router-dom';
import Grow from '@material-ui/core/Grow';

import ThemeProvider from 'src/theme/components/ThemeProvider';

import routes from './routes';

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-unused-expressions
  import(/* webpackPreload: true */ './App.css');
} else {
  // eslint-disable-next-line no-unused-expressions
  import('./App.css');
}

function App({ locale }) {
  return (
    <ThemeProvider dir={locale.dir}>
      <SnackbarProvider
        maxSnack={4}
        autoHideDuration={4000}
        TransitionComponent={Grow}
        hideIconVariant
      >
        <Switch>{routes}</Switch>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default connect((state) => state)(App);
