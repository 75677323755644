import createReducer from 'src/utils/createReducer';
import { SET_LANGUAGE, SET_MESSAGES } from 'src/intl/redux/actions';
import { allLanguages } from 'src/intl/constants';

export default createReducer(
  {
    dir: 'ltr',
    language: 'en',
    defaultLocation: 'united states',
    browserLanguage: 'en',
    messages: {},
  },
  {
    [SET_LANGUAGE]: (state, { language }) => ({
      ...state,
      ...allLanguages[language],
    }),
    [SET_MESSAGES]: (state, { messages }) => ({
      ...state,
      messages,
    }),
  }
);
