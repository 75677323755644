import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import TextInput from 'src/input/components/TextInput';
import ArrowDown from 'src/img/icons/ArrowDown';

import { industries } from './constants';

const useStyles = makeStyles(() => ({
  autocomplete: {
    width: '100%',
    '& fieldset': {
      borderRadius: 6,
    },
  },
  popupIcon: {
    width: '12px',
    height: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  errorText: {
    marginLeft: 0,
    fontSize: 14,
  },
}));

export default ({
  values,
  errors,
  setFieldValue,
  touched,
  className,
  textInputClassName,
  intl,
  handleChange,
  handleBlur,
  disabled,
}) => {
  const classes = useStyles();

  const onIndustryChange = (event, value, reason) => {
    setFieldValue('industrySpecified', '');
    if (reason === 'clear') {
      setFieldValue('industry', '');
    }
    if (value) {
      setFieldValue('industry', value);
    }
  };

  return (
    <>
      <Autocomplete
        name='industry'
        options={industries}
        getOptionLabel={(option) => option}
        onChange={onIndustryChange}
        value={values.industry}
        getOptionSelected={(label) => label === values.industry}
        popupIcon={
          <div className={classes.popupIcon}>
            <ArrowDown color={disabled ? '#9A9DAE' : null} />
          </div>
        }
        openOnFocus
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: 'autoComplete.industry',
              defaultMessage: 'Choose an industry',
            })}
            error={touched.industry && !!errors.industry}
            helperText={
              touched.industry && errors.industry
                ? intl.formatMessage({
                    id: `errors.industry.${errors.industry}`,
                    defaultMessage: 'Error',
                  })
                : ''
            }
            className={`${classes.autocomplete} ${textInputClassName}`}
            variant='outlined'
            FormHelperTextProps={{
              className: classes.errorText,
            }}
          />
        )}
        className={className}
      />
      {values.industry === 'Other' ? (
        <TextInput
          name='industrySpecified'
          label={intl.formatMessage({
            id: 'autoComplete.industrySpecified',
            defaultMessage: 'Specify an industry',
          })}
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          touched={touched}
          errors={errors}
          className={className}
          intl={intl}
        />
      ) : null}
    </>
  );
};
