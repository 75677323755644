import createReducer from 'src/utils/createReducer';

import {
  SET_SELECTED_CUSTOMER,
  CLEAR_SELECTED_CUSTOMER,
  FETCH_SELECTED_CUSTOMER
} from 'src/admin/redux/actions';

const INITIAL_STATE = {};

export default createReducer(INITIAL_STATE, {
  [SET_SELECTED_CUSTOMER]: (state, { customer }) => ({
    ...state,
    ...customer,
  }),
  [CLEAR_SELECTED_CUSTOMER]: () => ({
    ...INITIAL_STATE,
  }),
  [FETCH_SELECTED_CUSTOMER.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_SELECTED_CUSTOMER.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [FETCH_SELECTED_CUSTOMER.SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
    loading: false,
  }),
});
