import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';

import {
  applyForJob,
  saveJob,
  deleteSavedJob,
  deleteJobSavedScreen,
  getHighlights,
} from 'src/jobs/redux/actions';
import { toggleModal } from 'src/onboarding/redux/actions';
import { toggleCVModal } from 'src/user/redux/actions/profile';
import { trackJobAction } from 'src/analytics/redux/actions';

import JobDesc from './JobDesc';

export default compose(
  withSnackbar,
  connect(
    (state, ownProps) => ({
      isLogged: !!state.user.profile.confirmed,
      profile: state.user.profile,
      loading: state.user.loading || state.jobs.applyLoading,
      saveLoading: state.jobs.saveLoading,
      savedJobs: state.jobs.jobs.savedJobs,
      appliedJobs: state.jobs.jobs.appliedJobs,
      highlights: state.jobs.jobs.highlights[ownProps.companyName],
      currentUrl: state.router.location.pathname + state.router.location.search,
      messages: state.locale.messages,
      isRtl: state.locale.dir === 'rtl',
      langCode: state.locale.language,
      query: queryString.parse(state.router.location?.search),
      backUrl: queryString.parse(state.router.location?.search)?.backUrl || '',
    }),
    {
      applyForJob,
      toggleModal,
      saveJob,
      deleteSavedJob,
      toggleCVModal,
      deleteJobSavedScreen,
      getHighlights,
      // getOverview,
      trackJobAction,
    },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      toggleLoginModal: () =>
        dispatchProps.toggleModal(true, 'isLoginClientOpen'),
      saveJobD: async (jobId) => {
        const { error } = await dispatchProps.saveJob(jobId);
        if (error) {
          return parentProps.enqueueSnackbar(
            error === 'ALREADY_SAVED'
              ? stateProps.messages['snackbar.error.jobAlreadySaved']
              : stateProps.messages['snackbar.error.errorOccured'],
            {
              variant: 'error',
            }
          );
        }
        return parentProps.enqueueSnackbar(
          stateProps.messages['snackbar.success.jobSaved'],
          {
            variant: 'success',
          }
        );
      },
      deleteSavedJobD: async (jobId) => {
        const { error } = await dispatchProps.deleteSavedJob(jobId);

        parentProps.setOpen && parentProps.setOpen();
        if (error) {
          return parentProps.enqueueSnackbar(
            stateProps.messages['snackbar.error.somethingWentWrong'],
            {
              variant: 'error',
            }
          );
        } else {
          parentProps.isSavedScreen &&
            dispatchProps.deleteJobSavedScreen(jobId);
        }

        return parentProps.enqueueSnackbar(
          stateProps.messages['snackbar.success.jobDeletedByUser'],
          {
            variant: 'success',
          }
        );
      },
      applyForJobD: async (jobId) => {
        const { error } = await dispatchProps.applyForJob(jobId);

        if (error) {
          if (error === 'ALREADY_APPLIED') {
            return parentProps.enqueueSnackbar(
              stateProps.messages['snackbar.error.alreadyApplied'],
              {
                variant: 'error',
              }
            );
          }

          if (error === 'CANNOT_APPLY_FOR_YOUR_OWN') {
            return parentProps.enqueueSnackbar(
              stateProps.messages['snackbar.error.applyOwnJob'],
              {
                variant: 'error',
              }
            );
          }

          return parentProps.enqueueSnackbar(
            stateProps.messages['snackbar.error.applyJobSomeError'],
            {
              variant: 'error',
            }
          );
        }

        parentProps.enqueueSnackbar(
          stateProps.messages['snackbar.success.jobApplied'],
          {
            variant: 'success',
          }
        );
      },
    })
  ),
  injectIntl
)(JobDesc);
