import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color, size, style }) => (
  <SvgIcon
    viewBox='0 0 11 11'
    style={{
      height: size || '14px',
      width: size || '14px',
      ...style,
    }}
  >
    <path
      d='M6 0L5.64645 5.64645L0 6'
      transform='matrix(0.7071066 0.7071069 -0.7071069 0.7071066 5.303223 1.060547)'
      id='Path'
      fill='none'
      stroke={color || '#0041E5'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
);
