import React from 'react';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import wrapIntoLayout from 'src/utils/wrapIntoLayout';
import Layout from 'src/layout/components/Layout';
import { getNavigationUrl } from 'src/utils/history';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    minHeight: 'calc(100vh - 160px)',
    backgroundColor: palette.primary.light,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: palette.primary.main,
    fontWeight: 'bold',
    fontSize: 20,
    padding: '0 20px',
  },
  title: {
    color: palette.primary.dark,
    textAlign: 'center',
    marginTop: 200,
  },
  button: {
    cursor: 'pointer',
    textTransform: 'none',
    fontSize: 16,
    borderRadius: 8,
    width: 188,
    height: 48,
    margin: '200px 0 40px 0',
    fontWeight: 'normal',
  },
}));

const NotFound = ({}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        <FormattedMessage
          id="links.badLink"
          defaultMessage="This page was not found"
        />
      </h1>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        component={Link}
        to={getNavigationUrl('/')}
      >
        <FormattedMessage id="links.goToJobs" defaultMessage="Go to jobs" />
      </Button>
    </div>
  );
};

export default compose(wrapIntoLayout(Layout))(NotFound);
