export const TOGGLE_COMPLETE_CUSTOMER_REG_MODAL =
  'TOGGLE_COMPLETE_CUSTOMER_REG_MODAL';

export const toggleCompleteCustomerRegModal = (isOpen) => ({
  type: TOGGLE_COMPLETE_CUSTOMER_REG_MODAL,
  isOpen,
});

export const TOGGLE_CUSTOMER_MODAL_LOADING = 'TOGGLE_CUSTOMER_MODAL_LOADING';

export const toggleCustomerModalLoading = (isLoading) => ({
  type: TOGGLE_CUSTOMER_MODAL_LOADING,
  isLoading,
});
