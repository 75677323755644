import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CustomerHeader from './CustomerHeader';

export default compose(
  withRouter,
  connect((state) => ({
    userRole: state.customer.user.currentLoggedUser?.role,
  }))
)(CustomerHeader);
