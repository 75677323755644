import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const CLEAR_CV_PREVIEW = 'CLEAR_CV_PREVIEW';
export const FETCH_CV = createAsyncActionRequestTypes('FETCH_CV');

export const clearCvPreview = () => ({
  type: CLEAR_CV_PREVIEW,
});

export const getCV = (userId) =>
  createApiAction(
    {
      url: '/customer/cv/:userId',
      params: {
        userId,
      },
    },
    FETCH_CV
  );
