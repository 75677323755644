import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, shadows }) => ({
  unauthorizedContainer: {
    width: 'auto',
    minHeight: 300,
    height: 'auto',
    backgroundColor: '#F1F7FF',
    padding: '20px 15px',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    gap: 15,
    '& h2': {
      color: '#20285C',
      fontSize: 22,
    },
    '& p': {
      color: '#20285C',
      fontWeight: 500,
      textAlign: 'center',
      fontSize: 16,
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
    },
    '@media (min-width: 900px)': {
      minHeight: 400,
      gap: 20,
      '& h2': {
        fontSize: 30,
      },
      '& p': {
        fontWeight: 400,
        fontSize: 18,
        color: '#6b7280',
      },
      '& > div': {
        gap: 20,
      },
    },
  },
  loginBtn: {
    backgroundColor: 'transparent',
    border: '1px solid ' + palette.primary.main,
    color: palette.primary.main,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  homeBtn: {
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.main,
    },
  },
  jobsPanel: {
    height: '100%',
    width: '100%',
    minWidth: 275,
    overflowY: 'none',
    '&::-webkit-scrollbar': { width: '9px' },
    '&::-webkit-scrollbar-track': { background: '#f6f6f6' },
    '&::-webkit-scrollbar-thumb': {
      background: '#d7d7d7',
      borderRadius: 2,
    },
  },
  breadcrumbsContainer: {
    width: '100%',
    height: 'auto',
    marginBottom: 5,
    margin: 0,
    padding: 0,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  jobDescColumn: {
    height: 'calc(100% - 40px)',
    display: 'flex',
    alignItems: 'flex-start',
    width: '66%',
    marginBottom: 70,
    '@media (max-width: 1000px)': {
      '&': {
        width: '63%',
      },
    },
    '@media (max-width: 900px)': {
      '&': {
        width: '100%',
        marginBottom: 0,
      },
    },
  },
  contentContainer: {
    backgroundColor: palette.background.default,
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
  },
  contentContainerLogged: {},
  jobsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  jobsInnerContainer: {
    width: '90%',
    maxWidth: '1840px',
    display: 'flex',
    marginBottom: 50,
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    '@media (max-width: 900px)': {
      maxHeight: 'none',
    },
  },
  loaderDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    minHeight: 400,
  },
  '@keyframes shine': {
    to: {
      backgroundPositionX: '-200%',
    },
  },

  filtersRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0 0 0',
    width: '90%',
    maxWidth: '1840px',
    minHeight: 110,
    marginBottom: 10,
    boxSizing: 'border-box',
    color: palette.text.secondary,
  },
  generatedH1Skeleton: {
    margin: '3px 0',
    width: 275,
    height: 39,
    borderRadius: 5,
    background: 'linear-gradient(110deg, #dddde4 8%, #e7e7ec 18%, #dddde4 33%)',
    backgroundSize: ' 200% 100%',
    animation: '1.5s $shine linear infinite',
  },
  introSkeleton: {
    margin: '15px 0 10px 0',
    borderRadius: 5,
    background: 'linear-gradient(110deg, #dddde4 8%, #e7e7ec 18%, #dddde4 33%)',
    backgroundSize: ' 200% 100%',
    animation: '1.5s $shine linear infinite',
    height: 45,
    width: '100%',
  },
  foundJobsInfo: {
    position: 'relative',
    width: '100%',
    maxWidth: '1840px',
    '& h1': {
      '@media (max-width: 500px)': {
        fontSize: 24,
      },
    },
    '& span': {
      fontSize: 15,
      display: 'block',
    },
    '@media (max-width: 600px)': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  foundJobsName: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 20,
  },
  filtersWrapper: {
    width: '100%',
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
  },
  jobsNum: {
    height: 40,
    width: '100%',
    justifyContent: 'start',
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'end',
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },
  alertDiv: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.primary.main,
    height: 25,
    fontSize: 18,
    cursor: 'pointer',
    flexWrap: 'nowrap',
    '& p': {
      marginLeft: 5,
      marginTop: -4,
    },
    '@media (max-width: 900px)': {
      height: 15,
      fontSize: 17,
    },
  },
  alertDivRtl: {
    padding: '0px 10px',
  },
  filters: {
    display: 'flex',
    justifyContent: 'start',
    flex: 2,
    flexWrap: 'wrap',
    margin: '0 -10px',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  filterItem: {
    minWidth: 250,
    maxWidth: 275,
    height: '40px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    color: '#20285C',
    margin: '0 10px',
    boxSizing: 'border-box',
    '& div': {
      width: '100%',
      height: '100%',
      padding: '0 15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  openFilterItem: {
    border: `1px ${palette.primary.main} solid`,
    '& div': {
      padding: '0 14px',
    },
  },
  row: {
    minWidth: 164,
    flex: 1,
    height: '40px',
    margin: '0 10px',
  },
  rootSelect: {
    backgroundColor: '#fff',
    borderRadius: '6px',
    '&:focus': {
      backgroundColor: '#fff',
      borderRadius: '6px',
    },
  },
  formControl: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
      zIndex: 100,
    },
    '& .Mui-focused': {
      border: `1px ${palette.primary.main} solid`,
    },
  },
  menu: {
    marginTop: 1,
    borderRadius: '0 0 6px 6px',
    '& .Mui-selected': {
      backgroundColor: '#F1F7FF',
    },
  },
  jobCard: {
    paddingLeft: 0,
    maxHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    marginTop: 15,
    '&:first-child': {
      marginTop: 0,
    },
  },
  mobileJobDescContainer: {
    position: 'relative',
    zIndex: 2,
    top: -5,
    left: 0,
    backgroundColor: 'white',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    width: '100%',
    height: 0,
    overflow: 'hidden',
  },
  mobileDescWrapper: {
    width: '100%',
    height: 300,
    padding: '0 15px 15px 15px',
  },
  showMobileJobDesc: {
    animation: `$fadeIn 0.75s 1`,
    height: 300,
  },
  mobileJobDescBorder: {
    position: 'absolute',
    height: '100%',
    backgroundColor: palette.secondary.main,
    borderRadius: '0 0 0 6px',
    border: '2px solid #FF6E7E',
    zIndex: 5,
    top: 0,
    left: 0,
    transition: 'all 0.355s ease-in',
    opacity: 0,
  },

  '@keyframes fadeIn': {
    '0%': {
      height: 0,
    },
    '100%': {
      height: 300,
    },
  },
  mobileJobDescBorderActive: {
    opacity: 1,
  },
  jobsColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  paginationDiv: {
    display: 'flex',
    height: 30,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },

  categoriesContainer: {
    width: '100%',
    margin: '15px 0 30px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  cardsContainer: {
    height: 'auto',
    maxHeight: 550,
    overflow: 'hidden',
    margin: '10px 0',
    width: '90%',
    maxWidth: 1840,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 1s ease-in-out',
  },
  flexWrap: {
    width: '100%',
    maxWidth: '1840px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  '@media (min-width: 900px)': {
    jobsInnerContainer: {
      flexDirection: 'row',
    },
    jobsColumn: {
      width: '35%',
      marginTop: 0,
    },
  },
  '@media (min-width:1000px)': {
    jobsColumn: {
      width: '33%',
    },
    filters: {
      marginRight: 10,
    },
  },
  '@media (min-width: 1366px)': {
    jobsNum: {
      marginRight: 60,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: palette.background.paper,
    boxShadow: shadows[5],
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  articleContainer: {
    backgroundColor: palette.background.default,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px 75px 0',
  },
  freeTextWrapper: {
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    height: 'auto',
    width: '90%',
    backgroundColor: 'white',
    padding: '35px 50px',
    maxWidth: 1250,
    borderRadius: 7,
    minWidth: 250,
    marginBottom: 50,
    whiteSpace: 'pre-wrap',
    '@media (max-width: 1000px)': { padding: 25 },
  },
  myArticle: {
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    height: 'auto',
    width: '90%',
    backgroundColor: 'white',
    padding: '35px 50px',
    maxWidth: 1250,
    borderRadius: 7,
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 1000px)': { padding: 25 },
    '& img': {
      width: '100%',
      height: 300,
      objectFit: 'cover',
      borderRadius: 10,
    },
    '& h2': {
      fontSize: 40,
      marginBottom: 10,
      color: '#393939',
      fontWeight: 650,
      textAlign: 'center',
    },
    '& h3': {
      fontSize: 24,
      marginBottom: 6,
      fontWeight: 600,
      color: '#414141',
    },
    '& h4': {
      color: '#515151',
    },
    '& p': {
      fontSize: 18,
      fontWeight: 'normal',
      marginBottom: 0,
    },
  },
  hrSeperator: {
    border: `5px solid ${palette.primary.main}`,
    borderRadius: 5,
  },
  freeText: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    padding: '10px 0px 0px 0px',
    maxWidth: '1840px',
    boxSizing: 'border-box',
    color: palette.text.secondary,
    fontSize: 18,
  },
  showMoreBtn: {
    height: 30,
    padding: '17px 20px',
    fontSize: 20,
    borderRadius: 6,
    backgroundColor: '#20285C',
    color: 'white',
    '&:hover': {
      backgroundColor: '#313e8e',
    },
  },
  showAllCat: {
    overflow: 'visible',
    maxHeight: 'none',
  },
}));
