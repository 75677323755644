import React, { useEffect } from 'react';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { fetchSimilarJobs } from 'src/jobs/redux/actions';
import wrapIntoLayout from 'src/utils/wrapIntoLayout';
import Layout from 'src/layout/components/Layout';
import { injectIntl } from 'react-intl';
import { getNavigationUrl } from 'src/utils/history';
import queryString from 'query-string';
import JobSlider from 'src/jobs/components/JobSlider';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    minHeight: 'calc(75vh - 160px)',
    backgroundColor: palette.primary.light,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: palette.primary.main,
    fontWeight: 'bold',
    fontSize: 20,
    padding: '100px 10%',
  },
  similarJobsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'yellow',
    flexWrap: 'wrap',
    height: '100%',
  },
  recommendedContainer: {
    width: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px 0px',
    '& > p': {
      marginTop: 15,
      width: '100%',
      textAlign: 'center',
      padding: 10,
      color: palette.text.secondary,
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  title: {
    color: palette.primary.dark,
    textAlign: 'center',
    fontSize: 30,
  },
  button: {
    cursor: 'pointer',
    textTransform: 'none',
    fontSize: 16,
    borderRadius: 8,
    width: 188,
    height: 48,
    fontWeight: 'normal',
  },
  jobCard: {
    cursor: 'pointer',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 300,
    maxWidth: 500,
    padding: '10px 15px',
  },
}));

const JobNotFoundPage = ({
  intl,
  fetchSimilarJobs,
  query,
  langCode,
  similarJobs,
}) => {
  const classes = useStyles();

  useEffect(() => {
    let paramsObj = query;
    Object.keys(paramsObj).forEach(
      (key) => (paramsObj[key] = paramsObj[key].replaceAll('%20', ' '))
    );
    if (paramsObj.title || paramsObj.country)
      fetchSimilarJobs(paramsObj, langCode);
  }, []);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        <FormattedMessage
          id='jobs.jobNotFound.title'
          defaultMessage={`We're sorry! Seems like we couldn't find the job you were looking for.{br}These might be a better fit`}
          values={{
            br: <br />,
          }}
        />
      </h1>
      {similarJobs && similarJobs.length > 0 && (
        <div className={classes.recommendedContainer}>
          <JobSlider hideTitle hideLinkButton jobs={similarJobs} />
        </div>
      )}
      <Button
        className={classes.button}
        variant='contained'
        color='primary'
        component={Link}
        to={getNavigationUrl('/')}
      >
        <FormattedMessage id='links.goToJobs' defaultMessage='Go to jobs' />
      </Button>
    </div>
  );
};

export default compose(
  wrapIntoLayout(Layout),
  injectIntl,
  connect(
    (state, props) => ({
      query: queryString.parse(props.location.search),
      langCode: state.locale.language,
      similarJobs: state.jobs.jobs.similarJobs,
    }),
    {
      fetchSimilarJobs,
    },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
    })
  )
)(JobNotFoundPage);
