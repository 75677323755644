import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, shadows }) => ({
  translateX: {
    transform: 'translateX(-8px)',
  },
  modal: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topButtons: {
    paddingRight: 15,
    minHeight: 55,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #d1d5db',
  },
  topButtonWrapper: {
    margin: '5px 10px',
    display: 'flex',
    padding: '2px 5px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customerLoginBtn: {
    border: 'none',
    color: 'white !important',
    display: 'inline-block',
    outline: 0,
    border: 0,
    cursor: 'pointer',
    willChange: 'box-shadow,transform',
    backgroundColor: palette.primary.main,
    boxShadow:
      '0px 2px 4px rgb(45 35 66 / 20%), 0px 4px 5px -3px rgb(45 35 66 / 20%), inset 0px -3px 0px rgb(58 65 111 / 30%)',
    padding: '3px 20px 4px 20px',
    borderRadius: 6,
    fontSize: 18,
    textShadow: '0 1px 0 rgb(0 0 0 / 40%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  paper: {
    backgroundColor: 'white',
    boxShadow: shadows[5],
    width: '100vw',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    color: palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    '& a:visited': {
      color: palette.primary.dark,
    },
  },
  imageDiv: {
    display: 'flex',
    padding: '0 0px 0 25px',
    margin: '10px 0',
    width: '100%',
    zIndex: 0,
  },
  jobTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: 'gray',
  },
  imageContainer: {
    height: 75,
    objectFit: 'cover',
    aspectRatio: '1/1',
    borderRadius: '50%',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    border: 'none',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    backgroundColor: 'white',
    '& a': {
      color: '#011554 !important',
    },
  },

  cvBuilderLink: {
    minWidth: 100,
    position: 'relative',
    borderRadius: 5,
  },

  navItemDropdown: {
    border: '1px solid transparent',
    transition: 'all 0.1s ease-in-out',
    // position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  navItem: {
    gap: 15,
    color: '#011554',
    fontWeight: 400,
    padding: '20px 25px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    userSelect: 'none',
  },
  navItemSelected: {
    paddingBottom: 15,
  },
  dropdownOpen: {
    backgroundColor: '#F5F5F5',
    borderTop: '1px solid #d1d5db',
    borderBottom: '1px solid #d1d5db',
    boxShadow:
      'rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.02) 0px 1px 3px 1px',
  },
  navItemIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  underline: {
    textDecoration: 'underline',
  },
  navItemIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: 15,
    minWidth: 51,
  },
  jobsMenu: {
    width: '100%',
    color: '#011554',
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    padding: '5px 0 15px 0',
  },
  submenuContainer: {
    width: '100%',
    padding: '10px 0 0 5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    gap: 15,
  },
  jobsMenuIcons: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 15,
    width: '100%',
  },
  jobsMenuItem: {
    paddingLeft: 110,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    border: '1px solid transparent',
    transition: 'all 0.1s ease-in-out',
  },

  dot: {
    width: 7,
    aspectRatio: '1/1',
    backgroundColor: '#0041E5',
    borderRadius: '50%',
  },
  jobsSubmenuItem: {
    textDecoration: 'underline',
    marginLeft: 35,
    color: '#011554',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 10,
  },
  jobsMenuItemSelected: {
    padding: '5px 0 10px 110px ',
    backgroundColor: '#e6e8eb',
    borderTop: '1px solid #d1d5db',
    borderBottom: '1px solid #d1d5db',
    boxShadow:
      'rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.02) 0px 1px 3px 1px',
    '& p': {
      fontWeight: 500,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  userName: {
    fontSize: 20,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 15,
    maxWidth: 'calc(100vw - 158px)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
  },
  profileMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #d1d5db',
    borderTop: '1px solid #d1d5db',
    boxShadow:
      'rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.02) 0px 1px 3px 1px',
  },
  profileMenuItem: {
    width: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    padding: '7px 0px 5px 0px',
    '&:not(:last-child)': {
      borderRight: '1px solid #d1d5db',
    },
    '& p': {
      margin: 0,
      lineHeight: '15px',
      marginTop: 5,
      color: palette.primary.main,
      textAlign: 'center',
      fontSize: 15,
    },
  },
  label: {
    marginRight: 10,
    marginLeft: 10,
  },
  noMargin: {
    margin: 0,
  },
}));
