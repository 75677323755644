import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, shadows }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: palette.background.paper,
    boxShadow: shadows[5],
    width: '90vw',
    maxWidth: 510,
    height: 'auto',
    maxHeight: process.env.BROWSER ? window.innerHeight * 0.95 : '100%',
    borderRadius: 16,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    color: palette.text.secondary,
    // paddingBottom: 25,
  },
  ellipse: {
    width: 'calc(100% + 60px)',
    marginLeft: -30,
    height: 100,
    borderRadius: '16px 16px 60% 60%',
    backgroundColor: palette.background.blue,
    position: 'absolute',
    zIndex: 0,
  },
  closeIcon: {
    cursor: 'pointer',
    borderRadius: 6,
    width: 34,
    height: 34,
    margin: '20px 0 0 20px',
    '&:focus': {
      backgroundColor: palette.primary.medium,
    },
    zIndex: 1000,
  },
  content: {
    zIndex: 100,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: -46,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    margin: '10px 0',
  },
  button: {
    minHeight: 40,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 30,
    cursor: 'pointer',
    userSelect: 'none',
    color: palette.text.white,
    backgroundColor: palette.primary.main,
    width: 145,
    borderRadius: 6,
    fontSize: 16,
    '&:hover': {
      boxShadow: '-3px 3px 7px 0 rgba(0,0,0,0.22)',
    },
  },
  textField: {
    width: '90%',
    maxWidth: 310,
    marginTop: 20,
  },
  '@media screen and (min-width: 1400px)': {
    button: {
      height: 48,
    },
  },
}));
