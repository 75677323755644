import { createApiAction, createAsyncActionRequestTypes } from 'src/api';
export const ARTICLES_AUTOCOMPLETE = createAsyncActionRequestTypes(
  'ARTICLES_AUTOCOMPLETE'
);
export const FETCH_ARTICLES = createAsyncActionRequestTypes('FETCH_ARTICLES');
export const GET_ARTICLE = createAsyncActionRequestTypes('GET_ARTICLE');
export const GET_TOP_COMPANIES =
  createAsyncActionRequestTypes('GET_TOP_COMPANIES');
export const GET_TOP_COUNTRIES =
  createAsyncActionRequestTypes('GET_TOP_COUNTRIES');
export const GET_TOP_CITIES = createAsyncActionRequestTypes('GET_TOP_CITIES');
export const GET_TOP_STATE_CITIES = createAsyncActionRequestTypes(
  'GET_TOP_STATE_CITIES'
);
export const GET_TOP_JOBS = createAsyncActionRequestTypes('GET_TOP_JOBS');

export const GET_ANALYTICS = createAsyncActionRequestTypes('GET_ANALYTICS');

export const INITIATE_ARTICLES_FETCH = 'INITIATE_ARTICLES_FETCH';
export const INITIATE_UNIQUE_TOP_FETCH = 'INITIATE_UNIQUE_TOP_FETCH';
export const ARTICLES_AUTOCOMPLETE_SUCCESS = 'ARTICLES_AUTOCOMPLETE_SUCCESS1';
export const SET_ARTICLES_META = 'SET_ARTICLES_META';
export const UPDATE_AO_RESULTS_ARTICLES = 'UPDATE_AO_RESULTS_ARTICLES';
export const SET_TOP_META = 'SET_TOP_META';
export const SET_WAS_SSRED = 'SET_WAS_SSRED';
export const CLEAR_AUTOCOMPLETE = 'CLEAR_AUTOCOMPLETE';
export const AUTOCOMPLETE_INITITATED_ARTICLES =
  'AUTOCOMPLETE_INITITATED_ARTICLES';

export const initiateAutocomplete = (payload) => ({
  type: AUTOCOMPLETE_INITITATED_ARTICLES,
  payload,
});

export const updateAutocompleteLoading = (payload) => ({
  type: ARTICLES_AUTOCOMPLETE_SUCCESS,
  payload,
});

export const clearAutocomplete = () => ({
  type: CLEAR_AUTOCOMPLETE,
});

export const initiateArticlesFetch = (data) => {
  return {
    type: INITIATE_ARTICLES_FETCH,
    payload: data,
  };
};

export const initiateUniqueTopFetch = (data) => {
  return {
    type: INITIATE_UNIQUE_TOP_FETCH,
    payload: data,
  };
};

export const setArticlesMeta = (status) => {
  return {
    type: SET_ARTICLES_META,
    payload: status,
  };
};

export const setTopMeta = (status) => {
  return {
    type: SET_TOP_META,
    payload: status,
  };
};

export const setWasSsred = (flag) => {
  return {
    type: SET_WAS_SSRED,
    payload: flag,
  };
};

export const setSelectedArticles = (data) => {
  return {
    type: GET_ARTICLE.SUCCESS,
    data,
  };
};

export const updateAutocompleteResults = (data) => {
  return {
    type: UPDATE_AO_RESULTS_ARTICLES,
    data,
  };
};

export const getCardArticles = (params = {}) =>
  createApiAction(
    {
      url: '/articles',
      query: params,
      params,
    },
    FETCH_ARTICLES
  );

export const autocompleteArticlesByField = (params = {}) =>
  createApiAction(
    {
      method: 'GET',
      url: '/articles/autocomplete/title',
      query: params,
      // params,
    },
    ARTICLES_AUTOCOMPLETE
  );

export const getFullArticles = ({ articleId, side, downgrade }) =>
  createApiAction(
    {
      url: `/articles/:articleId`,
      query: { side, downgrade },
      params: { articleId },
    },
    GET_ARTICLE
  );

export const getAnalytics = ({ curTopType, langCode = 'en', query = {} }) =>
  createApiAction(
    {
      url: `/top/${curTopType}`,
      query: { langCode, ...query },
    },
    GET_ANALYTICS
  );

export const SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICLE';

export const setCurrentArticle = (article = null) => ({
  type: SET_CURRENT_ARTICLE,
  article,
});
