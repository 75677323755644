import createReducer from 'src/utils/createReducer';
import { LOG_OUT } from 'src/customer/redux/actions';

import {
  FETCH_CUSTOMER_JOB_INFO,
  CLEAR_VIEWED_JOB,
} from 'src/customerJobs/redux/actions';

const INITIAL_STATE = null;

export default createReducer(INITIAL_STATE, {
  [FETCH_CUSTOMER_JOB_INFO.SUCCESS]: (_, { data }) => data,
  [LOG_OUT]: () => INITIAL_STATE,
  [CLEAR_VIEWED_JOB]: () => INITIAL_STATE,
});
