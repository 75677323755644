export const columnOne = {
  he: [
    {
      title: 'דרושים הייטק',
      pathname: '/he',
    },
    {
      title: 'דרושים הייטק ללא נסיון',
      pathname: '/he/?title=junior',
    },
    {
      title: 'דרושים סטודנט הייטק',
      pathname: '/he/?title=student',
    },
    {
      title: 'כתיבת קורות חיים',
      pathname: '/he/cv-builder',
    },
    {
      title: 'דוגמאות לקורות חיים',
      pathname: '/he/cv-builder/cv-examples',
    },
    {
      title: 'תבניות קורות חיים',
      pathname: '/he/cv-builder/templates',
    },
    {
      title: 'קורות חיים באנגלית',
      pathname: '/cv-builder',
    },
    {
      title: 'טבלאות שכר בהייטק',
      pathname: '/he/articles/35',
    },
    {
      title: 'מגזין',
      pathname: '/he/articles',
    },
    {
      title: 'קריירה בהייטק',
      pathname: '/he/analytics/job-titles',
    },
    {
      title: 'חברות הייטק',
      pathname: '/he/analytics/companies',
    },
    {
      title: 'רילוקיישן הייטק',
      pathname: '/he/analytics/countries',
    },
    {
      title: 'הייטק בישראל',
      pathname: '/he/analytics/cities',
    },
  ],
  en: [
    {
      title: 'Job Search',
      pathname: '/',
    },
    {
      title: 'Tech Jobs for Juniors',
      pathname: '/?title=junior',
    },
    {
      title: 'Tech Jobs for Students',
      pathname: '/?title=student',
    },
    {
      title: 'CV Builder',
      pathname: '/cv-builder',
    },
    {
      title: 'CV Examples',
      pathname: '/cv-builder/cv-examples',
    },
    {
      title: 'CV Templates',
      pathname: '/cv-builder/templates',
    },
    {
      title: 'Alljobs by titles',
      pathname: '/analytics/job-titles',
    },
    {
      title: 'Alljobs by companies',
      pathname: '/analytics/companies',
    },
    {
      title: 'Alljobs by countries',
      pathname: '/analytics/countries',
    },
    {
      title: 'Alljobs by states (US)',
      pathname: '/analytics/cities',
    },
    {
      title: '10 Trending tech jobs 2021, make the right career choice',
      pathname: '/articles/11',
    },
    {
      title: 'Top 14 interview questions and answers to them',
      pathname: '/articles/13',
    },
  ],
};
export const jobsList = {
  he: [
    {
      title: 'Software Engineer',
      label: 'מהנדס תוכנה',
    },
    {
      title: 'DevOps',
      label: 'DevOps',
    },
    {
      title: 'Data Engineer',
      label: 'מהנדס נתונים',
    },
    {
      title: 'Product Manager',
      label: 'מנהל מוצר',
    },
    {
      title: 'QA Engineer',
      label: 'מהנדס בדיקות',
    },
    {
      title: 'Full Stack Developer',
      label: 'פול סטאק',
    },
    {
      title: 'Marketing Manager',
      label: 'מנהל שיווק',
    },
    {
      title: 'Java Software Developer',
      label: 'מפתח תוכנה גאווה',
    },
    {
      title: 'Backend Engineer',
      label: 'מפתח בקאנד',
    },
    {
      title: 'Data Analyst',
      label: 'דאטה אנליסט',
    },
    {
      title: 'Data Scientist',
      label: 'מדען נתונים',
    },
    {
      title: 'Business Analyst',
      label: 'אנליסט עסקי',
    },
    {
      title: 'BI Developer',
      label: 'מפתח BI',
    },
    {
      title: 'Big Data',
      label: 'ביג דאטה',
    },
    {
      title: 'Systems Engineer',
      label: 'מנתח מערכות',
    },
  ],
  en: [
    {
      title: 'Software Engineer',
    },
    {
      title: 'DevOps',
    },
    {
      title: 'Data Engineer',
    },
    {
      title: 'Product Manager',
    },
    {
      title: 'QA Engineer',
    },
    {
      title: 'Full Stack Developer',
    },
    {
      title: 'Marketing Manager',
    },
    {
      title: 'Java Software Developer',
    },
    {
      title: 'Backend Engineer',
    },
    {
      title: 'Data Analyst',
    },
    {
      title: 'Data Scientist',
    },
    {
      title: 'Business Analyst',
    },
    {
      title: 'BI Developer',
    },
    {
      title: 'Big Data',
    },
    {
      title: 'Systems Engineer',
    },
  ],
};

export const companiesList = {
  he: [
    { name: 'Apple', hrefTitle: 'צפו במשרות דרושים אפל' },
    { name: 'Mobileye', hrefTitle: 'צפו במשרות דרושים מוביליי' },
    { name: 'Cyberark', hrefTitle: 'צפו במשרות דרושים Cyberark' },
    { name: 'Unity', hrefTitle: 'צפו במשרות דרושים יוניטי' },
    { name: 'Teva', hrefTitle: 'צפו במשרות דרושים טבע' },
    { name: 'Google', hrefTitle: 'צפו במשרות דרושים גוגל' },
    { name: 'Payoneer', hrefTitle: 'צפו במשרות דרושים פיונייר' },
    { name: 'Nvidia', hrefTitle: 'צפו במשרות דרושים אנבידיה' },
    { name: 'Amazon', hrefTitle: 'צפו במשרות דרושים אמזון' },
    { name: 'Wix', hrefTitle: 'צפו במשרות דרושים Wix' },
    { name: 'MoonActive', hrefTitle: 'צפו במשרות דרושים MoonActive' },
    { name: 'Palo Alto', hrefTitle: 'צפו במשרות דרושים Palo Alto' },
    { name: 'Cisco', hrefTitle: 'צפו במשרות דרושים Cisco' },
    { name: 'Playtika', hrefTitle: 'צפו במשרות דרושים פלייטיקה' },
    { name: 'Intel', hrefTitle: 'צפו במשרות דרושים אינטל' },
  ],
  en: [
    { name: 'JPMorgan' },
    { name: 'Nvidia' },
    { name: 'Apple' },
    { name: 'Wells Fargo' },
    { name: 'Honeywell' },
    { name: 'Td bank' },
    { name: 'Google' },
    { name: 'Truist' },
    { name: 'Uber' },
    { name: 'Amazon' },
    { name: 'PNC' },
    { name: 'Walmart' },
    { name: 'Cisco' },
    { name: 'Bank Of America' },
    { name: 'Teva' },
  ],
};

export const countriesList = [
  'United States',
  'India',
  'United Kingdom',
  'Israel',
  'China',
  'Germany',
  'Canada',
  'Singapore',
  'Mexico',
  'France',
  'Philippines',
  'Poland',
  'Japan',
  'Australia',
  'Ireland',
];
