import React from 'react';
import { Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import EyeInput from 'src/input/components/EyeInput';
import TextInput from 'src/input/components/TextInput';
import FBLogin from 'src/onboarding/components/FBLogin';
import LILogin from 'src/onboarding/components/LILogin';
import GoogleLogin from 'src/onboarding/components/GoogleLogin';
import Close from 'src/img/icons/Close';
import CheckboxInput from 'src/input/components/CheckboxInput';
import BlueWave from 'src/img/icons/BlueWave';
import Spinner from 'src/layout/Spinner';
import { dataLayerPush } from 'src/analytics/ga';

import useStyles from './styles';

export default function Login({
  toggleLoginModal,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  values = {},
  errors = {},
  touched = {},
  handleForgotPassword,
  type,
  showSocialLogin,
  intl,
  ...props
}) {
  const classes = useStyles();

  const isBigScreen = useMediaQuery('(min-width:1400px)');
  const elementSize = isBigScreen ? 'medium' : 'small';

  const closeModal = () => {
    toggleLoginModal(false);
  };

  if (props.loading)
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: 140,
          marginBottom: 140,
        }}
      >
        <Spinner isSmall />
      </div>
    );

  return (
    <div className={classes.root}>
      <div className={classes.blueWave}>
        <BlueWave />
      </div>
      <Form className={classes.form}>
        <div className={classes.closeDiv}>
          <div
            className={classes.closeIcon}
            tabIndex='0'
            onClick={closeModal}
            onKeyDown={(e) => e.keyCode === 13 && closeModal()}
          >
            <Close />
          </div>
        </div>
        <div
          className={clsx(
            classes.iconDiv,
            showSocialLogin && classes.iconDivSocials
          )}
        >
          <img alt='' src={require('src/img/icons/login.svg')}></img>
        </div>
        <div className={classes.title}>
          <FormattedMessage id='mdlLogin.loginTitle' defaultMessage='Log in' />
        </div>

        <div className={classes.emailLoginBlock}>
          <TextInput
            name='email'
            label={intl.formatMessage({
              id: 'inputText.email',
              defaultMessage: 'Email',
            })}
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            touched={touched}
            errors={errors}
            size={elementSize}
            className={classes.textField}
            intl={intl}
          />
          <EyeInput
            id='outlined-basic'
            label={intl.formatMessage({
              id: 'inputText.password',
              defaultMessage: 'Password',
            })}
            variant='outlined'
            className={classes.textField}
            onChange={handleChange}
            onBlur={handleBlur}
            name='password'
            margin='normal'
            containerClassName={classes.textField}
            value={values.password}
            error={touched.password && !!errors.password}
            iconStyle={{ top: isBigScreen ? 28 : 24 }}
            size={elementSize}
            helperText={
              errors.password && touched.password
                ? intl.formatMessage({
                    id: `errors.password.${errors.password}`,
                    defaultMessage: 'Error',
                  })
                : ''
            }
            FormHelperTextProps={{
              className: classes.errorText,
            }}
          />
          <div className={classes.checkboxDiv}>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <CheckboxInput
                  value={values.remember}
                  onChange={() => setFieldValue('remember', !values.remember)}
                  name='remember-me'
                />
              }
              label={intl.formatMessage({
                id: 'mdlLogin.rememberMe',
                defaultMessage: 'Remember me',
              })}
            />
          </div>
          <button
            className={classes.button}
            onClick={handleSubmit}
            type='submit'
          >
            <FormattedMessage id='btns.login' defaultMessage='Log in' />
          </button>
          {showSocialLogin ? (
            <>
              {/* <div className={classes.dividerDiv}>
              <div className={classes.dividerSide}></div>
              <div className={classes.dividerText}>
                <FormattedMessage id='mdlLogin.or' defaultMessage='Or' />
              </div>
              <div className={classes.dividerSide}></div>
            </div> */}
              <div className={classes.socialDiv}>
                <FBLogin customer={type === 'customer'} size={elementSize} />
                <LILogin customer={type === 'customer'} size={elementSize} />
                <GoogleLogin
                  customer={type === 'customer'}
                  size={elementSize}
                />
              </div>
            </>
          ) : null}
          <div
            onClick={handleForgotPassword}
            className={classes.forgotDiv}
            tabIndex='0'
            onKeyDown={(e) => e.keyCode === 13 && handleForgotPassword()}
          >
            <FormattedMessage
              id='forgotPwd.forgotPwd'
              defaultMessage='Forgot Password'
            />
          </div>
        </div>

        <>
          <div className={clsx(classes.dividerDiv, classes.customerDivider)}>
            <div className={classes.dividerSide}></div>
            <div className={classes.dividerText}>
              <FormattedMessage id='mdlLogin.or' defaultMessage='Or' />
            </div>
            <div className={classes.dividerSide}></div>
          </div>
          <button
            className={clsx(classes.button, classes.registerButton)}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (type === 'customer') props.setRegistration();
              else {
                closeModal();
                toggleLoginModal(true, 'isRegModalOpen');
                dataLayerPush('registration_click');
              }
            }}
          >
            <FormattedMessage id='btns.signUp' defaultMessage='Sign up' />
          </button>
        </>
      </Form>
    </div>
  );
}
