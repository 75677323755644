import React from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import SelectInput from 'src/input/components/SelectInput';
import TextInput from 'src/input/components/TextInput';
import { USER_ROLES_MAP } from 'src/customer/constants';

import useStyles from './styles';

const UserInfoModal = ({
  isOpen,
  toggleInfoModal,
  setLanguage,
  user = {},
  intl,
}) => {
  const classes = useStyles();

  const closeModal = () => {
    toggleInfoModal(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.container}>
          <TextInput
            name="fullName"
            disabled
            values={user}
            label={intl.formatMessage({
              id: 'inputText.fullName',
              defaultMessage: 'Full Name',
            })}
            className={classes.textField}
            intl={intl}
          />
          <TextInput
            name="email"
            label={intl.formatMessage({
              id: 'inputText.email',
              defaultMessage: 'Email',
            })}
            disabled
            values={user}
            className={classes.textField}
            intl={intl}
          />
          <TextInput
            name="jobTitle"
            label={intl.formatMessage({
              id: 'inputText.jobTitle',
              defaultMessage: 'Job Title',
            })}
            disabled
            values={user}
            className={classes.textField}
            intl={intl}
          />
          <TextInput
            name="department"
            label={intl.formatMessage({
              id: 'inputText.department',
              defaultMessage: 'Department',
            })}
            disabled
            values={user}
            className={classes.textField}
            intl={intl}
          />
          {/* <div className={classes.checkboxDiv}>
            <Checkbox
              checked={!!user.is_contact_person}
              inputProps={{
                id: 'is_contact_person',
              }}
              className={classes.checkbox}
              disabled
            />
            <label htmlFor="is_contact_person">
              <FormattedMessage
                id="usersList.isContactPerson"
                defaultMessage="Is contact person"
              />
            </label>
          </div> */}
          <TextInput
            name="phone"
            label={intl.formatMessage({
              id: 'inputText.phone',
              defaultMessage: 'Phone',
            })}
            disabled
            values={user}
            className={classes.textField}
            intl={intl}
          />
          <TextInput
            name="role"
            label={intl.formatMessage({
              id: 'inputText.role',
              defaultMessage: 'Role',
            })}
            disabled
            value={
              USER_ROLES_MAP[user.role]
                ? intl.formatMessage({
                    id: USER_ROLES_MAP[user.role],
                    defaultMessage: USER_ROLES_MAP[user.role],
                  })
                : ''
            }
            className={classes.textField}
            intl={intl}
          />
          <FormControl
            className={clsx(classes.textField, classes.select)}
            variant="outlined"
          >
            <InputLabel id="language">
              <FormattedMessage
                id="inputText.defaultLanguage"
                defaultMessage="Default language"
              />
            </InputLabel>
            <SelectInput
              name="language"
              labelId="language"
              value={'en'}
              onChange={(value) => {
                //send request
                closeModal();
                // if success setLanguage(value)
              }}
              displayEmpty
              label={intl.formatMessage({
                id: 'inputText.defaultLanguage',
                defaultMessage: 'Default language',
              })}
            >
              {[
                { value: 'en', name: 'English' },
                { value: 'he', name: 'Hebrew' },
              ].map((e, i) => (
                <MenuItem key={i} value={e.value}>
                  {e.name}
                </MenuItem>
              ))}
            </SelectInput>
          </FormControl>
          <button className={classes.cancelButton} onClick={closeModal}>
            <FormattedMessage id="btns.close" defaultMessage="Close" />
          </button>
        </div>
      </Fade>
    </Modal>
  );
};

export default UserInfoModal;
