import React from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';

import CheckboxInput from 'src/input/components/CheckboxInput';
import Upload from 'src/img/icons/Upload';
import Camera from 'src/img/icons/Camera';
import { TYPES_OF_EMPLOYMENT } from 'src/user/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    padding: '0 20px',
  },
  inputContainer: {
    width: '100%',
    maxWidth: '310px',
    height: '49px',
    border: `1px dashed ${theme.palette.primary.medium}`,
    borderRadius: '6px',
    marginBottom: '28px',
    '&:focus': {
      border: `1px solid transparent`,
      boxShadow: `0 0 0 2px ${theme.palette.primary.medium}`,
    },
  },
  errorInputContainer: {
    border: `1px dashed red`,
  },
  label: {
    margin: 0,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    height: '100%',
    cursor: 'pointer',
  },
  fileName: {
    width: '230px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 10,
  },
  uploadTitle: {
    margin: 0,
  },
  input: {
    display: 'none',
  },
  size: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  userTypeContainer: {
    width: '100%',
    maxWidth: 310,
    paddingBottom: 20,
    borderBottom: `1px ${theme.palette.divider} solid`,
    color: theme.palette.text.secondary,
    '& label.Mui-focused': {
      color: `${theme.palette.text.secondary} !important`,
    },
    '& span': {
      fontSize: 14,
    },
  },
  typeLabel: {
    marginBottom: 10,
    fontSize: 14,
  },
  formGroup: {
    flexDirection: 'row',
    marginLeft: -20,
  },
  checkbox: {
    marginLeft: 10,
  },
  error: {
    color: 'red',
    marginTop: 5,
    fontSize: 14,
  },
  toggleDiv: {
    width: '100%',
    maxWidth: '310px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    alignItems: 'center',
    marginLeft: 3,
    magrinRight: 6,
  },
  toggleTitle: {
    color: theme.palette.primary.dark,
    fontSize: '14px',
  },
  switch: {
    marginRight: '-12px',
  },
  publicDesc: {
    width: '100%',
    maxWidth: '310px',
    fontSize: 14,
    marginTop: -15,
    marginBottom: 20,
    color: theme.palette.grey['500'],
  },
  marginTop: {
    marginTop: 15,
  },
}));

export default ({
  values,
  errors,
  handleChange,
  setFieldValue,
  touched,
  showCVError,
  intl,
}) => {
  const classes = useStyles();
  const cvError = errors.cv && touched.cv;

  return (
    <div className={classes.container}>
      <div
        tabIndex='0'
        className={clsx(
          classes.inputContainer,
          cvError && classes.errorInputContainer
        )}
        onKeyDown={(e) =>
          e.keyCode === 13 &&
          document.getElementById('icon-button-file').click()
        }
      >
        <input
          accept='.pdf'
          className={classes.input}
          id='icon-button-file'
          type='file'
          onChange={({ target }) => {
            if (target.files[0].type !== 'application/pdf') {
              return showCVError('type');
            }
            target.files[0].size >= 10515973
              ? showCVError('size')
              : setFieldValue('cv', target.files[0]);
          }}
        />
        <label htmlFor='icon-button-file' className={classes.label}>
          <Upload />
          {values.cv?.name ? (
            <div className={classes.fileName}>{values.cv?.name}</div>
          ) : (
            <>
              <p className={classes.uploadTitle}>
                <FormattedMessage
                  id='inputText.uploadCV'
                  defaultMessage='Upload CV'
                />
              </p>
              <span className={classes.size}>
                <FormattedMessage id='cv.max10mb' defaultMessage='(max 10mb)' />
              </span>
            </>
          )}
        </label>
        {cvError ? (
          <div className={classes.error}>
            <FormattedMessage
              id='errors.cv.required'
              defaultMessage='Required'
            />
          </div>
        ) : null}
      </div>

      <div
        tabIndex='0'
        className={clsx(classes.inputContainer, cvError && classes.marginTop)}
        onKeyDown={(e) =>
          e.keyCode === 13 && document.getElementById('icon-button-pic').click()
        }
      >
        <input
          accept='image/*'
          className={classes.input}
          id='icon-button-pic'
          type='file'
          onChange={({ target }) => setFieldValue('avatar', target.files[0])}
        />
        <label htmlFor='icon-button-pic' className={classes.label}>
          <Camera />
          {values.avatar?.name ? (
            <div className={classes.fileName}>{values.avatar?.name}</div>
          ) : (
            <>
              <p className={classes.uploadTitle}>
                <FormattedMessage
                  id='inputText.uploadPhoto'
                  defaultMessage='Upload Photo'
                />
              </p>
              <span className={classes.size}>
                <FormattedMessage id='cv.max10mb' defaultMessage='(max 10mb)' />
              </span>
            </>
          )}
        </label>
      </div>
      <div className={classes.userTypeContainer}>
        <FormControl>
          <FormLabel className={classes.typeLabel}>
            <FormattedMessage
              id='userDetails.areYou'
              defaultMessage='Looking for a job as:'
            />
          </FormLabel>
          <FormGroup className={classes.formGroup}>
            {TYPES_OF_EMPLOYMENT.map((item) => (
              <FormControlLabel
                name={item.type}
                key={item.type}
                control={
                  <CheckboxInput
                    value={values.typeOfEmployment.includes(item.type)}
                    onChange={(e) => {
                      setFieldValue(
                        'typeOfEmployment',
                        values.typeOfEmployment.includes(item.type)
                          ? values.typeOfEmployment.filter(
                              (i) => i !== item.type
                            )
                          : [...values.typeOfEmployment, item.type]
                      );
                    }}
                    name={item.type}
                  />
                }
                label={intl.formatMessage({
                  id: item.labelId,
                  defaultMessage: 'Employment type',
                })}
                className={classes.checkbox}
              />
            ))}
          </FormGroup>
          {errors.typeOfEmployment ? (
            <FormHelperText className={classes.error}>
              <FormattedMessage
                id='errors.atLeast1'
                defaultMessage='You have to choose at least 1'
              />
            </FormHelperText>
          ) : null}
        </FormControl>
      </div>
      <div className={classes.toggleDiv}>
        <span className={classes.toggleTitle}>
          <FormattedMessage
            id='userDetails.publicUserShortExplain'
            defaultMessage='Let employers find you - Public User'
          />
        </span>
        <Switch
          id='allowCompanies'
          onChange={handleChange}
          checked={values.allowCompanies}
          color='primary'
          inputProps={{ 'aria-label': 'primary checkbox' }}
          classes={{
            root: classes.switch,
          }}
        />
      </div>
      <div className={classes.publicDesc}>
        <FormattedMessage
          id='userDetails.publicUserLongExplain'
          defaultMessage='Public: Hiring managers will be able to view your profile and reach out about potential opportunities.'
        />
      </div>
      <div className={classes.toggleDiv}>
        <span className={classes.toggleTitle}>
          <FormattedMessage
            id='userDetails.allowJobNotification'
            defaultMessage='Allow Job notifications'
          />
        </span>
        <Switch
          id='jobNotification'
          checked={values.jobNotification}
          onChange={handleChange}
          color='primary'
          inputProps={{ 'aria-label': 'primary checkbox' }}
          classes={{
            root: classes.switch,
          }}
        />
      </div>
    </div>
  );
};
