import { createApiAction, createAsyncActionRequestTypes } from 'src/api';
import queryString from 'query-string';

export const CLEAR_CUSTOMER_JOBS = 'CLEAR_CUSTOMER_JOBS';
export const CLEAR_VIEWED_JOB = 'CLEAR_VIEWED_JOB';
export const CREATE_JOB = createAsyncActionRequestTypes('CREATE_JOB');
export const EDIT_JOB = createAsyncActionRequestTypes('EDIT_JOB');
export const PROMOTE_JOB = createAsyncActionRequestTypes('PROMOTE_JOB');
export const GET_NEXT_ID = createAsyncActionRequestTypes('GET_NEXT_ID');
export const FETCH_JOB_NAMES = createAsyncActionRequestTypes('FETCH_JOB_NAMES');
export const FETCH_CUSTOMER_JOBS = createAsyncActionRequestTypes(
  'FETCH_CUSTOMER_JOBS'
);
export const FETCH_CUSTOMER_JOB_INFO = createAsyncActionRequestTypes(
  'FETCH_CUSTOMER_JOB_INFO'
);

export const clearCustomerJobs = () => ({
  type: CLEAR_CUSTOMER_JOBS,
});

export const clearViewedJob = () => ({
  type: CLEAR_VIEWED_JOB,
});

export const createJob = (body) =>
  createApiAction(
    {
      method: 'POST',
      url: '/jobs/create',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    },
    CREATE_JOB
  );

export const editJob = (body) =>
  createApiAction(
    {
      method: 'PATCH',
      url: '/jobs',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    },
    EDIT_JOB
  );

export const promoteJob = (body) =>
  createApiAction(
    {
      method: 'PATCH',
      url: '/jobs/promote-job',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    },
    PROMOTE_JOB
  );

export const getNextJobId = () =>
  createApiAction(
    {
      method: 'POST',
      url: '/jobs/get-next-job-id',
    },
    GET_NEXT_ID
  );

export const fetchJobNames = (value = '') =>
  createApiAction(
    {
      url: `/jobs/customer-autocomplete?value=${value}`,
      params: {
        value: value,
      },
    },
    FETCH_JOB_NAMES
  );

export const fetchMyJobs = ({ page = 1, ...params } = {}) =>
  createApiAction(
    {
      url:
        '/jobs/customer-jobs?' +
        queryString.stringify(
          { ...params, page: page - 1 },
          { skipEmptyString: true }
        ),
      params: {
        ...params,
      },
    },
    FETCH_CUSTOMER_JOBS
  );

export const fetchJobInfo = (id) =>
  createApiAction(
    {
      url: '/jobs/:id/info',
      params: {
        id,
      },
    },
    FETCH_CUSTOMER_JOB_INFO
  );
