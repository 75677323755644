import { createStore as createStoreRedux, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import api from 'src/api/redux/middleware';

import { sagaMiddleware, runSaga } from './sagasMiddleware';
import rootSaga from './rootSaga';
import reducer from './reducer';

const getPreloadState = () => {
  if (process.env.BROWSER && window.__INITIATL_STATE__) {
    const state = window.__INITIATL_STATE__;
    delete window.__INITIATL_STATE__;

    return state;
  }

  return {};
};

let storeInstance;
let historyInstance;
const createStore = (url = '/', intitalState = getPreloadState()) => {
  const history = process.env.BROWSER
    ? createBrowserHistory()
    : createMemoryHistory({
        initialEntries: [url],
      });
  historyInstance = history;

  const middleWare = [api, sagaMiddleware, routerMiddleware(history)].concat(
    process.env.BROWSER && process.env.NODE_ENV === 'development' ? logger : []
  );

  const store = createStoreRedux(
    reducer(history),
    intitalState,
    applyMiddleware(...middleWare)
  );

  storeInstance = store;

  // TODO: add own router redux
  // history.listen(() => store.dispatch({ type: 'CHANGED' }));

  runSaga(rootSaga(store));

  return {
    store,
    history,
  };
};

export const getStoreInstance = () => storeInstance;
export const getHistoryInstance = () => historyInstance;

export default createStore;
