// libraries and functions
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useUnsubscribeEmail from 'src/jobs/hooks/unsubscribeEmail';
import { navigateIntl } from 'src/utils/history';
import useStyles from './styles';
// import queryString from 'query-string';

// components
import Spinner from 'src/layout/Spinner';
import Breadcrumbs from 'src/common/components/Breadcrumbs';
import Pagination from 'src/common/components/Pagination';
import Searchbar from 'src/layout/components/Searchbar';
import LandingModal from 'src/about/components/LandingModal';
import HelmetDefaultTags from 'src/layout/components/HelmetDefaultTags';
import Button from '@material-ui/core/Button';
// import JobCategories from 'src/jobs/components/JobCategories';
import JobFilters from '../JobFilters/JobFilters';
import JobItem from '../JobItem/JobItem';
import JobDesc from '../JobDesc';

// modals
import AddCVModal from 'src/user/components/AddCVModal';
import AlertModal from 'src/jobs/components/AlertModal';

//icons
import Bell from 'src/img/icons/Bell';
import Unauthorized from 'src/img/icons/Unauthorized';

const colors = [
  '#BADBFF',
  '#D4CFF6',
  '#f5cce2',
  '#82BCFF',
  '#FFB5B5',
  '#ccf3f5',
];
const companyColorMap = {};
let activeColor = 0;
const MOBILE_WIDTH = 900;

const Jobs = ({
  jobs: {
    list,
    total,
    pages,
    loading,
    currentJobIndex,
    currentJob = {},
    seoInfo,
  },
  isLogged,
  isCleanQuery,
  pathname,
  query: { page = 1, saved, applied, companyName, title },
  query,
  setCurrentJobIndexD,
  goTo,
  toggleLoginModal,
  isSagaRunning,
  resolvedOnSSR,
  initiateJobsFetch,
  toggleAlertModal,
  dir,
  enqueueSnackbar,
  trackJobAction,
  intl,
  currentArticle,
}) => {
  useUnsubscribeEmail(enqueueSnackbar);
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);
  const [jobDescShown, setJobDescShown] = useState(
    list?.length > 0 && Object.keys(currentJob).length > 0
  );
  const jobDescRef = useRef(null);
  const job = list ? list[currentJobIndex] : null;
  const totalJobs = total
    ? total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : null;
  // if was resolved on the server we do not show spinner at all
  const isSpinnerShown = loading || (resolvedOnSSR ? false : isSagaRunning);
  const allowedToBrowse = (page) => {
    // allow to browse up to page 1
    if (page <= 1 || isLogged) return page;
    else return false;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window?.innerWidth < 900 && !jobDescShown && !isSpinnerShown) {
        // mobile and single page
        window.scrollTo(0, 0);
      }
    }, 100);

    return () => {
      // clears timeout before running the new effect
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (isLogged && list.length)
      trackJobAction(list[0].id, {
        jobTitleSearch: title,
        companyNameSearch: companyName,
      });
  }, []);

  return (
    <>
      <HelmetDefaultTags
        title={seoInfo?.title}
        desc={seoInfo?.description}
        canonicalUrl={seoInfo?.canonicalUrl}
        xDefaultUrl={seoInfo?.xDefaultUrl}
        hreflangUrls={seoInfo?.hreflangUrls}
      />
      {true ? ( //allowedToBrowse(page)
        <>
          <div className={classes.container}>
            <Searchbar />
            <div
              className={clsx(
                classes.contentContainer,
                isLogged && classes.contentContainerLogged
              )}
            >
              <div className={classes.jobsContainer}>
                <div className={classes.filtersRow}>
                  {!isSpinnerShown &&
                    (seoInfo?.breadcrumbs?.links.length > 0 ||
                      seoInfo?.breadcrumbs?.screenName) && (
                      <div className={classes.breadcrumbsContainer}>
                        <Breadcrumbs
                          defaultMainName={'breadCrumbs.jobs'}
                          hideWhenEmpty
                          links={seoInfo?.breadcrumbs?.links}
                          screenName={seoInfo?.breadcrumbs?.screenName}
                        />
                      </div>
                    )}
                  <div className={classes.flexWrap}>
                    <div className={classes.foundJobsInfo}>
                      {isSpinnerShown ? (
                        <div className={classes.generatedH1Skeleton} />
                      ) : (
                        <h1>
                          {seoInfo?.generatedH1}
                          {page > 1 && (
                            <span>
                              {' ' +
                                intl.formatMessage(
                                  {
                                    id: 'breadCrumbs.pagination',
                                    defaultMessage: 'Page {page}',
                                  },
                                  {
                                    page,
                                  }
                                )}
                            </span>
                          )}
                        </h1>
                      )}
                      {isSpinnerShown
                        ? !isCleanQuery && (
                            <div className={classes.introSkeleton} />
                          )
                        : seoInfo?.intro && (
                            <div className={classes.freeText}>
                              {seoInfo?.intro}
                            </div>
                          )}
                      <div className={classes.filtersWrapper}>
                        {!saved && <JobFilters />}
                      </div>
                      {!isSpinnerShown && (
                        <div className={classes.jobsNum}>
                          <FormattedMessage
                            id={`jobs.${
                              totalJobs === 1 ? 'one' : totalJobs ? 'num' : 'no'
                            }${applied ? 'Applied' : saved ? 'Saved' : ''}Jobs`}
                            defaultMessage='Number of jobs'
                            values={{
                              myVar: totalJobs,
                            }}
                          />
                        </div>
                      )}
                      {!isSpinnerShown && isLogged && (
                        <div
                          className={clsx(
                            classes.alertDiv,
                            dir === 'rtl' && classes.alertDivRtl
                          )}
                          onClick={() => {
                            toggleAlertModal(true);
                          }}
                        >
                          <Bell size={isSmallScreen ? '16px' : null} />
                          <p>
                            <FormattedMessage
                              id='jobs.createJobAlert'
                              defaultMessage='Create job alert'
                            />
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {isSpinnerShown ? (
                  <div className={classes.loaderDiv}>
                    <Spinner isSmall />
                  </div>
                ) : (
                  total > 0 && (
                    <div className={classes.jobsInnerContainer}>
                      <div className={classes.jobsColumn}>
                        <div
                          className={clsx(
                            classes.jobsPanel,
                            job?.similarJobs &&
                              job.similarJobs.length &&
                              classes.withRecommended
                          )}
                        >
                          {list.map((item, index) => {
                            const color =
                              colors[
                                activeColor === 5
                                  ? (activeColor = 0)
                                  : activeColor
                              ];
                            return (
                              <div className={classes.jobCard} key={index}>
                                <JobItem
                                  handleClick={(ref) => {
                                    if (isSmallScreen) {
                                      if (
                                        !job ||
                                        !job?.id ||
                                        job?.id !== item.id ||
                                        !jobDescShown
                                      ) {
                                        setJobDescShown(true);
                                        setCurrentJobIndexD(index);
                                        setTimeout(() => {
                                          let position =
                                            ref?.current?.getBoundingClientRect();

                                          position &&
                                            window.scrollTo({
                                              left: position.left,
                                              top:
                                                position.top +
                                                window.scrollY -
                                                10,
                                              behavior: 'smooth',
                                            });
                                        }, 100);

                                        if (isLogged)
                                          trackJobAction(item.id, {
                                            jobTitleSearch: title,
                                            companyNameSearch: companyName,
                                          });
                                      } else {
                                        setJobDescShown(false);
                                        // setCurrentJobIndexD(index);
                                      }
                                    } else {
                                      // big screen
                                      if (
                                        !job ||
                                        !job?.id ||
                                        job?.id !== item.id
                                      ) {
                                        setCurrentJobIndexD(index);
                                        if (isLogged)
                                          trackJobAction(item.id, {
                                            jobTitleSearch: title,
                                            companyNameSearch: companyName,
                                          });
                                      }
                                    }
                                  }}
                                  job={{
                                    ...item,
                                    clicked:
                                      job &&
                                      job.id === item.id &&
                                      ((isSmallScreen && jobDescShown) ||
                                        !isSmallScreen),
                                  }}
                                  color={
                                    companyColorMap[item.companyName] ||
                                    ((companyColorMap[item.companyName] =
                                      color),
                                    activeColor++,
                                    color)
                                  }
                                  onCompanyNameClick={(e) => {
                                    e.stopPropagation();
                                    initiateJobsFetch({
                                      companyName: item.companyName,
                                    });
                                  }}
                                  isSmallScreen={isSmallScreen}
                                  jobDescShown={jobDescShown}
                                />
                              </div>
                            );
                          })}
                        </div>
                        {total > 0 && (
                          <div className={classes.paginationDiv}>
                            <Pagination
                              query={query}
                              pathname={pathname}
                              pages={pages}
                              currentPage={parseInt(page)}
                              shown={6}
                              allowedToBrowse={allowedToBrowse}
                              onPageClick={(page) => {
                                if (!allowedToBrowse(page))
                                  toggleLoginModal('true', 'isLoginClientOpen');
                                else goTo('page', page);
                                setCurrentJobIndexD(0);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {total && total !== 0 && (
                        <div className={classes.jobDescColumn} id='jobDesc'>
                          <JobDesc
                            jobDescRef={jobDescRef}
                            isHidden={isSmallScreen}
                            isDerectLink={false}
                            onCompanyNameClick={() =>
                              initiateJobsFetch({
                                companyName: job.companyName,
                              })
                            }
                            companyLinkEnabled
                            {...job}
                          />
                        </div>
                      )}
                    </div>
                  )
                )}
                {!isSpinnerShown &&
                  total !== 0 &&
                  seoInfo?.freeText?.trim() &&
                  page === 1 && (
                    <div className={classes.freeTextWrapper}>
                      {seoInfo?.freeText}
                    </div>
                  )}
                {/* {isCleanQuery && langCode == 'he' && (
                <JobCategories
                  query={{ ...query, location: defaultLocation }}
                  intl={intl}
                />
              )} */}
              </div>
            </div>
            <LandingModal />
            {isLogged && (
              <>
                <AddCVModal />
                <AlertModal />
              </>
            )}
          </div>
          {!isSpinnerShown && currentArticle && currentArticle.id && (
            <div className={classes.articleContainer}>
              <article
                className={classes.myArticle}
                id={`'article'${currentArticle.id}`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentArticle?.htmlContent?.replace(
                      /<img[^>]*>/g,
                      ''
                    ), // hide image
                  }}
                />
              </article>
            </div>
          )}
        </>
      ) : (
        <div className={classes.unauthorizedContainer}>
          <Unauthorized size={isSmallScreen ? 50 : 80} color={'#20285C'} />
          <h2>
            <FormattedMessage
              id={'jobs.unauthorized.title'}
              defaultMessage='No authorization found'
            />
          </h2>
          <p>
            <FormattedMessage
              id={'jobs.unauthorized.text'}
              defaultMessage='This page is not publicly available.{br}To access it please login first.'
              values={{
                br: <br />,
              }}
            />
          </p>
          <div>
            <Button
              onClick={() => {
                toggleLoginModal('true', 'isLoginClientOpen');
              }}
              className={classes.loginBtn}
              variant='contained'
              color='primary'
              size={isSmallScreen ? 'small' : 'large'}
            >
              <FormattedMessage id={'btns.login'} defaultMessage='Login' />
            </Button>
            <Button
              onClick={() => {
                navigateIntl('/');
              }}
              className={classes.homeBtn}
              variant='contained'
              color='primary'
              size={isSmallScreen ? 'small' : 'large'}
            >
              <FormattedMessage
                id={'btns.backToHomePage'}
                defaultMessage='Back to home page'
              />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Jobs;
