import { getStoreInstance, getHistoryInstance } from 'src/store';
import { replaceLangInPath, allLanguages } from 'src/intl/utils';

export const navigateIntl = (route, options) => {
  const history = getHistoryInstance();
  history.push(getNavigationUrl(route), options);
};

export const getNavigationUrl = (route) => {
  const store = getStoreInstance();
  const {
    locale: { language },
  } = store.getState();

  const newUrl = replaceLangInPath(
    route,
    language === allLanguages.en.language ? null : language
  );

  return newUrl;
};
