import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';

import resolveRoute from 'src/utils/resolveRoute';
import {
  changeCompanyValue,
  initiateAutocomplete,
  initiateJobsFetch,
} from 'src/jobs/redux/actions';
import { getDefaultCompaniesSaga } from 'src/jobs/redux/sagas/index';
import { navigateIntl } from 'src/utils/history';

import CompanyDropdown from './CompanyDropdown';

export default compose(
  withRouter,
  withSnackbar,
  resolveRoute(function* () {
    yield getDefaultCompaniesSaga();
  }),
  connect(
    (state, props) => ({
      user: state.user.profile,
      isAuthenticated: Boolean(state.user.profile.confirmed),
      query: queryString.parse(props.location.search),
      result: state.jobs.autocomplete?.result?.companyName,
      defaultCompanies: state.jobs.autocomplete?.defaultCompanies,
      loading: state.jobs.jobs.loading,
      companyValue: state.jobs.autocomplete.companyValue,
    }),
    {
      initiateAutocomplete,
      changeCompanyValue,
      initiateJobsFetch,
    },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      handleSubmit: async (selectedCompanies) => {
        const currentParams = queryString.parse(parentProps.location.search);
        const { companyName, ...rest } = currentParams;

        const params = {
          ...rest,
          ...(selectedCompanies.length
            ? {
                companyName: selectedCompanies.map((company) =>
                  company.toLowerCase()
                ),
              }
            : {}),
        };

        // reset page
        delete params.page;

        navigateIntl(
          `/?${queryString.stringify(params, {
            skipEmptyString: true,
            arrayFormat: 'comma',
          })}`
        );
        dispatchProps.initiateJobsFetch(params);
      },
    })
  ),
  injectIntl
)(CompanyDropdown);
