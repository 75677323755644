import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import CountryAutocomplete from 'src/input/components/CountryAutocomplete';
import CityAutocomplete from 'src/input/components/CityAutocomplete';
import StateAutocomplete from 'src/input/components/StateAutocomplete';
import { cities } from 'src/Dashboards/AutoCompleteConst';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    padding: '0 20px',
  },
  textField: {
    width: '100%',
    maxWidth: 310,
    '& fieldset': {
      borderRadius: 6,
    },
  },
  autocomplete: {
    width: '100%',
    maxWidth: 310,
  },
  dense: {
    marginTop: 19,
  },
  privacyDiv: {
    fontSize: 14,
    fontWeight: '500',
    color: theme.palette.text.disabled,
    marginTop: 10,
    width: '100%',
    maxWidth: 310,
  },
  privacyLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export default ({
  values,
  errors,
  handleChange,
  setFieldValue,
  touched,
  handleSubmit,
  togglePrivacyPopup,
  intl,
}) => {
  const classes = useStyles();
  const countryPlaceholder = intl.formatMessage({
    id: 'pleaseSelectCountry',
    defaultMessage: 'Please select a country',
  });
  const [citiesList, setCitiesList] = useState([countryPlaceholder]);

  useEffect(() => {
    setCitiesList(
      values.country ? cities[values.country] || [] : [countryPlaceholder]
    );
  }, [values.country]);

  return (
    <div className={classes.container}>
      <CountryAutocomplete
        errors={errors}
        values={values}
        touched={touched}
        setFieldValue={setFieldValue}
        variant="outlined"
        textInputClassName={classes.autocomplete}
        className={clsx(classes.autocomplete, classes.textField)}
        intl={intl}
      />
      <StateAutocomplete
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        errors={errors}
        values={values}
        touched={touched}
        variant="outlined"
        textInputClassName={classes.autocomplete}
        className={clsx(classes.textField, classes.dense)}
        intl={intl}
      />
      <CityAutocomplete
        setFieldValue={setFieldValue}
        errors={errors}
        values={values}
        touched={touched}
        citiesList={citiesList}
        variant="outlined"
        textInputClassName={classes.autocomplete}
        className={clsx(classes.textField, classes.dense)}
        intl={intl}
      />
      <div className={classes.privacyDiv}>
        <FormattedMessage
          id="registrationForm.user.3.agreeToTerms"
          defaultMessage="By completing this form, I agree to {span1} and {span2}. I want to receive promotional emails, unless I opt out."
          values={{
            span1: (
              <span
                className={classes.privacyLink}
                onClick={() => {
                  togglePrivacyPopup('user', 'terms');
                }}
              >
                <FormattedMessage id="terms.terms" defaultMessage="Terms" />
              </span>
            ),
            span2: (
              <span
                className={classes.privacyLink}
                onClick={() => {
                  togglePrivacyPopup('user', 'privacyPolicy');
                }}
              >
                <FormattedMessage
                  id="privacy.privacyPolicy"
                  defaultMessage="Privacy Policy"
                />
              </span>
            ),
          }}
        />
      </div>
    </div>
  );
};
