import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';
import { loadableReady } from '@loadable/component';
import IntlProvider from 'src/intl/components/IntlProvider';
// import * as serviceWorker from '../serviceWorker';

import 'react-bootstrap-typeahead/css/Typeahead.css';

import createStore from 'src/store';

import App from './App';
// import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line no-unused-expressions
import(/* webpackPreload: true */ './index.css');

const { history, store } = createStore(window.location.url);

function Client() {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <IntlProvider>
              {/* Comment out as Strict Mode doesnt not work with material ui(SSR with function makeStyle class mismatch)*/}
              {/* <React.StrictMode> */}
              <App />
              {/* </React.StrictMode> */}
            </IntlProvider>
          </ConnectedRouter>
        </Provider>
      </Router>
    </HelmetProvider>
  );
}

loadableReady(() => {
  ReactDOM.hydrate(<Client />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
