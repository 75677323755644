import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default () => (
  <SvgIcon
    viewBox="0 0 22 20"
    style={{
      height: '20px',
      width: '22px',
    }}
  >
    <g id="camera" transform="translate(0.75 0.75)">
      <path
        d="M20 16C20 17.1046 19.1046 18 18 18L2 18C0.895431 18 1.35271e-16 17.1046 0 16L0 5C0 3.9 0.9 3 2 3L5 3L7 0L13 0L15 3L18 3C19.1046 3 20 3.89543 20 5L20 16Z"
        id="Path"
        fill="none"
        stroke="#0041E5"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M4 8C6.20914 8 8 6.20914 8 4C8 1.79086 6.20914 0 4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8Z"
        transform="translate(6 6)"
        id="Oval"
        fill="none"
        stroke="#0041E5"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </g>
  </SvgIcon>
);
