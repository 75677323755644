import createReducer from 'src/utils/createReducer';
import { LOG_OUT } from 'src/customer/redux/actions';
import { FETCH_CV, CLEAR_CV_PREVIEW } from 'src/customer/redux/actions/cv';

const INITIAL_STATE = { loading: false, cvs: {} };

export default createReducer(INITIAL_STATE, {
  [FETCH_CV.REQUEST]: (state) => ({ ...state, loading: true }),
  [FETCH_CV.SUCCESS]: (state, { data, params: { userId } }) => ({
    ...state,
    loading: false,
    cvs: {
      ...state.cvs,
      [userId]: data,
    },
  }),
  [FETCH_CV.FAILURE]: (state) => ({ ...state, loading: false }),
  [LOG_OUT]: () => INITIAL_STATE,
  [CLEAR_CV_PREVIEW]: () => INITIAL_STATE,
});
