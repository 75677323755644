import queryString from 'query-string';

import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const INITIATE_CVS_UPLOAD = 'INITIATE_CVS_UPLOAD';
export const INITIATE_CHECK_KEYS = 'INITIATE_CHECK_KEYS';
export const KEYS_REQUEST_TIMED_OUT = 'KEYS_REQUEST_TIMED_OUT';
export const UPDATE_UPLOADED_FILES = 'UPDATE_UPLOADED_FILES';
export const UPDATE_IMPORTED_CANDIDATES = 'UPDATE_IMPORTED_CANDIDATES';
export const CLEAR_CANDIDATES = 'CLEAR_CANDIDATES';
export const GET_CANDIDATE_CV2 =
  createAsyncActionRequestTypes('GET_CANDIDATE_CV2');
export const FETCH_CUSTOMER_JOBS_CANDIDATES = createAsyncActionRequestTypes(
  'FETCH_CUSTOMER_JOBS_CANDIDATES'
);
export const FETCH_AUTOCOMPLETE_RESULTS = createAsyncActionRequestTypes(
  'FETCH_AUTOCOMPLETE_RESULTS'
);

export const clearCandidates = () => ({
  type: CLEAR_CANDIDATES,
});

export const initiateCvsUpload = (data) => {
  return {
    type: INITIATE_CVS_UPLOAD,
    payload: data,
  };
};

export const initiateCheckKeys = () => {
  return {
    type: INITIATE_CHECK_KEYS,
  };
};

export const keysRequestTimedOut = ({ enqueueSnackbar, message }) => {
  return {
    type: KEYS_REQUEST_TIMED_OUT,
    payload: { enqueueSnackbar, message },
  };
};

export const updateUploadedFiles = (data) => {
  return {
    type: UPDATE_UPLOADED_FILES,
    payload: data,
  };
};

export const updateImportedCandidates = (data) => {
  return {
    type: UPDATE_IMPORTED_CANDIDATES,
    payload: data,
  };
};

export const UPLOAD_IMPORTED_CV =
  createAsyncActionRequestTypes('UPLOAD_IMPORTED_CV');
export const CHECK_KEYS = createAsyncActionRequestTypes('CHECK_KEYS');

export const fetchMyCandidates = ({ size = 4, page = 1, ...params } = {}) =>
  createApiAction(
    {
      url:
        '/jobs/customer-candidates?' +
        queryString.stringify(
          { ...params, page: page - 1, size },
          { skipEmptyString: true }
        ),
      params,
    },
    FETCH_CUSTOMER_JOBS_CANDIDATES
  );

export const fetchMyCandidatesForReal = ({ page = 1, ...params } = {}) =>
  createApiAction(
    {
      url:
        '/jobs/my-candidates?' +
        queryString.stringify(
          { ...params, page, size: 12 },
          { skipEmptyString: true }
        ),
      params,
    },
    FETCH_CUSTOMER_JOBS_CANDIDATES
  );
export const TOGGLE_IMPORT_CANDIDATES_MODAL = 'TOGGLE_IMPORT_CANDIDATES_MODAL';

export const toggleImportCandidatesModal = (isOpen) => ({
  type: TOGGLE_IMPORT_CANDIDATES_MODAL,
  isOpen,
});

export const getCandidateCV2 = (filename) =>
  createApiAction(
    {
      method: 'GET',
      url: `/customer/candidate/cv/:filename`,
      headers: {
        'Content-Type': 'application/pdf',
      },
      params: { filename },
      responseType: 'blob',
    },
    GET_CANDIDATE_CV2
  );

export const uploadImportedCv = (body) => {
  return createApiAction(
    {
      method: 'POST',
      url: '/customer/candidate-import',
      body: body,
    },
    UPLOAD_IMPORTED_CV
  );
};

export const checkKeys = (body) => {
  return createApiAction(
    {
      method: 'POST',
      url: '/customer/candidate-import/keys',
      body: body,
    },
    CHECK_KEYS
  );
};

export const fetchAutocompleteResults = (field, value) =>
  createApiAction(
    {
      url: `/customer/candidate/autocomplete?field=${field}&value=${value}`,
      params: {
        field,
        value,
      },
    },
    FETCH_AUTOCOMPLETE_RESULTS
  );

export const FETCH_IMPORTED_CANDIDATES = createAsyncActionRequestTypes(
  'FETCH_IMPORTED_CANDIDATES'
);

export const REFRESH_IMPORTED_CANDIDATES = createAsyncActionRequestTypes(
  'REFRESH_IMPORTED_CANDIDATES'
);

export const fetchImportedCandidates = ({ page = 1, ...params } = {}) =>
  createApiAction(
    {
      url:
        '/customer/candidate-import?' +
        queryString.stringify(
          { ...params, page, size: 12 },
          { skipEmptyString: true }
        ),
      params,
    },
    FETCH_IMPORTED_CANDIDATES
  );

export const refreshImportedCandidates = ({ page = 1, ...params } = {}) =>
  createApiAction(
    {
      url:
        '/customer/candidate-import?' +
        queryString.stringify(
          { ...params, page: page - 1, size: 12 },
          { skipEmptyString: true }
        ),
      params,
    },
    REFRESH_IMPORTED_CANDIDATES
  );
