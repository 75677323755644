import createReducer from 'src/utils/createReducer';

import { FETCH_SELECTED_CUSTOMER_DEPARTMENTS } from 'src/admin/redux/actions';

const INITIAL_STATE = {
  list: [],
  isLoading: false,
};

export default createReducer(INITIAL_STATE, {
  [FETCH_SELECTED_CUSTOMER_DEPARTMENTS.REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_SELECTED_CUSTOMER_DEPARTMENTS.SUCCESS]: (state, { data }) => ({
    ...state,
    list: data,
    isLoading: false,
  }),
  [FETCH_SELECTED_CUSTOMER_DEPARTMENTS.FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
});
