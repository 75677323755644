import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default () => (
  <SvgIcon
    viewBox="0 0 375 375"
    style={{
      height: 22,
      width: 22,
      marginLeft: -3,
    }}
  >
    <defs>
      <clipPath id="id1">
        <path
          d="M 34 10.292969 L 371 10.292969 L 371 375 L 34 375 Z M 34 10.292969 "
          clipRule="nonzero"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#id1)">
      <path
        fill="#0041E5"
        d="M 168.007812 10.292969 C 123.847656 10.292969 87.835938 46.285156 87.835938 90.417969 C 87.835938 132.777344 121.238281 167.414062 162.996094 170.125 C 91.59375 172.628906 34.175781 231.367188 34.175781 303.355469 L 60.902344 303.355469 C 60.902344 244.410156 108.816406 196.523438 167.796875 196.523438 C 214.460938 196.523438 253.816406 226.777344 268.328125 268.613281 L 288.996094 247.851562 C 268.433594 203.199219 224.066406 171.796875 172.183594 170.125 C 214.359375 167.933594 248.179688 133.089844 248.179688 90.417969 C 248.179688 46.285156 212.164062 10.292969 168.007812 10.292969 Z M 168.007812 37 C 197.65625 37 221.457031 60.789062 221.457031 90.417969 C 221.457031 120.046875 197.65625 143.835938 168.007812 143.835938 C 138.359375 143.835938 114.558594 120.046875 114.558594 90.417969 C 114.558594 60.789062 138.359375 37 168.007812 37 Z M 352.15625 223.335938 L 232.730469 342.585938 L 183.875 293.964844 L 165.085938 312.851562 L 232.730469 380.457031 L 370.945312 242.324219 Z M 352.15625 223.335938 "
        fillOpacity="0.7"
        fillRule="nonzero"
      />
    </g>
  </SvgIcon>
);
