import { combineReducers } from 'redux';

import allCustomers from './allCustomers';
import selectedCustomer from './selectedCustomer';
import autocompleteCustomers from './autocompleteCustomers';

export default combineReducers({
  allCustomers,
  selectedCustomer,
  autocompleteCustomers,
});
