import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: '35px',
    height: '20px',
    background: 'grey',
    borderRadius: '35px',
    position: 'relative',
    transition: 'background-color 0.2s',
  },
  button: {
    content: "''",
    position: 'absolute',
    top: '2px',
    left: '2px',
    width: '16px',
    height: '16px',
    borderRadius: '16px',
    transition: '0.2s',
    background: palette.background.paper,
    boxShadow: '0 0 2px 0 rgba(10, 10, 10, 0.29)',
  },
  active: {
    background: palette.primary.main,
    '& $button': {
      left: 'calc(100% - 2px)',
      transform: 'translateX(-100%)',
    },
  },
}));

export default ({ isOn, handleToggle, id, activeClass }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.label,
        isOn && classes.active,
        isOn && activeClass
      )}
      onClick={handleToggle}
    >
      <span className={classes.button} />
    </div>
  );
};
