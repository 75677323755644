import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  root: {
    position: 'relative',
    padding: 20,
  },
  blueWave: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '15vh',
    marginTop: -20,
    minHeight: 106,
    maxHeight: 215,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  closeDiv: {
    width: '100%',
  },
  closeIcon: {
    cursor: 'pointer',
    borderRadius: 6,
    width: 34,
    height: 34,
    '&:focus': {
      backgroundColor: palette.primary.medium,
    },
  },
  iconDiv: {
    display: 'none',
    justifyContent: 'center',
    width: '16vh',
    height: '16vh',
    marginTop: -40,
    maxWidth: 190,
    maxHeight: 190,
    minWidth: 90,
    minHeight: 90,
  },
  iconDivSocials: {
    display: 'none',
  },
  title: {
    color: palette.primary.dark,
    fontSize: '24px',
    fontWeight: 'bold',
    height: 'calc(15vh - 49px)',
    minHeight: 57,
  },
  emailLoginBlock: {
    width: '100%',
    maxWidth: 310,
    order: 3,
  },
  textField: {
    width: '100%',
    '& fieldset': {
      borderRadius: 6,
    },
  },
  errorText: {
    color: 'red',
    fontSize: '14px',
    marginTop: 0,
    marginLeft: 0,
    fontWeight: 'normal',
    textAlign: 'left',
  },
  checkboxDiv: {
    width: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    paddingLeft: 2,
  },
  button: {
    height: '40px',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: 16,
    color: palette.text.white,
    '&:hover': {
      boxShadow: '-3px 3px 7px 0 rgba(0,0,0,0.22)',
    },
    backgroundColor: palette.primary.main,
    width: '100%',
    maxWidth: 310,
    borderRadius: '6px',
  },
  forgotDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '100%',
    borderRadius: 6,
    color: '#9A9DAE',
    cursor: 'pointer',
    '&:focus': {
      backgroundColor: palette.primary.light,
    },
  },
  dividerDiv: {
    width: '100%',
    maxWidth: 310,
    display: 'flex',
    justifyContent: 'space-between',
    order: 2,
  },
  dividerSide: {
    height: '20px',
    width: '40%',
    borderBottom: `1px ${palette.divider} solid`,
  },
  dividerText: {
    color: palette.text.secondary,
    fontSize: '24px',
    fontWeight: 'bold',
    height: '40px',
  },
  socialDiv: {
    width: '100%',
    maxWidth: 310,
    display: 'flex',
    justifyContent: 'space-between',
    order: 1,
    margin: '10px 0',
  },
  registerButton: {
    backgroundColor: palette.background.paper,
    border: `1px ${palette.primary.main} solid`,
    color: palette.primary.main,
    order: 6,
    marginBottom: 0,
  },
  customerDivider: {
    order: 5,
  },
  '@media screen and (min-height: 598px)': {
    registerButton: {
      marginBottom: 20,
    },
  },
  '@media screen and (min-height: 610px)': {
    socialDiv: {
      margin: '20px 0',
    },
    dividerDiv: {
      marginBottom: 10,
    },
  },
  '@media screen and (min-height: 900px)': {
    iconDiv: {
      display: 'flex',
    },
    iconDivSocials: {
      display: 'flex',
    },
    title: {
      height: 'auto',
      marginTop: 20,
    },
    button: {
      height: 48,
    },
    socialDiv: {
      order: 'initial',
    },
    dividerDiv: {
      order: 'initial',
    },
    emailLoginBlock: {
      order: 'initial',
    },
    customerDivider: {
      order: 'initial',
    },
  },
  '@media screen and (min-height: 800px)': {
    blueWave: {
      height: '20vh',
    },
    iconDiv: {
      height: '20vh',
    },
  },
}));
