import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import { FormattedMessage } from 'react-intl';
import { getNavigationUrl } from 'src/utils/history';
import ArrowLeft from 'src/img/icons/ArrowLeft';
import ArrowRight from 'src/img/icons/ArrowRight';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: '100%',
  },
  link: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  separator: {
    marginTop: 2,
  },
  p: {
    fontWeight: 500,
    color: '#545555',
  },
}));

const BreadcrumbsComponent = ({
  defaultMainName = 'links.home',
  hideWhenEmpty = false,
  pathname,
  screenName,
  links = [],
  isRtl,
}) => {
  const classes = useStyles();
  const customer = pathname.includes('/customer');
  return (
    <div className={classes.container}>
      {(links.length > 0 || !hideWhenEmpty || screenName) && (
        <Breadcrumbs
          aria-label='breadcrumb'
          separator={
            isRtl ? (
              <ArrowLeft
                color='#20285C'
                thickness='1'
                className={classes.separator}
              />
            ) : (
              <ArrowRight
                color='#20285C'
                thickness='1'
                className={classes.separator}
              />
            )
          }
        >
          <Link
            className={classes.link}
            to={getNavigationUrl(customer ? '/customer' : '/')}
          >
            <FormattedMessage id={defaultMainName} defaultMessage='Home' />
          </Link>
          {links.map((item, i) => (
            <Link
              className={classes.link}
              to={getNavigationUrl(item.link)}
              key={i}
            >
              {item.name}
            </Link>
          ))}
          <p className={classes.p}>{screenName}</p>
        </Breadcrumbs>
      )}
    </div>
  );
};

export default connect((state) => ({
  pathname: state.router.location.pathname,
  isRtl: state.locale.dir === 'rtl',
}))(BreadcrumbsComponent);
