import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { IntlProvider as Provider, ReactIntlErrorCode } from 'react-intl';

const IntlProvider = ({ language, dir, messages, children }) => {
  const onError = useCallback((e) => {
    if ((e.code = ReactIntlErrorCode.MISSING_DATA)) {
      return;
    }
    console.error(e);
  }, []);

  useEffect(() => {
    const body = document.body;
    body.dir = dir;
    body.parentElement.lang = language;
  }, [language]);

  return (
    <Provider
      locale={language}
      messages={messages}
      defaultLocale="en"
      onError={onError}
    >
      {children}
    </Provider>
  );
};

export default connect((state) => state.locale)(IntlProvider);
