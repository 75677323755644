import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ height, width, color }) => (
  <SvgIcon
    viewBox="0 0 4 24"
    style={{
      height: height || '24px',
      width: width || '4px',
    }}
  >
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.254831 0 0 0 0 0.898041 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Profile--Employee" transform="translate(-1757.000000, -28.000000)">
        <g id="Header--signed-in">
          <g filter="url(#filter-1)" id="more-vertical">
            <g transform="translate(1757.000000, 28.000000)">
              <circle
                id="Oval"
                fill={color || '#20285C'}
                cx="2"
                cy="12"
                r="2"
              ></circle>
              <circle
                id="Oval"
                fill={color || '#20285C'}
                cx="2"
                cy="2"
                r="2"
              ></circle>
              <circle
                id="Oval"
                fill={color || '#20285C'}
                cx="2"
                cy="22"
                r="2"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
