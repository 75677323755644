import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const TRACK_JOB_ACTION =
  createAsyncActionRequestTypes('TRACK_JOB_ACTION');

export const trackJobAction = (id, body) =>
  createApiAction(
    {
      method: 'POST',
      url: '/jobs/:id/track',
      params: {
        id,
      },
      body,
      headers: {
        'Content-type': 'application/json',
      },
    },
    TRACK_JOB_ACTION
  );
