import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ width, height }) => (
  <SvgIcon
    viewBox="0 0 143 21"
    style={{
      height: height || '21px',
      width: width || '143px',
    }}
  >
    <g id="Logo">
      <g id="Group-5">
        <g id="Group-8-Copy">
          <g id="Group-7-Copy" transform="translate(40 0)">
            <path
              d="M36.1632 18.084C34.5792 18.9112 32.7928 19.3248 30.804 19.3248C28.8152 19.3248 27.0288 18.9112 25.4448 18.084Q23.0688 16.8432 21.7224 14.6388C20.8248 13.1692 20.376 11.5104 20.376 9.6624C20.376 7.8144 20.8248 6.1556 21.7224 4.686Q23.0688 2.4816 25.4448 1.2408Q27.8208 0 30.804 0Q33.7872 0 36.1632 1.2408Q38.5392 2.4816 39.8856 4.686Q41.232 6.8904 41.232 9.6624Q41.232 12.4344 39.8856 14.6388Q38.5392 16.8432 36.1632 18.084ZM6.2304 14.3616L6.2304 18.9024L0 18.9024L0 0.4224L8.8968 0.4224Q11.4576 0.4224 13.3584 1.2672Q15.2592 2.112 16.2888 3.696C16.9752 4.752 17.3184 5.984 17.3184 7.392C17.3184 8.8 16.9752 10.032 16.2888 11.088Q15.2592 12.672 13.3584 13.5168Q11.4576 14.3616 8.8968 14.3616L6.2304 14.3616ZM45.0024 0.4224L51.2328 0.4224L51.2328 18.9024L45.0024 18.9024L45.0024 0.4224ZM61.8144 18.9024L61.8144 10.5072L68.8368 18.9024L73.9584 18.9024L73.9584 0.4224L67.8864 0.4224L67.8864 8.8176L60.864 0.4224L55.7424 0.4224L55.7424 18.9024L61.8144 18.9024ZM88.7184 5.4312L88.7184 19.08L82.488 19.08L82.488 5.4312L77.076 5.4312L77.076 0.6L94.1304 0.6L94.1304 5.4312L88.7184 5.4312ZM30.804 14.2824Q29.6424 14.2824 28.7052 13.7148Q27.768 13.1472 27.2136 12.1044Q26.6592 11.0616 26.6592 9.6624Q26.6592 8.2632 27.2136 7.2204Q27.768 6.1776 28.7052 5.61Q29.6424 5.0424 30.804 5.0424Q31.9656 5.0424 32.9028 5.61C33.5276 5.9884 34.0248 6.5252 34.3944 7.2204C34.764 7.9156 34.9488 8.7296 34.9488 9.6624C34.9488 10.5952 34.764 11.4092 34.3944 12.1044C34.0248 12.7996 33.5276 13.3364 32.9028 13.7148Q31.9656 14.2824 30.804 14.2824ZM8.5008 9.5568L6.2304 9.5568L6.2304 5.2272L8.5008 5.2272Q9.768 5.2272 10.4016 5.7948Q11.0352 6.3624 11.0352 7.392Q11.0352 8.4216 10.4016 8.9892Q9.768 9.5568 8.5008 9.5568Z"
              transform="translate(0.224 0.8976)"
              id="EXPOINT."
              fill="#fff"
              fillRule="evenodd"
              stroke="none"
            />
            <path
              d="M3.4848 6.8112C4.488 6.8112 5.3196 6.49 5.9796 5.8476C6.6396 5.2052 6.9696 4.3824 6.9696 3.3792C6.9696 2.376 6.6396 1.562 5.9796 0.9372C5.3196 0.3124 4.488 0 3.4848 0C2.4816 0 1.65 0.3124 0.99 0.9372C0.33 1.562 0 2.376 0 3.3792C0 4.3824 0.33 5.2052 0.99 5.8476C1.65 6.49 2.4816 6.8112 3.4848 6.8112Z"
              transform="translate(96 13)"
              id="Path"
              fill="#fff"
              stroke="none"
            />
          </g>
          <path
            d="M15.3648 18.48L15.3648 13.7808L6.1248 13.7808L6.1248 11.352L13.9392 11.352L13.9392 6.864L6.1248 6.864L6.1248 4.6992L15.0216 4.6992L15.0216 0L0 0L0 18.48L15.3648 18.48Z"
            transform="translate(0 1.32)"
            id="Path"
            fill="#fff"
            stroke="none"
          />
          <path
            d="M7.1016 18.48L10.2168 13.5432L13.4112 18.48L20.5392 18.48L13.728 8.9232L20.1432 0L13.4376 0L10.3752 4.6464L7.2336 0L0.2376 0L6.6792 9.1608L0 18.48L7.1016 18.48Z"
            transform="translate(17.4192 1.32)"
            id="Path"
            fill="#fff"
            stroke="none"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
