import createReducer from 'src/utils/createReducer';
import {
  FETCH_INVOICES,
  SET_INVOICES_PAGE,
  CLEAR_INVOICES,
} from 'src/customer/redux/actions/invoices';

const INITIAL_STATE = {
  invoices: [],
  loading: false,
  curPage: null,
  pages: 0,
  total: 0,
};

export default createReducer(INITIAL_STATE, {
  [SET_INVOICES_PAGE]: (state, { payload: page }) => ({
    ...state,
    curPage: parseInt(page),
  }),
  [FETCH_INVOICES.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_INVOICES.SUCCESS]: (
    state,
    { data: { invoices, total, curPage, pages } }
  ) => ({
    ...state,
    invoices,
    total,
    curPage: parseInt(curPage),
    pages,
    loading: false,
  }),
  [CLEAR_INVOICES]: () => INITIAL_STATE,
});
