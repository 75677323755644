const jobsMenuItems = {
  he: [
    {
      label: 'פיתוח תוכנה',
      children: [
        {
          label: 'מהנדס תוכנה',
          pathname: '/?title=software engineer',
        },
        {
          label: 'מפתח תוכנה',
          pathname: '/?title=software developer',
        },
        {
          label: 'מפתח תוכנה Java',
          pathname: '/?title=java software developer',
        },
        {
          label: 'מהנדס Backend',
          pathname: '/?title=backend engineer',
        },
        {
          label: 'מפתח פולסטאק',
          pathname: '/?title=full stack developer',
        },
      ],
    },
    {
      label: 'פיתוח עסקי',
      children: [
        {
          label: 'מנהל שיווק',
          pathname: '/?title=marketing manager',
        },
        {
          label: 'מנהל מכירות',
          pathname: '/?title=sales manager',
        },
        {
          label: 'מנהל מוצר',
          pathname: '/?title=product manager',
        },
        {
          label: 'אנליסט עסקי',
          pathname: '/?title=business analyst',
        },
      ],
    },
    {
      label: 'דאטה',
      children: [
        {
          label: 'מדען נתונים',
          pathname: '/?title=data scientist',
        },
        {
          label: 'אנליסט נתונים',
          pathname: '/?title=data analyst',
        },
        {
          label: 'מפתח BI',
          pathname: '/?title=bi developer',
        },
        {
          label: 'מהנדס נתונים',
          pathname: '/?title=data engineer',
        },
        {
          label: 'מהנדס ביג דאטה',
          pathname: '/?title=big data engineer',
        },
      ],
    },
    {
      label: 'תשתית',
      children: [
        {
          label: 'מהנדס DevOps',
          pathname: '/?title=devops engineer',
        },
        {
          label: 'ארכיטקט ענן',
          pathname: '/?title=cloud architect',
        },
        {
          label: 'מנהל תשתיות IT',
          pathname: '/?title=system administrator',
        },
      ],
    },
    {
      label: 'בדיקות תוכנה',
      children: [
        {
          label: 'מהנדס בדיקות',
          pathname: '/?title=qa engineer',
        },
        {
          label: 'בודק תוכנה',
          pathname: '/?title=qa',
        },
      ],
    },
    {
      label: 'ניהול',
      children: [
        {
          label: 'מנהל פרויקטים',
          pathname: '/?title=project manager',
        },
        {
          label: 'מנהל פיתוח תוכנה',
          pathname: '/?title=software development manager',
        },
      ],
    },
    {
      label: 'עיצוב מערכות',
      children: [
        {
          label: 'ארכיטקט תוכנה',
          pathname: '/?title=software architect',
        },
        {
          label: 'מעצב UX UI',
          pathname: '/?title=ux ui',
        },
        {
          label: 'ארכיטקט מערכות',
          pathname: '/?title=system architect',
        },
        {
          label: 'מנתח מערכות',
          pathname: '/?title=system analyst',
        },
      ],
    },
    {
      label: 'משרות הייטק ללא ניסיון',
      pathname: '/he/?title=junior',
    },
    {
      label: 'משרות סטודנט הייטק',
      pathname: '/he/?title=student',
    },
  ],
  en: [
    {
      label: 'Software Development',
      children: [
        {
          label: 'Software Engineer',
        },
        {
          label: 'Java Software Developer',
        },
        {
          label: 'Backend Engineer',
        },
        {
          label: 'Full Stack Developer',
        },
      ],
    },
    {
      label: 'Business Development',
      children: [
        {
          label: 'Marketing Manager',
        },
        {
          label: 'Sales Manager',
        },
        {
          label: 'Business Analyst',
        },
        {
          label: 'Product Manager',
        },
      ],
    },
    {
      label: 'Data',
      children: [
        {
          label: 'Data Scientist',
        },
        {
          label: 'Data Analyst',
        },
        {
          label: 'BI Developer',
        },
        {
          label: 'Data Engineer',
        },
        {
          label: 'Big Data Engineer',
        },
      ],
    },
    {
      label: 'Infrastructure',
      children: [
        {
          label: 'DevOps Engineer',
        },
        {
          label: 'Cloud Architect',
        },
        {
          label: 'System Administrator',
        },
      ],
    },
    {
      label: 'QA',
      children: [
        {
          label: 'QA Engineer',
        },
      ],
    },
    {
      label: 'Management',
      children: [
        {
          label: 'Project Manager',
        },
        {
          label: 'Software Development Manager',
        },
      ],
    },
    {
      label: 'Software Design',
      children: [
        {
          label: 'Software Architect',
        },
        {
          label: 'UX UI',
        },
        {
          label: 'System Architect',
        },
        {
          label: 'System Analyst',
        },
      ],
    },
    {
      label: 'Junior jobs',
      pathname: '/?title=junior',
    },
    {
      label: 'Student tech jobs',
      pathname: '/?title=student',
    },
  ],
};
export const analyticsItems = {
  he: [
    {
      label: 'קריירה בהייטק',
      pathname: '/analytics/job-titles',
    },
    {
      label: 'חברות הייטק',
      pathname: '/analytics/companies',
    },
    {
      label: 'רילוקיישן הייטק',
      pathname: '/analytics/countries',
    },
    {
      label: 'הייטק בישראל',
      pathname: '/analytics/cities',
    },
  ],
  en: [
    {
      label: 'Alljobs by titles',
      pathname: '/analytics/job-titles',
    },
    {
      label: 'Alljobs by companies',
      pathname: '/analytics/companies',
    },
    {
      label: 'Alljobs by countries',
      pathname: '/analytics/countries',
    },
    {
      label: 'Alljobs by states (US)',
      pathname: '/analytics/cities',
    },
  ],
};

export function getJobLinks(langCode) {
  /* pathname logic:
   * 1) When in en mode, drop the locale. In any other language present the locale.
   * 2) When in he mode, add location=israel. In any other language do a job search worldwide.
   *  */
  return jobsMenuItems[langCode].map((item) => {
    if (item.children) {
      // it's a subMenu:
      const subMenu = {
        ...item,
        children: item.children.map((child) => {
          // it's a menuItem:
          return {
            pathname: `/${
              langCode === 'en' ? '' : langCode + '/'
            }?title=${child.label.toLowerCase()}`,
            ...child,
          };
        }),
      };
      if (item.pathname) {
        subMenu.pathname = `${langCode === 'en' ? '' : '/' + langCode}${
          item.pathname
        }`;
      }
      return subMenu;
    } else {
      // it's a menuItem:
      return {
        pathname: `/${langCode === 'en' ? '' : langCode + '/'}?title=${
          item.label
        }`,
        ...item,
      };
    }
  });
}

export function getArticleLinks() {
  const articles = [
    {
      title: 'חיפוש עבודה בהייטק',
      pathname: '/he/articles/4',
    },
    {
      title: 'משרות הייטק ללא ניסיון',
      pathname: '/he/?title=junior',
    },
    {
      title: 'משרות סטודנט הייטק',
      pathname: '/he/?title=student',
    },
    {
      title: 'מגייסים',
      pathname: '/he/articles/5',
    },
    {
      title: 'עבודה בהייטק',
      pathname: '/he/articles/7',
    },
    {
      title: 'הצג הכל',
      pathname: '/he/articles',
    },
  ];
  return articles.map((article) => ({
    label: article.title,
    onClick: (e) => {
      e.stopPropagation();
    },
    pathname: article.pathname,
    condition: true,
  }));
}
