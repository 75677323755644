import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color, style, size }) => (
  <SvgIcon
    viewBox="0 0 11 11"
    style={{
      height: size || '14px',
      width: size || '14px',
      ...style,
    }}
  >
    <path
      d="M6 0L5.64645 5.64645L0 6"
      transform="matrix(0.7071067 -0.7071068 -0.7071068 -0.7071067 5.303711 9.545898)"
      id="Path"
      fill="none"
      stroke={color || '#0041E5'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
