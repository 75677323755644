import queryString from 'query-string';

export const urlInterpolate = (urlTemplate, query, params) => {
  const interpolatedUrl = urlTemplate.replace(
    /:([\w\d-]+)/g,
    (sustr, match) => params[match] || ''
  );

  if (Object.keys(query).length > 0) {
    return `${interpolatedUrl}?${queryString.stringify(query)}`;
  }
  return interpolatedUrl;
};

export const createReducer =
  (initialState, fnMap) =>
  (state, action, ...rest) => {
    const { type } = action;
    const handler = fnMap[type];
    const newState = state || initialState;

    return handler ? handler(newState, action, ...rest) : newState;
  };

export const trimValues = (obj) => {
  Object.keys(obj).map((key) => {
    const value = obj[key];
    obj[key] = value?.trim ? value.trim() : value;
  });
  return obj;
};

export const getWordsCount = (value) => {
  const res = value
    .replace(/<[^>]*>/g, ' ')
    .replace(/\s+/g, ' ')
    .replaceAll('&nbsp;', ' ')
    .trim();
  return res.split(/\s+/).length;
};
