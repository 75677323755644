import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  root: {
    margin: 'auto',
    display: 'flex',
    minHeight: 'calc(100vh - 80px)',
    position: 'relative',
  },
  rootCandidates: {
    backgroundColor: palette.background.paper,
    display: 'flex',
    '@media screen and (min-width: 600px)': {
      '&': {
        backgroundColor: palette.background.default,
      },
    },
  },
  tabs: {
    minWidth: '259px',
    minHeight: '100%',
    backgroundColor: palette.primary.dark,
    color: 'white',
    '@media screen and (max-width: 1000px)': {
      '&': {
        minWidth: '220px',
      },
    },
  },
  linkItem: {
    width: '100%',
    height: '66px',
    paddingLeft: 25,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textTransform: 'none',
    fontSize: 16,
    color: palette.text.white,
    opacity: 1,
    fontWeight: 'normal',
  },
  linkIcon: {
    width: 30,
    marginTop: 5,
  },
  activeLink: {
    backgroundColor: palette.primary.darkHovered,
  },
  dNone: {
    display: 'none',
  },
  linkLabel: {
    marginBottom: 0,
    lineHeight: '16px',
    color: palette.text.white,
  },
  activeLinkLabel: {
    color: palette.primary.medium,
    fontWeight: 'bold',
  },
  subLink: {
    height: '40px',
    marginTop: '5px',
  },
  subLabel: {
    color: palette.text.white,
    fontWeight: 'normal',
  },
  tabPanel: {
    width: 'calc(100% - 220px)',
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    '@media screen and (min-width: 1000px)': {
      '&': {
        width: 'calc(100% - 259px)',
      },
    },
  },
  tabPanelCandidates: {
    width: '100%',
    flex: 1,
    '& .MuiBox-root': {
      height: '100%',
      overflowY: 'hidden',
      flex: 1,
    },
  },
  footer: {
    minWidth: '259px',
    height: '75px',
    backgroundColor: '#1B224F',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    color: '#fff',
    cursor: 'pointer',
    fontSize: '14px',
    '@media screen and (max-width: 1000px)': {
      '&': {
        minWidth: '220px',
      },
    },
  },
  footerOpen: {
    height: '150px',
  },
  footerActions: {
    padding: '40px 0',
    width: '100%',
  },
  footerLink: {
    height: 30,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 48,
    userSelect: 'none',
    '&:hover': {
      backgroundColor: palette.primary.dark,
    },
  },
  footerArrow: {
    position: 'absolute',
    top: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  footerArrowClosed: {
    top: -10,
    transform: ' rotate(0.5turn)',
  },
  footerArrowOpen: {
    height: 30,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: palette.primary.darkHovered,
    },
  },
  footerClosedDiv: {
    width: '30px',
    height: '30px',
    backgroundColor: '#1B224F',
    position: 'absolute',
    top: -15,
    transform: 'rotate(45deg) skew(-7deg, -7deg)',
  },
  restrictedContainer: {
    padding: 50,
    fontSize: 20,
    fontWeight: '500',
    color: palette.text.secondary,
  },
  '@media screen and (max-width: 700px)': {
    root: {
      flexDirection: 'column',
    },
    tabs: {
      heigth: '80px',
      width: '100%',
    },
    tabPanel: {
      width: '100%',
    },
    footer: {
      display: 'none',
    },
    linkItem: {
      width: 'auto',
      height: '80',
      padding: '0 10px',
    },
  },
  '@media screen and (max-height: 915px)': {
    root: {
      minHeight: 915,
    },
  },
}));
