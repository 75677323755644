import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const CLEAR_INVOICES = 'CLEAR_INVOICES';
export const INITIATE_INVOICES_FETCH = 'INITIATE_INVOICES_FETCH';
export const SET_INVOICES_PAGE = 'SET_INVOICES_PAGE';
export const FETCH_INVOICES = createAsyncActionRequestTypes('FETCH_INVOICES');

export const clearInvoices = () => ({
  type: CLEAR_INVOICES,
});

export const fetchInvoices = (params = {}) =>
  createApiAction(
    {
      url: '/customer/invoices',
      query: params,
      params,
    },
    FETCH_INVOICES
  );

export const initiateInvoicesFetch = (data) => {
  return {
    type: INITIATE_INVOICES_FETCH,
    payload: data,
  };
};

export const setInvoicesPage = (page) => {
  return {
    type: SET_INVOICES_PAGE,
    payload: page,
  };
};
