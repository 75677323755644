import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import LogoLight from 'src/img/icons/LogoLight';
import PrivacyPopup from 'src/about/components/PrivacyPopup';

const useStyles = makeStyles(({ palette }) => ({
  footer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px 8%',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 80,
    backgroundColor: palette.background.dark,
  },
  termsContainer: {
    display: 'flex',
    marginRight: 10,
  },
  termsLink: {
    fontSize: 12,
    cursor: 'pointer',
    color: palette.text.white,
    '&:hover': {
      textDecoration: 'none',
      color: palette.primary.medium,
    },
  },
  divider: {
    width: 1,
    height: 14,
    backgroundColor: palette.text.white,
    margin: '0 10px',
  },
  creditLink: {
    color: '#fff',
    fontSize: 11,
    display: 'block',
    marginLeft: -1,
  },
}));

export default function CustomerLandingFooter({ togglePrivacyPopup }) {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <LogoLight width={100} height={15} />
      <div className={classes.termsContainer}>
        <div
          className={classes.termsLink}
          onClick={(e) => {
            e.target.blur();
            togglePrivacyPopup('customer', 'privacyPolicy');
          }}
          tabIndex='0'
        >
          <FormattedMessage
            id='privacy.privacyPolicy'
            defaultMessage='Privacy Policy'
          />
        </div>
        <div className={classes.divider} />
        <div
          className={classes.termsLink}
          onClick={(e) => {
            e.target.blur();
            togglePrivacyPopup('customer', 'terms');
          }}
          tabIndex='0'
        >
          <FormattedMessage
            id='terms.termsOfUse'
            defaultMessage='Terms of use'
          />
        </div>
      </div>
      <a
        href='https://seadata.co.il/'
        target='_blank'
        rel='noopener noreferrer'
        className={classes.creditLink}
      >
        <FormattedMessage
          id='footer.developedBy'
          defaultMessage='Developed by SeaData'
        />
      </a>
      <PrivacyPopup />
    </div>
  );
}
