import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  titleSpan: {
    height: 20,
    width: '100%',
    textAlign: 'left',
    fontSize: 16,
    color: palette.primary.dark,
    fontWeight: 'bold',
    margin: 0,
    display: 'block',
    marginBottom: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  companyNameSpan: {
    width: '100%',
    textAlign: 'left',
    display: 'block',
    fontSize: 18,
    color: palette.primary.dark,
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  locationSpan: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    display: 'block',
    fontWeight: 'normal',
    color: palette.text.disabled,
    fontSize: '14px',
  },
  rowReverse: {
    direction: 'ltr',
  },
  row: {
    display: 'flex',
    width: '100%',
    '@media (max-width: 900px)': {
      marginBottom: 5,
    },
  },
  jobDesc: {
    maxHeight: 0,
    width: 0,
    // transition: 'max-height 0.05s ease-out',
    overflow: 'hidden',
  },
  jobDescActive: {
    '@media (max-width: 900px)': {
      width: '100%',
      maxHeight: 500,
      transition: 'max-height 0.5s ease-in-out',
    },
  },
  jobItem: {
    borderRadius: '6px',
    minWidth: 240,
    display: 'flex',
    height: 100,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: 15,
    backgroundColor: palette.background.paper,
    boxSizing: 'border-box',
    width: '100%',
    '&:hover, &:focus': {
      boxShadow: '0 6px 12px 0 rgba(32,40,92,0.1)',
      transition: 'all 0.3s ease',
      zIndex: '1',
    },
    position: 'relative',
    '@media (max-width: 900px)': {
      height: 'auto',
      flexDirection: 'column',
    },
  },
  smallJobItem: {
    backgroundColor: '#f2f2f2',
    border: '1px solid #e5e5e5',
    height: 80,
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
    padding: 7,
    width: 275,
  },
  border: {
    position: 'absolute',
    height: '100%',
    backgroundColor: palette.secondary.main,
    borderRadius: '6px 0 0 6px',
    border: '2px solid #FF6E7E',
    top: 0,
    left: 0,
    transition: 'all 0.355s ease-in',
    opacity: 0,
    '@media (max-width: 900px)': {
      borderRadius: '6px 0 0 0',
    },
  },
  activeBorder: {
    opacity: 1,
  },
  image: {
    width: '76px',
    aspectRatio: '1/1',
    marginRight: '15px',
    borderRadius: 4,
    fontSize: 18,
    fontWeight: 'bold',
    color: palette.primary.dark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  smallImage: {
    width: '55px !important',
  },
  infoCol: {
    position: 'relative',
    maxHeight: '100%',
    margin: 0,
    flex: 1,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc(100% - 100px)',
    '& span': {
      height: 22,
    },
  },
  companyName: {
    fontSize: '18px',
    color: palette.primary.dark,
  },
  companyNameLink: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  title: {
    fontSize: '18px',
    color: palette.primary.dark,
    fontWeight: 'bold',
    margin: 0,
    display: 'block',
    textOverflow: 'ellipsis',
    wordWrap: 'anywhere',
    overflow: 'hidden',
  },
  countryDateRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% + 10px)',
    marginTop: 10,
  },
  country: {
    color: palette.text.disabled,
    fontSize: '14px',
    margin: 0,
    flexWrap: 'wrap',
  },
  date: {
    position: 'absolute',
    top: 10,
    right: 15,
    color: palette.primary.medium,
    fontSize: '14px',
    marginLeft: 10,
  },
}));
