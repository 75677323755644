import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  searchedItem: {
    cursor: 'pointer',
    padding: '2px 15px',
    overflow: 'hidden',
    width: '100%',
    transition: 'all 0.15s ease-in-out',
    '&:first-child': {
      paddingTop: 5,
    },
    '& span': {
      padding: 2,
    },
    '&:hover': {
      backgroundColor: '#F0F2FA',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '100%',
    border: 'none',
    padding: '5px 0',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    color: '#6a6a6a',
    backgroundColor: '#e9eaf0',
    transition: 'all 0.12s ease-in',
    '&:hover, &:focus': {
      backgroundColor: '#d6d8e3',
      color: 'black',
    },
  },
  menuWrapper: {
    borderTop: '2px solid #2F64EA',
    borderRadius: 6,
    width: '100%',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': `inset 0 0 6px rgba(0,0,0,0.1)`,
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: 8,
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#316afa',
    },
  },
  dropdownContainer: {
    border: 'none',
    boxShadow: 'none',
    border: `1px solid ${palette.divider}`,
    borderRadius: 3,
    boxShadow:
      'rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.1) 0px 2px 6px 2px',
  },
}));
