import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { getNavigationUrl } from 'src/utils/history';
import ArrowRight from 'src/img/icons/ArrowRight';
import ArrowLeft from 'src/img/icons/ArrowLeft';
import queryString from 'query-string';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    marginBottom: 0,
    display: 'flex',
    '@media (max-width: 700px)': {
      '&': {
        width: '90%',
        justifyContent: 'space-evenly',
      },
    },
  },
  item: {
    minWidth: '30px',
    height: '30px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 5,
  },
  prevDiv: {
    marginRight: '5px',
    '&:hover, &:focus': {
      backgroundColor: palette.background.blue,
    },
  },
  nextDiv: {
    marginLeft: '5px',
    '&:hover, &:focus': {
      backgroundColor: palette.background.blue,
    },
  },
  currentPage: {
    backgroundColor: palette.primary.main,
    color: palette.text.white,
  },
  pageItem: {
    color: palette.text.disabled,
    '&:hover, &:focus ': {
      backgroundColor: palette.background.blue,
    },
  },
}));

export default function PaginationCustom({
  pathname = '/',
  query = {},
  allowedToBrowse = () => true,
  pages = 3,
  currentPage = 1,
  shown = 3,
  onPageClick,
}) {
  if (pages === 1) {
    return null;
  }
  const classes = useStyles();
  const dir = useSelector((state) => state.locale.dir);
  // get the middle page by dividing shown elements by 2, in corner cases we add 1. 3 / 2 = 1,5 -> 2.
  const middle = Math.floor(shown / 2) + 1;
  // to calculate what page number should be displayed on each button.
  const offset =
    currentPage === pages ? currentPage - shown : currentPage - middle;
  const buildHref = (page) => {
    if (!allowedToBrowse(page)) return '#';
    // build the next url dynamicly
    const params = {
      ...query,
      page,
    };
    // if page requested = 1 => ignore it and remove the params
    if (params?.page === 1) delete params.page;
    if (Object.keys(params).length)
      return getNavigationUrl(
        `${pathname}?${queryString.stringify(params, {
          skipEmptyString: true,
        })}`
      );
    return `${pathname}`;
  };
  const renderItems = () => {
    let res = [];

    const isFirstShown = currentPage > shown;
    const isPrevShown = currentPage > 1;
    const isNextShown = currentPage < pages;
    const isLastShown = currentPage < pages - shown + 1;
    let lastPageNumber = 0;

    // if (isFirstShown)
    //   res.push(<Pagination.First key="first" onClick={() => onPageClick(1)} />);

    if (isPrevShown)
      res.push(
        <div
          key='prev'
          onClick={() => {
            onPageClick(currentPage - 1);
            document.activeElement.blur();
          }}
          onKeyDown={(e) => e.keyCode === 13 && onPageClick(currentPage - 1)}
          className={clsx(classes.item, classes.prevDiv)}
          tabIndex='0'
        >
          {dir === 'ltr' ? <ArrowLeft /> : <ArrowRight />}
        </div>
      );

    // create buttons for each page. total amount of buttons equals the shown props
    res = res.concat(
      new Array(Math.min(shown, pages)).fill().map((_, idx) => {
        const index = idx + 1;
        // for the first pages we don't want to shift array to the left,
        // e.g current page = 1 shown = 3 pages = 9 so buttons labels would be 1 2 3
        // for current page = 3 the same buttons would have labels 2 3 4
        // and for the current page = 9 it would be 7 8 9
        const pageNumber = offset > 0 ? Math.floor(index + offset) : index;

        if (pageNumber > pages) return null;

        lastPageNumber = pageNumber;

        return (
          <a
            href={'' + buildHref(pageNumber)}
            target='_blank'
            key={index}
            tabIndex={currentPage === pageNumber ? '-1' : 0}
            onClick={(event) => {
              event.preventDefault();
              onPageClick(pageNumber);
              document.activeElement.blur();
            }}
            onKeyDown={(e) => e.keyCode === 13 && onPageClick(pageNumber)}
            className={clsx(
              classes.item,
              currentPage === pageNumber
                ? classes.currentPage
                : classes.pageItem
            )}
          >
            {pageNumber}
          </a>
        );
      })
    );

    if (isNextShown)
      res.push(
        <div
          key='next'
          tabIndex='0'
          onClick={() => {
            onPageClick(currentPage + 1);
            document.activeElement.blur();
          }}
          onKeyDown={(e) => e.keyCode === 13 && onPageClick(currentPage + 1)}
          className={clsx(classes.item, classes.nextDiv)}
        >
          {dir === 'ltr' ? <ArrowRight /> : <ArrowLeft />}
        </div>
      );

    // if (isLastShown)
    //   res.push(
    //     <Pagination.Last key="last" onClick={() => onPageClick(pages)} />
    //   );

    return res;
  };

  return <div className={classes.container}>{renderItems()}</div>;
}
