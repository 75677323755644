import createReducer from 'src/utils/createReducer';
import { LOG_OUT } from 'src/customer/redux/actions';
import {
  EDIT_CUSTOMER_USER,
  GET_CUSTOMER_USERS,
  GET_MANAGERS,
  FETCH_EMAILS,
  TOGGLE_USERS_MODAL,
  TOGGLE_INFO_MODAL,
  TOGGLE_PASSWORD_MODAL,
  CHANGE_PASSWORD_USER,
  CLEAR_CUSTOMER_USERS,
  UPDATE_CUSTOMER_USERS,
} from 'src/users/redux/actions';

const INITIAL_STATE = {
  list: [],
  page: 0,
  pages: 0,
  isModalOpen: false,
  modalType: null,
  itemId: null,
  infoModalOpen: false,
  passwordModalOpen: false,
  loading: false,
  managers: [],
  emails: [],
};

export default createReducer(INITIAL_STATE, {
  [TOGGLE_USERS_MODAL]: (state, { isOpen, modalType, itemId }) => ({
    ...state,
    isModalOpen: isOpen,
    modalType,
    itemId,
  }),
  [TOGGLE_INFO_MODAL]: (state, { isOpen }) => ({
    ...state,
    infoModalOpen: isOpen,
  }),
  [TOGGLE_PASSWORD_MODAL]: (state, { isOpen }) => ({
    ...state,
    passwordModalOpen: isOpen,
  }),
  [GET_CUSTOMER_USERS.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [GET_CUSTOMER_USERS.SUCCESS]: (state, { data: { d: list, ...d } }) => ({
    ...state,
    ...d,
    list,
    loading: false,
  }),
  [GET_CUSTOMER_USERS.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [GET_MANAGERS.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [GET_MANAGERS.SUCCESS]: (state, { data: { d } }) => {
    return {
      ...state,
      managers: d,
      loading: false,
    };
  },
  [GET_MANAGERS.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [FETCH_EMAILS.SUCCESS]: (state, { data: { d } }) => {
    return {
      ...state,
      emails: d,
    };
  },
  [EDIT_CUSTOMER_USER.SUCCESS]: (state, { data }) => ({
    ...state,
    list: state.list.map((e) => (e.id === data.id ? data : e)),
  }),
  [CHANGE_PASSWORD_USER.REQUEST]: (state, { data }) => ({
    ...state,
    loading: true,
  }),
  [CHANGE_PASSWORD_USER.SUCCESS]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [CHANGE_PASSWORD_USER.FAILURE]: (state, { data }) => ({
    ...state,
    loading: false,
  }),
  [UPDATE_CUSTOMER_USERS]: (state, { payload }) => ({
    ...state,
    list: payload,
  }),
  [LOG_OUT]: () => INITIAL_STATE,
  [CLEAR_CUSTOMER_USERS]: () => INITIAL_STATE,
});
