import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFormik } from 'formik';
import { withSnackbar } from 'notistack';

import { updateMe } from 'src/user/redux/actions';
import { toggleAlertModal } from 'src/jobs/redux/actions';

import AlertModal from './AlertModal';

export default compose(
  connect(
    (state) => ({
      open: state.jobs.alertModalOpen,
      profile: state.user.profile,
      loading: state.user.loading,
      messages: state.locale.messages,
    }),
    { updateMe, toggleAlertModal }
  ),
  withSnackbar,
  withFormik({
    mapPropsToValues: ({
      profile: {
        searchedJobNotification = true,
        appliedJobNotification = true,
        savedJobNotification = true,
      },
    }) => ({
      searchedJobNotification,
      appliedJobNotification,
      savedJobNotification,
    }),
    displayName: 'AlertsNotifications',
    handleSubmit: async (
      val,
      { props: { updateMe, enqueueSnackbar, toggleAlertModal, messages } }
    ) => {
      const res = await updateMe(val);
      if (res.error || res.errors) {
        return enqueueSnackbar(messages['snackbar.error.errorOccured'], {
          variant: 'error',
        });
      }
      toggleAlertModal(false);
      return enqueueSnackbar(
        messages['snackbar.success.notificationsUpdated'],
        {
          variant: 'success',
        }
      );
    },
  })
)(AlertModal);
