import {
  put,
  select,
  putResolve,
  takeEvery,
  all,
  call,
} from 'redux-saga/effects';
import { redirect } from 'src/router/redux/actions';
import { useSelector } from 'react-redux';

import {
  me,
  loadLogo,
  CHANGE_LICENSE,
  UPLOAD_LOGO,
} from 'src/customer/redux/actions';
import { SET_TOKEN } from 'src/onboarding/redux/actions';
import { getCards } from 'src/payment/redux/actions';
import {
  ADD_CUSTOMER_USER,
  EDIT_CUSTOMER_USER,
  DELETE_CUSTOMER_USER,
  updateCustomerUsers,
} from 'src/users/redux/actions';
import { getCardsSaga } from 'src/payment/redux/sagas';

export function* getMeCustomer(customerOnlyAccess = true) {
  const {
    locale: { language = 'en' },
    customer: { user },
    onboarding: { token },
    router: {
      location: { query = {}, pathname, search = '' },
    },
  } = yield select();

  if (token) {
    yield all([putResolve(me())]);
  } else {
    if (customerOnlyAccess) {
      yield put(
        redirect(
          `${
            language != 'en' ? `/${language}` : ''
          }/customer/?showLogin=1&redirectUrl=${pathname + search}`
        )
      );
    }
  }

  // if (!token) return;

  // if ((!user.id || !user.extras) && !user.loading) {
  //   return yield putResolve(me());
  // }
}

export function* loadLogoSaga() {
  const { user } = yield select((state) => state.customer);

  if (!user.logo) {
    return yield putResolve(loadLogo());
  }
}

export function* fetchLogo() {
  return yield putResolve(loadLogo());
}

export function* changeLicenseSaga() {
  yield putResolve(me());
  // yield putResolve(getCards()); //TODO сделать отображение Billing и Invoices для EXPOINT_ADMIN
  getCardsSaga(); // putResolve(getCards()),
}

function* getMeUsers() {
  yield putResolve(me());
}

function* addCustomerUserToStore({ body, data }) {
  const {
    customer: {
      users: { list },
    },
  } = yield select();
  yield put(updateCustomerUsers([data?.dataValues, ...list]));
}

export default function* () {
  yield all([
    takeEvery(SET_TOKEN, getMeCustomer),
    takeEvery(CHANGE_LICENSE.SUCCESS, changeLicenseSaga),
    takeEvery(UPLOAD_LOGO.SUCCESS, fetchLogo),
    takeEvery(ADD_CUSTOMER_USER.SUCCESS, addCustomerUserToStore),
    takeEvery(EDIT_CUSTOMER_USER.SUCCESS, getMeUsers),
    takeEvery(DELETE_CUSTOMER_USER.SUCCESS, getMeUsers),
  ]);
}
