import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, shadows }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80vw',
    boxShadow: shadows[5],
    minWidth: '300px',
    maxWidth: 710,
    backgroundColor: palette.background.paper,
    borderRadius: 6,
    height: 'calc(100vh - 248px)',
    minHeight: 500,
    paddingBottom: 55,
    '@media screen and (max-width: 600px)': {
      width: '98vw',
    },
  },
  cardHeader: {
    minHeight: 100,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 21px',
    borderBottom: '1px solid rgba(20,25,60,0.2)',
    '& p': {
      fontSize: 24,
      margin: 0,
      fontWeight: 'bold',
      color: palette.primary.dark,
    },
  },
  modalButton: {
    display: 'flex',
    color: palette.primary.main,
    fontSize: 16,
    fontWeight: '500',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 6,
    padding: '5px 10px',
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
  cardBody: {
    padding: 20,
    height: 'calc(100% - 100px)',
    minHeight: 380,
    overflowY: 'scroll',
    lineHeight: 1.5,
    '& a:visited': {
      color: palette.primary.main,
    },
    '& img': {
      width: '100%',
      height: 300,
      objectFit: 'cover',
      borderRadius: 6,
    },
    '& h1': {
      fontSize: 32, // 40
      marginBottom: 35, // 10 50
      color: palette.primary.main,
      fontWeight: 650,
      '@media screen and (max-width: 600px)': {
        fontSize: 22,
        textAlign: 'center',
      },
    },
    '& h2': {
      fontSize: 24,
      marginBottom: 6,
      fontWeight: 600,
      '@media screen and (max-width: 600px)': {
        fontSize: 22,
      },
    },
    '& h3': {
      fontSize: 22,
      marginBottom: 6,
      fontWeight: 500,
    },
    '& p': {
      fontSize: 18,
      fontWeight: 'normal',
      marginBottom: 0,
    },
    '& section': {
      fontSize: 18,
      fontWeight: 'normal',
      marginBottom: 10,
    },
    '& span': {
      display: 'inline',
      fontSize: 18,
      fontWeight: 'normal',
    },
    '& li': {
      fontSize: 18,
      fontWeight: 'normal',
    },
    '& div': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& ol, ul': {
      padding: '0 22px',
      marginTop: 0,
      marginBottom: 0,
    },
    '& table': {
      width: '100%',
      maxWidth: 700,
      border: '1px solid black',
    },
    '& th': {
      paddingTop: 12,
      paddingBottom: 12,
      textAlign: 'left',
      backgroundColor: '#007bff' /*#04aa6d*/,
      color: 'white',
      '& p': {
        width: 'fit-content',
        margin: '0 auto',
      },
    },
    '& td, th': {
      border: '1px solid #ddd',
      padding: 8,
      width: 'auto',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#f2f2f2',
      width: 'auto',
    },
    '& tr:hover': {
      backgroundColor: '#ddd',
    },
  },
  termsList: {
    listStyleType: 'lower-latin',
    marginLeft: 15,
    '& li': {
      marginBottom: 10,
    },
    '& li::marker': {
      fontWeight: 'bold',
    },
  },
  list: {
    marginLeft: 14,
    '& li': {
      marginBottom: 10,
    },
  },
  innerList: {
    marginLeft: 20,
    marginTop: 10,
    '& li::marker': {
      fontWeight: 'normal',
    },
  },
  link: {
    textDecoration: 'underline',
    color: palette.primary.main,
    fontWeight: 500,
    cursor: 'pointer',
  },
  arrow: {
    marginTop: 2,
  },
}));
