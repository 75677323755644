import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';

import { parseLocationString } from 'src/intl/utils';
import { initiateJobsFetch } from 'src/jobs/redux/actions';
import { navigateIntl } from 'src/utils/history';

import StateDropdown from './StateDropdown';

export default compose(
  withRouter,
  withSnackbar,
  connect(
    (state, props) => ({
      locationQuery: parseLocationString(
        queryString.parse(props.location.search).location
      ),
      langCode: state.locale.language,
    }),
    { initiateJobsFetch },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      handleSubmit: async (state) => {
        const currentParams = queryString.parse(parentProps.location.search);
        const { location, ...rest } = currentParams;

        const { country = 'united states' } = parseLocationString(
          currentParams?.location
        );

        const params = {
          ...rest,
          location: [country, state].filter(Boolean).join(', ').toLowerCase(),
        };

        // reset page
        delete params.page;

        navigateIntl(
          `/?${queryString.stringify(params, {
            skipEmptyString: true,
            arrayFormat: 'comma',
          })}`
        );
        dispatchProps.initiateJobsFetch(params);
      },
    })
  ),
  injectIntl
)(StateDropdown);
