import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

import Switch from 'src/input/components/Switch';
import Spinner from 'src/layout/Spinner';

const useStyles = makeStyles(({ palette, shadows }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: palette.background.paper,
    boxShadow: shadows[5],
    width: '90vw',
    minWidth: '300px',
    maxWidth: '600px',
    height: 'auto',
    maxHeight: process.env.BROWSER ? window.innerHeight * 0.95 : '100%',
    borderRadius: '6px',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '48px 60px 38px',
    alignItems: 'center',
    color: palette.primary.dark,
    minHeight: 453,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 50,
  },
  listItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
    fontSize: 18,
    '& p': {
      width: 'calc(100% - 35px)',
      marginRight: 5,
      marginTop: -3,
    },
  },
  button: {
    borderRadius: '6px',
    fontSize: 16,
    height: 48,
    textTransform: 'none',
    fontWeight: 'normal',
    width: '100%',
    maxWidth: 310,
    marginTop: 20,
  },
  cancelButton: {
    border: 'none',
    marginTop: 10,
  },
}));

export default ({
  open,
  toggleAlertModal,
  values,
  setFieldValue,
  handleSubmit,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => toggleAlertModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {loading ? (
            <Spinner isSmall />
          ) : (
            <>
              <div className={classes.title}>
                <FormattedMessage
                  id="userDetails.alertsAndNotifications"
                  defaultMessage="Job alerts & Notifications"
                />
              </div>
              <div className={classes.listItem}>
                <p>
                  <FormattedMessage
                    id="userDetails.newJob"
                    defaultMessage="A new job that matches your job search"
                  />
                </p>
                <Switch
                  id="searchedJobNotification"
                  isOn={values.searchedJobNotification}
                  handleToggle={() =>
                    setFieldValue(
                      'searchedJobNotification',
                      !values.searchedJobNotification
                    )
                  }
                />
              </div>
              <div className={classes.listItem}>
                <p>
                  <FormattedMessage
                    id="userDetails.appliedJobs"
                    defaultMessage="Applied jobs"
                  />
                </p>
                <Switch
                  id="appliedJobNotification"
                  isOn={values.appliedJobNotification}
                  handleToggle={() =>
                    setFieldValue(
                      'appliedJobNotification',
                      !values.appliedJobNotification
                    )
                  }
                />
              </div>
              <div className={classes.listItem}>
                <p>
                  <FormattedMessage
                    id="userDetails.savedJobs"
                    defaultMessage="Your saved jobs search alert"
                  />
                </p>
                <Switch
                  id="savedJobNotification"
                  isOn={values.savedJobNotification}
                  handleToggle={() =>
                    setFieldValue(
                      'savedJobNotification',
                      !values.savedJobNotification
                    )
                  }
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.button}
              >
                <FormattedMessage id="btns.save" defaultMessage="Save" />
              </Button>
              <Button
                className={clsx(classes.button, classes.cancelButton)}
                onClick={() => toggleAlertModal(false)}
                variant="outlined"
              >
                <FormattedMessage id="btns.cancel" defaultMessage="Cancel" />
              </Button>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
};
