import createReducer from 'src/utils/createReducer';
import { LOG_OUT } from 'src/onboarding/redux/actions';

import {
  FETCH_LICENSES,
  GET_CARDS,
  UPDATE_CARD,
  DELETE_CARD,
  TOGGLE_PAYMENT_MODAL,
  PAY_EXTRA,
} from './actions';

const INITIAL_STATE = {
  loading: false,
  cardsLoading: false,
  licenses: {
    list: [],
    currencyRates: {},
  },
  cards: [],
  paymentModalOpen: false,
};

export default createReducer(INITIAL_STATE, {
  [FETCH_LICENSES.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_LICENSES.SUCCESS]: (state, { data: { d, currencyRates } }) => ({
    ...state,
    loading: false,
    licenses: {
      ...state.licenses,
      list: d,
      currencyRates,
    },
  }),
  [FETCH_LICENSES.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [GET_CARDS.REQUEST]: (state) => ({
    ...state,
    cardsLoading: true,
  }),
  [GET_CARDS.SUCCESS]: (state, { data: { d } }) => ({
    ...state,
    cardsLoading: false,
    cards: d,
  }),
  [GET_CARDS.FAILURE]: (state) => ({
    ...state,
    cardsLoading: false,
  }),

  [UPDATE_CARD.REQUEST]: (state) => ({
    ...state,
    cardsLoading: true,
  }),
  [UPDATE_CARD.SUCCESS]: (state, { data: d }) => ({
    ...state,
    cardsLoading: false,
    cards: state.cards.map((item) =>
      item.id === d.id
        ? d
        : { ...item, is_active: d.is_active ? false : item.is_active }
    ),
  }),
  [UPDATE_CARD.FAILURE]: (state) => ({
    ...state,
    cardsLoading: false,
  }),

  [DELETE_CARD.REQUEST]: (state) => ({
    ...state,
    cardsLoading: true,
  }),
  [DELETE_CARD.SUCCESS]: (state, { params }) => ({
    ...state,
    cardsLoading: false,
    cards: state.cards.filter((item) => item.id !== params.id),
  }),
  [DELETE_CARD.FAILURE]: (state) => ({
    ...state,
    cardsLoading: false,
  }),

  [PAY_EXTRA.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [PAY_EXTRA.SUCCESS]: (state, { data: { d } }) => ({
    ...state,
    loading: false,
  }),
  [PAY_EXTRA.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [TOGGLE_PAYMENT_MODAL]: (state, { isOpen }) => ({
    ...state,
    paymentModalOpen: isOpen,
  }),

  [LOG_OUT]: () => INITIAL_STATE,
});
