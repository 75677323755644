import React from 'react';

import Spinner from 'src/layout/Spinner';
import Logo from 'src/img/icons/Logo';

// TODO: move these components into src/router/Spinner/Customer|ClientSpinner.js
const LoadingScreenClient = (
  <div
    style={{
      height: '100vh',
    }}
  >
    <div
      style={{
        minHeight: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid #D6D8DF`,
      }}
    >
      <div
        style={{
          marginLeft: -20,
          width: '85%',
        }}
      >
        <Logo />
      </div>
    </div>
    <Spinner />
  </div>
);

const LoadingScreenCustomer = (
  <div
    style={{
      height: '100vh',
    }}
  >
    <Spinner />
  </div>
);

export const loadableConfig = {
  customer: { fallback: LoadingScreenCustomer },
  client: { fallback: LoadingScreenClient },
  cvBuilder: {
    fallback: LoadingScreenCustomer,
  },
};
