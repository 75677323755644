import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import isEmail from 'validator/lib/isEmail';

import { register, uploadAvatar, checkEmail } from 'src/user/redux/actions';
import { uploadCV } from 'src/cv/redux/actions';
import { toggleModal } from 'src/onboarding/redux/actions';
import { showSnackbar, toggleSucccessBar } from 'src/layout/redux/actions';
import { execute } from 'src/utils/captcha';
import { togglePrivacyPopup } from 'src/about/redux/actions';
import { dataLayerPush } from 'src/analytics/ga';
import { fetchUserJobTitlesAutocomplete } from 'src/user/redux/actions';

import Registration from './Registration';

let oldValue;
let lastValid;
const validationSchema = ({ checkEmail }) => {
  return Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('required')
      .test('is-email-valid', 'invalid', (value) => {
        if (!value) return true;
        return isEmail(value);
      })
      .test('user-exists', 'alreadyExists', (value) => {
        if (!value) return true;
        if (!isEmail(value)) return true;

        if (value === oldValue) return lastValid;

        const debon = async (d) => {
          const { error } = await checkEmail({ email: d });
          return error !== 'ALREADY_EXISTS';
        };

        const isValid = debon(value);

        oldValue = value;
        lastValid = isValid;
        return isValid;
      })
      .matches(/^[\x20-\x7F]*$/, 'invalid'),
    firstName: Yup.string().trim().required('required'),
    lastName: Yup.string().trim().required('required'),
    experienceTitle: Yup.number()
      .typeError('invalid')
      .required('required')
      .min(0, 'invalid')
      .max(99, 'invalid'),
    password: Yup.string()
      .required('required')
      .min(8, 'minChars')
      .test('contains non-english chars', 'onlyEnglishAllowed', (value) =>
        /^[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]+$/.test(
          value
        )
      ),
    password2: Yup.string()
      .required('required')
      .min(8, 'minChars')
      .test('contains non-english chars', 'onlyEnglishAllowed', (value) =>
        /^[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]+$/.test(
          value
        )
      )
      .when('password', {
        is: (val) => val && val.length > 0,
        then: Yup.string().oneOf([Yup.ref('password')], 'noMatch'),
      }),
    phone: Yup.string().matches(/^\+\d{8,}$/, 'invalid'),
    jobTitle: Yup.string().trim().required('required'),
    cv: Yup.mixed().required('required'),
    city: Yup.string().trim().required('required'),
    country: Yup.string().trim().required('required'),
    typeOfEmployment: Yup.array().min(1, 'atLeast1'),
  });
};

const states = {
  REG_REQUEST: 'Creating a user...',
  CV_UPLOAD_REQUEST: 'Uploading your cv...',
  FILE_UPLOAD_REQUEST: 'Uploading your image...',
};

export default compose(
  withSnackbar,
  connect(
    (state) => ({
      progressState: states[state.user.progressState],
      loading: state.user.loading,
      messages: state.locale.messages,
      userJobTitles: state.user.userJobTitlesAutocomplete,
      langCode: state.locale.language,
    }),
    {
      registerUser: register,
      showSnackbar,
      uploadCVDispatch: uploadCV,
      uploadAvatarDispatch: uploadAvatar,
      toggleModal,
      checkEmail,
      toggleSucccessBar,
      togglePrivacyPopup,
      fetchUserJobTitlesAutocomplete,
    },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      showCVError: (type) => {
        parentProps.enqueueSnackbar(
          type === 'size'
            ? stateProps.messages['errors.file.tooBig']
            : stateProps.messages['errors.cv.onlyPdf'],
          {
            variant: 'error',
          }
        );
      },
    })
  ),
  injectIntl,
  withFormik({
    mapPropsToValues: () => ({
      email: '',
      password: '',
      password2: '',
      jobTitle: '',
      city: '',
      country: '',
      state: '',
      allowCompanies: true,
      jobNotification: true,
      firstName: '',
      lastName: '',
      seniority: '',
      suggestedSeniority: '',
      linkedin: '',
      facebook: '',
      phone: '',
      experienceTitle: '',
      typeOfEmployment: ['employee'],
    }),
    displayName: 'RegistrationUserForm',
    validationSchema,
    handleSubmit: async (
      val,
      {
        setFieldError,
        props: {
          registerUser,
          uploadCVDispatch,
          uploadAvatarDispatch,
          toggleModal,
          enqueueSnackbar,
          toggleSucccessBar,
          messages,
          langCode,
        },
      }
    ) => {
      try {
        const captchaToken = await execute({
          action: 'submit',
        });
        const { cv, avatar, ...form } = val;
        const trimmedForm = Object.entries(form).reduce(
          (acc, [k, v]) => (v ? (acc[k] = v) : acc, acc),
          {}
        );

        // delete the suggested field
        if (!trimmedForm.seniority)
          trimmedForm.seniority = trimmedForm.suggestedSeniority.seniority;
        delete trimmedForm.suggestedSeniority;

        const { error, errors, t } = await registerUser({
          captchaToken,
          langCode,
          ...trimmedForm,
        });

        const headers = {
          'x-auth-token': t,
        };

        if (error) {
          error === 'ALREADY_EXISTS' &&
            setFieldError('email', 'userAlreadyExists');
          return enqueueSnackbar(
            error === 'ALREADY_EXISTS'
              ? messages['errors.email.userAlreadyExists']
              : messages['snackbar.error.errorOccured'],
            { variant: 'error' }
          );
        }

        if (errors) {
          return errors.forEach(({ msg, param }) => setFieldError(param, msg));
        }

        toggleModal(false);
        toggleSucccessBar(true);

        dataLayerPush('registration_succeed', {
          method: 'email',
        });

        if (cv) {
          const CVData = new FormData();
          CVData.append('file', cv);
          CVData.append('type', 'cv');

          await uploadCVDispatch(CVData, headers);
        }

        if (avatar) {
          const imgData = new FormData();
          imgData.append('file', avatar);

          await uploadAvatarDispatch(imgData, headers);
        }
      } catch (e) {
        console.log(e);
      }
    },
  })
)(Registration);
