import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fade from '@material-ui/core/Fade';

import Upload from 'src/img/icons/Upload';
import TextInput from 'src/input/components/TextInput';
import Spinner from 'src/layout/Spinner';
import Switch from 'src/input/components/Switch';
import { cities } from 'src/Dashboards/AutoCompleteConst';
import CountryAutocomplete from 'src/input/components/CountryAutocomplete';
import CityAutocomplete from 'src/input/components/CityAutocomplete';
import CheckboxInput from 'src/input/components/CheckboxInput';

import useStyles from './styles';

const AddCVModal = ({
  isOpen,
  toggleCVModal,
  values,
  errors,
  handleChange,
  setFieldValue,
  touched,
  handleBlur,
  handleSubmit,
  loading,
  showCVError,
  user,
  intl,
  oneField,
  togglePrivacyPopup,
}) => {
  const classes = useStyles();
  const cvError = errors.cv && touched.cv;
  const countryPlaceholder = intl.formatMessage({
    id: 'pleaseSelectCountry',
    defaultMessage: 'Please select a country',
  });
  const [citiesList, setCitiesList] = useState([countryPlaceholder]);
  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    setCitiesList(
      values.country ? cities[values.country] || [] : [countryPlaceholder]
    );
  }, [values.country]);

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        toggleCVModal(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 0,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.card}>
          <div className={classes.title}>
            {loading ? (
              <FormattedMessage
                id='addCVModal.beingUpdated'
                defaultMessage='Data is being updated...'
              />
            ) : oneField ? (
              <FormattedMessage
                id='addCVModal.updatedTitle'
                defaultMessage='Please update your job title'
              />
            ) : (
              <FormattedMessage
                id='addCVModal.updatedProfile'
                defaultMessage='Update your profile to view this job'
              />
            )}
          </div>
          {loading ? (
            <div className={classes.spinnerDiv}>
              <Spinner isSmall />
            </div>
          ) : (
            <>
              {user.cv || oneField ? null : (
                <>
                  <input
                    accept='.pdf'
                    ref={hiddenFileInput}
                    className={classes.input}
                    id='icon-button-file'
                    type='file'
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={({ target }) => {
                      if (!target.files) return;
                      if (target.files[0].type !== 'application/pdf') {
                        return showCVError('type');
                      }
                      target.files[0].size >= 10515973
                        ? showCVError('size')
                        : setFieldValue('cv', target.files[0]);
                    }}
                  />
                  <div
                    tabIndex='0'
                    className={clsx(
                      classes.inputContainer,
                      errors.cv && touched.cv && classes.errorInputContainer
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      hiddenFileInput.current.click();
                    }}
                  >
                    <Upload />
                    {values.cv?.name ? (
                      <div className={classes.fileName}>{values.cv?.name}</div>
                    ) : (
                      <>
                        <p className={classes.uploadTitle}>
                          <FormattedMessage
                            id='inputText.uploadCV'
                            defaultMessage='Upload CV'
                          />
                        </p>
                        <span className={classes.size}>
                          <FormattedMessage
                            id='cv.max10mb'
                            defaultMessage='(max 10mb)'
                          />
                        </span>
                      </>
                    )}
                    {errors.cv && touched.cv ? (
                      <div className={classes.error}>
                        <FormattedMessage
                          id='errors.cv.required'
                          defaultMessage='CV is required'
                        />
                      </div>
                    ) : null}
                  </div>
                </>
              )}
              {user.jobTitle ? null : (
                <TextInput
                  name='jobTitle'
                  label={intl.formatMessage({
                    id: 'inputText.jobTitle',
                    defaultMessage: 'Job Title',
                  })}
                  className={classes.textField}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  intl={intl}
                />
              )}
              {user.experienceTitle || oneField ? null : (
                <TextInput
                  name='experienceTitle'
                  errorName='experienceTitle'
                  label={intl.formatMessage({
                    id: 'inputText.yearsOfExp',
                    defaultMessage: 'Years of experience',
                  })}
                  className={classes.textField}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  intl={intl}
                />
              )}
              {oneField ? null : (
                <>
                  <CountryAutocomplete
                    key='country'
                    errors={errors}
                    values={values}
                    touched={touched}
                    className={classes.textField}
                    variant='outlined'
                    setFieldValue={setFieldValue}
                    intl={intl}
                  />
                  <CityAutocomplete
                    key='city'
                    errors={errors}
                    values={values}
                    touched={touched}
                    citiesList={citiesList}
                    className={classes.textField}
                    variant='outlined'
                    setFieldValue={setFieldValue}
                    intl={intl}
                  />
                  <div
                    className={classes.switchContainer}
                    onClick={() =>
                      setFieldValue('allowCompanies', !values.allowCompanies)
                    }
                  >
                    <p className={classes.employersTitle}>
                      <FormattedMessage
                        id='userDetails.publicUserShortExplain'
                        defaultMessage='Let employers find you - Public User'
                      />
                    </p>
                    <Switch id='allowCompanies' isOn={values.allowCompanies} />
                  </div>
                  <div className={classes.publicDesc}>
                    <FormattedMessage
                      id='userDetails.publicUserLongExplain'
                      defaultMessage='Public: Hiring managers will be able to view your profile and reach out about potential opportunities.'
                    />
                  </div>
                </>
              )}
              <div
                className={clsx(
                  classes.termsCheckbox,
                  errors.agreeToTerms &&
                    touched.agreeToTerms &&
                    classes.termsError
                )}
              >
                <FormControlLabel
                  control={
                    <CheckboxInput
                      value={values.agreeToTerms}
                      onChange={handleChange}
                      name='agreeToTerms'
                      error={errors.agreeToTerms && touched.agreeToTerms}
                    />
                  }
                  label={
                    <div className={classes.termsLabel}>
                      <FormattedMessage
                        id='payment.iAgreeToTerms'
                        defaultMessage='I agree to '
                      />
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          togglePrivacyPopup('user', 'terms');
                        }}
                      >
                        <FormattedMessage
                          id='payment.termsAndConditions'
                          defaultMessage='Terms and Conditions'
                        />
                      </span>
                    </div>
                  }
                />
              </div>
              <div className={classes.buttonDiv}>
                <button onClick={handleSubmit} className={classes.button}>
                  <FormattedMessage
                    id='btns.saveAndContinue'
                    defaultMessage='Save and continue'
                  />
                </button>
              </div>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default AddCVModal;
