import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'nowarp',
    alignItems: 'stretch',
    '@media screen and (max-width: 650px)': {
      justifyContent: 'center',
    },
  },
  moreFiltersBtn: {
    height: 60,
    flexGrow: 1,
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      margin: '0',
    },
  },
  moreFiltersContainer: {
    overflow: 'hidden',
    backgroundColor: 'white',
    borderTop: '2px solid #2F64EA',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    borderRadius: 6,
    width: 275,
    height: 'auto',
    '& > div': {
      width: 275,
      margin: '0',
      borderRadius: 0,
      '&:first-child': {
        borderRadius: '6px 6px 0 0',
      },
    },
  },
  moreFiltersDropdown: {
    width: 180,
    height: 40,
    margin: 0,
    cursor: 'pointer',
    padding: '0 12.5px',
    borderRadius: 6,
    backgroundColor: 'red',
  },
  filtersContainer: {
    gap: 15,
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    paddingTop: 10,
    alignItems: 'center',
    maxHeight: 50,
    width: '100%',
    transition: 'all 0.2s ease-in-out',
    '& > div': {
      minWidth: 180,
    },
    '@media screen and (min-width: 600px)': {
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#fbfbfb',
      },
      '&::-webkit-scrollbar': {
        height: 8,
        backgroundColor: '#F5F5F5',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#cecece',
        borderRadius: 3,
      },
    },
    '@media screen and (max-width: 500px)': {
      flexDirection: 'column',
      '& > div': {
        minWidth: 275,
      },
    },
    '@media screen and (max-width: 650px)': {
      display: 'none',
    },
  },
  gradient: {
    height: 50,
    position: 'absolute',
    backgroundColor: 'transparent',
    top: 0,
    zIndex: 100,
    width: '50%',
    pointerEvents: 'none',
    transition: 'all 0.5s ease-in-out',
    opacity: 0,
  },
  rightGradient: {
    opacity: 1,
    background:
      'linear-gradient(90deg, rgba(0,0,0,0) 95%, rgba(237,237,241,1) 100%)',
    right: 0,
  },
  leftGradient: {
    opacity: 1,
    background:
      'linear-gradient(90deg, rgba(237,237,241,1) 0%, rgba(0,0,0,0) 10%)',
    left: 0,
  },
  filtersButton: {
    display: 'none',
    justifyContent: 'center',
    width: '95%',
    maxWidth: 250,
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 15px',
    border: 'none',
    backgroundColor: 'white',
    color: '#2F64EA',
    borderRadius: 6,
    height: 40,
    fontSize: 16,
    '& > p': {
      marginLeft: 12.5,
    },
    '@media screen and (max-width: 650px)': {
      display: 'flex',
    },
  },
  filtersModalContainer: {
    backgroundColor: 'white',
    position: 'fixed',
    zIndex: 100,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  modalHeadersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '10px 20px',
    borderBottom: '1px solid #f3f4f6',
    '& > p': {
      fontWeight: 500,
      fontSize: 17,
    },
  },
  resetFiltersBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: 15,
  },
  closeModalBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
  },
}));
