import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';

import {
  register,
  checkWebsite,
  checkEmail,
  checkCompanyName,
  checkPhone,
} from 'src/customer/redux/actions';
import { showSnackbar, toggleSucccessBar } from 'src/layout/redux/actions';
import { toggleModal } from 'src/onboarding/redux/actions';
import { execute } from 'src/utils/captcha';
import { togglePrivacyPopup } from 'src/about/redux/actions';

import Registration from './Registration';
import { trimValues } from 'src/utils';

let oldEmailValue,
  lastEmailValid,
  oldWebsiteValue,
  lastWebsiteValid,
  oldCompanyName,
  lastCompanyValid,
  oldPhoneValue,
  lastPhoneValid;

const validationSchema = ({
  checkEmail,
  checkWebsite,
  checkCompanyName,
  checkPhone,
}) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('required')
      .test('company-name-exists', 'alreadyExists', (value) => {
        if (!value) return true;
        if (value === oldCompanyName) return lastCompanyValid;
        const debon = async (d) => {
          const res = await checkCompanyName({ name: d });
          return res?.error !== 'ALREADY_EXISTS';
        };
        const isValid = debon(value);

        oldCompanyName = value;
        lastCompanyValid = isValid;
        return isValid;
      }),
    web_site: Yup.string()
      .trim()
      .test('is-url-valid', 'invalid', (value) => {
        if (!value) return true;
        return isURL(value);
      })
      .test('website-exists', 'alreadyExists', (value) => {
        if (!value) return true;
        if (!isURL(value)) return true;
        if (value === oldWebsiteValue) return lastWebsiteValid;
        const debon = async (d) => {
          const res = await checkWebsite({ website: d });
          return res?.error !== 'ALREADY_EXISTS';
        };
        const isValid = debon(value);

        oldWebsiteValue = value;
        lastWebsiteValid = isValid;
        return isValid;
      }),
    country: Yup.string().required('required'),
    state: Yup.string(),
    city: Yup.string().required('required'),
    contact_person: Yup.string().required('required'),
    contact_person_job_title: Yup.string().required('required'),
    contact_person_email: Yup.string()
      .required('required')
      .test('is-email-valid', 'invalid', (value) => {
        if (!value) return true;
        return isEmail(value);
      })
      .test('user-exists', 'alreadyExists', (value) => {
        if (!value) return true;
        if (!isEmail(value)) return true;

        if (value === oldEmailValue) return lastEmailValid;

        const debon = async (d) => {
          const res = await checkEmail({ email: d });
          return res?.error !== 'ALREADY_EXISTS';
        };

        const isValid = debon(value);

        oldEmailValue = value;
        lastEmailValid = isValid;
        return isValid;
      })
      .matches(/^[\x20-\x7F]*$/, 'invalid'),
    contact_person_phone: Yup.string()
      .required('required')
      .matches(/^\+\d{8,}$/, 'invalid')
      .test('phone-exists', 'alreadyExists', (value) => {
        if (!value) return true;
        if (value.length < 9) return true;

        if (value === oldPhoneValue) return lastPhoneValid;

        const debon = async (d) => {
          const res = await checkPhone({ phone: d });
          return res?.error !== 'ALREADY_EXISTS';
        };

        const isValid = debon(value);

        oldPhoneValue = value;
        lastPhoneValid = isValid;
        return isValid;
      }),
    contact_person_password: Yup.string()
      .required('required')
      .min(8, 'minChars')
      .test('contains non-english chars', 'onlyEnglishAllowed', (value) =>
        /^[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]+$/.test(
          value
        )
      ),
    contact_person_password_verification: Yup.string()
      .required('required')
      .when('contact_person_password', {
        is: (val) => val && val.length > 0,
        then: Yup.string().oneOf(
          [Yup.ref('contact_person_password')],
          'noMatch'
        ),
      }),
  });

export default compose(
  withSnackbar,
  connect(
    (state) => ({
      messages: state.locale.messages,
      langCode: state.locale.language,
    }),
    {
      registerUser: register,
      showSnackbar,
      toggleModal,
      checkWebsite,
      checkEmail,
      checkCompanyName,
      toggleSucccessBar,
      checkPhone,
      togglePrivacyPopup,
    }
  ),
  injectIntl,
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      web_site: '',
      country: '',
      state: '',
      city: '',
      industry: '',
      industrySpecified: '',
      num_of_employees: '1',
      contact_person: '',
      contact_person_email: '',
      contact_person_phone: '',
      contact_person_job_title: '',
      contact_person_password: '',
      contact_person_password_verification: '',
    }),
    displayName: 'RegistrationForm',
    validationSchema,
    handleSubmit: async (
      val,
      {
        props: {
          registerUser,
          enqueueSnackbar,
          toggleSucccessBar,
          toggleModal,
          messages,
          langCode,
        },
        setSubmitting,
      }
    ) => {
      try {
        const captchaToken = await execute({
          action: 'submit',
        });
        if (val.industrySpecified) {
          val.industry = val.industrySpecified;
        }
        delete val.industrySpecified;
        const { error, errors } = await registerUser({
          ...trimValues(val),
          captchaToken,
          langCode,
        });
        setSubmitting(false);

        if (error || errors) {
          return enqueueSnackbar(
            messages['snackbar.error.somethingWentWrong'],
            { variant: 'error' }
          );
        }
        toggleModal(false);
        toggleSucccessBar(true, true);
      } catch (e) {
        console.log(e);
        return enqueueSnackbar(messages['snackbar.error.somethingWentWrong'], {
          variant: 'error',
        });
      }
    },
  })
)(Registration);
