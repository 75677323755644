import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const CLEAR_CUSTOMER_DATA = 'CLEAR_CUSTOMER_DATA';
export const CLEAR_USER = 'CLEAR_USER';
export const FETCH_CUSTOMER = createAsyncActionRequestTypes('FETCH_CUSTOMER');
export const UPDATE_CUSTOMER = createAsyncActionRequestTypes('UPDATE_CUSTOMER');
export const UPLOAD_LOGO = createAsyncActionRequestTypes('UPLOAD_LOGO');
export const LOAD_LOGO = createAsyncActionRequestTypes('LOAD_LOGO');
export const CHANGE_LICENSE = createAsyncActionRequestTypes('CHANGE_LICENSE');
export const TOGGLE_STATUS_MEMO = 'TOGGLE_STATUS_MEMO';

export const clearCustomerData = () => ({
  type: CLEAR_CUSTOMER_DATA,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});

export const me = () =>
  createApiAction(
    {
      url: '/customer',
    },
    FETCH_CUSTOMER
  );

export const update = (body) =>
  createApiAction(
    {
      method: 'PATCH',
      url: '/customer',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    },
    UPDATE_CUSTOMER
  );

export const uploadLogo = (body) =>
  createApiAction(
    {
      method: 'POST',
      url: '/customer/logo',
      body: body,
    },
    UPLOAD_LOGO
  );

export const loadLogo = () =>
  createApiAction(
    {
      url: '/customer/logo',
    },
    LOAD_LOGO
  );

export const changeLicense = (body) =>
  createApiAction(
    {
      method: 'POST',
      url: '/customer/change-license',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    CHANGE_LICENSE
  );

export const toggleStatusMemo = (isOpen) => ({
  type: TOGGLE_STATUS_MEMO,
  isOpen,
});
