import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color, size, style }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    style={{
      height: size || '12px',
      width: size || '12px',
      ...style,
    }}
  >
    <path
      fill={color}
      d='m22.707 11.293-7-7a1 1 0 0 0 -1.414 1.414l5.293 5.293h-17.586a1 1 0 0 0 0 2h17.586l-5.293 5.293a1 1 0 1 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414z'
    />
  </SvgIcon>
);
