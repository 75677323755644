import { connect } from 'react-redux';

import LILogin from './LILogin';

import { connectUser } from 'src/onboarding/redux/actions';

export default connect(
  (state) => ({
    ...state.locale,
    redirectBackUrl:
      state.router.location.query?.redirectUrl ||
      state.router.location.pathname,

    templateName: state?.cvBuilder?.cvData?.templateName,
  }),
  { connectUser }
)(LILogin);
