import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  headerContainer: {
    position: 'fixed',
    display: 'flex',
    backgroundColor: palette.background.paper,
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    width: '100%',
    zIndex: 1000,
    height: 55,
    boxSizing: 'border-box',
    boxShadow: '0 5px 10px 1px rgba(32,40,92,0.08)',
  },
  header: {
    width: '100%',
    maxWidth: 1840,
    minHeight: 55,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 40px',
    alignItems: 'center',

    '& a': {
      color: '#4E5C73',
    },
    '& a:visited': {
      color: '#4E5C73',
    },
  },
  logoBar: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
  },
  headerMenu: {
    display: 'none',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  menuIcon: {
    cursor: 'pointer',
  },
  divider: {
    height: 32,
    width: 1,
    backgroundColor: palette.text.disabled,
    margin: 'auto',
    '@media (max-width: 270px)': {
      '&': {
        display: 'none',
      },
    },
  },
  bold: {
    fontWeight: 600,
  },
  headerLink: {
    color: '#4E5C73',
    minWidth: 90,
    width: '100%',
    height: 55,
    padding: '0 25px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
  },
  loginButton: {
    border: `1px ${palette.primary.main} solid`,
    width: '80%',
    maxWidth: 210,
    height: 48,
    color: palette.primary.main,
    backgroundColor: '#fff',
    textTransform: 'none',
    fontSize: 16,
  },
  '@media screen and (min-width: 1050px)': {
    headerContainer: {
      height: 60,
    },
    header: {
      padding: '0 15px',
    },
    headerMenu: {
      width: '100%',
      marginLeft: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > div': {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        gap: 10,
      },
    },

    headerLink: {
      height: 32,
      width: 'auto',
      padding: '5px 10px',
      margin: 0,
    },
    menuIcon: {
      display: 'none',
    },
    linkDivider: {
      paddingRight: 28,
      marginRight: 28,
      borderRight: `1px ${palette.text.disabled} solid`,
    },
    registerLink: {
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: '600',
      borderRadius: 6,
      height: 48,
      margin: '0 10px',
      color: '#00a085',
      backgroundColor: '#CCF3F5',
      width: '103px',
      height: '40px',
      '&:hover': {
        backgroundColor: '#ace9d1',
      },
    },
    loginLink: {},
  },
  '@media screen and (min-width: 1100px)': {
    header: {
      minHeight: 60,
    },
  },
  loginButtonContainer: {
    display: 'flex',
    borderTop: '1px solid #d1d5db',
    justifyContent: 'center',
    padding: '10px 5px',
    alignItems: 'center',
    width: '100%',
    alignSelf: 'end',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
  },
  navItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },
  navItemBorder: {
    borderBottom: `2px ${palette.primary.main} solid`,
    fontWeight: '600',
    '& > a': {
      color: palette.primary.main + ' !important',
    },
  },
}));
