import { createApiAction, createAsyncActionRequestTypes } from 'src/api';
import queryString from 'query-string';

export const GET_CUSTOMER_USERS = createAsyncActionRequestTypes(
  'GET_CUSTOMER_USERS'
);
export const GET_CUSTOMER_FILTERED_USERS = createAsyncActionRequestTypes(
  'GET_CUSTOMER_FILTERED_USERS'
);
export const ADD_CUSTOMER_USER = createAsyncActionRequestTypes(
  'ADD_CUSTOMER_USER'
);
export const EDIT_CUSTOMER_USER = createAsyncActionRequestTypes(
  'EDIT_CUSTOMER_USER'
);
export const DELETE_CUSTOMER_USER = createAsyncActionRequestTypes(
  'DELETE_CUSTOMER_USER'
);

const BASE_URL = '/customer/user';
const HEADERS = {
  'Content-Type': 'application/json',
};

export const getUsers = (
  { page = 1, ...params } = {},
  action = GET_CUSTOMER_USERS
) => {
  return createApiAction(
    {
      url: `${BASE_URL}?${queryString.stringify(
        {
          ...params,
          page: page - 1,
        },
        { skipEmptyString: true }
      )}`,
      headers: HEADERS,
    },
    action
  );
};

export const getFilteredUsers = (params) =>
  getUsers(params, GET_CUSTOMER_FILTERED_USERS);

export const crudAction = (params, method, action, url = BASE_URL) =>
  createApiAction(
    {
      method,
      url,
      headers: HEADERS,
      body: params,
    },
    action
  );

export const addUser = (params) =>
  crudAction(params, 'POST', ADD_CUSTOMER_USER);
export const updateUser = (params) =>
  crudAction(params, 'PATCH', EDIT_CUSTOMER_USER);
export const deleteUser = (params) =>
  crudAction(
    params,
    'DELETE',
    DELETE_CUSTOMER_USER,
    `${BASE_URL}/${params.id}`
  );
