export const makeInitials = (companyName) => {
  if (!companyName) return '';
  if (companyName.length <= 3) {
    return companyName;
  } else {
    let onlyUppercase = companyName.replace(/[a-z ]/g, '');
    if (onlyUppercase[0] !== companyName[0]) {
      onlyUppercase = companyName[0] + onlyUppercase;
    }
    return onlyUppercase.length
      ? onlyUppercase.slice(0, 3)
      : companyName
          .split(' ')
          .map((word) => word[0])
          .join('');
  }
};

export const truncate = (str, length) =>
  str.length > length ? str.substring(0, length) + '...' : str;

export const getManagersList = (arr = []) => {
  if (!arr.length) return '';
  let res = '';
  let shortArr = arr.slice(0, 3);
  shortArr.forEach((item, i, arr) => {
    res += ' ' + item.fullName;
    if (i !== arr.length - 1) {
      res += ',';
    }
  });
  if (arr.length > 3) {
    res += '...';
  }
  return res;
};

export const checkIsRtl = (str) => (/[\u0590-\u05FF]/.test(str) ? 'he' : 'en');
