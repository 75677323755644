import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { uploadCV, fetchCV } from 'src/cv/redux/actions';
import { updateMe } from 'src/user/redux/actions';
import { toggleCVModal } from 'src/user/redux/actions/profile';
import { togglePrivacyPopup } from 'src/about/redux/actions';

import AddCVModal from './AddCVModal';

const validationSchema = Yup.object().shape({
  jobTitle: Yup.string().required('required'),
  cv: Yup.mixed().required('required'),
  experienceTitle: Yup.number()
    .typeError('invalid')
    .required('required')
    .min(0, 'invalid')
    .max(99, 'invalid'),
  allowCompanies: Yup.boolean(),
  country: Yup.string().required('required'),
  city: Yup.string().required('required'),
  agreeToTerms: Yup.boolean().oneOf([true], 'required'),
});

export default compose(
  withSnackbar,
  connect(
    (state, props) => ({
      loading: state.user.loading,
      isOpen: state.user.isCVModalOpen,
      oneField: state.user.cvModalOneField,
      user: state.user.profile,
      cvModalCallback: state.user.cvModalCallback,
      messages: state.locale.messages,
    }),
    {
      fetchCV,
      updateMe,
      toggleCVModal,
      uploadCVDispatch: uploadCV,
      togglePrivacyPopup,
    },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      showCVError: (type) => {
        parentProps.enqueueSnackbar(
          type === 'size'
            ? stateProps.messages['errors.file.tooBig']
            : stateProps.messages['errors.cv.onlyPdf'],
          {
            variant: 'error',
          }
        );
      },
    })
  ),
  injectIntl,
  withFormik({
    mapPropsToValues: ({ user }) => ({
      jobTitle: user.jobTitle || '',
      cv: user.cv,
      experienceTitle: user.experienceTitle || '',
      allowCompanies: true,
      country: user.country || '',
      city: user.city || '',
      agreeToTerms: false,
    }),
    displayName: 'CVTitleForm',
    validationSchema: (props) =>
      props.oneField
        ? Yup.object().shape({
            jobTitle: Yup.string().required('required'),
          })
        : validationSchema,
    handleSubmit: async (
      val,
      {
        props: {
          uploadCVDispatch,
          enqueueSnackbar,
          updateMe,
          fetchCV,
          toggleCVModal,
          cvModalCallback,
          messages,
          oneField,
        },
      }
    ) => {
      const { cv, jobTitle, experienceTitle, allowCompanies, country, city } =
        val;
      let cvRes;
      if (cv) {
        const CVData = new FormData();
        CVData.append('file', cv);
        CVData.append('type', 'cv');
        cvRes = await uploadCVDispatch(CVData);
      }

      const profileRes = await updateMe(
        oneField
          ? { jobTitle }
          : {
              jobTitle,
              experienceTitle: experienceTitle.toString(),
              allowCompanies,
              country,
              city,
            }
      );

      toggleCVModal(false);
      !oneField && fetchCV();

      const error = cvRes?.error || profileRes.error;
      !error && cvModalCallback && cvModalCallback();
      return enqueueSnackbar(
        error
          ? messages['snackbar.error.somethingWentWrong']
          : oneField
          ? messages['snackbar.success.jobTitleUploaded']
          : messages['snackbar.success.cvJobTitleUploaded'],
        {
          variant: error ? 'error' : 'success',
        }
      );
    },
  })
)(AddCVModal);
