import React from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import EyeInput from 'src/input/components/EyeInput';
import TextInput from 'src/input/components/TextInput';
import PhoneNumberInput from 'src/input/components/PhoneNumberInput';
import ArrowDown from 'src/img/icons/ArrowDown';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
  },
  textField: {
    width: '100%',
    maxWidth: 310,
    marginTop: 20,
    '& fieldset': {
      borderRadius: 6,
    },
  },
  dense: {
    marginTop: 19,
    marginBottom: 20,
  },
  errorText: {
    color: 'red',
    fontSize: '14px',
    marginTop: 0,
    marginLeft: 0,
    fontWeight: 'normal',
    textAlign: 'left',
  },
  popupIcon: {
    width: '12px',
    height: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  containerClass: {
    width: '100%',
    maxWidth: 310,
  },
}));

export default ({
  values,
  errors,
  handleChange,
  touched,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  intl,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TextInput
        name='contact_person'
        errorName='contactPerson'
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        label={intl.formatMessage({
          id: 'inputText.contactPerson',
          defaultMessage: 'Your name',
        })}
        touched={touched}
        errors={errors}
        className={classes.textField}
        intl={intl}
      />
      <form className={classes.containerClass}>
        <TextInput
          name='contact_person_email'
          errorName='email'
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          label={intl.formatMessage({
            id: 'inputText.contactPersonEmail',
            defaultMessage: 'Email',
          })}
          touched={touched}
          errors={errors}
          className={classes.textField}
          autoComplete='email'
          error={errors.contact_person_email === 'alreadyExists'}
          intl={intl}
        />
        <EyeInput
          name='contact_person_password'
          type='password'
          autoComplete='new-password'
          value={values.contact_person_password}
          onChange={handleChange}
          onBlur={handleBlur}
          variant='outlined'
          label={intl.formatMessage({
            id: 'inputText.contactPersonPassword',
            defaultMessage: 'Create password',
          })}
          helperText={
            touched.contact_person_password && errors.contact_person_password
              ? intl.formatMessage({
                  id: `errors.password.${errors.contact_person_password}`,
                  defaultMessage: 'Error',
                })
              : ''
          }
          error={
            touched.contact_person_password && !!errors.contact_person_password
          }
          className={classes.textField}
          containerClassName={classes.containerClass}
          iconStyle={{ top: '31px' }}
          FormHelperTextProps={{
            className: classes.errorText,
          }}
        />
        <EyeInput
          name='contact_person_password_verification'
          type='password'
          autoComplete='verify new-password'
          value={values.contact_person_password_verification}
          onChange={handleChange}
          onBlur={handleBlur}
          variant='outlined'
          label={intl.formatMessage({
            id: 'inputText.password2',
            defaultMessage: 'Password Verify',
          })}
          helperText={
            touched.contact_person_password_verification &&
            errors.contact_person_password_verification
              ? intl.formatMessage({
                  id: `errors.password.${errors.contact_person_password_verification}`,
                  defaultMessage: 'Error',
                })
              : ''
          }
          error={
            touched.contact_person_password_verification &&
            !!errors.contact_person_password_verification
          }
          className={classes.textField}
          containerClassName={classes.containerClass}
          iconStyle={{ top: '31px' }}
          FormHelperTextProps={{
            className: classes.errorText,
          }}
        />
      </form>
      <PhoneNumberInput
        name='contact_person_phone'
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        value={values.contact_person_phone}
        touched={touched.contact_person_phone}
        error={errors.contact_person_phone}
        className={clsx(classes.textField)}
        intl={intl}
      />
      <TextInput
        name='contact_person_job_title'
        errorName='jobTitle'
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        label={intl.formatMessage({
          id: 'inputText.contactPersonJobTitle',
          defaultMessage: 'Job title',
        })}
        touched={touched}
        errors={errors}
        className={classes.textField}
        intl={intl}
      />
      {/* <Autocomplete
        name="contact_person_job_title"
        getOptionLabel={(option) => option}
        onChange={(event, value, reason) => {
          if (reason === 'clear') {
            setFieldValue('contact_person_job_title', '');
          }
          if (value) {
            setFieldValue('contact_person_job_title', value);
          }
        }}
        value={values.contact_person_job_title}
        getOptionSelected={({ label }) =>
          label === values.contact_person_job_title
        }
        popupIcon={
          <div className={classes.popupIcon}>
            <ArrowDown />
          </div>
        }
        openOnFocus
        className={classes.textField}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: 'inputText.contactPersonJobTitle',
              defaultMessage: 'Job title',
            })}
            error={
              touched.contact_person_job_title &&
              !!errors.contact_person_job_title
            }
            helperText={
              touched.contact_person_job_title &&
              errors.contact_person_job_title
                ? intl.formatMessage({
                    id: `errors.jobTitle.${errors.contact_person_job_title}`,
                    defaultMessage: 'Error',
                  })
                : ''
            }
            className={classes.autocomplete}
            variant="outlined"
            FormHelperTextProps={{
              className: classes.errorText,
            }}
          />
        )}
      /> */}
    </div>
  );
};
