import React from 'react';

import Login from 'src/onboarding/components/Login';
import { navigateIntl } from 'src/utils/history';

export default (props) => {
  const handleForgotPassword = () => {
    props.toggleLoginModal(false);
    navigateIntl(`/forgot-password`, { c: 0 });
  };

  return (
    <Login
      type="client"
      handleForgotPassword={handleForgotPassword}
      showSocialLogin
      {...props}
    />
  );
};
