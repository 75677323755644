import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import { VariableSizeList as List } from 'react-window';
import clsx from 'clsx';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import CheckboxInput from 'src/input/components/CheckboxInput';
import FilterItem from '../JobFilters/FilterItem';
import useStyles from './styles';
import { allLanguages, capFirstLetters } from 'src/intl/utils';
import {
  DEFAULT_ITEM_HEIGHT,
  DEFAULT_SEARCH_HEIGHT,
  MAX_ITEM_SIZE_CITY,
} from '../../utils/constants';
import {
  measureTextWidth,
  customSort,
  getWindowHeight,
} from '../../utils/functions';

const AsyncTypeahead = withAsync(Typeahead);

export default ({
  handleSubmit,
  langCode,
  locationQuery: {
    country: countryQuery = '',
    state: stateQuery = '',
    city: cityQuery = '',
  },
  citiesList = [],
  getCitiesAutocomplete,
  intl,
  dropdownMenu = false,
  openFilter,
  setOpenFilter,
}) => {
  const classes = useStyles();
  const listRef = useRef({});
  const rowHeights = useRef({});
  const anchorRef = useRef(null);
  const isMenuOpen = openFilter === 'city';
  // const [filteredCities, setFilteredCities] = useState(citiesList);
  const [searchCity, setSearchCity] = useState(cityQuery);
  const [textWidths, setTextWidths] = useState([]);
  const [windowHeight, setWindowHeight] = useState(DEFAULT_SEARCH_HEIGHT);

  const filteredCities = useMemo(() => {
    return citiesList
      .filter((city) => city.toLowerCase().includes(searchCity.toLowerCase()))
      .sort((a, b) => customSort(a, b, cityQuery));
  }, [searchCity, citiesList, cityQuery]);

  // useEffect(() => {
  //   const sortedTitles = citiesList
  //     .filter((category) =>
  //       category.toLowerCase().includes(searchCity.toLowerCase())
  //     )
  //     .sort((a, b) => customSort(a, b, cityQuery));
  //   setFilteredCities(sortedTitles);
  // }, [searchCity, citiesList, cityQuery]);

  const itemSize = useCallback(
    (index) =>
      textWidths[index] > MAX_ITEM_SIZE_CITY
        ? DEFAULT_ITEM_HEIGHT * 1.5
        : DEFAULT_ITEM_HEIGHT,
    [textWidths]
  );

  useEffect(() => {
    const len = filteredCities.length;
    if (len === 0) {
      setWindowHeight(DEFAULT_SEARCH_HEIGHT);
      return;
    }

    const height = getWindowHeight(rowHeights, len);

    height &&
      setWindowHeight(
        len === 1 ? height + 1 : Math.min(height, DEFAULT_SEARCH_HEIGHT)
      );
  }, [filteredCities]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setTextWidths(filteredCities.map((value) => measureTextWidth(value)));
    }
  }, [filteredCities]);

  useEffect(() => {
    const params = {
      country: countryQuery || allLanguages[langCode].defaultLocation,
      state: stateQuery,
    };
    getCitiesAutocomplete(params);
  }, [countryQuery, stateQuery, langCode]);

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const Row = ({ index, style }) => {
    const rowRef = useRef({});
    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [index, rowRef]);

    const city = filteredCities[index];
    const isChecked = city.toLowerCase() === cityQuery.toLowerCase();
    return (
      <div key={index} className={classes.menuItem} style={style} ref={rowRef}>
        <FormControlLabel
          control={
            <CheckboxInput
              value={isChecked}
              onChange={() => {
                if (isChecked) {
                  handleSubmit('');
                } else {
                  setOpenFilter(null);
                  handleSubmit(city);
                  setSearchCity('');
                }
              }}
              name={city}
            />
          }
          label={city}
          className={classes.controlLabel}
        />
      </div>
    );
  };

  const dropdown = () => (
    <div
      className={clsx(
        classes.container,
        dropdownMenu && classes.dropdownContainer
      )}
    >
      <AsyncTypeahead
        id='city'
        labelKey='city'
        placeholder={intl.formatMessage({
          id: 'inputText.city',
          defaultMessage: 'City',
        })}
        isLoading={false}
        options={[]}
        defaultInputValue={''}
        useCache={false}
        defaultSelected={[capFirstLetters(searchCity)]}
        onSearch={(text) => {}}
        onInputChange={(text) => {
          setSearchCity(text);
        }}
        minLength={1}
        inputProps={{
          className: clsx(classes.autocomplete),
        }}
        renderMenu={() => null}
      />
      <div className={classes.menu}>
        {filteredCities.length > 0 ? (
          <List
            height={windowHeight}
            itemCount={filteredCities.length}
            itemSize={itemSize}
            width={'100%'}
            className={classes.menu}
            ref={listRef}
          >
            {Row}
          </List>
        ) : (
          <p className={classes.noResults}>
            {intl.formatMessage({
              id: 'inputText.noResults',
              defaultMessage: 'No results found',
            })}
          </p>
        )}
      </div>
    </div>
  );
  
  return (
    <>
      <FilterItem
        handleClick={() => setOpenFilter(isMenuOpen ? null : 'city')}
        anchorRef={anchorRef}
        open={isMenuOpen}
        placeholder={intl.formatMessage({
          id: 'inputText.city',
          defaultMessage: 'City',
        })}
        value={capFirstLetters(cityQuery)}
        onlyValue
        showResetButton={cityQuery}
        resetFilter={(e) => {
          setSearchCity('');
          handleSubmit('');
          e.stopPropagation();
        }}
        dropdown={dropdownMenu && dropdown()}
      />
      {!dropdownMenu && (
        <Popper
          open={isMenuOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 100,
          }}
          placement='bottom-start'
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
            },
            arrow: {
              enabled: false,
              element: anchorRef,
            },
            hide: {
              enabled: false,
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={{ enter: 300, exit: 0 }}>
              <Paper className={classes.paper}>
                <ClickAwayListener
                  onClickAway={() => {
                    if (isMenuOpen) {
                      setOpenFilter(null);
                      setSearchCity('');
                    }
                  }}
                >
                  {dropdown()}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};
