import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import { FormattedMessage } from 'react-intl';
import { VariableSizeList as List } from 'react-window';
import clsx from 'clsx';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import CheckboxInput from 'src/input/components/CheckboxInput';
import FilterItem from '../JobFilters/FilterItem';
import useStyles from './styles';

import {
  DEFAULT_ITEM_HEIGHT,
  DEFAULT_SEARCH_HEIGHT,
  MAX_ITEM_SIZE_COMPANY,
} from '../../utils/constants';
import { measureTextWidth, getWindowHeight } from '../../utils/functions';

const AsyncTypeahead = withAsync(Typeahead);

export default ({
  handleSubmit,
  query: { companyName },
  initiateAutocomplete,
  defaultCompanies,
  result,
  loading,
  companyValue,
  changeCompanyValue,
  intl,
  dropdownMenu = false,
  setOpenFilter,
  openFilter,
}) => {
  const classes = useStyles();
  const isMenuOpen = openFilter === 'company';
  const companiesQuery = companyName ? companyName.split(',') : [];
  const listRef = useRef({});
  const rowHeights = useRef({});
  const companyAnchor = useRef(null);
  const debounceTimer = useRef(null);
  const [textWidths, setTextWidths] = useState([]);
  const [windowHeight, setWindowHeight] = useState(DEFAULT_SEARCH_HEIGHT);
  const [searchedCompanies, setSearchedCompanies] = useState([]);

  const closeDropdown = () => {
    setOpenFilter(null);
    if (companyValue !== '') changeCompanyValue('');
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setTextWidths(searchedCompanies.map((value) => measureTextWidth(value)));
    }
  }, [searchedCompanies]);

  const itemSize = useCallback(
    (index) =>
      textWidths[index] > MAX_ITEM_SIZE_COMPANY
        ? DEFAULT_ITEM_HEIGHT * 1.5
        : DEFAULT_ITEM_HEIGHT,
    [textWidths]
  );

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const Row = ({ index, style }) => {
    const rowRef = useRef({});
    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [index, rowRef]);

    const item = searchedCompanies[index];
    const isChecked = companiesQuery
      .map((company) => company.toLowerCase())
      .includes(item.toLowerCase());
    return (
      <div key={index} className={classes.menuItem} style={style} ref={rowRef}>
        <FormControlLabel
          control={
            <CheckboxInput
              value={isChecked}
              onChange={(e) => {
                changeCompanyValue('');
                handleSubmit(
                  isChecked
                    ? companiesQuery.filter(
                        (company) =>
                          company.toLowerCase() !== item.toLowerCase()
                      )
                    : companiesQuery.concat(item)
                );
              }}
              name={item}
            />
          }
          label={item}
          className={classes.controlLabel}
        />
      </div>
    );
  };

  useEffect(() => {
    if (companyValue.trim()) {
      setSearchedCompanies(result?.map(({ companyName }) => companyName) || []);
    } else {
      let selectedCompanies = companiesQuery
        .map((company) => company.toLowerCase())
        .sort((a, b) => a.localeCompare(b));
      let remainingCompanies = defaultCompanies
        .map((company) => company.toLowerCase())
        .filter((company) => !companiesQuery.includes(company))
        .sort((a, b) => a.localeCompare(b));
      let combinedCompanies = [...selectedCompanies, ...remainingCompanies].map(
        (company) => company.charAt(0).toUpperCase() + company.slice(1)
      );
      setSearchedCompanies(combinedCompanies);
    }
  }, [companyValue, companyName, defaultCompanies, result]);

  useEffect(() => {
    const len = searchedCompanies.length;
    if (len === 0) {
      setWindowHeight(DEFAULT_SEARCH_HEIGHT);
      return;
    }

    const height = getWindowHeight(rowHeights, len);

    height &&
      setWindowHeight(
        len === 1 ? height + 1 : Math.min(height, DEFAULT_SEARCH_HEIGHT)
      );
  }, [searchedCompanies]);

  const onCompanyClick = (company) => {
    if (!company) return;

    changeCompanyValue('');
    if (searchedCompanies.includes(company.toLowerCase())) {
      // if (selectedCompanies.includes(company.toLowerCase())) {
      //   handleSubmit(selectedCompanies.concat(company));
      // }
      handleSubmit(companiesQuery); // TODO мб не надо
    } else {
      // setSelectedCompanies(selectedCompanies.concat(company));
      handleSubmit(companiesQuery.concat(company));
    }
  };

  const resetCompanySearch = () => {
    closeDropdown();
    handleSubmit([]);
  };

  const onChangeInputValue = useCallback(
    (text) => {
      changeCompanyValue(text);
      clearTimeout(debounceTimer.current);
      debounceTimer.current = setTimeout(() => {
        initiateAutocomplete('companyName', text);
      }, 500);
    },
    [searchedCompanies]
  );

  const dropdown = () => (
    <div
      className={clsx(
        classes.menuWrapper,
        dropdownMenu && classes.dropdownContainer
      )}
    >
      <AsyncTypeahead
        id='companyName'
        labelKey='companyName'
        placeholder={intl.formatMessage({
          id: 'inputText.companyName',
          defaultMessage: 'Company name',
        })}
        isLoading={false}
        options={result}
        defaultInputValue={''}
        useCache={false}
        selected={[companyValue]}
        onSearch={onChangeInputValue}
        onChange={([selected]) => {
          if (!selected) return;
          const val = selected.companyName || selected || '';
          changeCompanyValue(val);
          onCompanyClick(val);
        }}
        onInputChange={onChangeInputValue}
        minLength={1}
        inputProps={{
          className: clsx(classes.autocomplete),
        }}
        renderMenu={() => null}
      />
      <div className={classes.menu}>
        {searchedCompanies?.length > 0 ? (
          <List
            height={windowHeight}
            itemCount={searchedCompanies.length}
            itemSize={itemSize}
            width={'100%'}
            className={classes.menu}
            ref={listRef}
          >
            {Row}
          </List>
        ) : (
          <div className={classes.noResults}>
            <FormattedMessage
              id='inputText.noResults'
              defaultMessage='No results found'
            />
          </div>
        )}
      </div>
      {companiesQuery.length > 0 && !companyValue > 0 && (
        <div className={classes.buttonsContainer}>
          <button className={classes.button} onClick={resetCompanySearch}>
            <FormattedMessage id='btns.reset' defaultMessage='Reset search' />
          </button>
        </div>
      )}
    </div>
  );

  return (
    <>
      <FilterItem
        handleClick={() => {
          isMenuOpen ? closeDropdown() : setOpenFilter('company');
        }}
        anchorRef={companyAnchor}
        open={isMenuOpen}
        placeholder={intl.formatMessage({
          id: 'jobs.company',
          defaultMessage: 'Company',
        })}
        value={companyName ? ' (' + companyName.split(',').length + ')' : ''}
        dropdown={dropdownMenu && dropdown()}
      />
      {!dropdownMenu && (
        <Popper
          open={isMenuOpen}
          anchorEl={companyAnchor.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 100,
          }}
          placement='bottom-start'
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
            },
            arrow: {
              enabled: false,
              element: companyAnchor,
            },
            hide: {
              enabled: false,
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={{ enter: 300, exit: 0 }}>
              <Paper className={classes.paper}>
                <ClickAwayListener
                  onClickAway={(e) => {
                    isMenuOpen && closeDropdown();
                  }}
                >
                  {dropdown()}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};
