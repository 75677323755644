import React from 'react';

import RegistrationForm from 'src/onboarding/components/RegistrationForm';

import FirstForm from './Forms/FirstForm';
import SecondForm from './Forms/SecondForm';
import ThirdForm from './Forms/ThirdForm';

const steps = [
  {
    label: 'Account',
    fields: ['name', 'web_site'],
    title: 'registrationForm.customer.0.title',
  },
  {
    label: 'Profile',
    fields: [
      'contact_person',
      'contact_person_email',
      'contact_person_phone',
      'contact_person_job_title',
      'contact_person_password',
      'contact_person_password_verification',
    ],
    title: 'registrationForm.customer.1.title',
  },
  {
    label: 'Location',
    fields: ['country', 'state', 'city'],
    title: 'registrationForm.customer.2.title',
  },
];

export default (props) => {
  const getStepContent = (step = 1) => {
    switch (step) {
      case 0:
        return <FirstForm {...props} />;
      case 1:
        return <ThirdForm {...props} />;
      case 2:
        return <SecondForm {...props} />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <RegistrationForm
      {...props}
      getStepContent={getStepContent}
      steps={steps}
      showSocialLogin
      type={'customer'}
    />
  );
};
