import { takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import { track } from 'src/analytics/ga';

export default function* () {
  yield takeEvery(LOCATION_CHANGE, ({ payload }) => {
    track('page', payload.location.pathname);
  });
}
