import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  autocomplete: {
    overflow: 'hidden',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    width: '100%',
    border: 'none',
    borderBottom: `1px ${palette.divider} solid`,
    color: palette.text.primary,
    padding: '5px 10px',
    fontSize: 16,
    height: 40,
  },
  paper: {
    opacity: 1,
    width: 275,
    '@media screen and (max-width: 500px)': {
      width: 275,
    },
  },
  container: {
    borderTop: '2px solid #2F64EA',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
    overflow: 'hidden',
    borderRadius: 6,
  },
  dropdownContainer: {
    border: 'none',
    boxShadow: 'none',
    border: `1px solid ${palette.divider}`,
    borderRadius: 3,
    boxShadow:
      'rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.1) 0px 2px 6px 2px',
  },
  menu: {
    maxHeight: 220,
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': `inset 0 0 6px rgba(0,0,0,0.1)`,
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: 8,
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#316afa',
    },
  },
  menuItem: {
    cursor: 'pointer',
    padding: '2px 15px',
    width: '100%',
    transition: 'all 0.15s ease-in-out',
    '&:first-child': {
      paddingTop: 5,
    },
    '& span': {
      padding: 2,
    },
    '&:hover': {
      backgroundColor: '#F0F2FA',
    },
  },
  noResults: {
    zIndex: 100,
    padding: '10px',
    marginBottom: 2,
    fontWeight: 500,
    fontSize: 14,
    userSelect: 'none',
  },
  controlLabel: {
    marginRight: 0,
  },
}));
