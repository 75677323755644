export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';

export const showSnackbar = (message, variant) => ({
  type: SHOW_SNACKBAR,
  data: {
    message,
    variant,
  },
});

export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const removeSnackbar = (id) => ({
  type: REMOVE_SNACKBAR,
  id,
});

export const TOGGLE_SUCCESS_BAR = 'TOGGLE_SUCCESS_BAR';

export const toggleSucccessBar = (isShown, isCustomer) => ({
  type: TOGGLE_SUCCESS_BAR,
  isShown,
  isCustomer,
});

export const TOGGLE_HEADER_MODAL = 'TOGGLE_HEADER_MODAL';

export const toggleHeaderModal = (isShown) => ({
  type: TOGGLE_HEADER_MODAL,
  isShown,
});
