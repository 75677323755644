import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import CheckboxEmpty from 'src/img/icons/CheckboxEmpty';
import CheckboxFilled from 'src/img/icons/CheckboxFilled';

export default ({ value, onChange, name, ...props }) => {
  return (
    <Checkbox
      checked={value}
      onChange={onChange}
      name={name}
      icon={<CheckboxEmpty color={props.error ? '#FF0000' : null} />}
      checkedIcon={<CheckboxFilled />}
      color="primary"
      {...props}
    />
  );
};
