export const PIE_BOX_COLORS = {
  none: '#E3F4FE',
  entry: '#92d14f',
  junior: '#ffc000',
  mid: '#5a9bd5',
  senior: '#214d77',
  executive: '#be0002',
  // junior: '#1D71F2',
  // entry: '#1C9CF6',
  // executive: '#19C3FB',
  // senior: '#FFFAE0',
  // mid: '#FFCD00',
};

export const BAR_CHART_COLORS_CANDIDATES = [
  { type: 'applied', color: '#82ca9d' },
  { type: 'viewed', color: '#FF7F50' },
  { type: 'rejected', color: 'red' },
  { type: 'contacted', color: 'blue' },
  { type: 'interview_scheduled', color: 'pink' },
];

export const VIEWED_STATISTICS = (totalJobsEvents) => {
  return [
    { name: 'viewed', value: totalJobsEvents.viewed, color: '#FF7F50' },
    { name: 'rest', value: totalJobsEvents.applied, color: 'white' },
  ];
};

export const APPLIED_STATISTICS = (totalJobsEvents) => {
  return [
    { name: 'applied', value: totalJobsEvents.applied, color: '#B8E986' },
    { name: 'rest', value: totalJobsEvents.viewed, color: 'white' },
  ];
};

export const STATISTICS_DATA = (totalJobsEvents) => {
  return [
    { name: 'viewed', value: totalJobsEvents.viewed, color: '#FF7F50' },
    { name: 'applied', value: totalJobsEvents.applied, color: '#B8E986' },
  ];
};

export const DIAGRAM_DATA = (jobsStatistics) => {
  return jobsStatistics
    .map((stat) => {
      return {
        name: stat.jobTitle,
        viewed:
          stat.events.find((event) => event.key === 'viewed')?.doc_count,
        applied:
          stat.events.find((event) => event.key === 'applied')?.doc_count,
        jobId: stat.jobId,
      };
    })
    .filter((stat) => +stat.viewed + stat.applied !== 0);
};
