import { takeLatest, put, all, select } from 'redux-saga/effects';
import jscookie from 'js-cookie';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  getMessagesForLang,
  getLangFromPath,
  allLanguages,
} from 'src/intl/utils';
import { navigateIntl } from 'src/utils/history';

import { SET_LANGUAGE, setMessages, setLanguage } from './actions';

export default function* () {
  yield all([
    takeLatest(SET_LANGUAGE, function* ({ language }) {
      const langFromPath =
        getLangFromPath(window.location.pathname) || allLanguages.en.language;

      if (langFromPath === language) return;

      jscookie.set('lang', language);

      yield put(setMessages(getMessagesForLang(language)));

      navigateIntl(window.location.pathname + window.location.search);
    }),
    takeLatest(
      LOCATION_CHANGE,
      function* ({
        payload: {
          action,
          isFirstRendering,
          location: { pathname },
        },
      }) {
        if (action === 'POP' && !isFirstRendering) {
          const { language } = yield select((state) => state.locale);
          const lang = getLangFromPath(pathname) || 'en';

          if (language !== lang) {
            if (process.env.BROWSER) jscookie.set('lang', lang);

            yield put(setLanguage(lang));
            yield put(setMessages(getMessagesForLang(lang)));
          }
        }
      }
    ),
  ]);
}
