import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CheckboxInput from 'src/input/components/CheckboxInput';
import FilterItem from '../JobFilters/FilterItem';
import useStyles from './styles';

export default ({
  handleSubmit,
  intl,
  loading,
  query: { typeOfEmployment = '' },
  setOpenFilter,
  openFilter,
  dropdownMenu = false,
}) => {
  const classes = useStyles();
  const FILTER_NAME = 'jobType';
  const isMenuOpen = openFilter === FILTER_NAME;
  const [selectedTags, setSelectedTags] = useState([]);
  const anchorRef = useRef(null);
  const tagsQuery = typeOfEmployment ? typeOfEmployment.split(',') : [];
  const tagsList = [
    { key: 'student' },
    { key: 'junior' },
    { key: 'midLevel' },
    { key: 'senior' },
    { key: 'remote' },
    { key: 'relocation' },
    { key: 'freelance' },
    { key: 'partTime' },
    { key: 'fullTime' },
  ];

  useEffect(() => {
    setSelectedTags(
      tagsQuery
        .filter((tagKey) => tagsList.some(({ key }) => tagKey === key))
        .map((tagKey) => {
          let res = tagsList.filter((tag) => tag?.key === tagKey);
          if (res.length && res[0]?.key) return res[0];
        })
    );
  }, [typeOfEmployment]);

  const dropdown = () => (
    <div
      className={clsx(
        classes.menuWrapper,
        dropdownMenu && classes.dropdownContainer
      )}
    >
      {tagsList.map(({ key }, i) => {
        const isChecked = selectedTags.some((tag) => tag.key === key);
        return (
          <div className={classes.searchedItem} key={i}>
            <FormControlLabel
              control={
                <CheckboxInput
                  value={isChecked}
                  onChange={() => {
                    isChecked
                      ? handleSubmit(
                          selectedTags.filter((tag) => tag.key !== key)
                        )
                      : handleSubmit(selectedTags.concat({ key }));
                  }}
                  name={key}
                />
              }
              label={intl.formatMessage({
                id: `inputText.typeOfEmployment.${key}`,
              })}
            />
          </div>
        );
      })}
      {/* {typeOfEmployment?.length > 0 && (
        <div className={classes.buttons}>
          <button
            className={classes.button}
            onClick={() => {
              handleSubmit([]);
              setIsJobTypeOpen(false);
            }}
          >
            <FormattedMessage id='btns.reset' defaultMessage='Reset search' />
          </button>
        </div>
      )} */}
    </div>
  );

  return (
    <>
      <FilterItem
        handleClick={() => {
          setOpenFilter(isMenuOpen ? null : FILTER_NAME);
        }}
        anchorRef={anchorRef}
        open={isMenuOpen}
        placeholder={intl.formatMessage({
          id: 'jobs.typeOfEmployment',
          defaultMessage: 'Job type',
        })}
        value={
          typeOfEmployment
            ? ' (' + typeOfEmployment?.split(',').length + ')'
            : ''
        }
        showResetButton={typeOfEmployment}
        resetFilter={(e) => {
          setSelectedTags([]);
          handleSubmit('');
          e.stopPropagation();
        }}
        dropdown={dropdownMenu && dropdown()}
      />
      {!dropdownMenu && (
        <Popper
          open={isMenuOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 100,
          }}
          placement='bottom-start'
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
            },
            arrow: {
              enabled: false,
              element: anchorRef,
            },
            hide: {
              enabled: false,
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={{ enter: 300, exit: 0 }}>
              <Paper className={classes.paper}>
                <ClickAwayListener
                  onClickAway={() => {
                    if (isMenuOpen) setOpenFilter(null);
                  }}
                >
                  {dropdown()}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};
