import React, { useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import OnboardingButtons from 'src/onboarding/components/Buttons';
import ProfileMenu from 'src/user/components/ProfileMenu';
import Logo from 'src/img/icons/Logo';
import JobsMenu from 'src/jobs/JobsMenu/Menu';
import Menu from 'src/img/icons/Menu';
import Modal from 'src/layout/components/Modal';
import LoginClient from 'src/user/components/Login';
import RegistrationClient from 'src/user/components/Registration';
import OnboardingCustomer from 'src/customer/components/Onboarding';
import LanguageSwitchButton from 'src/layout/components/LanguageSwitchButton';
import { getNavigationUrl } from 'src/utils/history';
import { isRoot } from 'src/intl/utils';
import HeaderModal from '../HeaderModal';
import useStyles from './styles';
import { getJobLinks, analyticsItems } from './dropdownLists';
import CompleteRegModal from 'src/user/components/CompleteRegModal';

export default function Header({
  isAuthenticated,
  history,
  toggleOnboardingModal,
  isModalShown,
  toggleHeaderModal,
  isRegModalOpen,
  isLoginCustomerOpen,
  isLoginClientOpen,
  isCompleteRegModalOpen,
  initiateJobsFetch,
  isRtl,
  isHebrew,
  langCode,
  intl,
  clearCV,
}) {
  const classes = useStyles();
  const LoginEmployerBtn = () => (
    <Link className={classes.loginButton} to={getNavigationUrl('/customer')}>
      <FormattedMessage id='links.recruitment' defaultMessage='Post a job' />
    </Link>
  );
  const onboardingModals = useMemo(
    () => (
      <>
        <Modal
          open={isLoginClientOpen}
          setOpen={() => toggleOnboardingModal(false)}
          isLogin
        >
          <LoginClient />
        </Modal>
        <Modal
          open={isCompleteRegModalOpen}
          setOpen={() => toggleOnboardingModal(false)}
          isLogin
        >
          <CompleteRegModal />
        </Modal>
        <Modal
          open={isRegModalOpen}
          setOpen={() => toggleOnboardingModal(false)}
          disableBackdropClick
        >
          <RegistrationClient />
        </Modal>
      </>
    ),
    [
      isLoginCustomerOpen,
      isRegModalOpen,
      isLoginClientOpen,
      toggleOnboardingModal,
      isCompleteRegModalOpen,
    ]
  );

  return (
    <>
      <nav className={classes.container}>
        <div className={classes.innerContainer}>
          <Link
            to={getNavigationUrl('/')}
            onClick={() => initiateJobsFetch()}
            className={classes.logoBar}
          >
            <Logo />
          </Link>
          <div className={classes.rightRow}>
            <div className={classes.navRow}>
              <div
                tabIndex='0'
                className={clsx(
                  classes.navItem,
                  classes.jobsNavItem,
                  isRoot(history.location.pathname) && classes.navItemBorder
                )}
              >
                <JobsMenu
                  label={intl.formatMessage({
                    id: 'links.hiTechJobs',
                    defaultMessage: 'Tech Jobs',
                  })}
                  items={getJobLinks(langCode)}
                  isActive={isRoot(history.location.pathname)}
                  labelLink='/'
                />
              </div>

              {isHebrew && (
                <div
                  tabIndex='0'
                  className={clsx(
                    classes.navItem,
                    history.location.pathname.includes('/he/articles/35') &&
                      classes.navItemBorder
                  )}
                >
                  <Link
                    to='/he/articles/35'
                    className={clsx(
                      history.location.pathname.includes('/he/articles/35') &&
                        classes.activeNavItem
                    )}
                  >
                    <div className={classes.aTagOnHover}>טבלאות שכר</div>
                  </Link>
                </div>
              )}

              <div
                tabIndex='0'
                className={clsx(
                  classes.navItem,
                  history.location.pathname.includes('/cv-builder') &&
                    classes.navItemBorder
                )}
              >
                <JobsMenu
                  label={intl.formatMessage({
                    id: 'links.cvBuilder',
                    defaultMessage: 'CV Builder',
                  })}
                  items={[
                    ...(isAuthenticated
                      ? [
                          {
                            label: intl.formatMessage({
                              id: 'cvBuilder.myCvLibrary',
                              defaultMessage: 'My CV Library',
                            }),
                            pathname: '/cv-builder/library',
                          },
                        ]
                      : []),
                    {
                      label: intl.formatMessage({
                        id: 'links.cvExamples',
                        defaultMessage: 'CV Examples',
                      }),
                      pathname: '/cv-builder/cv-examples',
                    },
                    {
                      label: intl.formatMessage({
                        id: 'cvBuilder.templates',
                        defaultMessage: 'CV Templates',
                      }),
                      pathname: '/cv-builder/templates',
                    },
                    {
                      label: intl.formatMessage({
                        id: 'cvBuilder.langLink',
                        defaultMessage: "Hebrew CV's",
                      }),
                      pathname:
                        langCode === 'en' ? '/he/cv-builder' : `/cv-builder`,
                      directURL: true,
                    },
                  ]}
                  isActive={history.location.pathname.includes('/cv-builder')}
                  labelLink='/cv-builder'
                />
              </div>

              <div
                tabIndex='0'
                className={clsx(
                  classes.navItem,
                  history.location.pathname.includes('/analytics') &&
                    classes.navItemBorder
                )}
              >
                <JobsMenu
                  label={intl.formatMessage({
                    id: 'links.analytics',
                    defaultMessage: 'Career center',
                  })}
                  items={analyticsItems[langCode]}
                  isActive={history.location.pathname.includes('/analytics')}
                  labelLink='/analytics'
                />
              </div>

              <div
                tabIndex='0'
                className={clsx(
                  classes.navItem,
                  history.location.pathname.includes('/articles') &&
                    !history.location.pathname.includes('/he/articles/35') &&
                    classes.navItemBorder
                )}
              >
                <JobsMenu
                  label={intl.formatMessage({
                    id: 'links.articles',
                    defaultMessage: 'Articles',
                  })}
                  isActive={
                    history.location.pathname.includes('/articles') &&
                    !history.location.pathname.includes('/he/articles/35')
                  }
                  labelLink='/articles'
                />
              </div>

              <div
                tabIndex='0'
                className={clsx(
                  classes.navItem,
                  history.location.pathname.includes('/about') &&
                    classes.navItemBorder
                )}
              >
                <Link
                  to={getNavigationUrl('/about')}
                  className={clsx(
                    history.location.pathname.includes('/about') &&
                      classes.activeNavItem
                  )}
                >
                  <FormattedMessage id='links.about' defaultMessage='About' />
                </Link>
              </div>
            </div>
            <div
              className={clsx(
                classes.extraButtonsContainer,
                isAuthenticated && classes.flexEnd
              )}
            >
              {isAuthenticated ? (
                <>
                  <LanguageSwitchButton />
                  <ProfileMenu />
                </>
              ) : (
                <>
                  <div className={classes.LoginEmployerBtn}>
                    <LoginEmployerBtn />
                  </div>
                  <div className={classes.divider} />
                  <OnboardingButtons />
                  <div className={classes.divider} />
                  <LanguageSwitchButton />
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      <div className={classes.smallScreenContainer}>
        <div className={classes.smallScreenInnerContainer}>
          <Link to={getNavigationUrl('/')} onClick={() => initiateJobsFetch()}>
            <Logo />
          </Link>
          <div onClick={() => toggleHeaderModal(true)}>
            <Menu />
          </div>
          <HeaderModal
            open={!!isModalShown}
            handleClose={() => toggleHeaderModal(false)}
            LoginEmployerBtn={LoginEmployerBtn}
            history={history}
            isAuthenticated={isAuthenticated}
            jobLinks={getJobLinks(langCode)}
            analyticsItems={analyticsItems[langCode]}
          />
        </div>
      </div>
      {onboardingModals}
    </>
  );
}
