export const SET_LANGUAGE = 'SET_LANGUAGE';

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  language,
});

export const SET_MESSAGES = 'SET_MESSAGES';

export const setMessages = (messages) => ({
  type: SET_MESSAGES,
  messages,
});
