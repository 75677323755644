import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import { VariableSizeList as List } from 'react-window';
import clsx from 'clsx';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import { capFirstLetters } from 'src/intl/utils';
import FilterItem from '../JobFilters/FilterItem';
import CheckboxInput from 'src/input/components/CheckboxInput';
import useStyles from './styles';

import {
  DEFAULT_ITEM_HEIGHT,
  DEFAULT_SEARCH_HEIGHT,
  MAX_ITEM_SIZE_COUNTRY_1,
  MAX_ITEM_SIZE_COUNTRY_2,
} from '../../utils/constants';
import {
  measureTextWidth,
  customSort,
  getWindowHeight,
} from '../../utils/functions';

const AsyncTypeahead = withAsync(Typeahead);

export default ({
  handleSubmit,
  intl,
  countryQuery = '',
  countriesList = [],
  dropdownMenu = false,
  openFilter,
  setOpenFilter,
}) => {
  const classes = useStyles();
  const listRef = useRef({});
  const rowHeights = useRef({});
  const anchorRef = useRef(null);
  const isMenuOpen = openFilter === 'country';
  // const [filteredCountries, setFilteredCountries] = useState(countriesList);
  const [searchCountry, setSearchCountry] = useState('');
  const [textWidths, setTextWidths] = useState([]);
  const [windowHeight, setWindowHeight] = useState(DEFAULT_SEARCH_HEIGHT);

  const filteredCountries = useMemo(() => {
    return countriesList
      .filter((country) =>
        country.toLowerCase().includes(searchCountry.toLowerCase())
      )
      .sort((a, b) => customSort(a, b, countryQuery));
  }, [searchCountry, countriesList, countryQuery]);

  // useEffect(() => {
  //   const sortedTitles = countriesList
  //     .filter((country) =>
  //       country.toLowerCase().includes(searchCountry.toLowerCase())
  //     )
  //     .sort((a, b) => customSort(a, b, countryQuery));
  //   setFilteredCountries(sortedTitles);
  // }, [searchCountry, countriesList, countryQuery]);

  const itemSize = useCallback(
    (index) =>
      textWidths[index] > MAX_ITEM_SIZE_COUNTRY_1
        ? textWidths[index] > MAX_ITEM_SIZE_COUNTRY_2
          ? DEFAULT_ITEM_HEIGHT * 2
          : DEFAULT_ITEM_HEIGHT * 1.5
        : DEFAULT_ITEM_HEIGHT,
    [textWidths]
  );

  useEffect(() => {
    const len = filteredCountries.length;
    if (len === 0) {
      setWindowHeight(DEFAULT_SEARCH_HEIGHT);
      return;
    }

    const height = getWindowHeight(rowHeights, len);

    height &&
      setWindowHeight(
        len === 1 ? height + 1 : Math.min(height, DEFAULT_SEARCH_HEIGHT)
      );
  }, [filteredCountries]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setTextWidths(filteredCountries.map((value) => measureTextWidth(value)));
    }
  }, [filteredCountries]);

  const setRowHeight = (index, size) => {
    rowHeights.current = { ...rowHeights.current, [index]: size };
    listRef.current.resetAfterIndex(0);
  };

  const Row = ({ index, style }) => {
    const rowRef = useRef(null);
    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [index, rowRef]);

    const country = filteredCountries[index];
    const isChecked = country.toLowerCase() === countryQuery.toLowerCase();
    return (
      <div key={index} className={classes.menuItem} style={style} ref={rowRef}>
        <FormControlLabel
          control={
            <CheckboxInput
              value={isChecked}
              onChange={() => {
                if (isChecked) {
                  handleSubmit('');
                } else {
                  setOpenFilter(null);
                  handleSubmit(country);
                  setSearchCountry('');
                }
              }}
              name={country}
            />
          }
          label={country}
          className={classes.controlLabel}
        />
      </div>
    );
  };

  const dropdown = () => (
    <div
      className={clsx(
        classes.container,
        dropdownMenu && classes.dropdownContainer
      )}
    >
      <AsyncTypeahead
        id='country'
        labelKey='country'
        placeholder={intl.formatMessage({
          id: 'inputText.country',
          defaultMessage: 'Country',
        })}
        isLoading={false}
        options={[]}
        defaultInputValue={''}
        useCache={false}
        defaultSelected={[capFirstLetters(searchCountry)]}
        onSearch={(text) => {}}
        onInputChange={(text) => {
          setSearchCountry(text);
        }}
        minLength={1}
        inputProps={{
          className: clsx(classes.autocomplete),
        }}
        renderMenu={() => null}
      />
      <div className={classes.menu}>
        {filteredCountries?.length > 0 ? (
          <List
            height={windowHeight}
            itemCount={filteredCountries.length}
            itemSize={itemSize}
            width={'100%'}
            className={classes.menu}
            ref={listRef}
          >
            {Row}
          </List>
        ) : (
          <div className={classes.noResults}>
            {intl.formatMessage({
              id: 'inputText.noResults',
              defaultMessage: 'No results found',
            })}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <FilterItem
        handleClick={() => setOpenFilter(isMenuOpen ? null : 'country')}
        anchorRef={anchorRef}
        open={isMenuOpen}
        placeholder={intl.formatMessage({
          id: 'inputText.defaultCountry',
          defaultMessage: 'United States',
        })}
        value={capFirstLetters(countryQuery)}
        onlyValue
        showResetButton={countryQuery}
        resetFilter={(e) => {
          setSearchCountry('');
          handleSubmit('');
          e.stopPropagation();
        }}
        dropdown={dropdownMenu && dropdown()}
      />
      {!dropdownMenu && (
        <Popper
          open={isMenuOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 100,
          }}
          placement='bottom-start'
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
            },
            arrow: {
              enabled: false,
              element: anchorRef,
            },
            hide: {
              enabled: false,
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={{ enter: 300, exit: 0 }}>
              <Paper className={classes.paper}>
                <ClickAwayListener
                  onClickAway={() => {
                    if (isMenuOpen) {
                      setOpenFilter(null);
                      setSearchCountry('');
                    }
                  }}
                >
                  {dropdown()}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};
