import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, shadows }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: palette.background.paper,
    boxShadow: shadows[5],
    width: '35vw',
    minWidth: '400px',
    maxWidth: '600px',
    height: 'auto',
    maxHeight: '90vh',
    borderRadius: '6px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '40px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    width: 310,
    marginTop: 20,
    '& fieldset': {
      borderRadius: 6,
    },
  },
  title: {
    color: palette.primary.dark,
    fontSize: '24px',
    fontWeight: 'bold',
  },
  saveButton: {
    borderRadius: '6px',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    color: palette.text.white,
    width: '100%',
    maxWidth: 310,
    marginTop: '20px',
    backgroundColor: palette.primary.main,
    height: '47px',
    '&:hover': {
      boxShadow: '-3px 3px 7px 0 rgba(0,0,0,0.22)',
    },
  },
  cancelButton: {
    width: '310px',
    marginTop: '10px',
    height: '47px',
    borderRadius: '6px',
    border: 'none',
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: palette.background.default,
    color: palette.primary.dark,
    '&:hover': {
      boxShadow: '-3px 3px 7px 0 rgba(0,0,0,0.22)',
      backgroundColor: '#E2E6EA',
    },
  },
  errorText: {
    marginLeft: 0,
    fontSize: 14,
  },
}));
