export const allLanguages = {
  en: {
    language: 'en',
    langName: 'English',
    defaultLocation: 'united states',
    dir: 'ltr',
  },
  he: {
    language: 'he',
    langName: 'עברית',
    defaultLocation: 'israel',
    dir: 'rtl',
  },
};

export const langArr = [
  {
    ...allLanguages.en,
  },
  {
    ...allLanguages.he,
  },
];
