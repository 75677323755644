import createReducer from 'src/utils/createReducer';

import {
  ADD_CV_DATA,
  SET_EDIT_ID,
  SET_SHOW_FORM,
  SET_EDIT_SECTION,
  SET_SHOW_LICENSE_FORM,
  SET_EDIT_CV,
  CLEAR_CV,
  SET_CVS,
  GET_CVS,
  SET_LOADING,
  DELETE_CV,
  GET_SKILLS_AUTOCOMPLETE,
  CV_PLANS,
  GENERATE_CV_IMAGES,
  GET_CV_EXAMPLES,
} from './actions';
import { LOG_OUT } from 'src/user/redux/actions';

const INITIAL_STATE = {
  // cvData: {
  //   id: 'LbVQKXoB_Tep3dA-ldGJ',
  //   skills: [
  //     {
  //       default: true,
  //       level: 5,
  //       name: 'Photoshop',
  //     },
  //     {
  //       default: true,
  //       level: 5,
  //       name: 'HTML',
  //     },
  //     {
  //       name: 'Sketch',
  //       level: 5,
  //       default: true,
  //     },
  //     {
  //       name: 'Photoshop',
  //       level: 5,
  //       default: true,
  //     },
  //     {
  //       name: 'CSS',
  //       level: 5,
  //       default: true,
  //     },
  //   ],
  //   experience: [
  //     {
  //       isCurrentlyWorking: true,
  //       jobTitle: 'Full Stack Developer',
  //       start: 1451602800000,
  //       description:
  //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //       company: 'UnicornDev',
  //     },
  //     {
  //       isCurrentlyWorking: true,
  //       jobTitle: 'Full Stack Developer',
  //       start: 1451602800000,
  //       description:
  //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //       company: 'UnicornDev',
  //     },
  //     {
  //       isCurrentlyWorking: true,
  //       jobTitle: 'Full Stack Developer',
  //       start: 1451602800000,
  //       description:
  //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //       company: 'UnicornDev',
  //     },
  //   ],
  //   education: [
  //     {
  //       isCurrentlyWorking: false,
  //       degree: 'education.options.mastersDegree',
  //       start: 1157061600000,
  //       course: 'Finance and Accounting',
  //       description:
  //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //       location: 'Russia, Krasnodar',
  //       end: 1306879200000,
  //       place: 'University Of Krasnodar City',
  //     },
  //     {
  //       isCurrentlyWorking: false,
  //       degree: 'education.options.mastersDegree',
  //       start: 1157061600000,
  //       course: 'Finance and Accounting',
  //       description:
  //         'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //       location: 'Russia, Krasnodar',
  //       end: 1306879200000,
  //       place: 'University Of Krasnodar City',
  //     },
  //   ],
  //   licenseCerts: [
  //     {
  //       date: '04/05',
  //       associatedWith: 'Another license association',
  //       name: 'The best license ever',
  //       credentialID: '12312312312',
  //       issued: 'License organization',
  //     },
  //     {
  //       date: '04/05',
  //       associatedWith: 'Another license association',
  //       name: 'The best license ever',
  //       credentialID: '12312312312',
  //       issued: 'License organization',
  //     },
  //     {
  //       date: '04/05',
  //       associatedWith: 'Another license association',
  //       name: 'The best license ever',
  //       credentialID: '12312312312',
  //       issued: 'License organization',
  //     },
  //     {
  //       date: '04/05',
  //       associatedWith: 'Another license association',
  //       name: 'The best license ever',
  //       credentialID: '12312312312',
  //       issued: 'License organization',
  //     },
  //   ],
  //   website: 'https://ven-korolev.web.app/',
  //   facebook: '',
  //   linkedin: 'https://www.linkedin.com/in/ven-korolev-36985189/',
  //   twitter: '',
  //   phone: '+972 453 453 45',
  //   jobTitle: 'Developer',
  //   experienceTitle: '22',
  //   country: 'Netherlands',
  //   city: 'Amsterdam',
  //   state: '',
  //   firstName: 'Veniamin',
  //   lastName: 'Korolev',
  //   email: 'koro123leven@yandex.ru',
  //   bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //   userId: 'LLVQKXoB_Tep3dA-lNGW',
  //   templateName: 'whiteHeader',
  //   name: 'asdasd_asdasd_CV_2021',
  //   createdAt: 1624190784850,
  //   updatedAt: 1624190843038,
  //   languages: [
  //     {
  //       language: 'ru',
  //       level: 'cvBuilder.languages.level2',
  //     },
  //     {
  //       language: 'English',
  //       level: 'cvBuilder.languages.level3',
  //     },
  //   ],
  //   additionalLinks: [],
  //   additionalProfiles: [],
  // },
  cvData: {
    templateName: 'whiteHeader',
  },
  images: null,
  editId: null,
  editSection: false,
  editCv: false,
  showItemForm: true,
  showLicenseForm: null,
  cvs: [],
  loading: false,
  imagesLoading: false,
  skillsResults: [],
  plans: {
    isLoading: false,
    data: [],
  },
  cvExamples: {},
};

export default createReducer(INITIAL_STATE, {
  [ADD_CV_DATA]: (state, { data }) => ({
    ...state,
    cvData: {
      ...state.cvData,
      ...data,
    },
  }),
  [CLEAR_CV]: (state) => ({
    ...state,
    cvData: { templateName: 'whiteHeader' },
    editCv: false,
    editSection: false,
  }),
  [LOG_OUT]: (state) => ({
    ...state,
    cvData: { templateName: 'whiteHeader' },
  }),
  [SET_EDIT_ID]: (state, { id }) => ({
    ...state,
    editId: id,
  }),
  [SET_SHOW_FORM]: (state, { showItemForm }) => ({
    ...state,
    showItemForm,
  }),
  [SET_SHOW_LICENSE_FORM]: (state, { showLicenseForm }) => ({
    ...state,
    showLicenseForm,
  }),
  [SET_EDIT_SECTION]: (state, { editSection }) => ({
    ...state,
    editSection,
  }),
  [SET_EDIT_CV]: (state, { editCv }) => ({
    ...state,
    editCv,
  }),
  [DELETE_CV.SUCCESS]: (state, { params: { cvId } }) => ({
    ...state,
    cvs: state.cvs.filter((item) => item.id !== cvId),
  }),
  [GET_CV_EXAMPLES.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [GET_CV_EXAMPLES.SUCCESS]: (state, { data: { cvExamples } }) => ({
    ...state,
    cvExamples,
    loading: false,
  }),
  [GET_CVS.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [GET_CVS.SUCCESS]: (state, { data: { cvs } }) => {
    cvs.sort((a, b) => b.updatedAt - a.updatedAt);
    return {
      ...state,
      loading: false,
      cvs,
    };
  },
  [GET_CVS.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [SET_CVS]: (state, { cvs }) => ({
    ...state,
    cvs,
  }),
  [SET_LOADING]: (state, { loading }) => ({
    ...state,
    loading,
  }),
  [GET_SKILLS_AUTOCOMPLETE.SUCCESS]: (state, { data }) => ({
    ...state,
    skillsResults: data.d,
  }),
  [CV_PLANS.REQUEST]: (state) => ({
    ...state,
    plans: {
      data: [],
      isLoading: true,
    },
  }),
  [CV_PLANS.FAILURE]: (state, { d }) => ({
    ...state,
    plans: {
      data: [],
      isLoading: false,
    },
  }),
  [CV_PLANS.SUCCESS]: (state, { data: { d } }) => ({
    ...state,
    plans: {
      data: d,
      isLoading: false,
    },
  }),

  [GENERATE_CV_IMAGES.REQUEST]: (state) => ({
    ...state,
    imagesLoading: true,
  }),
  [GENERATE_CV_IMAGES.SUCCESS]: (state, { data: { d } }) => ({
    ...state,
    images: d,
    imagesLoading: false,
  }),
  [GENERATE_CV_IMAGES.FAILURE]: (state) => ({
    ...state,
    imagesLoading: false,
  }),
});
