import { API } from 'src/api/constants';
import FormData from 'form-data';

import {
  createRequestAction,
  createSuccessAction,
  createFailureAction,
} from 'src/api';

import { renewToken } from 'src/onboarding/redux/actions';

const api = async (store, action) => {
  const { token } = store.getState().onboarding;

  const headers = {
    ...(token ? { 'x-auth-token': token } : {}),
    ...action.headers,
  };

  store.dispatch(createRequestAction(action));

  let reqBody;

  if (action.body instanceof FormData) {
    reqBody = action.body;
  } else {
    reqBody = action.body ? JSON.stringify(action.body) : null;
  }

  try {
    const result = await fetch(action.url, {
      method: action.method,
      body: reqBody,
      headers,
    });

    const newToken = result.headers.get('r-x-auth-token');

    if (newToken) {
      store.dispatch(renewToken(newToken));
    }
    const contentType = result.headers.get('content-type');

    const body =
      contentType === 'application/pdf' ? result : await result.json();

    if (result.ok) {
      store.dispatch(createSuccessAction(action, body));
      return body;
    }

    const statusBody = { ...body, status: result.status };

    store.dispatch(createFailureAction(action, statusBody));

    return statusBody;
  } catch (e) {
    store.dispatch(createFailureAction(action));
    console.log(e);
  }
};

export default (store) => (next) => (action) => {
  if (action.type !== API) return next(action);

  return api(store, action);
};
