import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDown from 'src/img/icons/ArrowDown';
import ArrowUp from 'src/img/icons/ArrowUp';
import Close from 'src/img/icons/Close';
import clsx from 'clsx';

const useStyles = makeStyles(({ palette }) => ({
  filterItemWrapper: {
    width: 180,
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: 'white',
    borderRadius: 6,
    minHeight: 40,
    '@media screen and (max-width: 650px)': {
      width: '100%',
    },
  },
  filterItem: {
    padding: '0px 12.5px',
    width: '100%',
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    userSelect: 'none',
  },
  dropdownWrapper: {
    width: '100%',
    height: '100%',
    padding: '15px 0',
  },
  dropdownMenu: {
    borderBottom: '1px solid #f3f4f6',
  },
  dropdownFilterItem: {
    borderBottom: '1px solid #f3f4f6',
    borderRadius: 0,
  },
  closeBtn: {
    marginTop: 5,
    cursor: 'pointer',
    position: 'absolute',
    right: 13,
  },
  arrows: {
    position: 'absolute',
    right: 18,
  },
}));

const FilterItem = ({
  handleClick = () => {},
  anchorRef,
  open = false,
  value = '',
  placeholder = '',
  showResetButton = false,
  onlyValue = false,
  resetFilter = () => {},
  dropdown = null,
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.filterItemWrapper,
        dropdown && classes.dropdownMenu
      )}
    >
      <div
        onClick={() => {
          handleClick();
        }}
        ref={anchorRef}
        className={clsx(
          classes.filterItem,
          dropdown && classes.dropdownFilterItem
        )}
      >
        {onlyValue ? value || placeholder : `${placeholder} ${value}`}
        {showResetButton ? (
          <div className={classes.closeBtn} onClick={resetFilter}>
            <Close color='#0041E5' size={20} strokeWidth={2.25} />
          </div>
        ) : (
          <div className={classes.arrows}>
            {open ? (
              <ArrowUp
                size={'13px'}
                style={{
                  marginTop: 5,
                }}
              />
            ) : (
              <ArrowDown size={'13px'} />
            )}
          </div>
        )}
      </div>
      {open && dropdown && (
        <div className={classes.dropdownWrapper}>{dropdown}</div>
      )}
    </div>
  );
};

export default FilterItem;
