import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  searchbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.text.white,
    marginLeft: -1,
    marginTop: -1,
  },
  bold: {
    fontSize: 30,
    fontWeight: 400,
  },
  button: {
    border: 'none',
    borderRadius: '6px',
    height: 46,
    width: '100%',
    backgroundColor: palette.primary.main,
    marginTop: 10,
    padding: '0.5rem 1.5rem',
    flex: 1,
    fontSize: 16,
    '&:hover, &:focus': {
      backgroundColor: palette.primary.main,
      boxShadow: '0 2px 10px 5px rgba(20,25,60,0.2)',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    color: palette.text.white,
  },
  noAuthSearchbar: {
    height: 'auto',
    padding: '20px 0',
    boxSizing: 'border-box',
    position: 'relative',
    '@media (min-width: 600px)': {
      '&': {
        height: 250,
      },
    },
    '@media (min-width: 1400px)': {
      '&': {
        height: 300,
      },
    },
    '@media (min-width: 1600px)': {
      '&': {
        height: 350,
      },
    },
  },
  authSearchbar: {
    minWidth: 120,
    padding: '30px 0 15px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundImage: {
    zIndex: -1,
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  innerContainer: {
    width: '83%',
    maxWidth: '1840px',
  },
  tagline: {
    marginBottom: '35px',
    userSelect: 'none',
    '& div, & h1, & h2, & span': {
      fontSize: 30,
      fontWeight: '500',
      margin: 0,
    },
  },
  taglineBlue: {
    display: 'inline-block',
    color: '#1154FE',
    // color: '#999',
  },
  inputs: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  authInputs: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputDiv: {
    width: '100%',
    marginRight: 0,
    position: 'relative',
    marginTop: 10,
    maxHeight: 64,
    borderRadius: 6,
  },
  autocomplete: {
    height: 46,
    borderRadius: '6px',
    width: '100%',
    color: palette.text.primary,
    padding: '0.5rem 1.5rem',
    border: `1.5px transparent solid`,
    fontSize: 16,
    '&:focus': {
      color: palette.primary.main,
      boxShadow: 'none',
      border: `1.5px ${palette.primary.main} solid`,
    },
  },
  autocompleteMenuOpen: {
    borderRadius: '6px 6px 0px 0px',
    border: `1.5px ${palette.primary.main} solid`,
  },
  menu: {
    borderTop: 0,
    borderRadius: '0 0 6px 6px',
    marginTop: 0,
    backgroundColor: 'white',
    color: '#212529',
    height: 'auto',
    zIndex: 1000,
    width: 'calc(100% - 2px) !important',
    left: '1px !important',
  },
  menuItem: {
    minHeight: 40,
    color: palette.primary.dark,
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    '&[aria-selected="true"]': {
      backgroundColor: palette.primary.main,
      color: palette.text.white,
    },
  },
  boldInput: {
    color: palette.primary.main,
  },
  locationIcon: {
    position: 'absolute',
    left: '1.5rem',
    top: 'calc(50% - 12px)',
    zIndex: 1,
  },
  locationAutocomplete: {
    paddingLeft: 50,
  },
  authInput: {
    backgroundColor: palette.primary.light,
    '&:focus': {
      backgroundColor: palette.primary.light,
    },
  },
  authMenu: {
    backgroundColor: palette.primary.light,
  },
  '@media (min-width: 500px)': {
    inputDiv: {
      width: '43%',
      margin: '0 15px 15px 15px',
    },
    button: {
      maxWidth: '43%',
      margin: '0 15px 15px 15px',
    },
    inputs: {
      marginLeft: -15,
      marginRight: -15,
    },
  },
  '@media (min-width: 600px)': {
    button: {
      height: 54,
    },
    autocomplete: {
      height: 54,
    },
  },
  '@media (min-width: 900px)': {
    inputDiv: {
      width: '30%',
      maxWidth: 420,
    },
    authInputDiv: {
      flex: 2,
      marginRight: 25,
    },
    button: {
      width: 103,
      maxWidth: 103,
      height: 54,
    },
    authButton: {
      maxWidth: 300,
    },
    tagline: {
      '& div, & h1, & p, & span': {
        fontSize: 36,
      },
    },
  },
  '@media (min-width: 1400px)': {
    button: {
      height: 55,
      maxWidth: 300,
    },
    autocomplete: {
      height: 55,
    },
    tagline: {
      '& div, & h1, & p, & span': {
        fontSize: 56,
      },
    },
  },
  banner: {},
}));
