import createReducer from 'src/utils/createReducer';
import { LOG_OUT } from 'src/customer/redux/actions';
import {
  CHANGE_LICENSE,
  LOAD_LOGO,
  FETCH_CUSTOMER,
  UPDATE_CUSTOMER,
  TOGGLE_STATUS_MEMO,
  CLEAR_USER,
} from 'src/customer/redux/actions/user';
import {
  DELETE_CUSTOMER,
  LOGIN_CUSTOMER,
  REGISTER_CUSTOMER,
} from 'src/customer/redux/actions/onboarding';
import { ADD_CUSTOMER_USER } from 'src/customer/redux/actions/customerUsers';
import { CREATE_JOB, EDIT_JOB } from 'src/customerJobs/redux/actions';
import { PAY_EXTRA } from 'src/payment/redux/actions';

const INITIAL_STATE = {
  loading: false,
  updateLoading: false,
  showStatusMemo: false,
};

export default createReducer(INITIAL_STATE, {
  [REGISTER_CUSTOMER.SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [LOGIN_CUSTOMER.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [LOGIN_CUSTOMER.SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
    loading: false,
  }),
  [LOGIN_CUSTOMER.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [FETCH_CUSTOMER.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_CUSTOMER.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [FETCH_CUSTOMER.SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
    loading: false,
  }),
  [CREATE_JOB.SUCCESS]: (state, { data: { availableJobs = 0 } }) => {
    return {
      ...state,
      extras: {
        ...state.extras,
        availableJobs,
      },
    };
  },
  [PAY_EXTRA.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [PAY_EXTRA.SUCCESS]: (
    state,
    { data: { extraJobs, extraUsers, availableUsers = 0, availableJobs = 0 } }
  ) => {
    return {
      ...state,
      loading: false,
      extras: {
        ...state.extras,
        extraJobs,
        extraUsers,
        availableJobs,
        availableUsers,
      },
    };
  },
  [PAY_EXTRA.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [EDIT_JOB.SUCCESS]: (state, { data: { availableJobs = 0 } }) => {
    return {
      ...state,
      extras: {
        ...state.extras,
        availableJobs,
      },
    };
  },
  [LOAD_LOGO.SUCCESS]: (state, { data }) => ({
    ...state,
    logo: data.url,
  }),
  [CHANGE_LICENSE.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [CHANGE_LICENSE.SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [CHANGE_LICENSE.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [UPDATE_CUSTOMER.REQUEST]: (state) => ({
    ...state,
    updateLoading: true,
  }),
  [UPDATE_CUSTOMER.SUCCESS]: (state, { data: { data } }) => ({
    ...state,
    ...data,
    updateLoading: false,
  }),
  [UPDATE_CUSTOMER.FAILURE]: (state) => ({
    ...state,
    updateLoading: false,
  }),

  [LOG_OUT]: () => INITIAL_STATE,

  [DELETE_CUSTOMER.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [DELETE_CUSTOMER.SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [DELETE_CUSTOMER.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [ADD_CUSTOMER_USER.SUCCESS]: (state, { data: { availableUsers = 0 } }) => {
    return {
      ...state,
      extras: {
        ...state.extras,
        availableUsers,
      },
    };
  },
  [TOGGLE_STATUS_MEMO]: (state, { isOpen }) => ({
    ...state,
    showStatusMemo: isOpen,
  }),
  [CLEAR_USER]: () => INITIAL_STATE,
});
