import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import EyeInput from 'src/input/components/EyeInput';
import Spinner from 'src/layout/Spinner';

import useStyles from './styles';

const PasswordChangeModal = ({
  isOpen,
  togglePasswordModal,
  values = {},
  errors,
  handleChange,
  touched,
  handleBlur,
  handleSubmit,
  resetForm,
  loading,
  intl,
}) => {
  const classes = useStyles();

  const closeModal = () => {
    togglePasswordModal(false);
    resetForm();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.container}>
          <div className={classes.title}>
            <FormattedMessage
              id="btns.changePassword"
              defaultMessage="Change password"
            />
          </div>
          {loading ? (
            <div style={{ marginTop: 20 }}>
              <Spinner isSmall />
            </div>
          ) : (
            <>
              <EyeInput
                name="password"
                label={intl.formatMessage({
                  id: 'settings.enterCurrentPassword',
                  defaultMessage: 'Current password',
                })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                helperText={
                  touched.password && errors.password
                    ? intl.formatMessage({
                        id: `errors.password.${errors.password}`,
                        defaultMessage: 'Error',
                      })
                    : ''
                }
                error={errors.password && touched.password}
                className={classes.textField}
                type="password"
                variant="outlined"
                FormHelperTextProps={{
                  className: classes.errorText,
                }}
                iconStyle={{
                  top: '31px',
                }}
              />
              <EyeInput
                name="newPassword"
                label={intl.formatMessage({
                  id: 'settings.enterNewPassword',
                  defaultMessage: 'New password',
                })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                helperText={
                  touched.newPassword && errors.newPassword
                    ? intl.formatMessage({
                        id: `errors.password.${errors.newPassword}`,
                        defaultMessage: 'Error',
                      })
                    : ''
                }
                error={errors.newPassword && touched.newPassword}
                className={classes.textField}
                type="password"
                variant="outlined"
                FormHelperTextProps={{
                  className: classes.errorText,
                }}
                iconStyle={{
                  top: '31px',
                }}
              />
              <EyeInput
                name="verifyPassword"
                label={intl.formatMessage({
                  id: 'inputText.password2',
                  defaultMessage: 'Confirm new password',
                })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.verifyPassword}
                helperText={
                  touched.verifyPassword && errors.verifyPassword
                    ? intl.formatMessage({
                        id: `errors.password.${errors.verifyPassword}`,
                        defaultMessage: 'Error',
                      })
                    : ''
                }
                error={errors.verifyPassword && touched.verifyPassword}
                className={classes.textField}
                type="password"
                variant="outlined"
                FormHelperTextProps={{
                  className: classes.errorText,
                }}
                iconStyle={{
                  top: '31px',
                }}
              />
              <button
                className={classes.saveButton}
                onClick={handleSubmit}
                type="submit"
              >
                <FormattedMessage id="btns.save" defaultMessage="Save" />
              </button>
              <button className={classes.cancelButton} onClick={closeModal}>
                <FormattedMessage id="btns.cancel" defaultMessage="Cancel" />
              </button>
            </>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default PasswordChangeModal;
