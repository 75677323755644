import { compose } from 'redux';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import JobItem from '../JobItem/JobItem';
import Carousel from 'react-elastic-carousel';
import { injectIntl } from 'react-intl';
import { getNavigationUrl } from 'src/utils/history';

const JobSlider = ({ jobs, intl, hideTitle = false }) => {
  const classes = useStyles();
  const companyColorMap = {};
  const colors = [
    '#BADBFF',
    '#D4CFF6',
    '#f5cce2',
    '#82BCFF',
    '#FFB5B5',
    '#ccf3f5',
  ];
  let activeColor = 0;
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 615, itemsToShow: 2 },
    { width: 850, itemsToShow: 3 },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {!hideTitle && (
          <p className={classes.sliderTitle}>
            {intl.formatMessage({
              id: 'jobs.recommendedJobs.title',
              defaultMessage: 'Also recommended for you',
            })}
          </p>
        )}
        <div className={classes.jobsSliderContainer}>
          <Carousel
            pagination={false}
            className={classes.carousel}
            isRTL={true}
            breakPoints={breakPoints}
          >
            {jobs.map((job, index) => {
              const color =
                colors[activeColor === 5 ? (activeColor = 0) : activeColor];
              return (
                <Link
                  key={index}
                  // target='_blank'
                  to={getNavigationUrl(`/job/${job.id}`)}
                  className={classes.jobItemContainer}
                >
                  <JobItem
                    small
                    rowReverse={true}
                    handleClick={() => {}}
                    hideJobDesc
                    job={job}
                    color={
                      companyColorMap[job.companyName] ||
                      ((companyColorMap[job.companyName] = color),
                      activeColor++,
                      color)
                    }
                  />
                </Link>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect((state) => ({
    // jobs: state.jobs.jobs.list.slice(0, 9),
  })),
  injectIntl
)(JobSlider);
