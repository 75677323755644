const memoryStorage = {
  setItem(key, value) {
    this[key] = value;
  },
  getItem(key) {
    return this[key];
  },
  removeItem(key) {
    delete this[key];
  },
};

const getStorage = () => {
  if (!process.env.BROWSER || !localStorage) {
    return memoryStorage;
  }

  return localStorage;
};

export const setItem = (key, value) => getStorage().setItem(key, value);

export const getItem = (key) => getStorage().getItem(key);

export const removeItem = (key) => getStorage().removeItem(key);
