import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';

import { navigateIntl } from 'src/utils/history';
import { initiateJobsFetch } from 'src/jobs/redux/actions';
import resolveRoute from 'src/utils/resolveRoute';
import {
  getJobTitlesAutocomplete,
  setJobCategory,
} from 'src/jobs/redux/actions';

import JobTitleDropdown from './JobTitleDropdown';

export default compose(
  withRouter,
  withSnackbar,
  resolveRoute(function* () {
    // yield putResolve(());
  }),
  connect(
    (state, props) => ({
      jobTitleQuery: queryString.parse(props.location.search)?.title,
      jobTitlesList: state.jobs.autocomplete?.jobTitles,
      langCode: state.locale.language,
      jobTitleCategory: state.jobs.autocomplete.jobCategory,
    }),
    { initiateJobsFetch, getJobTitlesAutocomplete, setJobCategory },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      handleSubmit: async (jobTitle) => {
        const currentParams = queryString.parse(parentProps.location.search);

        const params = {
          ...currentParams,
          title: jobTitle?.toLowerCase(),
        };

        // reset page
        delete params.page;

        navigateIntl(
          `/?${queryString.stringify(params, {
            skipEmptyString: true,
            arrayFormat: 'comma',
          })}`
        );
        dispatchProps.initiateJobsFetch(params);
      },
      fetchJobTitlesAutocomplete: async (jobTitleCategory) => {
        if (jobTitleCategory)
          dispatchProps.getJobTitlesAutocomplete(jobTitleCategory);
      },
      resetCategory: async () => {
        dispatchProps.setJobCategory('');
      },
    })
  ),
  injectIntl
)(JobTitleDropdown);
