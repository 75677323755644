import loadable from '@loadable/component';
import React from 'react';

import Jobs from 'src/jobs/components/Jobs';
import { loadableConfig } from 'src/router/config';
import { langRegex } from 'src/intl/utils';
import Layout from 'src/layout/components/Layout';
import wrapIntoLayout from 'src/utils/wrapIntoLayout';
import JobNotFoundPage from 'src/onboarding/components/JobNotFoundPage';

const Profile = loadable(
  () => import('src/user/components/Profile'),
  loadableConfig.client
);
const ConfirmPage = loadable(() =>
  import('src/onboarding/components/ConfirmPage')
);
const AboutPage = loadable(
  () => import('src/about/components/AboutPage'),
  loadableConfig.client
);
const TermsOfUsePage = loadable(
  () => import('src/about/components/TermsOfUsePage'),
  loadableConfig.client
);
const PrivacyPolicyPage = loadable(
  () => import('src/about/components/PrivacyPolicyPage'),
  loadableConfig.client
);
// const JobsCategoriesPage = loadable(
//   () => import('src/jobs/components/JobCategories'),
//   loadableConfig.client
// );
const ArticlesPage = loadable(
  () => import('src/articles/components/ArticlesPage'),
  loadableConfig.customer
);
const ArticlePage = loadable(
  () => import('src/articles/components/ArticlePage'),
  loadableConfig.customer
);
const AnalyticsPage = loadable(() =>
  import('src/articles/components/AnalyticsPage')
);
const CVLibraryPage = loadable(
  () => import('src/cv/components/CVLibraryPage'),
  loadableConfig.cvBuilder
);

export const Payment = loadable(
  () => import('src/cv/components/CVPayment'),
  loadableConfig.cvBuilder
);
export const Upload = loadable(
  () => import('src/cv/components/Upload'),
  loadableConfig.cvBuilder
);
export const Basics = loadable(
  () => import('src/cv/components/Basics'),
  loadableConfig.cvBuilder
);
export const Experience = loadable(() =>
  import('src/cv/components/Experience')
);
export const Education = loadable(
  () => import('src/cv/components/Education'),
  loadableConfig.cvBuilder
);
export const Skills = loadable(
  () => import('src/cv/components/Skills'),
  loadableConfig.cvBuilder
);
export const ContactInfo = loadable(() =>
  import('src/cv/components/ContactInfo')
);
export const Summary = loadable(
  () => import('src/cv/components/Summary'),
  loadableConfig.cvBuilder
);
export const Licences = loadable(
  () => import('src/cv/components/Licences'),
  loadableConfig.cvBuilder
);
export const Templates = loadable(
  () => import('src/cv/components/Templates'),
  loadableConfig.cvBuilder
);
export const CVExamples = loadable(
  () => import('src/cv/components/CVExamples'),
  loadableConfig.cvBuilder
);
export const CVSummary = loadable(
  () => import('src/cv/components/CVSummary'),
  loadableConfig.cvBuilder
);
export const CVCover = loadable(
  () => import('src/cv/components/Cover'),
  loadableConfig.cvBuilder
);
const CVBuilderPage = loadable(
  () => import('src/cv/components/CVBuilderPage'),
  loadableConfig.cvBuilder
);
const Feedback = loadable(() => import('src/cv/components/Feedback'));
const SharedJob = loadable(
  () => import('src/jobs/components/SharedJob'),
  loadableConfig.client
);

const routes = [
  {
    exact: true,
    path: `${langRegex}/`,
    component: Jobs,
  },
  {
    exact: true,
    path: `${langRegex}/index.html`,
    component: Jobs,
  },
  {
    path: `${langRegex}/job/:id`,
    exact: true,
    component: SharedJob,
  },
  {
    exact: true,
    path: `${langRegex}/about`,
    component: AboutPage,
  },
  {
    exact: true,
    path: `${langRegex}/terms-of-use`,
    component: TermsOfUsePage,
  },
  {
    exact: true,
    path: `${langRegex}/privacy-policy`,
    component: PrivacyPolicyPage,
  },
  // {
  //   exact: true,
  //   path: `${langRegex}/categories`,
  //   component: JobsCategoriesPage,
  // },
  {
    path: `${langRegex}/cv-builder`,
    component: CVBuilderPage,
    children: [
      {
        exact: true,
        path: `${langRegex}/cv-builder`,
        component: () => (
          <CVCover
            preloadComponents={() => {
              Payment.preload();
              Upload.preload();
              Basics.preload();
              Experience.preload();
              Education.preload();
              Skills.preload();
              ContactInfo.preload();
              Summary.preload();
              Licences.preload();
              Templates.preload();
              CVExamples.preload();
              CVSummary.preload();
            }}
          />
        ),
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/cv-examples`,
        component: CVExamples,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/cv-examples/:id`,
        component: CVExamples,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/start`,
        component: Upload,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/basics`,
        component: Basics,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/experience`,
        component: Experience,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/education`,
        component: Education,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/skills`,
        component: Skills,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/contact-info`,
        component: ContactInfo,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/summary`,
        component: Summary,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/languages`,
        component: Licences,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/templates`,
        component: Templates,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/cv-summary`,
        component: CVSummary,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/payment`,
        component: Payment,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/library`,
        component: CVLibraryPage,
      },
      {
        exact: true,
        path: `${langRegex}/cv-builder/feedback`,
        component: Feedback,
      },
    ],
  },
  {
    exact: true,
    path: `${langRegex}/my-profile`,
    component: Profile,
  },
  {
    exact: true,
    path: `${langRegex}/confirm`,
    component: ConfirmPage,
  },
  {
    exact: true,
    path: `${langRegex}/articles`,
    component: wrapIntoLayout(Layout)(ArticlesPage),
  },
  {
    exact: true,
    path: `${langRegex}/articles/:id`,
    component: wrapIntoLayout(Layout)(ArticlePage),
  },
  {
    exact: true,
    path: `${langRegex}/analytics`,
    component: AnalyticsPage,
  },
  {
    exact: true,
    path: `${langRegex}/analytics/:id`,
    component: AnalyticsPage,
  },
  {
    exact: true,
    path: `${langRegex}/job-not-found/`,
    component: JobNotFoundPage,
  },
];

export default routes;
