import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { getNavigationUrl } from 'src/utils/history';

import useStyles from './styles.js';

export default function Menu({ label, items = [], isActive, labelLink }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <Link
            to={labelLink ? getNavigationUrl(labelLink) : '#'}
            className={isActive ? classes.labelActive : null}
          >
            {label}
          </Link>
          <div className={classes.menu}>
            {items.map((item, i) => {
              return (
                <div
                  key={i}
                  className={clsx(
                    classes.menuItem,
                    item?.children?.length && classes.hoverColor
                  )}
                >
                  {item?.directURL ? (
                    <a
                      className={item?.pathname ? null : classes.disableClick}
                      href={item?.pathname}
                    >
                      {item.label}
                    </a>
                  ) : (
                    <Link
                      className={item?.pathname ? null : classes.disableClick}
                      to={
                        item?.pathname ? getNavigationUrl(item.pathname) : '#'
                      }
                    >
                      {item?.label}
                    </Link>
                  )}
                  <div className={classes.menu}>
                    {item?.children?.map((subItem, k) => (
                      <div key={k} className={classes.menuItem}>
                        <Link to={getNavigationUrl(subItem.pathname)}>
                          {subItem.label}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
