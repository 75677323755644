import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { select, putResolve, put, takeEvery } from 'redux-saga/effects';
import { toggleModal } from 'src/onboarding/redux/actions';
import { toggleHeaderModal } from 'src/layout/redux/actions';
import { initiateJobsFetch } from 'src/jobs/redux/actions';
import { toggleCompleteRegModal } from 'src/user/redux/actions/profile';
import resolveRoute from 'src/utils/resolveRoute';
import { clearCV } from 'src/cv/redux/actions';
import queryString from 'query-string';

import Header from './Header';

export default compose(
  withRouter,
  connect(
    (state, props) => ({
      isAuthenticated: Boolean(state.user.profile.confirmed),
      isModalShown: state.layout.isHeaderModalShown,
      isRegModalOpen: state.onboarding.isRegModalOpen,
      isLoginCustomerOpen: state.onboarding.isLoginCustomerOpen,
      isLoginClientOpen: state.onboarding.isLoginClientOpen,
      isRtl: state.locale.dir === 'rtl',
      isHebrew: state.locale.language === 'he',
      langCode: state.locale.language,
      isCompleteRegModalOpen: queryString.parse(props.location.search)
        .completeRegistration,
    }),
    {
      toggleOnboardingModal: toggleModal,
      toggleHeaderModal,
      initiateJobsFetch,
      clearCV,
    }
  ),
  injectIntl
)(Header);
