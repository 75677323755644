import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { login } from 'src/customer/redux/actions';
import { toggleModal } from 'src/onboarding/redux/actions';
import { execute } from 'src/utils/captcha';

import Onboarding from './Onboarding';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('required'),
  password: Yup.string()
    .required('required')
    .min(8, 'minChars')
    .test('contains non-english chars', 'onlyEnglishAllowed', (value) =>
      /^[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]+$/.test(
        value
      )
    ),
});

export default compose(
  withRouter,
  withSnackbar,
  connect(
    (state) => ({
      loading: state.customer.user.loading,
      messages: state.locale.messages,
      isRegCustomerOpen: state.onboarding.isRegCustomerOpen,
    }),
    { loginCustomer: login, toggleLoginModal: toggleModal }
  ),
  injectIntl,
  withFormik({
    mapPropsToValues: () => ({
      email: '',
      password: '',
    }),
    displayName: 'LoginForm',
    validationSchema,
    handleSubmit: async (
      val,
      {
        setFieldValue,
        resetForm,
        props: { loginCustomer, enqueueSnackbar, messages },
      }
    ) => {
      const captchaToken = await execute({
        action: 'submit',
      });
      const { error } = await loginCustomer({ ...val, captchaToken });

      if (error) {
        if (error === 'WRONG_CREDENTIALS') {
          setFieldValue('password', '');
          enqueueSnackbar(messages['snackbar.error.loginFailed'], {
            variant: 'error',
          });
        }

        if (error === 'NOT_VERIFIED') {
          enqueueSnackbar(messages['snackbar.error.unverifiedEmail'], {
            variant: 'error',
          });
        }

        if (error === 'USER_NOT_ACTIVE') {
          enqueueSnackbar(messages['snackbar.error.userInactive'], {
            variant: 'error',
          });
        }

        if (error === 'NOT_FOUND_COMPANY') {
          enqueueSnackbar(messages['snackbar.error.noCompany'], {
            variant: 'error',
          });
        }
        resetForm();
      }
    },
  })
)(Onboarding);
