import React, { useState, useEffect } from 'react';

import Login from 'src/onboarding/components/Login';
import Registration from 'src/customer/components/Registration';
import { navigateIntl } from 'src/utils/history';

export default ({ isRegCustomerOpen, toggleLoginModal, ...props }) => {
  const [registration, setRegistration] = useState(isRegCustomerOpen);

  const handleForgotPassword = () => {
    toggleLoginModal(false, 'isLoginModalOpen');
    navigateIntl(`/forgot-password`, { c: 1 });
  };

  return registration ? (
    <Registration returnToLogin={() => setRegistration(false)} />
  ) : (
    <Login
      showSocialLogin
      type='customer'
      handleForgotPassword={handleForgotPassword}
      setRegistration={() => setRegistration(true)}
      toggleLoginModal={toggleLoginModal}
      {...props}
    />
  );
};
