import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import ArrowDown from 'src/img/icons/ArrowDown';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    width: '100%',
    '& fieldset': {
      borderRadius: 6,
    },
  },
  errorText: {
    marginLeft: 0,
    fontSize: 14,
  },
}));

export default ({
  values,
  errors,
  setFieldValue,
  touched,
  citiesList,
  className,
  textInputClassName,
  intl,
  disabled,
}) => {
  const classes = useStyles();

  const onCityChange = (event, value, reason) => {
    if (reason === 'clear') {
      setFieldValue('city', '');
    }
    if (value) {
      setFieldValue('city', value);
    }
  };

  return (
    <Autocomplete
      name='city'
      options={citiesList}
      // inputValue={values.city}
      value={values.city}
      getOptionLabel={(option) => option}
      getOptionSelected={({ label }) => label === values.city}
      popupIcon={<ArrowDown />}
      openOnFocus
      onChange={onCityChange}
      disabled={disabled}
      renderInput={(params) => {
        params.inputProps.autoComplete = 'new-city';
        return (
          <TextField
            {...params}
            error={touched.city && !!errors.city}
            helperText={
              touched.city && errors.city
                ? intl.formatMessage({
                    id: `errors.city.${errors.city}`,
                    defaultMessage: 'Error',
                  })
                : ''
            }
            className={`${classes.autocomplete} ${textInputClassName}`}
            label={intl.formatMessage({
              id: 'chooseCity',
              defaultMessage: 'City',
            })}
            variant='outlined'
            FormHelperTextProps={{
              className: classes.errorText,
            }}
          />
        );
      }}
      className={className}
    />
  );
};
