import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import {
  updateUser,
  togglePasswordModal,
  changePasswordUser,
} from 'src/users/redux/actions';

import PasswordChangeModal from './PasswordChangeModal';

export default compose(
  connect(
    (state) => ({
      isOpen: state.customer.users.passwordModalOpen,
      loading: state.customer.users.loading,
      messages: state.locale.messages,
    }),
    {
      updateUser,
      togglePasswordModal,
      changePasswordUser,
    }
  ),
  injectIntl,
  withSnackbar,
  withFormik({
    mapPropsToValues: () => ({
      password: '',
      newPassword: '',
      verifyPassword: '',
    }),
    validationSchema: Yup.object().shape({
      password: Yup.string().required('required'),
      newPassword: Yup.string()
        .min(8, 'minChars')
        .test('contains non-english chars', 'onlyEnglishAllowed', (value) =>
          /^[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]+$/.test(
            value
          )
        )
        .required('required'),
        verifyPassword: Yup.string()
        .required('required')
        .when('newPassword', {
          is: (val) => val && val.length > 0,
          then: Yup.string().oneOf([Yup.ref('newPassword')], 'noMatch'),
        }),
    }),
    enableReinitialize: true,
    displayName: 'PasswordChange',
    handleSubmit: async (
      val,
      {
        resetForm,
        props: {
          enqueueSnackbar,
          changePasswordUser,
          togglePasswordModal,
          user,
          messages,
        },
      }
    ) => {
      const { fullName, jobTitle, phone } = user || {};
      const { error, errors } = await changePasswordUser({
        fullName,
        jobTitle,
        password: val.password,
        newPassword: val.newPassword,
        phone,
      });

      if (errors || error) {
        resetForm();
        return enqueueSnackbar(
          error === 'WRONG_CREDENTIALS'
            ? messages['snackbar.error.wrongCurrentPassword']
            : messages['snackbar.error.errorOccured'],
          { variant: 'error' }
        );
      }
      resetForm();
      togglePasswordModal(false);
      return enqueueSnackbar(messages['snackbar.success.passwordUpdated'], {
        variant: 'success',
      });
    },
  })
)(PasswordChangeModal);
