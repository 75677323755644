import React, { useState } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import Menu from 'src/img/icons/Menu';
import Logo from 'src/img/icons/Logo';

import { getNavigationUrl } from 'src/utils/history';
import LanguageSwitchButton from 'src/layout/components/LanguageSwitchButton';
import ModalComponent from 'src/layout/components/Modal';
import HeaderModal from 'src/layout/components/HeaderModal';
import SuccessBar from 'src/layout/components/SuccessBar';
import Modal from 'src/layout/components/Modal';

import CompleteRegModalCustomer from 'src/customer/components/CompleteRegModal';
import OnboardingCustomer from 'src/customer/components/Onboarding';

import useStyles from './styles';

export default function CustomerLandingHeader({
  toggleOnboardingModal,
  pathname,
  isLoginCustomerOpen,
  initiateJobsFetch,
  isCompleteRegModalOpen,
}) {
  const classes = useStyles();
  const [showHeaderModal, setShowHeaderModal] = useState(false);

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <Link
          to={getNavigationUrl('/')}
          onClick={() => {
            initiateJobsFetch();
          }}
          className={classes.logoBar}
        >
          <Logo />
        </Link>
        <div
          className={classes.menuIcon}
          onClick={() => setShowHeaderModal(true)}
        >
          <Menu />
        </div>
        {/* Menu */}
        <div className={classes.headerMenu}>
          <div>
            {/* Customer Hub */}
            <div
              className={clsx(
                classes.navItem,
                pathname.endsWith('/customer') && classes.navItemBorder
              )}
            >
              <Link
                to={getNavigationUrl('/customer')}
                className={clsx(classes.headerLink)}
              >
                <FormattedMessage
                  id='customerLanding.hub'
                  defaultMessage="Employer's hub"
                />
              </Link>
            </div>
            {/* Plans */}
            <div
              className={clsx(
                classes.navItem,
                pathname.endsWith('/customer/plans') && classes.navItemBorder
              )}
            >
              <Link
                to={getNavigationUrl('/customer/plans')}
                className={clsx(
                  classes.headerLink,
                  pathname.endsWith('/customer/plans') && classes.activeLink
                )}
              >
                <FormattedMessage id='billing.plans' defaultMessage='Plans' />
              </Link>
            </div>
            {/* Articles */}
            <div
              className={clsx(
                classes.navItem,
                pathname.endsWith('/customer/articles') && classes.navItemBorder
              )}
            >
              <Link
                to={getNavigationUrl('/customer/articles')}
                className={clsx(
                  classes.headerLink,
                  pathname.includes('/articles') && classes.activeLink
                )}
              >
                <FormattedMessage
                  id='links.articles'
                  defaultMessage='Articles'
                />
              </Link>
            </div>
          </div>

          <div>
            {/* Find Jobs */}
            <Link
              to={getNavigationUrl('/')}
              className={clsx(classes.headerLink, classes.bold)}
            >
              <FormattedMessage
                id='customerLanding.findJobs'
                defaultMessage='Find Jobs'
              />
            </Link>
            <div className={classes.divider} />
            {/* Login */}
            <div
              className={clsx(classes.headerLink, classes.bold)}
              onClick={() => {
                setShowHeaderModal(false);
                toggleOnboardingModal(true, 'isLoginCustomerOpen');
              }}
            >
              <FormattedMessage id='btns.login' defaultMessage='Login' />
            </div>
            {/* Post a job (Register) */}
            <div
              className={clsx(classes.headerLink, classes.registerLink)}
              onClick={() => {
                setShowHeaderModal(false);
                toggleOnboardingModal(true, 'isRegCustomerOpen');
              }}
            >
              <FormattedMessage id='btns.register' defaultMessage='Register' />
            </div>
            <div className={clsx(classes.divider)} />
            <LanguageSwitchButton />
          </div>
        </div>
        <HeaderModal
          open={showHeaderModal}
          handleClose={() => setShowHeaderModal(false)}
          isCustomerLanding
          onCustomerLogin={() => {}}
          LoginEmployerBtn={() => (
            <div className={classes.loginButtonContainer}>
              <Button
                className={classes.loginButton}
                onClick={() => {
                  setShowHeaderModal(false);
                  toggleOnboardingModal(true, 'isLoginCustomerOpen');
                }}
              >
                <FormattedMessage
                  id='btns.loginEmployer'
                  defaultMessage='Employer Login'
                />
              </Button>
            </div>
          )}
        />
        <ModalComponent
          open={isLoginCustomerOpen}
          setOpen={() => toggleOnboardingModal(false)}
        >
          <OnboardingCustomer />
        </ModalComponent>
        <Modal
          open={isCompleteRegModalOpen}
          setOpen={() => toggleOnboardingModal(false)}
          isLogin
        >
          <CompleteRegModalCustomer />
        </Modal>
        <SuccessBar />
      </div>
    </div>
  );
}
