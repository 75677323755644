import React from 'react';

import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { getNavigationUrl, navigateIntl } from 'src/utils/history';
import LogoLight from 'src/img/icons/LogoLight';
import PrivacyPopup from 'src/about/components/PrivacyPopup';
import ContactModal from 'src/about/components/ContactModal';
// import ArrowDown from 'src/img/icons/ArrowDown';
// import ArrowUp from 'src/img/icons/ArrowUp';

import useStyles from './styles';
import { columnOne, jobsList, companiesList, countriesList } from './constants';

export default function Footer({
  togglePrivacyPopup,
  onPrivacyPopupClose,
  langCode,
  toggleContactModal,
  queryString,
  intl,
}) {
  const classes = useStyles();

  const renderSocials = () => (
    <>
      <a
        className={classes.socialLink}
        href='https://il.linkedin.com/company/42800551'
        target='_blank'
        rel='noopener'
      >
        <svg
          viewBox='0 0 48 48'
          style={{
            height: '18px',
            width: '18px',
          }}
        >
          <path d='M8.421,14h0.052l0,0C11.263,14,13,12,13,9.5C12.948,6.945,11.263,5,8.526,5S4,6.945,4,9.5	C4,12,5.736,14,8.421,14z M4,17h9v26H4V17z M44,26.5c0-5.247-4.253-9.5-9.5-9.5c-3.053,0-5.762,1.446-7.5,3.684V17h-9v26h9V28l0,0	c0-2.209,1.791-4,4-4s4,1.791,4,4v15h9C44,43,44,27.955,44,26.5z' />
        </svg>
      </a>
      <a
        className={classes.socialLink}
        href='https://www.facebook.com/Expoint.co'
        target='_blank'
        rel='noopener'
      >
        <svg
          viewBox='0 0 24 24'
          style={{
            height: '18px',
            width: '18px',
          }}
        >
          <path d='M16.403,9H14V7c0-1.032,0.084-1.682,1.563-1.682h0.868c0.552,0,1-0.448,1-1V3.064c0-0.523-0.401-0.97-0.923-1.005 C15.904,2.018,15.299,1.999,14.693,2C11.98,2,10,3.657,10,6.699V9H8c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1l2-0.001V21 c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v-8.003l2.174-0.001c0.508,0,0.935-0.381,0.993-0.886l0.229-1.996 C17.465,9.521,17.001,9,16.403,9z' />
        </svg>
      </a>
    </>
  );

  return (
    <>
      <nav>
        <div className={classes.container}>
          <div>
            <LogoLight width={100} height={15} />
            <a
              href='https://seadata.co.il/'
              target='_blank'
              rel='noopener noreferrer'
              className={classes.creditLink}
            >
              <FormattedMessage
                id='footer.developedBy'
                defaultMessage='Developed by SeaData'
              />
            </a>
          </div>
        </div>
        <div className={classes.openContainer}>
          <div className={classes.termsContainer}>
            <Link
              className={classes.termsLink}
              to={getNavigationUrl('/privacy-policy')}
            >
              <FormattedMessage
                id='privacy.privacyPolicy'
                defaultMessage='Privacy Policy'
              />
            </Link>
            <div className={classes.divider} />
            <Link
              className={classes.termsLink}
              to={getNavigationUrl('/terms-of-use')}
            >
              <FormattedMessage
                id='terms.termsOfUse'
                defaultMessage='Terms of use'
              />
            </Link>
          </div>
          <div className={classes.footerTable}>
            <div className={classes.footerCol}>
              <div className={classes.footerColTitle}>
                <FormattedMessage id='links.home' defaultMessage='Home' />
              </div>
              <div>
                {columnOne[langCode].map(({ title, pathname }, i) => {
                  return (
                    <div className={classes.tableCell} key={i}>
                      <a href={pathname}>{title}</a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.footerCol}>
              <div className={classes.footerColTitle}>
                <FormattedMessage
                  id='footer.byJobTitle'
                  defaultMessage='Search by job title'
                />
              </div>
              <div>
                {jobsList[langCode].map(({ title, label }, i) => (
                  <div className={classes.tableCell} key={i}>
                    <a
                      href={getNavigationUrl(
                        `/?${queryString
                          .stringify(
                            { title },
                            {
                              skipEmptyString: true,
                            }
                          )
                          .toLowerCase()}`
                      )}
                      title={
                        label
                          ? intl.formatMessage(
                              {
                                id: 'footer.linkTitle',
                              },
                              {
                                title,
                              }
                            )
                          : null
                      }
                    >
                      <FormattedMessage
                        id='footer.wantedJob'
                        defaultMessage={`{jobTitle} jobs`}
                        values={{
                          jobTitle: label || title,
                        }}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.footerCol}>
              <div className={classes.footerColTitle}>
                <FormattedMessage
                  id='footer.byCountry'
                  defaultMessage='Jobs by Country'
                />
              </div>
              <div>
                {countriesList.map((country, i) => {
                  return (
                    <div className={classes.tableCell} key={i}>
                      <a
                        href={getNavigationUrl(
                          `/?location=${country.toLowerCase()}`
                        )}
                      >
                        <FormattedMessage
                          id='footer.wantedCountry'
                          defaultMessage='Jobs in {countryId}'
                          values={{
                            countryId: intl.formatMessage({
                              id: 'countries.' + country.replaceAll(' ', ''),
                            }),
                          }}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.footerCol}>
              <div className={classes.footerColTitle}>
                <FormattedMessage
                  id='footer.byCompany'
                  defaultMessage='Top Companies'
                />
              </div>
              <div>
                {companiesList[langCode]?.map(({ name, hrefTitle }, i) => {
                  return (
                    <div className={classes.tableCell} key={i}>
                      <a
                        href={getNavigationUrl(
                          `/?companyName=${name.toLowerCase()}`
                        )}
                        title={hrefTitle}
                      >
                        <FormattedMessage
                          id='footer.wantedCompany'
                          defaultMessage={`jobs at {companyName}`}
                          values={{
                            companyName: name,
                          }}
                        />
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.footerCol}>
              <div className={classes.footerColTitle}>
                <FormattedMessage
                  id='links.recruitingSystem'
                  defaultMessage='Recruiting'
                />
              </div>
              <div>
                {/* {articlesNames.recruiters.map((article, i) => (
                  <div className={classes.tableCell} key={i}>
                    <a href={getNavigationUrl(`/articles/${article.id}`)}>
                      <FormattedMessage
                        id={article.title}
                        defaultMessage={article.title}
                      />
                    </a>
                  </div>
                ))} */}
                <div className={classes.tableCell}>
                  <a href={getNavigationUrl(`/customer`)}>
                    <FormattedMessage
                      id='customerLanding.jobs'
                      defaultMessage='Post a job'
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className={classes.footerCol}>
              <div
                className={clsx(classes.contactButton, classes.footerColTitle)}
                onClick={() => toggleContactModal(true)}
              >
                <FormattedMessage
                  id='footer.contactUs'
                  defaultMessage='Contact Us'
                />
              </div>
              <div style={{ display: 'flex' }}>{renderSocials()}</div>
            </div>
          </div>
        </div>
      </nav>
      <PrivacyPopup onClose={onPrivacyPopupClose} />
      <ContactModal />
    </>
  );
}
