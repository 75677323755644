import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color, size }) => (
  <SvgIcon
    viewBox='0 0 512 512'
    style={{
      height: size || 18,
      width: size || 18,
      marginLeft: -2,
    }}
  >
    <g>
      <g>
        <path
          d='M481.439,285.56c0-24.861-20.226-45.088-45.088-45.088H316.188c9.763-17.36,14.958-67.083,14.958-87.544
			c0-34.599-28.149-62.748-62.748-62.748H256c-8.3,0-15.029,6.729-15.029,15.029c0,34.754-13.533,97.484-38.107,122.057
			l-0.954,0.954c-16.98,16.98-31.247,23.088-51.114,33.022v-5.741c0-8.3-6.729-15.029-15.029-15.029H45.591
			c-8.3,0-15.029,6.729-15.029,15.029v241.47c0,8.3,6.729,15.029,15.029,15.029h90.175c8.3,0,15.029-6.729,15.029-15.029v-9.207
			C180.592,497.697,217.754,512,274.721,512h101.513c24.861,0,45.088-20.226,45.088-45.088c0-5.863-1.125-11.468-3.17-16.611
			c19.126-5.22,33.229-22.747,33.229-43.506c0-5.863-1.125-11.468-3.17-16.611c19.126-5.22,33.229-22.747,33.229-43.506
			c0-11.377-4.155-22.246-11.74-30.805C476.988,307.861,481.439,297.219,481.439,285.56z M120.737,285.56v181.353v15.029H60.62
			V270.531h60.117V285.56z M436.351,361.708c-11.332,0-80.913,0-90.175,0c-8.3,0-15.029,6.729-15.029,15.029
			c0,8.3,6.729,15.029,15.029,15.029h60.117c8.287,0,15.029,6.742,15.029,15.029s-6.742,15.029-15.029,15.029
			c-6.371,0-53.746,0-60.117,0c-8.3,0-15.029,6.729-15.029,15.029c0,8.3,6.729,15.029,15.029,15.029h30.058
			c8.287,0,15.029,6.742,15.029,15.029s-6.742,15.029-15.029,15.029H274.72c-52.451,0-84.988-12.883-123.925-25.861V294.848
			c33.602-16.801,50.281-23.291,73.323-46.327c26.667-26.667,42.773-91.139,46.215-128.226
			c17.129,1.004,30.755,15.257,30.755,32.632c0,23.363-9.099,75.388-25.656,91.946c-4.298,4.298-5.584,10.763-3.258,16.379
			c2.326,5.616,7.806,9.278,13.885,9.278H436.35c8.287,0,15.029,6.742,15.029,15.029c0,8.287-6.741,15.03-15.028,15.03h-90.175
			c-8.3,0-15.029,6.729-15.029,15.029c0,8.3,6.729,15.029,15.029,15.029h90.175c8.006,0,15.029,7.492,15.029,16.031
			C451.38,354.966,444.638,361.708,436.351,361.708z'
          fill='#0041E5'
          stroke='none'
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill='#0041E5'
          d='M254.413,38.37L239.384,8.311c-3.711-7.423-12.738-10.434-20.163-6.721c-7.424,3.712-10.433,12.74-6.721,20.164
			l15.029,30.058c3.712,7.426,12.741,10.432,20.163,6.721C255.116,54.822,258.125,45.794,254.413,38.37z'
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill='#0041E5'
          d='M352.897,1.59c-7.419-3.711-16.451-0.703-20.163,6.721L317.704,38.37c-3.712,7.424-0.703,16.452,6.721,20.164
			c7.425,3.713,16.452,0.701,20.163-6.721l15.029-30.058C363.33,14.33,360.321,5.303,352.897,1.59z'
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill='#0041E5'
          d='M406.293,90.179h-30.058c-8.3,0-15.029,6.729-15.029,15.029c0,8.3,6.729,15.029,15.029,15.029h30.058
			c8.3,0,15.029-6.729,15.029-15.029C421.322,96.908,414.593,90.179,406.293,90.179z'
        />
      </g>
    </g>
    <g>
      <g>
        <path
          fill='#0041E5'
          d='M195.883,90.179h-30.058c-8.3,0-15.029,6.729-15.029,15.029c0,8.3,6.729,15.029,15.029,15.029h30.058
			c8.3,0,15.029-6.729,15.029-15.029C210.912,96.908,204.183,90.179,195.883,90.179z'
        />
      </g>
    </g>
  </SvgIcon>
);
