import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { toggleInfoModal } from 'src/users/redux/actions';
import { setLanguage } from 'src/intl/redux/actions';

import UserInfoModal from './UserInfoModal';

export default compose(
  connect(
    (state) => ({
      isOpen: state.customer.users.infoModalOpen,
      user: state.customer.user.currentLoggedUser,
    }),
    {
      toggleInfoModal,
      setLanguage,
    }
  ),
  injectIntl
)(UserInfoModal);
