import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { dataLayerPush } from 'src/analytics/ga';

import styles from './styles';

export default ({ toggleModal: toggleOnboardingModal, toggleHeaderModal }) => {
  const classes = styles();
  const menuAnchor = useRef(null);
  const isSmallScreen = useMediaQuery('(max-width:1124px)');

  const onOpenModal = (modal) => {
    toggleHeaderModal(false);
    toggleOnboardingModal(true, modal);
  };

  return (
    <div
      className={clsx(
        classes.container,
        isSmallScreen && classes.smallScreenContainer
      )}
    >
      <Button
        ref={menuAnchor}
        onMouseDown={() => {
          dataLayerPush('login_click');
          onOpenModal('isLoginClientOpen');
        }}
        onKeyDown={(e) => e.keyCode === 13 && onOpenModal('isLoginClientOpen')}
        className={clsx(
          classes.button,
          isSmallScreen && classes.outlinedButton
        )}
      >
        <FormattedMessage id='btns.login' defaultMessage='Login' />
      </Button>
      <Button
        onMouseDown={() => {
          dataLayerPush('registration_click');
          onOpenModal('isRegModalOpen');
        }}
        onKeyDown={(e) => e.keyCode === 13 && onOpenModal('isRegModalOpen')}
        className={clsx(
          classes.button,
          classes.registerButton,
          isSmallScreen && classes.smallScreenButton
        )}
      >
        <FormattedMessage id='btns.register' defaultMessage='Register' />
      </Button>
    </div>
  );
};
