import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  languageButton: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 'auto',
    display: 'flex',
    fontSize: 15,
    minWidth: 60,
    minHeight: 50,
    userSelect: 'none',
    cursor: 'pointer',
    opacity: '1 !important',
    '& p': {
      textTransform: 'uppercase',
    },
    '&:hover': {
      '& > div': {
        display: 'flex',
      },
    },
  },

  flagContainer: {
    height: 'auto',
    margin: '0px 5px ',
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center',
    cursor: 'pointer',
  },
  flagsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 1px',
    // backgroundColor: '#e2e8f0',
    borderRadius: 4,
    gap: 7,
    transition: 'all 0.2s ease-in-out',
  },
}));
