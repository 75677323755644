import createReducer from 'src/utils/createReducer';
import { UPLOAD_CV, FETCH_USER_CV } from 'src/cv/redux/actions';
import { UPLOAD_FILE } from 'src/file/redux/actions';

import {
  REGISTER_USER,
  FETCH_USER,
  UPDATE_USER,
  LOG_OUT,
  UPDATE_USER_SETTING,
  UPLOAD_AVATAR,
  LOGIN_USER,
  DELETE_USER,
  USER_JOB_TITLES_AUTOCOMPLETE,
} from './actions';
import {
  TOGGLE_CV_MODAL,
  TOGGLE_PROFILE_MODAL,
  TOGGLE_COMPLETE_REG_MODAL,
  TOGGLE_MODAL_LOADING,
} from './actions/profile';
import { DELETE_SAVED_JOB, SAVE_JOB_IN_FAVORITE } from 'src/jobs/redux/actions';
import { DELETE_USER_CV } from 'src/cv/redux/actions';

const INITIAL_STATE = {
  profile: {},
  isProfileModalOpen: false,
  modalType: null,
  showEmptyModal: false,
  modalItemId: null,
  loading: false,
  avatarLoading: false,
  isCVModalOpen: false,
  cvModalCallback: () => {},
  cvModalOneField: false,
  userJobTitlesAutocomplete: [],
};

export default createReducer(INITIAL_STATE, {
  [LOGIN_USER.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [LOGIN_USER.SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [LOGIN_USER.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [FETCH_USER.REQUEST]: (state) => ({
    ...state,
    profile: {
      ...state.profile,
      isLoading: true,
    },
  }),
  [FETCH_USER.FAILURE]: (state) => ({
    ...state,
    profile: {
      ...state.profile,
      isLoading: false,
    },
  }),
  [FETCH_USER.SUCCESS]: (state, { data }) => ({
    ...state,
    profile: {
      ...state.profile,
      ...data,
      isLoading: false,
    },
  }),
  [FETCH_USER_CV.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_USER_CV.SUCCESS]: (state, { data }) => ({
    ...state,
    loading: false,
    profile: {
      ...state.profile,
      cv: data,
    },
  }),
  [FETCH_USER_CV.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [UPDATE_USER.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [UPDATE_USER.SUCCESS]: (state, { data }) => ({
    ...state,
    loading: false,
    profile: {
      ...state.profile,
      ...data,
    },
  }),
  [UPDATE_USER.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [LOG_OUT]: (state) => INITIAL_STATE,
  // TMP
  [REGISTER_USER.REQUEST]: (state) => ({
    ...state,
    progressState: 'REG_REQUEST',
    loading: true,
  }),
  [REGISTER_USER.SUCCESS]: (state) => ({
    ...state,
    progressState: '',
    loading: false,
  }),
  [REGISTER_USER.FAILURE]: (state) => ({
    ...state,
    progressState: '',
    loading: false,
  }),
  [UPLOAD_CV.REQUEST]: (state) => ({
    ...state,
    loading: true,
    progressState: 'CV_UPLOAD_REQUEST',
  }),
  [UPLOAD_CV.SUCCESS]: (state) => ({
    ...state,
    loading: false,
    progressState: '',
  }),
  [UPLOAD_CV.FAILURE]: (state) => ({
    ...state,
    loading: false,
    progressState: '',
  }),
  [UPLOAD_FILE.REQUEST]: (state) => ({
    ...state,
    progressState: 'FILE_UPLOAD_REQUEST',
  }),
  [UPLOAD_FILE.SUCCESS]: (state) => ({
    ...state,
    progressState: '',
  }),
  [UPLOAD_FILE.FAILURE]: (state) => ({
    ...state,
    progressState: '',
  }),
  [UPDATE_USER_SETTING.SUCCESS]: (state, { body }) => ({
    ...state,
    profile: {
      ...state.profile,
      ...body,
    },
  }),
  [UPLOAD_AVATAR.REQUEST]: (state) => ({
    ...state,
    avatarLoading: true,
  }),
  [UPLOAD_AVATAR.SUCCESS]: (state, { data }) => ({
    ...state,
    avatarLoading: false,
    profile: {
      ...state.profile,
      ...data,
    },
  }),
  [UPLOAD_AVATAR.FAILURE]: (state) => ({
    ...state,
    avatarLoading: false,
  }),
  [USER_JOB_TITLES_AUTOCOMPLETE.REQUEST]: (state) => ({
    ...state,
  }),
  [USER_JOB_TITLES_AUTOCOMPLETE.SUCCESS]: (state, { data }) => ({
    ...state,
    userJobTitlesAutocomplete: data || [],
  }),
  [USER_JOB_TITLES_AUTOCOMPLETE.FAILURE]: (state) => ({
    ...state,
  }),
  [TOGGLE_PROFILE_MODAL]: (
    state,
    { isOpen, modalType, showEmpty, itemId }
  ) => ({
    ...state,
    isProfileModalOpen: isOpen,
    modalType: modalType || state.modalType,
    showEmptyModal: showEmpty,
    modalItemId: itemId,
  }),
  [TOGGLE_CV_MODAL]: (state, { isOpen, cb, oneField }) => ({
    ...state,
    isCVModalOpen: isOpen,
    cvModalCallback: isOpen ? cb : () => {},
    cvModalOneField: oneField,
  }),
  [TOGGLE_COMPLETE_REG_MODAL]: (state, { isOpen }) => ({
    ...state,
    isCompleteRegModal: isOpen,
  }),
  [TOGGLE_MODAL_LOADING]: (state, { isLoading }) => ({
    ...state,
    loading: isLoading,
  }),
  [DELETE_USER.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [DELETE_USER.SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [DELETE_USER.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [SAVE_JOB_IN_FAVORITE.SUCCESS]: (state) => ({
    ...state,
    profile: {
      ...state.profile,
      savedJobsCount: state.profile.savedJobsCount + 1,
    },
  }),
  [DELETE_SAVED_JOB.SUCCESS]: (state) => ({
    ...state,
    profile: {
      ...state.profile,
      savedJobsCount: state.profile.savedJobsCount - 1,
    },
  }),
  [DELETE_USER_CV.SUCCESS]: (state) => {
    const { cv, ...rest } = state.profile;
    return {
      ...state,
      profile: {
        ...rest,
        cvId: '',
      },
    };
  },
});
