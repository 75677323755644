import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ size, color, style = {}, hidePersonIcon = false }) => (
  <SvgIcon
    viewBox='0 0 512 512.00246'
    style={{
      height: size || '34px',
      width: size || '34px',
      ...style,
    }}
  >
    <path
      fill={color}
      d='m501.1875 446.898438-126.601562-126.601563c63.65625-93.640625 39.351562-221.152344-54.289063-284.808594-93.640625-63.660156-221.152344-39.347656-284.808594 54.289063-63.65625 93.640625-39.351562 221.152344 54.289063 284.8125 69.566406 47.289062 160.957031 47.289062 230.519531 0l126.601563 126.597656c15.230468 14.75 39.539062 14.363281 54.289062-.867188 14.417969-14.886718 14.417969-38.535156 0-53.421874zm-475.054688-241.601563c-.003906-98.949219 80.210938-179.167969 179.160157-179.171875s179.167969 80.207031 179.171875 179.160156c.003906 98.949219-80.210938 179.167969-179.160156 179.171875-.003907 0-.007813 0-.011719 0-98.898438-.121093-179.042969-80.261719-179.160157-179.160156zm456.957032 277.792969c-4.996094 5-13.097656 5-18.097656 0l-124.457032-124.457032c6.402344-5.652343 12.445313-11.695312 18.09375-18.097656l124.460938 124.460938c4.996094 4.996094 4.996094 13.097656 0 18.09375zm0 0'
    />
    {!hidePersonIcon && (
      <path
        fill={color}
        d='m246.242188 202.871094c27.160156-22.609375 30.847656-62.957032 8.238281-90.117188s-62.957031-30.847656-90.117188-8.238281c-27.160156 22.613281-30.847656 62.957031-8.238281 90.117187 2.492188 2.992188 5.246094 5.75 8.238281 8.238282-29.753906 15.226562-48.523437 45.789062-48.640625 79.210937-.007812 7.0625 5.714844 12.792969 12.78125 12.796875h.015625 153.554688c7.066406.003906 12.792969-5.714844 12.800781-12.777344 0-.007812 0-.015624 0-.019531-.117188-33.421875-18.878906-63.980469-48.632812-79.210937zm-79.332032-48.757813c0-21.203125 17.191406-38.394531 38.394532-38.394531 21.203124 0 38.390624 17.191406 38.390624 38.394531s-17.1875 38.390625-38.390624 38.390625c-21.199219-.019531-38.378907-17.199218-38.398438-38.394531zm-24.3125 115.179688c7.078125-34.636719 40.894532-56.976563 75.527344-49.894531 25.125 5.132812 44.761719 24.769531 49.894531 49.894531zm0 0'
      />
    )}
  </SvgIcon>
);
