import { put, putResolve, take, select } from 'redux-saga/effects';
import queryString from 'query-string';

import {
  fetchMyCandidatesForReal,
  fetchMyCandidates,
  FETCH_CUSTOMER_JOBS_CANDIDATES,
  updateUploadedFiles,
  updateImportedCandidates,
  uploadImportedCv,
  checkKeys,
} from 'src/candidates/redux/actions';

let _getMyCandidatesSaga = null;
const candidatesPatters = [
  FETCH_CUSTOMER_JOBS_CANDIDATES.SUCCESS,
  FETCH_CUSTOMER_JOBS_CANDIDATES.FAILURE,
];

export function* getMyCandidates(table) {
  if (_getMyCandidatesSaga) {
    return yield take(candidatesPatters);
  }
  const {
    location: { search },
  } = yield select((state) => state.router);

  if (table === 'private') {
    const selectedCustomerId = yield select(
      (state) => state.admin.selectedCustomer.customerInfo.id
    );
    const selectedCustomerCompanyId = yield select(
      (state) => state.admin.selectedCustomer.customerInfo.company_id
    );

    _getMyCandidatesSaga = yield put(
      fetchMyCandidatesForReal({
        ...queryString.parse(search),
        table,
        selectedCustomerId: selectedCustomerCompanyId || selectedCustomerId,
      })
    );
  } else {
    _getMyCandidatesSaga = yield put(
      fetchMyCandidates({ ...queryString.parse(search), table })
    );
  }
  yield take(candidatesPatters);
  _getMyCandidatesSaga = null;
}

// Worker saga:
export function* handleCvsUpload({
  payload: { files, selectedJobId, jobTitle, country },
}) {
  const watchArr = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const UUID = Math.random() * 999999999;
    const formData = new FormData();
    const filename = file.name;
    formData.append('UUID', UUID);
    formData.append('file', file);
    if (selectedJobId) formData.append('jobId', selectedJobId);
    formData.append('jobTitle', jobTitle);
    formData.append('country', country);
    yield put(uploadImportedCv(formData));
    const cvWatcher = {
      UUID,
      filename,
      selectedJobId,
      jobTitle,
      country,
      status: 'importing...',
    };
    watchArr.push(cvWatcher);
  }
  yield put(updateUploadedFiles(watchArr));
}

export function* handleCheckKeys() {
  try {
    const {
      customer: {
        jobsCandidates: {
          uploadedFiles,
          importedCandidates: { list },
        },
      },
    } = yield select();
    const newUploadedFiles = [...uploadedFiles];
    let newImportedCandidates = [...list];

    const importingKeysArr = [];
    for (let i = 0; i < newUploadedFiles.length; i++) {
      if (newUploadedFiles[i].status === 'importing...') {
        importingKeysArr.push(newUploadedFiles[i].UUID);
      }
    }

    if (!importingKeysArr.length) {
      console.log('no key is being imported.');
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < importingKeysArr.length; i++) {
      formData.append('keys', importingKeysArr[i]);
    }
    const {
      error,
      errorMsg,
      data: candidates,
    } = yield putResolve(checkKeys(formData));
    if (error) {
      console.error('error message is:', errorMsg);
      return;
    }
    if (!candidates.length) {
      return;
    }

    for (let i = 0; i < candidates.length; i++) {
      for (let j = 0; j < newUploadedFiles.length; j++) {
        if (newUploadedFiles[j].UUID == candidates[i].UUID) {
          newUploadedFiles[j].status = 'done';
        }
      }
    }

    const justIds = [];
    for (let i = 0; i < candidates.length; i++) {
      justIds.push(candidates[i].data.id);
    }
    newImportedCandidates = newImportedCandidates.filter(
      (cand) => !justIds.includes(cand.id)
    );
    for (let i = 0; i < candidates.length; i++) {
      newImportedCandidates.unshift(candidates[i].data);
    }

    yield put(updateUploadedFiles(newUploadedFiles));
    yield put(updateImportedCandidates(newImportedCandidates));
  } catch (err) {
    console.error(err);
  }
}

export function* handleKeysRequestTimedOut({
  payload: { enqueueSnackbar, message },
}) {
  try {
    const {
      customer: {
        jobsCandidates: { uploadedFiles },
      },
    } = yield select();
    const newUploadedFiles = [...uploadedFiles];

    for (let j = 0; j < newUploadedFiles.length; j++) {
      if (newUploadedFiles[j].status == 'importing...') {
        newUploadedFiles[j].status = 'failed';
      }
    }
    yield put(updateUploadedFiles(newUploadedFiles));
    enqueueSnackbar(message, { variant: 'error' });
  } catch (err) {
    console.error(err);
  }
}
