import { CURRENCIES } from 'src/customer/constants';

export default (from, to, currency) => {
  const transformNumber = (n) => parseFloat((n / 1000).toFixed(1));
  const currencySymbol =
    (CURRENCIES.find((item) => item.value === currency) || {}).symbol || '';

  const fromK =
    from >= 1000
      ? `${currencySymbol}${transformNumber(from)}K`
      : `${currencySymbol}${from}`;
  const toK =
    to >= 1000
      ? `${currencySymbol}${transformNumber(to)}K`
      : `${currencySymbol}${to}`;

  if (fromK === toK) return fromK;

  if (!from) {
    return 'To ' + toK;
  }
  if (!to || to === Number.MAX_SAFE_INTEGER) {
    return 'From ' + fromK;
  }

  return fromK + ' - ' + toK;
};
