import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { numberOfEmployees } from 'src/customer/constants';
import IndustryAutocomplete from 'src/input/components/IndustryAutocomplete';
import TextInput from 'src/input/components/TextInput';
import SelectInput from 'src/input/components/SelectInput';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
  },
  textField: {
    width: '100%',
    maxWidth: 310,
    '& fieldset': {
      borderRadius: 6,
    },
  },
  dense: {
    marginTop: '20px',
  },
  label: {
    marginLeft: '8px',
  },
  menuItem: {
    width: '100%',
    maxWidth: 310,
  },
  industryAutocomplete: {
    marginTop: 20,
  },
}));

export default ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  intl,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TextInput
        name="name"
        errorName="companyName"
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        label={intl.formatMessage({
          id: 'inputText.companyName',
          defaultMessage: 'Company name',
        })}
        touched={touched}
        errors={errors}
        className={classes.textField}
        intl={intl}
      />
      <TextInput
        name="web_site"
        errorName="website"
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        label={intl.formatMessage({
          id: 'inputText.website',
          defaultMessage: 'Website',
        })}
        touched={touched}
        errors={errors}
        className={classes.textField}
        intl={intl}
      />
      <FormControl
        variant="outlined"
        className={clsx(classes.dense, classes.textField)}
      >
        <InputLabel id="num_of_employees">
          <FormattedMessage
            id="inputText.numOfEmployees"
            defaultMessage="Number of employees"
          />
        </InputLabel>
        <SelectInput
          labelId="num_of_employees"
          name="num_of_employees"
          value={values.num_of_employees}
          onChange={handleChange}
          displayEmpty
          labelWidth={150}
        >
          {numberOfEmployees.map((e, i) => (
            <MenuItem key={i} value={e.value} className={classes.menuItem}>
              {e.label}
            </MenuItem>
          ))}
        </SelectInput>
      </FormControl>
      <IndustryAutocomplete
        values={values}
        errors={errors}
        touched={touched}
        className={clsx(classes.industryAutocomplete, classes.textField)}
        setFieldValue={setFieldValue}
        intl={intl}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    </div>
  );
};
