import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PhoneInput from 'react-phone-input-2';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { countries } from 'src/Dashboards/AutoCompleteConst';

if (process.env.BROWSER) require('react-phone-input-2/lib/material.css');

const useStyles = makeStyles(({ palette }) => ({
  inputContainer: {
    height: 50,
    minHeight: 46,
    maxWidth: '100%',
    minWidth: '100%',
    borderRadius: '6px !important',
    fontSize: '14px !important',
    '&:focus': {
      boxShadow: `0 0 0 1px ${palette.primary.main} !important`,
    },
    textAlign: 'left',
    paddingLeft: '58px !important',
  },
  dropdownHeight: {
    height: 150,
  },
  errorContainer: {
    border: 'none !important',
    boxShadow: `0 0 0 1px red !important`,
    '&:hover': {
      borderColor: 'red !important',
    },
    '&:focus': {
      boxShadow: `0 0 0 2px red !important`,
    },
  },
  container: {
    '& > div:first-child': {
      fontSize: 12,
      fontWeight: 'normal',
      marginTop: -3,
    },
    '& > div:last-child': {
      height: 46,
    },
  },
  containerRtl: {
    '& .flag-dropdown': {
      left: 8,
    },
    '& .special-label': {
      left: 10,
      right: 'initial',
      textAlign: 'left',
    },
  },
  dropdownRtl: {
    left: -6,
  },
  errorLabel: {
    '& > div:first-child': {
      color: 'red',
    },
  },
  errorMessage: {
    color: 'red',
    fontSize: 14,
    marginTop: 3,
    fontWeight: 'normal',
  },
  w100: {
    width: '100%',
  },
}));

const PhoneNumberInput = ({
  value,
  className,
  country,
  label,
  error,
  setFieldValue,
  setFieldTouched,
  name,
  touched,
  dropdownClass,
  onChange,
  intl,
}) => {
  const classes = useStyles();
  const {
    locale,
    user,
    customer: { user: customerUser },
    cvBuilder,
  } = useSelector((state) => state);
  country =
    country ||
    customerUser.country ||
    user.profile.country ||
    cvBuilder.cvData?.country;
  const initialCountry =
    country && countries.find((item) => item.label === country);
  const errorShown = error === 'alreadyExists' || (error && touched);

  return (
    <div className={clsx(className, classes.w100)}>
      <PhoneInput
        country={initialCountry?.key || 'il'}
        value={value}
        onChange={
          onChange ||
          ((value) => {
            setFieldValue(name, value ? '+' + value : '');
          })
        }
        onBlur={() => {
          setFieldTouched(name, true);
        }}
        specialLabel={
          label ||
          intl.formatMessage({
            id: 'inputText.phone',
            defaultMessage: 'Phone',
          })
        }
        containerClass={clsx(
          classes.container,
          errorShown && classes.errorLabel,
          locale.dir === 'rtl' && classes.containerRtl
        )}
        inputClass={clsx(
          classes.inputContainer,
          errorShown && classes.errorContainer
        )}
        buttonClass={classes.flagButton}
        copyNumbersOnly={false}
        placeholder=''
        dropdownClass={clsx(
          dropdownClass,
          classes.dropdownHeight,
          locale.dir === 'rtl' && classes.dropdownRtl
        )}
        containerStyle={{ direction: 'initial' }}
      />
      {errorShown ? (
        <div className={classes.errorMessage}>
          <FormattedMessage
            id={`errors.phone.${error}`}
            defaultMessage={`errors.phone.${error}`}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PhoneNumberInput;
