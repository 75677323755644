import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { togglePrivacyPopup } from 'src/about/redux/actions';

import CustomerLandingFooter from './CustomerLandingFooter';

export default compose(
  withRouter,
  connect(null, {
    togglePrivacyPopup,
  })
)(CustomerLandingFooter);
