import { putResolve, select, put, takeEvery, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { setItem, getItem, removeItem } from 'src/utils/localStorage';
import { setToken } from 'src/onboarding/redux/actions';
import {
  fetchCV,
  POST_CV,
  DELETE_CV,
  addCvData,
  getCVs,
  generateCvImages,
  getCVExamples,
} from 'src/cv/redux/actions';
import { LOCAL_STORE_TOKEN } from 'src/user/redux/sagas/onboarding';
import { getMe } from 'src/user/redux/actions';
import { TOGGLE_ONBOARDING_MODAL } from 'src/onboarding/redux/actions';

export const CV_DATA = 'CV_DATA';
export const LOGIN_FROM_CV_BUILDER = 'LOGIN_FROM_CV_BUILDER';
export const SHOW_CV_MODAL = 'SHOW_CV_MODAL';

export function* fetchCvSaga() {
  const { profile } = yield select((state) => state.user);

  if (!profile?.cv) {
    return yield putResolve(fetchCV());
  }
}

export function* postCVSaga({ data: { token, cvId } }) {
  yield put(addCvData({ cvId }));

  if (token) {
    yield setItem(LOCAL_STORE_TOKEN, token);
    yield put(setToken(token));
  }
}

export function* getCVsSaga() {
  yield putResolve(getCVs());
}

export function* getUserSaga() {
  const token = yield getItem(LOCAL_STORE_TOKEN);
  const {
    profile: { isLoading },
  } = yield select((state) => state.user);

  if (token && !isLoading) {
    yield put(setToken(token));
    yield putResolve(getMe());
  }
}

export function* getDataFromLocal() {
  const cvData = yield getItem(CV_DATA);

  if (cvData) {
    yield put(addCvData(JSON.parse(cvData)));
    yield removeItem(CV_DATA);
    yield removeItem(LOGIN_FROM_CV_BUILDER);
  }
}

export function* removeCvLoginKey({ isOpen }) {
  if (!isOpen) yield removeItem(LOGIN_FROM_CV_BUILDER);
}

export function* checkCvId() {
  const { cvData } = yield select((state) => state.cvBuilder);
  if (!cvData?.cvId && !cvData?.id) {
    yield put(push('/cv-builder/library'));
  }
}

export function* generateCvImagesSaga() {
  const { cvData } = yield select((state) => state.cvBuilder);
  yield putResolve(generateCvImages(cvData));
}

export function* getCVExamplesSaga() {
  // first check if data exists
  const { cvExamples } = yield select((state) => state.cvBuilder);
  if (Object.keys(cvExamples).length === 0) yield putResolve(getCVExamples());
}

export default function* () {
  yield all([
    takeEvery(POST_CV.SUCCESS, postCVSaga),
    takeEvery([DELETE_CV.SUCCESS, POST_CV.SUCCESS], getUserSaga),
    takeEvery(TOGGLE_ONBOARDING_MODAL, removeCvLoginKey),
  ]);
}
