import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UserJobTitlesAutocomplete from 'src/input/components/UserJobTitlesAutocomplete';
import UserSeniorityLevelDropdown from 'src/input/components/UserSeniorityLevelDropdown';

import TextInput from 'src/input/components/TextInput';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
  },
  textField: {
    width: '100%',
    maxWidth: 310,
    marginTop: 20,
    '& fieldset': {
      borderRadius: 6,
    },
    '&:first-child': {
      marginTop: 0,
    },
  },
}));

export default ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  intl,
  fetchUserJobTitlesAutocomplete,
  userJobTitles = [],
  setFieldValue,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <TextInput
        name='firstName'
        label={intl.formatMessage({
          id: 'inputText.firstName',
          defaultMessage: 'First Name',
        })}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        touched={touched}
        errors={errors}
        className={classes.textField}
        intl={intl}
      />
      <TextInput
        name='lastName'
        label={intl.formatMessage({
          id: 'inputText.lastName',
          defaultMessage: 'Last Name',
        })}
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        touched={touched}
        errors={errors}
        className={classes.textField}
        intl={intl}
      />
      <UserJobTitlesAutocomplete
        key='jobTitle'
        updateList={fetchUserJobTitlesAutocomplete}
        options={userJobTitles}
        errors={errors}
        values={values}
        touched={touched}
        className={classes.textField}
        variant='outlined'
        setFieldValue={setFieldValue}
        intl={intl}
      />
      <TextInput
        name='experienceTitle'
        label={intl.formatMessage({
          id: 'inputText.yearsOfExp',
          defaultMessage: 'Years of experience',
        })}
        errorName='experienceTitle'
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        touched={touched}
        errors={errors}
        className={classes.textField}
        intl={intl}
      />
      <UserSeniorityLevelDropdown
        key='seniority'
        errors={errors}
        values={values}
        touched={touched}
        className={classes.textField}
        variant='outlined'
        setFieldValue={setFieldValue}
      />
    </div>
  );
};
