import React, { useState } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ProfileMenu from 'src/user/components/ProfileMenu';
import LogoLight from 'src/img/icons/LogoLight';
import Menu from 'src/img/icons/Menu';
import Logo from 'src/img/icons/Logo';
import LanguageSwitchButton from 'src/layout/components/LanguageSwitchButton';
import { navigateIntl } from 'src/utils/history';

import HeaderModal from '../HeaderModal';
import useStyles from './styles';

export default function CustomerHeader({ history, userRole }) {
  const classes = useStyles();
  const [headerOpen, setHeaderOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const editPassAccess = userRole === 'ADMIN' || userRole === 'EDITOR';

  if (isSmallScreen) {
    return (
      <div className={classes.smallScreenContainer}>
        <Logo />
        <div onClick={() => setHeaderOpen(true)}>
          <Menu />
        </div>
        <HeaderModal
          open={headerOpen}
          handleClose={() => setHeaderOpen(false)}
          history={history}
          isCustomer
          isAuthenticated
        />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div
          className={classes.logoBar}
          onClick={() => navigateIntl('/customer/dashboard/jobs-statistics')}
        >
          <LogoLight />
        </div>
        <div className={classes.rightRow}>
          <div
            className={clsx(
              classes.navItem,
              history.location.pathname.includes('/articles') &&
                classes.navItemBorder
            )}
            onClick={() => {
              navigateIntl('/customer/dashboard/articles');
            }}
          >
            <FormattedMessage id="links.articles" defaultMessage="Articles" />
          </div>
          <div className={classes.divider} />
          <ProfileMenu isCustomer={true} editPassAccess={editPassAccess} />
          <LanguageSwitchButton />
        </div>
      </div>
    </div>
  );
}
