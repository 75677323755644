import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, shadows }) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
      {
        margin: 0,
        WebkitAppearance: 'none',
      },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  },
  title: {
    fontSize: 18,
    fontWeight: '500',
    color: palette.primary.dark,
  },
  card: {
    maxWidth: 500,
    width: '90%',
    height: 'auto',
    backgroundColor: palette.background.paper,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 0',
  },
  inputContainer: {
    width: '100%',
    maxWidth: '310px',
    height: '49px',
    marginTop: 20,
    border: `1px dashed ${palette.primary.medium}`,
    borderRadius: '6px',
    '&:focus': {
      border: `1px solid transparent`,
      boxShadow: `0 0 0 2px ${palette.primary.medium}`,
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    cursor: 'pointer',
  },
  errorInputContainer: {
    border: `1px dashed red`,
    marginBottom: 30,
  },
  fileName: {
    width: '230px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 10,
  },
  uploadTitle: {
    margin: 0,
  },
  input: {
    display: 'none',
  },
  size: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  error: {
    color: 'red',
    marginTop: 5,
    fontSize: 14,
  },
  buttonDiv: {
    width: '100%',
    maxWidth: 310,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    border: 'none',
    borderRadius: '6px',
    height: 46,
    width: 160,
    backgroundColor: palette.primary.main,
    color: palette.text.white,
    marginTop: 40,
    fontSize: 16,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover, &:focus': {
      backgroundColor: palette.primary.main,
      boxShadow: '0 2px 10px 5px rgba(20,25,60,0.2)',
    },
  },
  textField: {
    width: '100%',
    maxWidth: 310,
    marginTop: 20,
    '& fieldset': {
      borderRadius: 6,
    },
  },
  textFieldErr: {
    marginTop: 35,
  },
  switchContainer: {
    width: '100%',
    maxWidth: 310,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    cursor: 'pointer',
  },
  publicDesc: {
    width: '100%',
    maxWidth: 310,
    fontSize: 14,
    color: palette.grey['500'],
  },
  spinnerDiv: {
    marginTop: 20,
  },
  termsCheckbox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '3px',
    color: palette.primary.dark,
    marginTop: 18,
    width: '100%',
    maxWidth: 310,
  },
  termsLabel: {
    '& span': {
      fontWeight: '500',
      color: palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  termsError: {
    color: 'red',
    '& span': {
      color: 'red',
    },
  },
}));
