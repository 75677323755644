import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = 'white', size = 18 }) => (
  <SvgIcon
    viewBox='0 0 24 24'
    style={{
      height: size,
      width: size,
    }}
  >
    <path
      d='M24,3V20.611l-12,3.429L0,20.611V4c0-.95,.435-1.823,1.194-2.395,.249-.188,.523-.316,.806-.418V19.103l10,2.857,10-2.857V.187c.283,.102,.558,.23,.806,.418,.759,.572,1.194,1.445,1.194,2.395Zm-11,.371v14.263l-1,.286-1-.286V3.371c0-1.332-.895-2.519-2.251-2.903L7.08,.069c-1.571-.375-3.08,.817-3.08,2.432v15.213l8,2.286,8-2.286V2.535c0-1.597-1.477-2.785-3.037-2.442l-1.788,.393c-1.281,.366-2.176,1.553-2.176,2.885Z'
      fill={color}
    />
  </SvgIcon>
);
