import createReducer from 'src/utils/createReducer';

import {
  TOGGLE_ONBOARDING_MODAL,
  SET_REDIRECT_URL,
  SET_TOKEN,
  FORGOT_PASSWORD,
  SAVE_EXTRA_REDIRECT,
} from './actions';
import { LOG_OUT } from 'src/user/redux/actions';

export const initialState = {
  isRegModalOpen: false,
  isLoginCustomerOpen: false,
  isRegCustomerOpen: false,
  isLoginClientOpen: false,
  isSuccessModalOpen: false,
  isLoading: false,
  confirmation: {
    isLoading: false,
    confirmed: false,
  },
  extraRedirect: null,
  redirectUrl: null,
};

export default createReducer(initialState, {
  [TOGGLE_ONBOARDING_MODAL]: (
    state,
    { modalType = 'isRegModalOpen', isOpen, redirectUrl }
  ) => ({
    ...state,
    ...(isOpen
      ? { [modalType]: true, redirectUrl }
      : {
          isRegModalOpen: false,
          isLoginCustomerOpen: false,
          isRegCustomerOpen: false,
          isLoginClientOpen: false,
          isSuccessModalOpen: false,
        }),
  }),
  [SET_REDIRECT_URL]: (state, { redirectUrl }) => ({
    ...state,
    redirectUrl,
  }),
  [SET_TOKEN]: (state, { token }) => ({
    ...state,
    token,
  }),
  [SAVE_EXTRA_REDIRECT]: (state, { extraRedirect }) => {
    return { ...state, extraRedirect };
  },
  [FORGOT_PASSWORD.REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FORGOT_PASSWORD.SUCCESS]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [FORGOT_PASSWORD.FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [LOG_OUT]: (state) => ({
    ...initialState,
    extraRedirect: state.extraRedirect,
  }),
});
