import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const POST_REVIEW = createAsyncActionRequestTypes('POST_REVIEW');

export const postReview = (candidateId, body) =>
  createApiAction(
    {
      method: 'POST',
      url: '/customer/candidate/:candidateId/review',
      params: {
        candidateId,
      },
      body,
      headers: {
        'Content-type': 'application/json',
      },
    },
    POST_REVIEW
  );

export const UPDATE_REVIEW = createAsyncActionRequestTypes('UPDATE_REVIEW');

export const updateReview = (candidateId, reviewId, body) =>
  createApiAction(
    {
      method: 'PATCH',
      url: '/customer/candidate/:candidateId/review/:reviewId',
      params: {
        candidateId,
        reviewId,
      },
      body,
      headers: {
        'Content-type': 'application/json',
      },
    },
    UPDATE_REVIEW
  );

export const DELETE_REVIEW = createAsyncActionRequestTypes('DELETE_REVIEW');

export const deleteReview = (candidateId, reviewId) =>
  createApiAction(
    {
      url: '/customer/candidate/:candidateId/review/:reviewId',
      method: 'DELETE',
      params: {
        candidateId,
        reviewId,
      },
    },
    DELETE_REVIEW
  );

export const SET_CANDIDATE_RANK =
  createAsyncActionRequestTypes('SET_CANDIDATE_RANK');

export const setRank = (candidateId, rank) =>
  createApiAction(
    {
      method: 'POST',
      url: '/customer/candidate/:candidateId/rank',
      params: {
        candidateId,
      },
      body: {
        rank,
      },
      headers: {
        'Content-type': 'application/json',
      },
    },
    SET_CANDIDATE_RANK
  );

export const GET_CANDIDATE = createAsyncActionRequestTypes('GET_CANDIDATE');

export const getCandidate = (candidateId) =>
  createApiAction(
    {
      url: '/customer/candidate/:candidateId',
      params: {
        candidateId,
      },
    },
    GET_CANDIDATE
  );

export const DELETE_CANDIDATE =
  createAsyncActionRequestTypes('DELETE_CANDIDATE');

export const deleteCandidate = (candidateId, jobId) =>
  createApiAction(
    {
      method: 'DELETE',
      url: `/customer/candidate/:candidateId${jobId ? '/' + jobId : ''}`,
      params: {
        candidateId,
      },
    },
    DELETE_CANDIDATE
  );

export const GET_CANDIDATE_CV =
  createAsyncActionRequestTypes('GET_CANDIDATE_CV');

// export const getCandidateCV = (candidateId) =>
//   createApiAction(
//     {
//       url: '/customer/cv/:candidateId',
//       params: {
//         candidateId,
//       },
//     },
//     GET_CANDIDATE_CV
//   );

export const SET_CANDIDATE_STATUS = createAsyncActionRequestTypes(
  'SET_CANDIDATE_STATUS'
);

export const setStatus = (candidateId, status) =>
  createApiAction(
    {
      method: 'PATCH',
      url: '/customer/candidate/:candidateId/status',
      params: {
        candidateId,
      },
      body: {
        status,
      },
      headers: {
        'Content-type': 'application/json',
      },
    },
    SET_CANDIDATE_STATUS
  );

export const UPDATE_IMPORTED_CANDIDATE = createAsyncActionRequestTypes(
  'UPDATE_IMPORTED_CANDIDATE'
);

export const updateImportedCandidate = (candidateId, data) =>
  createApiAction(
    {
      method: 'PATCH',
      url: '/customer/candidate-import/:candidateId',
      params: {
        candidateId,
      },
      body: {
        data,
      },
      headers: {
        'Content-type': 'application/json',
      },
    },
    UPDATE_IMPORTED_CANDIDATE
  );

export const DELETE_IMPORTED_CANDIDATE = createAsyncActionRequestTypes(
  'DELETE_IMPORTED_CANDIDATE'
);

export const deleteImportedCandidate = (candidateId, jobId = '-1') =>
  createApiAction(
    {
      method: 'DELETE',
      url: '/customer/candidate-import/:candidateId/:jobId',
      params: { candidateId, jobId: jobId },
      headers: {
        'Content-type': 'application/json',
      },
    },
    DELETE_IMPORTED_CANDIDATE
  );
