import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default () => (
  <SvgIcon
    viewBox="0 0 14 15"
    style={{
      height: '15px',
      width: '14px',
    }}
  >
    <g id="Icon-/-line-/-user" transform="translate(0.5 0.5)">
      <g id="Group-5">
        <path
          d="M6.15375 1.05546C7.56196 2.46367 7.56196 4.74555 6.15375 6.15375C4.74647 7.56196 2.46367 7.56196 1.05546 6.15375C-0.351821 4.74555 -0.351821 2.46367 1.05546 1.05546C2.46367 -0.351821 4.74647 -0.351821 6.15375 1.05546Z"
          transform="translate(2.69803 0.07322112)"
          id="Stroke-1"
          fill="none"
          stroke="#0041E5"
          strokeWidth="1"
        />
        <path
          d="M0 6.23002C0 2.78968 2.78968 0 6.23002 0C9.67127 0 12.461 2.78968 12.461 6.23002"
          transform="translate(0.07271424 7.282667)"
          id="Stroke-3"
          fill="none"
          stroke="#0041E5"
          strokeWidth="1"
        />
      </g>
    </g>
  </SvgIcon>
);
