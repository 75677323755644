import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ isActive, color, size = 15 }) => {
  return (
    <SvgIcon
      viewBox='0 0 16 16'
      style={{
        height: size,
        width: size,
      }}
    >
      <g id='surface1'>
        <path
          fill={isActive ? '#82BCFF' : '#FFF'}
          fillRule='evenodd'
          d='M 0.0420201 -0.0104929 L 71.576934 107.542 '
          transform='matrix(0.0312347,0,0,-0.0312347,11.436188,4.921547)'
        />
        <path
          fill={isActive ? '#82BCFF' : '#FFF'}
          fillRule='evenodd'
          d='M 0.0237777 -0.0363151 L 55.801001 -27.924927 '
          transform='matrix(0.0312347,0,0,-0.0312347,8.643789,4.326991)'
        />
        <path
          fill={isActive ? '#82BCFF' : '#FFF'}
          fillRule='evenodd'
          d='M -0.0112464 -0.0406017 L 64.520249 64.615955 '
          transform='matrix(0.0312347,0,0,-0.0312347,5.469101,6.533888)'
        />
        <path
          fill={isActive ? '#82BCFF' : '#FFF'}
          fillRule='evenodd'
          d='M 0.00690215 0.0616245 L 55.784125 27.950236 '
          transform='matrix(0.0312347,0,0,-0.0312347,2.566191,8.244112)'
        />
        <g clipPath='url(#clip2)' clipRule='nonzero'>
          <path
            fill={isActive ? '#82BCFF' : '#FFF'}
            fillRule='evenodd'
            d='M 0.0364385 -0.00777495 L -50.113038 -0.00777495 C -53.614747 -0.00777495 -56.491151 -2.759118 -56.491151 -6.260827 L -56.491151 -144.328213 L 6.28949 -144.328213 L 6.28949 -6.260827 C 6.28949 -2.759118 3.413087 -0.00777495 0.0364385 -0.00777495 Z M 0.0364385 -0.00777495 '
            transform='matrix(0.0312347,0,0,-0.0312347,2.701987,11.249757)'
          />
        </g>
        <g clipPath='url(#clip3)' clipRule='nonzero'>
          <path
            fill={isActive ? '#82BCFF' : '#FFF'}
            fillRule='evenodd'
            d='M 0.0458263 0.03843 L -50.10365 0.03843 C -53.605359 0.03843 -56.481763 -2.837974 -56.481763 -6.214622 L -56.481763 -193.055813 L 6.298878 -193.055813 L 6.298878 -6.214622 C 6.298878 -2.837974 3.422474 0.03843 0.0458263 0.03843 Z M 0.0458263 0.03843 '
            transform='matrix(0.0312347,0,0,-0.0312347,5.740756,9.727763)'
          />
        </g>
        <g clipPath='url(#clip4)' clipRule='nonzero'>
          <path
            fill={isActive ? '#82BCFF' : '#FFF'}
            fillRule='evenodd'
            d='M 0.0552141 -0.0417381 L -50.219323 -0.0417381 C -53.595971 -0.0417381 -56.472375 -2.793081 -56.472375 -6.29479 L -56.472375 -290.933713 L 6.308266 -290.933713 L 6.308266 -6.29479 C 6.308266 -2.793081 3.431862 -0.0417381 0.0552141 -0.0417381 Z M 0.0552141 -0.0417381 '
            transform='matrix(0.0312347,0,0,-0.0312347,8.779525,6.670571)'
          />
        </g>
        <g clipPath='url(#clip5)' clipRule='nonzero'>
          <path
            fill={isActive ? '#82BCFF' : '#FFF'}
            fillRule='evenodd'
            d='M -0.0306299 0.00378687 L 485.456321 0.00378687 '
            transform='matrix(0.0312347,0,0,-0.0312347,0.415019,15.757931)'
          />
        </g>
        <g clipPath='url(#clip6)' clipRule='nonzero'>
          <path
            fill={isActive ? '#82BCFF' : '#FFF'}
            fillRule='evenodd'
            d='M 0.000787789 -0.0393261 L -7.627936 -0.0393261 C -11.004584 -0.0393261 -13.880987 -2.790669 -13.880987 -6.292378 L -13.880987 -241.782313 L 48.899654 -241.782313 L 48.899654 -6.292378 C 48.899654 -2.790669 46.02325 -0.0393261 42.521541 -0.0393261 L 35.017879 -0.0393261 '
            transform='matrix(0.0312347,0,0,-0.0312347,10.488257,8.205803)'
          />
        </g>
        <g clipPath='url(#clip7)' clipRule='nonzero'>
          <path
            fill={isActive ? '#82BCFF' : '#FFF'}
            fillRule='evenodd'
            d='M 0.0546415 0.0483883 L 0.0546415 175.133842 C 0.0546415 178.51049 -2.821762 181.386894 -6.323471 181.386894 L -56.472948 181.386894 C -59.849596 181.386894 -62.726 178.51049 -62.726 175.133842 L -62.726 -207.302813 L 0.0546415 -207.302813 L 0.0546415 -34.968702 '
            transform='matrix(0.0312347,0,0,-0.0312347,15.052981,9.282761)'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
