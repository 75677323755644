import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  disableClick: {
    pointerEvents: 'none !important',
  },
  labelActive: {
    fontWeight: 'bold !important',
    color: palette.primary.main + ' !important',
  },
  container: {
    zIndex: 10,
    '& $menuItem': {
      textAlign: 'left',
      position: 'relative',
      display: 'block',
      opacity: 0,
      cursor: 'pointer',
      '& a': {
        whiteSpace: 'nowrap',
        display: 'block',
      },
      '& > $menu': {
        top: 50,
        position: 'absolute',
        pointerEvents: 'none',
      },
    },
    '& > $menu': {
      display: 'flex',
      '& > $menuItem': {
        pointerEvents: 'all',
        opacity: '1',
        '& > a': {
          padding: '20px 0',
        },
        '& > $menu > $menuItem > $menu': {
          transform: 'translateX(100%)',
          top: 0,
          right: 1,
        },

        '& > $menu': {
          borderRadius: 5,
          transition:
            'background-color 0.3s,transition 0.3s, opacity 0.3s,box-shadow 0.3s',
          opacity: 0,
          '& > $menuItem': {
            '& > a': {
              zIndex: 11,
            },
            '&:hover': {
              '&:last-child': {
                borderBottomLeftRadius: 5,
              },
              '&:first-child': {
                borderTopLeftRadius: 5,
              },
            },
          },
        },
        '&:hover': {
          '& > $menu > $menuItem': {
            '& > a:hover': {
              textDecoration: 'underline',
            },
            opacity: 1,
          },

          '& > $menu': {
            boxShadow:
              'rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset',
            transition: 'background-color 0.3s,transition 0.3s, opacity 0.3s',
            backgroundColor: 'white',
            transform: 'translateY(0%)',
            opacity: 1,
          },
        },
      },
    },
    '& a': {
      color: palette.text.secondary,
      fontWeight: 400,
      transition: 'background 0.3s, color 0.3s, transform 0.3s',
      padding: '10px 15px',
      boxSizing: 'border-box',
      position: 'relative',
      '&:hover:before': {
        content: '',
        top: '0',
        left: '0',
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.2)',
        width: '100%',
        height: '100%',
      },
    },
  },
  menu: {
    whiteSpace: 'nowrap',
    display: 'block',
    '&:hover > $menuItem': {
      opacity: 1,
    },
  },
  menuItem: {
    whiteSpace: 'nowrap',
    display: 'block',
    '&:hover > $menu': {
      pointerEvents: 'initial',
      '& > $menuItem': {
        opacity: 1,
      },
    },
    '& > $menu > $menuItem': {
      '& > $menu > $menuItem': {
        // transition: 'transform 0.3s, opacity 0.3s',
        // transform: 'translateX(50px) translateY(0%)',
        opacity: 0,
      },
      '&:hover > $menu': {
        transition: 'background-color 0.15s',
        backgroundColor: '#EBEEEE',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
        zIndex: -10,
      },
      '&:hover > $menu > $menuItem': {
        // transform: 'translateX(0) translateY(0%)',
        opacity: 1,
      },
      '& > $menu': {
        borderRadius: 5,
        borderTopLeftRadius: 0,
        transition: 'background-color 0.15s',
        '& a:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  a: {
    whiteSpace: 'nowrap',
    display: 'block',
  },
  hoverColor: {
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#EBEEEE !important',
    },
  },
}));
