const gaExists = () => {
  if (!process.env.BROWSER) return;

  if (!window.ga) {
    console.error('GA IS NOT INITIALIZITE');

    return false;
  }

  return true;
};

if (gaExists()) window.ga('create', process.env.ANALYTICS_G_TAG_KEY, 'auto');

if (gaExists()) window.ga('send', 'pageview');

export const overwriteDefaultTracker = (params = {}) => {
  window.ga('create', {
    trackingId: process.env.ANALYTICS_G_TAG_KEY,
    cookieDomain: 'auto',
    ...params,
  });
};

export const track = (key, params = {}) => {
  if (!gaExists()) return;

  console.log('GA: track ', key);

  window.ga('set', key, params);

  window.ga('send', 'pageview');
};

export const dataLayerPush = (key, args = {}) => {
  if (process.env.BROWSER) {
    if (window && window.dataLayer && window.dataLayer.push) {
      window.dataLayer.push({
        event: key,
        ...args,
      });
    }
  }
};
