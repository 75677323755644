import { createApiAction, createAsyncActionRequestTypes } from 'src/api';
import queryString from 'query-string';

export const FETCH_LICENSES = createAsyncActionRequestTypes('FETCH_LICENSES');

export const getLicenses = () =>
  createApiAction(
    {
      url: '/payment/licenses',
    },
    FETCH_LICENSES
  );

export const GET_CARDS = createAsyncActionRequestTypes('GET_CARDS');

export const getCards = (params) =>
  createApiAction(
    {
      url: `/customer/payment-methods?${queryString.stringify(
        {
          ...params,
        },
        { skipEmptyString: true }
      )}`,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    GET_CARDS
  );

export const UPDATE_CARD = createAsyncActionRequestTypes('UPDATE_CARD');

export const updateCard = (id, body) =>
  createApiAction(
    {
      url: '/customer/payment-methods/:id',
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
      },
      params: {
        id,
      },
      body,
    },
    UPDATE_CARD
  );

export const DELETE_CARD = createAsyncActionRequestTypes('DELETE_CARD');

export const deleteCard = (id) =>
  createApiAction(
    {
      url: '/customer/payment-methods/:id',
      method: 'DELETE',
      params: {
        id,
      },
    },
    DELETE_CARD
  );

export const TOGGLE_PAYMENT_MODAL = 'TOGGLE_PAYMENT_MODAL';

export const togglePaymentModal = (isOpen) => ({
  type: TOGGLE_PAYMENT_MODAL,
  isOpen,
});

export const PAY_EXTRA = createAsyncActionRequestTypes('PAY_EXTRA');

export const payExtra = (d) =>
  createApiAction(
    {
      url: '/customer/extras',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    PAY_EXTRA
  );
