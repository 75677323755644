import loadable from '@loadable/component';
import { langRegex } from 'src/intl/utils';

const TopData = loadable(() => import('src/top/components/TopData'));

const routes = [
  {
    path: `${langRegex}/top/:attribute`,
    component: TopData,
    exact: true,
  },
];

export default routes;
