import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ isActive, color, size = 15 }) => (
  <SvgIcon
    viewBox='0 0 15 15'
    style={{
      height: size,
      width: size,
    }}
  >
    <g id='grid' transform='translate(0.4125977 0.4121094)'>
      <path
        d='M5.775 0L5.775 5.775L0 5.775L0 0L5.775 0ZM13.75 5.775L13.75 0L7.975 0L7.975 5.775L13.75 5.775ZM5.775 13.75L5.775 7.975L0 7.975L0 13.75L5.775 13.75ZM13.75 7.975L13.75 13.75L7.975 13.75L7.975 7.975L13.75 7.975Z'
        id='Shape'
        fill={color ? color : isActive ? '#82BCFF' : '#FFF'}
        fillRule='evenodd'
        stroke='none'
      />
    </g>
  </SvgIcon>
);
