import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { updateMe } from 'src/user/redux/actions';
import { navigateIntl } from 'src/utils/history';
import {
  toggleCompleteRegModal,
  toggleModalLoading,
} from 'src/user/redux/actions/profile';
import { allLanguages } from 'src/intl/utils';
import { togglePrivacyPopup } from 'src/about/redux/actions';
import { fetchUserJobTitlesAutocomplete } from 'src/user/redux/actions';

import CompleteRegModal from './CompleteRegModal';

const validationSchema = Yup.object().shape({
  jobTitle: Yup.string().required('required'),
  experienceTitle: Yup.number()
    .required('required')
    .typeError('invalid')
    .min(0, 'invalid')
    .max(99, 'invalid'),
  typeOfEmployment: Yup.array().min(1, 'atLeast1'),
  allowCompanies: Yup.boolean(),
  country: Yup.string().required('required'),
  city: Yup.string(),
  agreeToTerms: Yup.boolean().oneOf([true], 'required'),
  seniority: Yup.object().test(
    'is-seniority-exists',
    'required',
    (value, ctx) => {
      return value || ctx.parent.suggestedSeniority;
    }
  ),
});

export default compose(
  withSnackbar,
  injectIntl,
  connect(
    (state) => ({
      loading: state.user.loading,
      isOpen: state.user.isCompleteRegModal,
      messages: state.locale.messages,
      language: state.locale.language,
      redirectBackUrl: state.router.location.pathname,
      query: state.router.location.query,
      userJobTitles: state.user.userJobTitlesAutocomplete,
    }),
    {
      allLanguages,
      updateMe,
      toggleCompleteRegModal,
      toggleModalLoading,
      fetchUserJobTitlesAutocomplete,
      togglePrivacyPopup,
    },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      closeModalHandler: () => {
        navigateIntl(stateProps.redirectBackUrl);
        dispatchProps.toggleModalLoading(false);
      },
    })
  ),
  withFormik({
    mapPropsToValues: ({ query }) => ({
      jobTitle: query.jobTitle || '',
      experienceTitle: query.experienceTitle || '',
      seniority: '',
      suggestedSeniority: '',
      allowCompanies: true,
      country: query.country || '',
      city: query.city || '',
      typeOfEmployment: query.typeOfEmployment || ['employee'],
      agreeToTerms: false,
    }),
    displayName: 'CVTitleForm',
    validationSchema,
    handleSubmit: async (
      val,
      { props: { toggleModalLoading, language, redirectBackUrl, query } }
    ) => {
      let trimmedValues = { ...val };

      // delete the suggested field
      if (!trimmedValues.seniority)
        trimmedValues.seniority = trimmedValues.suggestedSeniority.seniority;
      else trimmedValues.seniority = trimmedValues.seniority.seniority;
      delete trimmedValues.suggestedSeniority;

      toggleModalLoading(true);
      const dev = process.env.NODE_ENV !== 'production';
      const href = `${dev ? process.env.SERVER_URL : ''}/api/user/${
        query.loginType
      }?state=${JSON.stringify({
        ...trimmedValues,
        lang: allLanguages.en.language === language ? '' : language,
        redirectBackUrl,
      })}${query.accessToken ? '&accessToken=' + query.accessToken : ''}${
        query.templateName ? '&templateName=' + query.templateName : ''
      }`;
      window.location.href = href;
    },
  })
)(CompleteRegModal);
