import createReducer from 'src/utils/createReducer';

import {
  SHOW_SNACKBAR,
  REMOVE_SNACKBAR,
  TOGGLE_SUCCESS_BAR,
  TOGGLE_HEADER_MODAL,
} from './actions';

export const initialState = {
  layoutErrors: [],
  isSuccessBarShown: false,
  isHeaderModalShown: false,
};

export default createReducer(initialState, {
  [SHOW_SNACKBAR]: (state, { data }) => ({
    layoutErrors: [
      ...state.layoutErrors,
      { id: Math.random().toString(36).substring(3), ...data },
    ],
  }),
  [REMOVE_SNACKBAR]: (state, { id }) => ({
    layoutErrors: state.layoutErrors.filter((e) => e.id !== id),
  }),
  [TOGGLE_SUCCESS_BAR]: (state, { isShown, isCustomer }) => ({
    ...state,
    isSuccessBarShown: isShown,
    isSuccessBarCustomer: isCustomer,
  }),
  [TOGGLE_HEADER_MODAL]: (state, { isShown }) => ({
    ...state,
    isHeaderModalShown: isShown,
  }),
});
