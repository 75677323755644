import createReducer from 'src/utils/createReducer';
import { LOG_OUT } from 'src/customer/redux/actions';
import {
  FETCH_JOBS_STATS,
  FETCH_CANDIDATES_SENIORITY_TRENDS,
  FETCH_JOB_SENIORITY_TRENDS,
  CLEAR_JOB_CANDIDATES_SENIORITY,
  CLEAR_DESTRIBUTIONS,
  SET_DEFAULT_STATS,
  SET_SELECTED_SEARCH,
} from './actions';

import { PIE_BOX_COLORS } from '../constants';

const INITIAL_STATE = {
  loading: false,
  selected: {
    period: 'days60',
    jobId: '',
    jobStatus: '',
    interval: 'day',
  },
  jobs: {
    total: 0,
    events: [],
    statistics: [],
  },
  candidates: {
    events: [],
    appliedTrends: [],
    viewedTrends: [],
    seniority: {
      applied: {
        sum: 0,
        array: [],
      },
      viewed: {
        sum: 0,
        array: [],
      },
    },
  },
  job: {
    events: [],
    appliedTrends: [],
    viewedTrends: [],
    seniority: {
      applied: {
        sum: 0,
        array: [],
      },
      viewed: {
        sum: 0,
        array: [],
      },
    },
  },
};

export default createReducer(INITIAL_STATE, {
  // FETCH_CUSTOMER_JOBS_STATS
  [FETCH_JOBS_STATS.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_JOBS_STATS.SUCCESS]: (
    state,
    { data: { jobsStatistics, totalJobsEvents, totalJobs } }
  ) => ({
    ...state,
    jobs: {
      total: totalJobs,
      events: totalJobsEvents,
      statistics: jobsStatistics,
    },
    loading: false,
  }),
  [FETCH_JOBS_STATS.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  // FETCH_CANDIDATES_SENIORITY_TRENDS
  [FETCH_CANDIDATES_SENIORITY_TRENDS.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_CANDIDATES_SENIORITY_TRENDS.SUCCESS]: (
    state,
    { data: { usersSeniority, appliedTrends, viewedTrends, events } }
  ) => {
    usersSeniority.applied.array.forEach((el) => {
      el.color = PIE_BOX_COLORS[el.name];
    });
    usersSeniority.viewed.array.forEach((el) => {
      el.color = PIE_BOX_COLORS[el.name];
    });
    return {
      ...state,
      candidates: {
        events,
        appliedTrends,
        viewedTrends,
        seniority: usersSeniority,
      },
      loading: false,
    };
  },
  [FETCH_CANDIDATES_SENIORITY_TRENDS.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  // FETCH_JOB_SENIORITY_TRENDS
  [FETCH_JOB_SENIORITY_TRENDS.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_JOB_SENIORITY_TRENDS.SUCCESS]: (
    state,
    { data: { usersSeniority, appliedTrends, viewedTrends, events } }
  ) => {
    usersSeniority.applied.array.forEach((el) => {
      el.color = PIE_BOX_COLORS[el.name];
    });
    usersSeniority.viewed.array.forEach((el) => {
      el.color = PIE_BOX_COLORS[el.name];
    });
    return {
      ...state,
      job: {
        events,
        appliedTrends,
        viewedTrends,
        seniority: usersSeniority,
      },
      loading: false,
    };
  },
  [FETCH_JOB_SENIORITY_TRENDS.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [CLEAR_JOB_CANDIDATES_SENIORITY]: (state) => ({
    ...state,
    jobCandidatesSeniority: [],
  }),
  [CLEAR_DESTRIBUTIONS]: (state) => ({
    ...state,
    candidatesAppliedDistribution: [],
    candidatesViewedDistribution: [],
  }),
  [SET_SELECTED_SEARCH]: (state, { payload: { field, value } }) => ({
    ...state,
    selected: {
      ...state.selected,
      [field]: value,
    },
  }),
  [SET_DEFAULT_STATS]: () => INITIAL_STATE,
  [LOG_OUT]: () => INITIAL_STATE,
});
