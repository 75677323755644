import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import ArrowDown from 'src/img/icons/ArrowDown';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    width: '100%',
    '& fieldset': {
      borderRadius: 6,
    },
  },
  errorText: {
    marginLeft: 0,
    fontSize: 14,
  },
}));

export default ({
  name = 'jobTitle',
  setFieldTouched = () => {},
  values,
  errors,
  setFieldValue,
  touched,
  className,
  textInputClassName,
  intl,
  disabled,
  updateList = () => {},
  options = [],
}) => {
  const classes = useStyles();
  const throttledInitiate = throttle((e) => {
    updateList(e.target?.value);
  }, 1000);
  const onJobTitleChange = (event, value, reason) => {
    if (reason === 'clear') {
      setFieldValue(name, '');
    }
    if (value) {
      setFieldValue(name, value);
    }
  };
  const onInputChange = useCallback((e) => {
    setFieldTouched(name, true);
    setFieldValue(name, e.target?.value);
    throttledInitiate(e);
  }, []);

  return (
    <Autocomplete
      key={name}
      name={name}
      options={options}
      value={values[name]}
      onFocus={onInputChange}
      getOptionLabel={(option) => option.label || option}
      getOptionSelected={({ label }) => label === values[name]}
      onChange={onJobTitleChange}
      popupIcon={<ArrowDown color={disabled ? '#9A9DAE' : null} />}
      openOnFocus
      disabled={disabled}
      onInput={onInputChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={touched[name] && !!errors[name]}
            helperText={
              touched[name] && errors[name]
                ? intl.formatMessage({
                    id: `errors.country.${errors[name]}`,
                    defaultMessage: 'Error',
                  })
                : ''
            }
            className={`${classes.autocomplete} ${textInputClassName}`}
            label={intl.formatMessage({
              id: 'inputText.jobTitle',
              defaultMessage: 'Job Title',
            })}
            variant='outlined'
            FormHelperTextProps={{
              className: classes.errorText,
            }}
          />
        );
      }}
      className={className}
    />
  );
};
