import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import CustomerLandingHeader from 'src/layout/components/CustomerLandingHeader';
import CustomerLandingFooter from 'src/layout/components/CustomerLandingFooter';

export default ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  return (
    <>
      <CustomerLandingHeader />
      {children}
      <CustomerLandingFooter />
    </>
  );
};
