import createReducer from 'src/utils/createReducer';

import {
  FETCH_ARTICLES,
  GET_ARTICLE,
  SET_WAS_SSRED,
  SET_ARTICLES_META,
  SET_TOP_META,
  SET_CURRENT_ARTICLE,
  GET_ANALYTICS,
  UPDATE_AO_RESULTS_ARTICLES,
  CLEAR_AUTOCOMPLETE,
  ARTICLES_AUTOCOMPLETE,
  ARTICLES_AUTOCOMPLETE_SUCCESS,
} from 'src/articles/redux/actions';

const INITIAL_STATE = {
  meta: {
    curLangCode: null,
    curPage: null,
    curSide: '',
    curTitle: false,
  },
  autocomplete: [],
  autocompleteLoading: false,
  list: null,
  loading: false,
  selected: [],
  currentArticle: null,
  footerArticles: [],
  pages: 0,
  total: 0,
  wasSsred: null,
  topData: null,
  topMeta: {
    curTopType: null,
    curLangCode: null,
  },
  biDataLoading: false,
  ssrTable: null,
};

export default createReducer(INITIAL_STATE, {
  [SET_CURRENT_ARTICLE]: (state, data) => {
    return {
      ...state,
      currentArticle: data,
    };
  },
  [FETCH_ARTICLES.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_ARTICLES.SUCCESS]: (
    state,
    { data: { list, total, curPage, curSide, pages } }
  ) => {
    return {
      ...state,
      list,
      total,
      curPage: parseInt(curPage),
      curSide,
      pages,
      loading: false,
      selected: [],
    };
  },
  [GET_ANALYTICS.REQUEST]: (state) => ({
    ...state,
    topData: null,
    biDataLoading: true,
  }),
  [GET_ANALYTICS.SUCCESS]: (state, { data }) => {
    return {
      ...state,
      topData: {
        ...state.topData,
        ...data,
      },
      biDataLoading: false,
      ssrTable: data?.ssrTable,
    };
  },
  [FETCH_ARTICLES.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [SET_WAS_SSRED]: (state, { payload: flag }) => ({ ...state, wasSsred: flag }),
  [SET_ARTICLES_META]: (state, { payload: meta }) => ({
    ...state,
    meta,
  }),
  [UPDATE_AO_RESULTS_ARTICLES]: (state, { data: list }) => {
    return {
      ...state,
      autocomplete: list,
    };
  },
  [CLEAR_AUTOCOMPLETE]: (state) => {
    return {
      ...state,
      autocomplete: [],
    };
  },
  [SET_TOP_META]: (state, { payload: topMeta }) => ({
    ...state,
    topMeta,
  }),
  [GET_ARTICLE.REQUEST]: (state) => ({
    ...state,
    loading: true,
    currentArticle: null,
  }),
  [GET_ARTICLE.SUCCESS]: (state, { data }) => {
    let obj = {};

    if (data && data.length > 0)
      obj = {
        currentArticle: data[0],
        selected: data,
      };

    return {
      ...state,
      ...obj,
      loading: false,
    };
  },
  [GET_ARTICLE.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [ARTICLES_AUTOCOMPLETE.REQUEST]: (state) => ({
    ...state,
    autocompleteLoading: true,
  }),
  [ARTICLES_AUTOCOMPLETE_SUCCESS]: (state) => ({
    ...state,
    autocompleteLoading: false,
  }),
});
