import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Footer from 'src/layout/components/Footer';
import Header from 'src/layout/components/Header';
import SuccessBar from 'src/layout/components/SuccessBar';

export default ({ children, cvBuilder }) => {
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  return (
    <>
      <Header />
      {children}
      {isMobile && cvBuilder ? null : <Footer />}
      <SuccessBar />
    </>
  );
};
