import loadable from '@loadable/component';

import { loadableConfig } from 'src/router/config';
import { langRegex } from 'src/intl/utils';

const SuccessLoginPage = loadable(() =>
  import('src/onboarding/components/SuccessLoginPage')
);
const ForgotPasswordPage = loadable(
  () => import('src/onboarding/components/ForgotPasswordPage'),
  loadableConfig.client
);
const RestorePasswordPage = loadable(() =>
  import('src/onboarding/components/RestorePasswordPage')
);

const routes = [
  {
    path: `${langRegex}/forgot-password`,
    component: ForgotPasswordPage,
  },
  {
    path: `${langRegex}/restore-password`,
    component: RestorePasswordPage,
  },
  {
    path: `${langRegex}/success-login`,
    component: SuccessLoginPage,
  },
];

export default routes;
