import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { togglePrivacyPopup } from 'src/about/redux/actions';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.background.dark,
    minHeight: 80,
    color: palette.text.white,
    padding: '20px 40px',
  },
  termsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  termsLink: {
    fontSize: 12,
  },
  divider: {
    width: 1,
    height: 14,
    backgroundColor: palette.text.white,
    margin: '0 10px',
  },
}));

const CustomerFooter = ({ togglePrivacyPopup }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  if (!isSmallScreen) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.termsContainer}>
        <div
          className={classes.termsLink}
          onClick={(e) => {
            e.target.blur();
            togglePrivacyPopup('customer', 'privacyPolicy');
          }}
          tabIndex="0"
        >
          <FormattedMessage
            id="privacy.privacyPolicy"
            defaultMessage="Privacy Policy"
          />
        </div>
        <div className={classes.divider} />
        <div
          className={classes.termsLink}
          onClick={(e) => {
            e.target.blur();
            togglePrivacyPopup('customer', 'terms');
          }}
          tabIndex="0"
        >
          <FormattedMessage
            id="terms.termsOfUse"
            defaultMessage="Terms of use"
          />
        </div>
      </div>
    </div>
  );
};

export default connect((state, props) => ({}), { togglePrivacyPopup })(
  CustomerFooter
);
