import React from 'react';

import Route from 'src/utils/Route';
import NotFoundPage from 'src/onboarding/components/NotFoundPage';
import Jobs from 'src/jobs/components/Jobs';
import customer from 'src/customer/routes';
import onboarding from 'src/onboarding/routes';
import user from 'src/user/routes';
import top from 'src/top/routes';

import client from './userRoutes';

const routes = [
  ...customer,
  ...onboarding,
  ...client,
  ...user,
  ...top,
  {
    path: '*',
    component: NotFoundPage,
  },
];

export default routes.map((r, i) => <Route key={i} {...r} />);
