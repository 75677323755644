import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

export default (Layout) => (Component) => {
  class WrapIntoLayout extends React.Component {
    static displayName = `WrapIntoLayout(${
      Component.displayName || Component.name
    })`;

    render() {
      return (
        <Layout {...this.props}>
          <Component {...this.props} />
        </Layout>
      );
    }
  }

  return hoistNonReactStatics(WrapIntoLayout, Component);
};
