import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { togglePrivacyPopup } from 'src/about/redux/actions';

import Dashboard from './Dashboard';

export default compose(
  connect(
    (state) => ({
      userRole: state.customer.user.currentLoggedUser?.role,
      userLicense: state.customer.user.license_type_id,
    }),
    { togglePrivacyPopup }
  ),
  injectIntl
)(Dashboard);
