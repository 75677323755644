import { combineReducers } from 'redux';

import departments from 'src/department/redux/reducer';
import users from 'src/users/redux/reducer';
import jobs from 'src/customerJobs/redux/reducer';
import jobsCandidates from 'src/candidates/redux/reducer';
import stats from 'src/insights/redux/reducer';

import cvPreview from './cvPreview';
import viewedJob from './viewedJob';
import user from './user';
import invoices from './invoices';
import test from './test'

export default combineReducers({
  cvPreview,
  departments,
  jobs,
  jobsCandidates,
  user,
  users,
  viewedJob,
  invoices,
  stats,
  test
});
