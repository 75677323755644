import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const POST_NOTE = createAsyncActionRequestTypes('POST_NOTE');

export const postNote = (d) =>
  createApiAction(
    {
      url: '/user-notes',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    POST_NOTE
  );

export const TOGGLE_PRIVACY_POPUP = 'TOGGLE_PRIVACY_POPUP';

export const togglePrivacyPopup = (isOpen, modalType) => ({
  type: TOGGLE_PRIVACY_POPUP,
  isOpen,
  modalType,
});

export const TOGGLE_LANDING_MODAL = 'TOGGLE_LANDING_MODAL';

export const toggleLandingModal = (isOpen) => ({
  type: TOGGLE_LANDING_MODAL,
  isOpen,
});

export const TOGGLE_CONTACT_MODAL = 'TOGGLE_CONTACT_MODAL';

export const toggleContactModal = (isOpen) => ({
  type: TOGGLE_CONTACT_MODAL,
  isOpen,
});
