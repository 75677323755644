import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const CONFIRM_EMAIL = createAsyncActionRequestTypes('CONFIRM_EMAIL');
export const FORGOT_PASSWORD = createAsyncActionRequestTypes('FORGOT_PASSWORD');
export const RESTORE_PASSWORD =
  createAsyncActionRequestTypes('RESTORE_PASSWORD');
export const CONNECT_USER = createAsyncActionRequestTypes('CONNECT_USER');
export const LOG_OUT = 'LOG_OUT';
export const SAVE_EXTRA_REDIRECT = 'SAVE_EXTRA_REDIRECT';

export const googleLogin = (d, type = 'user') => {
  console.log(type);
};
// createApiAction(
//   {
//     url: `/${type}/confirm`,
//     method: 'POST',
//     body: d,
//     headers: {
//       'Content-type': 'application/json',
//     },
//   },
//   CONFIRM_EMAIL
// );

export const confirmEMail = (d, type = 'user') =>
  createApiAction(
    {
      url: `/${type}/confirm`,
      method: 'POST',
      body: d,
      headers: {
        'Content-type': 'application/json',
      },
    },
    CONFIRM_EMAIL
  );

export const forgotPassword = (d, type = 'user') =>
  createApiAction(
    {
      url: `/recovery/${type}/forgotPassword`,
      method: 'POST',
      body: d,
      headers: {
        'Content-type': 'application/json',
      },
    },
    FORGOT_PASSWORD
  );

export const connectUser = (d, type = 'user') =>
  createApiAction(
    {
      url: '/:type/connect',
      method: 'POST',
      body: d,
      params: {
        type,
      },
      headers: {
        'Content-type': 'application/json',
      },
    },
    CONNECT_USER
  );

export const restorePassword = (d, type = 'user') =>
  createApiAction(
    {
      url: `/recovery/${type}/restorePassword`,
      method: 'POST',
      body: d,
      headers: {
        'Content-type': 'application/json',
      },
    },
    RESTORE_PASSWORD
  );

export const TOGGLE_ONBOARDING_MODAL = 'TOGGLE_ONBOARDING_MODAL';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

export const SET_TOKEN = 'SET_TOKEN';
export const RENEW_TOKEN = 'RENEW_TOKEN';
export const REMOVE_TOKEN = createAsyncActionRequestTypes('REMOVE_TOKEN');
export const removeToken = () =>
  createApiAction(
    {
      url: `/user/logout`,
    },
    REMOVE_TOKEN
  );

export const saveExtraRedirect = (extraRedirect) => ({
  type: SAVE_EXTRA_REDIRECT,
  extraRedirect,
});

export const toggleModal = (isOpen, modalType, redirectUrl) => ({
  type: TOGGLE_ONBOARDING_MODAL,
  isOpen,
  modalType,
  redirectUrl,
});

export const setRedirectUrl = (redirectUrl) => ({
  type: SET_REDIRECT_URL,
  redirectUrl,
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  token,
});

export const renewToken = (token) => ({
  type: RENEW_TOKEN,
  token,
});

export const logout = (data) => ({
  type: LOG_OUT,
  payload: data,
});
