import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, spacing }) => ({
  root: {
    position: 'relative',
  },
  blueWave: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '20vh',
    marginTop: -20,
    minHeight: 120,
    maxHeight: 215,
  },
  blueWaveSocials: {
    height: '18vh',
  },
  form: {
    width: '35vw',
    minWidth: '300px',
    maxWidth: '600px',
    padding: 0,
    margin: 0,
    height: 'auto',
    position: 'relative',
  },
  progressBarLoading: {
    height: '10px',
    backgroundColor: '#d9ebff',
    width: '100%',
  },
  progressBar: (props) => ({
    height: '10px',
    backgroundColor: palette.primary.medium,
    width: `calc(100%/${props.steps.length}*${props.activeStep + 1})`,
    borderTopLeftRadius: '6px',
  }),
  borderTopRadius: {
    borderTopRightRadius: '6px',
  },
  closeDiv: {
    width: '100%',
  },
  closeIcon: {
    marginTop: 15,
    marginLeft: 15,
    cursor: 'pointer',
    borderRadius: 6,
    width: 34,
    height: 34,
    '&:focus': {
      backgroundColor: palette.primary.medium,
    },
  },
  iconDiv: {
    display: 'none',
    justifyContent: 'center',
    width: '100%',
  },
  icon: {
    width: '15vh',
    height: '15vh',
    marginTop: -50,
    maxWidth: 190,
    maxHeight: 190,
    minWidth: 90,
    minHeight: 90,
  },
  iconDivSocials: {
    display: 'none',
  },
  loadingDiv: {
    height: 329,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialDiv: {
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bigSocialDiv: {
    marginTop: 0,
  },
  socialButtons: {
    width: '100%',
    maxWidth: 310,
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  dividerDiv: {
    width: '100%',
    maxWidth: 310,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  dividerSide: {
    height: 14,
    width: '40%',
    borderBottom: `1px ${palette.divider} solid`,
  },
  dividerText: {
    color: palette.text.secondary,
    fontSize: 18,
    fontWeight: 'bold',
    height: '40px',
  },
  title: {
    textAlign: 'center',
    color: palette.primary.dark,
    fontSize: 20,
    fontWeight: 'bold',
    height: 'calc(20vh - 79px)',
    minHeight: 45,
  },
  socialTitle: {
    marginBottom: 0,
    height: 34,
    minHeight: 34,
  },
  instructions: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  w100: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 40px',
  },
  cvFormButtons: {
    margin: 30,
  },
  '@media screen and (min-height: 620px)': {
    buttons: {
      marginTop: 40,
    },
  },
  '@media screen and (min-height: 760px)': {
    iconDiv: {
      display: 'flex',
    },
    dividerText: {
      fontSize: 24,
    },
    dividerSide: {
      height: 20,
    },
    title: {
      fontSize: 24,
      height: 'auto',
      marginTop: 10,
    },
    socialTitle: {
      marginBottom: 20,
    },
    socialDiv: {
      margin: '20px 0',
    },
  },
  '@media screen and (min-height: 800px)': {
    title: {
      marginBottom: 20,
      marginTop: 20,
    },
    socialTitle: {
      marginTop: 0,
    },
    buttons: {
      marginBottom: 40,
    },
  },
}));
