import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color }) => (
  <SvgIcon
    viewBox="0 0 140 103"
    style={{
      height: 103,
      width: 140,
    }}
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="HP-popup" transform="translate(-1021.000000, -468.000000)">
        <g id="Group-13" transform="translate(671.000000, 168.000000)">
          <g id="Group-27" transform="translate(350.000000, 300.000000)">
            <rect id="Rectangle" x="0" y="0" width="140" height="103"></rect>
            <g id="Group-16" transform="translate(9.000000, 9.000000)">
              <g id="Group-10" transform="translate(32.000000, 23.000000)">
                <rect
                  id="Rectangle"
                  fill="#82BCFF"
                  x="2.1509434"
                  y="38.2030457"
                  width="52.6981132"
                  height="14.7969543"
                  rx="1.61012649"
                ></rect>
                <path
                  d="M1.61012649,9.14720812 L55.3898735,9.14720812 C56.2791218,9.14720812 57,9.8680863 57,10.7573346 L57,37.3289586 C57,38.124753 56.418636,38.8011827 55.6318821,38.9207938 L28.7420085,43.0088924 C28.5815912,43.0332809 28.4184088,43.0332809 28.2579915,43.0088924 L1.36811794,38.9207938 C0.581363967,38.8011827 1.8644751e-15,38.124753 0,37.3289586 L0,10.7573346 C-1.08901509e-16,9.8680863 0.720878183,9.14720812 1.61012649,9.14720812 Z"
                  id="Rectangle-Copy-4"
                  fill="#80F1F9"
                ></path>
                <rect
                  id="Rectangle"
                  fill="#0041E5"
                  x="25.5424528"
                  y="38.2030457"
                  width="5.91509434"
                  height="8.34010152"
                  rx="1.61012649"
                ></rect>
                <path
                  d="M14.7877358,9.14720812 L14.7877358,4.29367063 C14.7877358,1.92234182 16.7100777,4.35606036e-16 19.0814065,0 L38.1874614,0 C40.5587903,-4.35606036e-16 42.4811321,1.92234182 42.4811321,4.29367063 L42.4811321,9.14720812 L42.4811321,9.14720812"
                  id="Path"
                  stroke="#82BCFF"
                  strokeWidth="3.22025297"
                ></path>
              </g>
              <g id="Group-7" transform="translate(94.000000, 0.000000)">
                <polygon
                  id="Rectangle"
                  fill="#82BCFF"
                  transform="translate(13.265023, 35.782975) rotate(-315.000000) translate(-13.265023, -35.782975) "
                  points="9.57602912 32.0939809 16.8841402 32.1638576 16.9540169 39.4719687 9.64590588 39.4020919"
                ></polygon>
                <path
                  d="M2.90472353,0.893125538 C4.06431423,0.904213005 5.00163258,1.84153136 5.01272005,3.00112206 C5.02353744,4.1324665 4.11517079,5.05837161 2.98382634,5.069189 C2.9707689,5.06931385 2.95771055,5.06931385 2.94465311,5.069189 C1.78506241,5.05810153 0.847744054,4.12078318 0.836656586,2.96119248 C0.825839197,1.82984804 1.73420585,0.903942928 2.8655503,0.893125538 C2.87860774,0.893000688 2.89166608,0.893000688 2.90472353,0.893125538 Z"
                  id="Rectangle-Copy-14"
                  fill="#0041E5"
                  transform="translate(2.924688, 2.981157) rotate(-315.000000) translate(-2.924688, -2.981157) "
                ></path>
                <line
                  x1="28"
                  y1="5.48052479"
                  x2="18.176682"
                  y2="14.43951"
                  id="Line"
                  stroke="#FF6E7E"
                  strokeWidth="2.70666667"
                  fill="#FF6E7E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="17.6596653"
                  y1="6.53452305"
                  x2="7.83634731"
                  y2="15.4935083"
                  id="Line-Copy-3"
                  stroke="#FFFFFF"
                  strokeWidth="2.70666667"
                  fill="#FF6E7E"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
              </g>
              <g
                id="Group-6-Copy-2"
                transform="translate(10.000000, 76.000000) scale(-1, -1) translate(-10.000000, -76.000000) translate(0.000000, 66.000000)"
              >
                <g
                  id="Group-6-Copy"
                  transform="translate(10.000000, 10.000000) scale(1, -1) rotate(-90.000000) translate(-10.000000, -10.000000) "
                >
                  <path
                    d="M4.75718942,11.8789697 C6.61498745,11.8789697 8.12103031,13.3850126 8.12103031,15.2428106 C8.12103031,17.1006086 6.61498745,18.6066515 4.75718942,18.6066515 C2.89939139,18.6066515 1.39334852,17.1006086 1.39334852,15.2428106 C1.39334852,13.3850126 2.89939139,11.8789697 4.75718942,11.8789697 Z"
                    id="Rectangle-Copy-15"
                    fill="#0041E5"
                    transform="translate(4.757189, 15.242811) rotate(-315.000000) translate(-4.757189, -15.242811) "
                  ></path>
                  <path
                    d="M16.1942485,1.11467882 C17.6804869,1.11467882 18.8853212,2.31951311 18.8853212,3.80575153 C18.8853212,5.29198996 17.6804869,6.49682425 16.1942485,6.49682425 C14.70801,6.49682425 13.5031757,5.29198996 13.5031757,3.80575153 C13.5031757,2.31951311 14.70801,1.11467882 16.1942485,1.11467882 Z"
                    id="Rectangle-Copy-13"
                    fill="#80F1F9"
                    transform="translate(16.194248, 3.805752) rotate(-315.000000) translate(-16.194248, -3.805752) "
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
