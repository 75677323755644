import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';

const FRONT_URL = process.env.FRONT_URL;

function HelmetDefaultTags({
  query: { companyName: _companyName } = {},
  langCode,
  title,
  metaTitle = title,
  desc,
  pathname = '',
  noIndex = false,
  xDefaultUrl = FRONT_URL + pathname,
  canonicalUrl = langCode === 'en'
    ? xDefaultUrl
    : `${FRONT_URL}/${langCode}${pathname}`,
  hreflangUrls = [
    { language: 'en', href: xDefaultUrl },
    { language: 'he', href: `${FRONT_URL}/he${pathname}` },
  ],
  hideAlternate = false,
  jobPostJSON,
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='title' content={metaTitle} />
      <meta name='description' content={desc} />
      <meta property='og:title' content={title} />
      <meta property='og:type' content='article' />
      <meta property='og:image' content={`${FRONT_URL}/landing.jpg`} />
      <meta property='og:description' content={desc} />
      <meta property='og:url' content={canonicalUrl} />
      {noIndex && <meta name='robots' content='noindex' />}
      <link href={canonicalUrl} rel='canonical' />
      {!hideAlternate && hreflangUrls.length > 0 && (
        <link rel='alternate' hreflang='x-default' href={xDefaultUrl} />
      )}
      {!hideAlternate &&
        hreflangUrls.map(({ language, href }) => (
          <link
            rel='alternate'
            key={language}
            hreflang={language}
            href={href}
          />
        ))}
      {jobPostJSON && (
        <script type='application/ld+json'>
          {`${JSON.stringify(jobPostJSON)}`}
        </script>
      )}
    </Helmet>
  );
}

export default compose(
  connect((state) => ({
    langCode: state.locale.language,
  })),
  injectIntl
)(HelmetDefaultTags);
