import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  container: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: palette.background.dark,
    minHeight: 80,
    color: palette.text.white,
    padding: '20px 40px',
    '& a': {
      color: palette.text.white,
      '&:hover': {
        textDecoration: 'none',
        color: palette.primary.medium,
      },
    },
  },
  creditLink: {
    color: '#fff',
    fontSize: 11,
    display: 'block',
    marginLeft: -1,
  },
  textField: {
    width: '100%',
    maxWidth: 310,
    '& fieldset': {
      borderRadius: 6,
    },
  },
  menuItem: {
    width: '100%',
    maxWidth: 310,
  },
  contactBlock: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: '20px 0',
  },
  contactButton: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: 14,
    '&:hover': {
      color: palette.primary.medium,
    },
  },
  dividerBig: {
    width: 1,
    height: 24,
    backgroundColor: palette.primary.medium,
    margin: '0 20px',
  },
  socialLink: {
    fill: palette.text.white,
    cursor: 'pointer',
    marginRight: 10,
    height: 18,
    width: 18,
    '&:hover': {
      fill: palette.primary.medium,
    },
    '&:last-child': {
      margin: 0,
    },
  },
  openButton: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: palette.primary.medium,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowUp: {
    marginTop: 10,
  },
  openContainer: {
    backgroundColor: palette.primary.dark,
    display: 'flex',
    flexWrap: 'wrap',
    padding: 40,
    color: palette.text.white,
  },
  termsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    order: 4,
  },
  termsLink: {
    color: 'white',
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: palette.primary.medium,
    },
  },
  divider: {
    width: 1,
    height: 14,
    backgroundColor: palette.text.white,
    margin: '0 10px',
  },
  footerTable: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media screen and (max-width: 450px)': {
      flexDirection: 'column',
    },
  },
  footerCol: {
    margin: '0 30px 40px 0',
  },
  footerColTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
    fontSize: 16,
  },
  tableCell: {
    fontSize: 14,
    marginBottom: 10,
    '& a': {
      color: palette.text.white,
    },
    '& a:hover': {
      color: palette.primary.medium,
    },
  },
  '@media screen and (min-width: 600px)': {
    openContainer: {
      justifyContent: 'center',
    },
  },
  '@media screen and (min-width: 900px)': {
    articleCol: {
      marginRight: 120,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));
