export const industries = [
  'Aerospace industry',
  'Agriculture',
  'Arms industry',
  'Automotive industry',
  'Banking industry',
  'Broadcasting',
  'Chemical industry',
  'Computer industry',
  'Construction industry',
  'Defense industry',
  'E-commerce industry',
  'Electrical power industry',
  'Energy industry',
  'Entertainment industry',
  'Film industry',
  'Finance industry',
  'Fishing industry',
  'Food industry',
  'Gaming industry',
  'Health care industry',
  'Hospitality industry',
  'Information industry',
  'Insurance industry',
  'Internet',
  'Investments industry',
  'Manufacturing',
  'Mass media',
  'Music industry',
  'News media',
  'Petroleum industry',
  'Pharmaceutical industry',
  'Publishing',
  'Pulp and paper industry',
  'Retail industry',
  'Shipbuilding industry',
  'Software industry',
  'Sport industry',
  'Steel industry',
  'Telecommunications industry',
  'Timber industry',
  'Tobacco industry',
  'Toy industry',
  'Video game industry',
  'Water industry',
  'Other',
];
