import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({}) => ({
  container: {
    display: 'flex',
    minHeight: '90vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    width: '100%',
  },
  spinnersBlock: {
    display: 'flex',
  },
  spinner: {
    boxSizing: 'border-box',
    width: '3em',
    height: '3em',
    borderRadius: '50%',
    animation: `$grow-pulse 1.0s ease-out infinite`,
  },
  '@keyframes grow-pulse': {
    '0%': {
      transform: 'scale(0.1)',
      opacity: 0,
    },
    '30%': {
      opacity: 0.8,
    },
    '60%': {
      transform: 'scale(1.0)',
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },
  darkOrange: {
    backgroundColor: 'rgba(255,110,126, 1.0)',
  },
  lightOrange: {
    backgroundColor: 'rgba(255,181,181, 1.0)',
  },
  darkPurple: {
    backgroundColor: 'rgba(169,102,255, 1.0)',
  },
  lightPurple: {
    backgroundColor: 'rgba(212,207,246, 1.0)',
  },
  darkBlue: {
    backgroundColor: 'rgba(130,188,255, 1.0)',
  },
  lightBlue: {
    backgroundColor: 'rgba(186,219,255, 1.0)',
  },
}));

export default ({ isSmall, spinnersCount = 6 }) => {
  const classes = useStyles();
  return (
    <div className={clsx(!isSmall && classes.container)}>
      {spinnersCount === 3 ? (
        <div className={classes.spinnersBlock}>
          <div className={clsx(classes.spinner, classes.lightPurple)} />
          <div className={clsx(classes.spinner, classes.darkBlue)} />
          <div className={clsx(classes.spinner, classes.lightBlue)} />
        </div>
      ) : (
        <div className={classes.spinnersBlock}>
          <div className={clsx(classes.spinner, classes.darkOrange)} />
          <div className={clsx(classes.spinner, classes.lightOrange)} />
          <div className={clsx(classes.spinner, classes.darkPurple)} />
          <div className={clsx(classes.spinner, classes.lightPurple)} />
          <div className={clsx(classes.spinner, classes.darkBlue)} />
          <div className={clsx(classes.spinner, classes.lightBlue)} />
        </div>
      )}
    </div>
  );
};
