import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: 10,
  },
  button: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: 6,
    height: 48,
  },
  smallScreenContainer: {
    borderTop: '1px solid #d1d5db',
    justifyContent: 'center',
    padding: '10px 5px',
    alignItems: 'center',
    width: '100%',
    alignSelf: 'end',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 8px',
  },
  registerButton: {
    color: '#00a085',
    backgroundColor: '#CCF3F5',
    width: '103px',
    height: '40px',
    '&:hover': {
      backgroundColor: '#ace9d1',
    },
    '@media (max-width: 1124px)': {
      '&': {
        height: 48,
      },
    },
  },
  outlinedButton: {
    border: `1px ${palette.primary.main} solid`,
    width: '103px',
    color: palette.primary.main,
    marginTop: 0,
    width: '100%',
    order: 2,
  },
  smallScreenButton: {
    marginTop: 0,
    width: '100%',
  },
}));
