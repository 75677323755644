import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

const baseURL = '/cv/builder';

export const ADD_CV_DATA = 'ADD_CV_DATA';

export const addCvData = (dataObj) => ({
  type: ADD_CV_DATA,
  data: dataObj,
});

export const CLEAR_CV = 'CLEAR_CV';

export const clearCV = () => ({
  type: CLEAR_CV,
});

export const SET_EDIT_ID = 'SET_EDIT_ID';

export const setEditId = (id) => ({
  type: SET_EDIT_ID,
  id,
});

export const SET_EDIT_SECTION = 'SET_EDIT_SECTION';

export const setEditSection = (editSection) => ({
  type: SET_EDIT_SECTION,
  editSection,
});

export const SET_SHOW_FORM = 'SET_SHOW_FORM';

export const setShowForm = (showItemForm) => ({
  type: SET_SHOW_FORM,
  showItemForm,
});

export const SET_SHOW_LICENSE_FORM = 'SET_SHOW_LICENSE_FORM';

export const setShowLicenseForm = (showLicenseForm) => ({
  type: SET_SHOW_LICENSE_FORM,
  showLicenseForm,
});

export const POST_CV = createAsyncActionRequestTypes('POST_CV');

export const postCV = (body) =>
  createApiAction(
    {
      method: 'POST',
      url: baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    },
    POST_CV
  );

export const GENERATE_CV_IMAGES =
  createAsyncActionRequestTypes('GENERATE_CV_IMAGES');

export const generateCvImages = (body) =>
  createApiAction(
    {
      method: 'POST',
      url: baseURL + '/generate-cv-image',
      body: body,
    },
    GENERATE_CV_IMAGES
  );
export const GET_CV_EXAMPLES = createAsyncActionRequestTypes('GET_CV_EXAMPLES');

export const getCVExamples = () =>
  createApiAction(
    {
      method: 'GET',
      url: baseURL + '/examples',
    },
    GET_CV_EXAMPLES
  );

export const UPDATE_CV_IMAGE = createAsyncActionRequestTypes('UPDATE_CV_IMAGE');

export const updateCVImage = (cvId, body) =>
  createApiAction(
    {
      method: 'POST',
      url: baseURL + '/cv/:cvId/image',
      params: {
        cvId,
      },
      body,
    },
    UPDATE_CV_IMAGE
  );

export const GET_CVS = createAsyncActionRequestTypes('GET_CVS');

export const getCVs = () =>
  createApiAction(
    {
      method: 'GET',
      url: baseURL + '/cvs',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    GET_CVS
  );

export const GET_CV = createAsyncActionRequestTypes('GET_CV');

export const getCV = (cvId) =>
  createApiAction(
    {
      method: 'GET',
      url: baseURL + `/cv/${cvId}`,
      headers: {
        'Content-Type': 'application/pdf',
      },
      responseType: 'blob',
    },
    GET_CV
  );

export const PATCH_CV = createAsyncActionRequestTypes('PATCH_CV');

export const patchCV = (cvId, body) =>
  createApiAction(
    {
      method: 'PATCH',
      url: baseURL + `/cv/${cvId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    },
    PATCH_CV
  );

export const DELETE_CV = createAsyncActionRequestTypes('DELETE_CV');

export const deleteCV = (cvId) =>
  createApiAction(
    {
      method: 'DELETE',
      url: baseURL + '/cv/:cvId',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        cvId,
      },
    },
    DELETE_CV
  );

export const CV_PLANS = createAsyncActionRequestTypes('CV_PLANS');

export const fetchCVPlans = () =>
  createApiAction(
    {
      url: '/payment/subscriptions',
    },
    CV_PLANS
  );

export const BUY_SUBSCRIPTION =
  createAsyncActionRequestTypes('BUY_SUBSCRIPTION');

export const buySubscription = (body) => {
  return createApiAction(
    {
      method: 'POST',
      url: '/cv/builder/buy-subscription',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    },
    BUY_SUBSCRIPTION
  );
};

export const GET_SKILLS_AUTOCOMPLETE = createAsyncActionRequestTypes(
  'GET_SKILLS_AUTOCOMPLETE'
);
export const getSkillsAutocomplete = (q) =>
  createApiAction(
    {
      method: 'GET',
      url: baseURL + `/skills?q=${q}`,
      headers: {
        'Content-Type': 'application/json',
      },
      params: { q },
    },
    GET_SKILLS_AUTOCOMPLETE
  );

export const SET_EDIT_CV = 'SET_EDIT_CV';

export const setEditCv = (editCv) => ({
  type: SET_EDIT_CV,
  editCv,
});

export const SET_CVS = 'SET_CVS';

export const setCvs = (cvs) => ({
  type: SET_CVS,
  cvs,
});

export const SET_LOADING = 'SET_LOADING';

export const setLoading = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const POST_FEEDBACK = createAsyncActionRequestTypes('POST_FEEDBACK');

export const postFeedback = (body) =>
  createApiAction(
    {
      method: 'POST',
      url: '/note',
      body,
      headers: {
        'Content-type': 'application/json',
      },
    },
    POST_FEEDBACK
  );
