export const measureTextWidth = (text, font = '16px Arial') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return context.measureText(text).width;
};

export const customSort = (a, b, query) => {
  if (a.toLowerCase() === query.toLowerCase()) {
    return -1;
  } else if (b.toLowerCase() === query.toLowerCase()) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
};

export const getWindowHeight = (rowHeights, len) => {
  let sum = 0;
  let count = 0;

  for (const key in rowHeights.current) {
    if (count >= Math.min(len, 5)) {
      break;
    }
    sum += rowHeights.current[key];
    count++;
  }
  
  return sum;
};
