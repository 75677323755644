import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const GET_AUTOCOMPLETE = createAsyncActionRequestTypes(
  'GET_AUTOCOMPLETE'
);

export const getAutocomplete = (term_type) =>
  createApiAction(
    {
      url: '/autocomplete?term_type=:term_type',
      params: {
        term_type,
      },
    },
    GET_AUTOCOMPLETE
  );

export const TOGGLE_CONFIRMATION = 'TOGGLE_CONFIRMATION';

export const toggleConfirmation = (isOpen, actions, title) => ({
  type: TOGGLE_CONFIRMATION,
  isOpen,
  actions,
  title,
});
