import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import { withFormik } from 'formik';
import * as Yup from 'yup';

import { updateMe } from 'src/user/redux/actions';
import { navigateIntl } from 'src/utils/history';
import {
  toggleCompleteCustomerRegModal,
  toggleCustomerModalLoading,
} from 'src/customer/redux/actions/test';
import { CUSTOMER_DASHBOARD_ROUTE } from 'src/customer/constants';
import { allLanguages } from 'src/intl/utils';
import { togglePrivacyPopup } from 'src/about/redux/actions';

import {
  // register,
  checkWebsite,
  checkCompanyName,
  checkPhone,
  checkLink,
} from 'src/customer/redux/actions';

import isURL from 'validator/lib/isURL';

import CompleteRegModal from './CompleteRegModal';

let oldWebsiteValue,
  lastWebsiteValid,
  oldCompanyName,
  lastCompanyValid,
  oldPhoneValue,
  lastPhoneValid;

const validationSchema = ({
  checkWebsite,
  checkCompanyName,
  checkPhone,
  checkLink,
}) =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('required')
      .test('company-name-exists', 'alreadyExists', (value) => {
        if (!value) return true;
        if (value === oldCompanyName) return lastCompanyValid;
        const debon = async (d) => {
          const res = await checkCompanyName({ name: d });
          return res?.error !== 'ALREADY_EXISTS';
        };
        const isValid = debon(value);

        oldCompanyName = value;
        lastCompanyValid = isValid;
        return isValid;
      }),
    web_site: Yup.string()
      .trim()
      .test('is-url-valid', 'invalid', (value) => {
        if (!value) return true;
        return isURL(value);
      })
      .test('is-url-active', 'notActual', (value) => {
        if (!value) return true;
        if (!isURL(value)) return true;
        const debon = async (d) => {
          const res = await checkLink({ website: d });
          return res?.error !== 'NOT_ACTUAL_LINK';
        };
        const isValid = debon(value);

        oldWebsiteValue = value;
        lastWebsiteValid = isValid;
        return isValid;
      })
      .test('website-exists', 'alreadyExists', (value) => {
        if (!value) return true;
        if (!isURL(value)) return true;
        if (value === oldWebsiteValue) return lastWebsiteValid;
        const debon = async (d) => {
          const res = await checkWebsite({ website: d });
          return res?.error !== 'ALREADY_EXISTS';
        };
        const isValid = debon(value);

        oldWebsiteValue = value;
        lastWebsiteValid = isValid;
        return isValid;
      })
      .required('required'),
    country: Yup.string().required('required'),
    state: Yup.string(),
    city: Yup.string().required('required'),
    industry: Yup.string(),
    contact_person: Yup.string().required('required'),
    contact_person_job_title: Yup.string().required('required'),
    contact_person_phone: Yup.string()
      .required('required')
      .matches(/^\+\d{8,}$/, 'invalid')
      .test('phone-exists', 'alreadyExists', (value) => {
        if (!value) return true;
        if (value.length < 9) return true;

        if (value === oldPhoneValue) return lastPhoneValid;

        const debon = async (d) => {
          const res = await checkPhone({ phone: d });
          return res?.error !== 'ALREADY_EXISTS';
        };

        const isValid = debon(value);

        oldPhoneValue = value;
        lastPhoneValid = isValid;
        return isValid;
      }),
    agreeToTerms: Yup.boolean().oneOf([true], 'required'),
  });

export default compose(
  withSnackbar,
  injectIntl,
  connect(
    (state) => ({
      loading: state.customer.test.loading, // TODO
      // isOpen: state.user.isCompleteRegModal,
      messages: state.locale.messages,
      language: state.locale.language,
      redirectBackUrl: state.router.location.pathname,
      query: state.router.location.query,
    }),
    {
      allLanguages,
      updateMe,
      toggleCompleteCustomerRegModal,
      toggleCustomerModalLoading,
      togglePrivacyPopup,
      checkWebsite,
      checkCompanyName,
      checkPhone,
      checkLink,
    },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      closeModalHandler: () => {
        navigateIntl(stateProps.redirectBackUrl);
        dispatchProps.toggleCompleteCustomerRegModal(false);
      },
    })
  ),
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      web_site: '',
      num_of_employees: '1',
      industry: '',
      contact_person: '',
      contact_person_phone: '',
      contact_person_job_title: '',
      country: '',
      state: '',
      city: '',
      agreeToTerms: false,
    }),
    displayName: 'CustomerSocialNetworkInfo',
    validationSchema,
    handleSubmit: async (
      val,
      { props: { toggleModalLoading, language, redirectBackUrl, query } }
    ) => {
      let trimmedValues = { ...val };

      // toggleModalLoading(true);
      const dev = process.env.NODE_ENV !== 'production';
      const href = `${dev ? process.env.SERVER_URL : ''}/api/customer/${
        query.loginType
      }?state=${JSON.stringify({
        ...trimmedValues,
        lang: allLanguages.en.language === language ? '' : language,
        redirectBackUrl,
        redirectSuccessUrl: CUSTOMER_DASHBOARD_ROUTE,
      })}${query.accessToken ? '&accessToken=' + query.accessToken : ''}${
        query.templateName ? '&templateName=' + query.templateName : ''
      }`;
      window.location.href = href;
    },
  })
)(CompleteRegModal);
