import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const CLEAR_DEPARTMENTS = 'CLEAR_DEPARTMENTS';
export const CREATE_OR_EDIT_DEPARTMENT = createAsyncActionRequestTypes(
  'CREATE_OR_EDIT_DEPARTMENT'
);
export const FETCH_CUSTOMER_DEPARTMENTS = createAsyncActionRequestTypes(
  'FETCH_CUSTOMER_DEPARTMENTS'
);
export const DELETE_CUSTOMER_DEPARTMENT = createAsyncActionRequestTypes(
  'DELETE_CUSTOMER_DEPARTMENT'
);

export const clearDepartments = () => ({
  type: CLEAR_DEPARTMENTS,
});

export const editOrCreateDepartment = (body) =>
  createApiAction(
    {
      method: body.id ? 'PATCH' : 'POST',
      url: '/departments',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    },
    CREATE_OR_EDIT_DEPARTMENT
  );

export const fetchDepartments = () =>
  createApiAction(
    {
      url: '/departments',
    },
    FETCH_CUSTOMER_DEPARTMENTS
  );

export const deleteDepartment = (departmentId) =>
  createApiAction(
    {
      method: 'DELETE',
      url: '/departments/:departmentId',
      params: {
        departmentId,
      },
    },
    DELETE_CUSTOMER_DEPARTMENT
  );
