import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const CREATE_OR_EDIT_DEPARTMENT = createAsyncActionRequestTypes(
  'CREATE_OR_EDIT_DEPARTMENT'
);
export const FETCH_CUSTOMER_DEPARTMENTS = createAsyncActionRequestTypes(
  'FETCH_CUSTOMER_DEPARTMENTS'
);

export const editOrCreateDepartment = (body) => {
  let methodType = body.id === 0 ? 'POST' : 'PATCH';
  return createApiAction(
    {
      method: methodType,
      url: '/departments',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    },
    CREATE_OR_EDIT_DEPARTMENT
  );
};

export const fetchDepartments = () =>
  createApiAction(
    {
      url: '/departments',
    },
    FETCH_CUSTOMER_DEPARTMENTS
  );
