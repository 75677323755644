import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ size, color, style = {} }) => (
  <SvgIcon
    viewBox='0 0 64 64'
    style={{
      height: size || '34px',
      width: size || '34px',
      ...style,
    }}
  >
    <g id='Layer_1'>
      <g>
        <g id='LINE_1_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m36.453 45.696c-.167 0-.336-.041-.491-.129-.48-.271-.65-.883-.378-1.363l3.121-5.518c.271-.48.882-.648 1.362-.379.48.271.65.883.378 1.363l-3.121 5.518c-.183.327-.522.508-.871.508z'
            />
          </g>
        </g>
        <g id='LINE_376_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m27.547 45.696c-.349 0-.688-.182-.871-.508l-3.039-5.373c-.272-.48-.103-1.09.378-1.361.479-.271 1.091-.104 1.362.377l3.039 5.373c.272.48.103 1.092-.378 1.363-.155.088-.324.129-.491.129z'
            />
          </g>
        </g>
        <g id='ARC_245_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m32.523 17.235c-.376 0-.735-.213-.906-.576-.234-.498-.02-1.094.48-1.33 1.478-.693 2.901-1.531 4.232-2.486.45-.322 1.073-.219 1.396.23.322.447.22 1.072-.229 1.395-1.43 1.027-2.96 1.926-4.549 2.672-.136.064-.281.095-.424.095z'
            />
          </g>
        </g>
        <g id='ARC_244_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m40.964 18.851c-2.712 0-5.319-.947-7.413-2.705-.424-.355-.479-.986-.123-1.408.354-.424.986-.479 1.408-.123 1.896 1.592 4.304 2.385 6.767 2.215.545-.029 1.026.381 1.063.932.036.551-.381 1.027-.933 1.064-.256.015-.513.025-.769.025z'
            />
          </g>
        </g>
        <g id='LWPOLYLINE_168_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m22.354 19.782c-.521 0-.96-.402-.997-.93-.038-.553.378-1.029.929-1.068l1.428-.098c2.89-.313 5.724-1.105 8.384-2.357.502-.234 1.096-.02 1.331.48.234.5.02 1.096-.479 1.33-2.862 1.346-5.91 2.199-9.059 2.539l-1.466.102c-.025.002-.048.002-.071.002z'
            />
          </g>
        </g>
        <g id='LINE_373_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m41.668 18.827c-.553 0-1-.447-1-1v-1.369c0-.553.447-1 1-1s1 .447 1 1v1.369c0 .553-.447 1-1 1z'
            />
          </g>
        </g>
        <g id='LINE_372_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m22.332 18.827c-.553 0-1-.447-1-1v-1.369c0-.553.447-1 1-1s1 .447 1 1v1.369c0 .553-.447 1-1 1z'
            />
          </g>
        </g>
        <g id='LWPOLYLINE_82_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m32.093 32.702c-2.562 0-5.135-1.244-7.096-3.596-2.938-3.523-3.615-8.07-3.665-11.264-.009-.553.433-1.008.984-1.016.567-.018 1.007.432 1.016.984.045 2.871.637 6.939 3.2 10.014 1.837 2.201 5.2 4.049 8.628 2.02 4.982-2.949 5.476-9.957 5.508-12.033.009-.553.47-1.002 1.016-.984.553.008.993.463.984 1.016-.098 6.246-2.523 11.375-6.488 13.723-1.292.765-2.688 1.136-4.087 1.136z'
            />
          </g>
        </g>
        <g id='ARC_243_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m41.668 17.458c-.003 0-.005 0-.008 0-.552-.004-.996-.455-.992-1.008.028-3.637-2.666-6.768-6.267-7.279-.547-.078-.928-.586-.85-1.131.078-.547.58-.934 1.131-.85 4.589.652 8.021 4.641 7.985 9.275-.003.55-.45.993-.999.993z'
            />
          </g>
        </g>
        <g id='ARC_242_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m34.543 9.181c-.044 0-.088-.004-.133-.01-1.6-.213-3.221-.213-4.82 0-.552.07-1.051-.311-1.123-.859-.073-.547.312-1.049.859-1.123 1.773-.236 3.574-.236 5.348 0 .548.074.933.576.859 1.123-.066.503-.496.869-.99.869z'
            />
          </g>
        </g>
        <g id='ARC_241_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m22.332 17.458c-.549 0-.996-.443-1-.992-.036-4.635 3.396-8.623 7.985-9.275.545-.084 1.053.303 1.131.85.078.545-.303 1.053-.85 1.131-3.601.512-6.295 3.643-6.267 7.279.004.553-.44 1.004-.992 1.008-.002-.001-.004-.001-.007-.001z'
            />
          </g>
        </g>
        <g id='ARC_226_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m10.725 50.124c-.02 0-.04 0-.06-.002-.552-.031-.973-.506-.939-1.057.117-1.988.288-4 .508-5.979.061-.549.556-.943 1.104-.885.549.063.944.557.884 1.105-.216 1.945-.384 3.922-.5 5.875-.032.533-.472.943-.997.943z'
            />
          </g>
        </g>
        <g id='ARC_225_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m53.275 50.124c-.525 0-.966-.41-.997-.941-.116-1.953-.284-3.93-.5-5.875-.061-.549.335-1.043.884-1.105.553-.059 1.044.336 1.104.885.22 1.979.391 3.99.508 5.979.033.551-.388 1.025-.939 1.057-.02 0-.04 0-.06 0z'
            />
          </g>
        </g>
        <g id='LINE_368_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m27.546 45.696c-.269 0-.537-.107-.734-.32-.375-.406-.35-1.039.056-1.414l4.454-4.117c.404-.375 1.037-.352 1.413.055.375.406.35 1.039-.056 1.414l-4.454 4.117c-.193.177-.436.265-.679.265z'
            />
          </g>
        </g>
        <g id='LINE_363_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m22.313 36.446c-.194 0-.392-.057-.563-.176-.456-.313-.572-.934-.261-1.389l1.537-2.246c.312-.455.937-.572 1.39-.26.456.313.572.934.261 1.389l-1.537 2.246c-.195.284-.508.436-.827.436z'
            />
          </g>
        </g>
        <g id='LINE_362_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m41.688 36.446c-.319 0-.633-.152-.826-.436l-1.537-2.246c-.312-.455-.195-1.076.261-1.389.455-.313 1.079-.195 1.39.26l1.537 2.246c.312.455.195 1.076-.261 1.389-.173.12-.37.176-.564.176z'
            />
          </g>
        </g>
        <g id='LINE_361_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m36.454 45.696c-.243 0-.486-.088-.679-.266l-4.454-4.117c-.405-.375-.431-1.008-.056-1.414s1.007-.43 1.413-.055l4.454 4.117c.405.375.431 1.008.056 1.414-.197.214-.465.321-.734.321z'
            />
          </g>
        </g>
        <g id='LINE_360_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m32 41.579c-.272 0-.544-.111-.741-.33-.371-.408-.34-1.041.07-1.412l8.149-7.377c.409-.373 1.042-.34 1.412.07.371.408.339 1.041-.07 1.412l-8.149 7.377c-.192.174-.432.26-.671.26z'
            />
          </g>
        </g>
        <g id='LINE_359_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m32 41.579c-.239 0-.479-.086-.671-.26l-8.149-7.377c-.409-.371-.441-1.004-.07-1.412.37-.408 1.003-.443 1.412-.07l8.149 7.377c.41.371.441 1.004.07 1.412-.197.219-.469.33-.741.33z'
            />
          </g>
        </g>
        <g id='LINE_358_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m46.252 37.292c-.061 0-.122-.004-.184-.016l-4.564-.848c-.543-.1-.901-.623-.801-1.166.102-.543.623-.904 1.166-.801l4.564.848c.543.1.901.623.801 1.166-.089.481-.509.817-.982.817z'
            />
          </g>
        </g>
        <g id='ARC_220_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m17.748 37.292c-.458 0-.87-.314-.975-.781-.121-.539.218-1.072.757-1.193 1.525-.344 3.086-.633 4.638-.861.548-.086 1.055.299 1.135.844.08.547-.297 1.055-.844 1.135-1.502.223-3.014.502-4.491.834-.073.014-.148.022-.22.022z'
            />
          </g>
        </g>
        <g id='ARC_219_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m11.229 44.196c-.045 0-.091-.002-.138-.01-.547-.074-.93-.58-.854-1.127.534-3.879 3.476-6.994 7.319-7.748.539-.109 1.067.246 1.174.789.106.541-.247 1.066-.789 1.174-3.005.59-5.305 3.025-5.724 6.059-.068.502-.496.863-.988.863z'
            />
          </g>
        </g>
        <g id='ARC_218_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m52.771 44.196c-.492 0-.921-.361-.989-.863-.419-3.033-2.719-5.469-5.724-6.059-.542-.107-.896-.633-.789-1.174.106-.543.633-.898 1.174-.789 3.844.754 6.785 3.869 7.319 7.748.076.547-.307 1.053-.854 1.127-.046.008-.092.01-.137.01z'
            />
          </g>
        </g>
        <g id='LINE_355_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m35.214 56.907c-.513 0-.949-.391-.995-.912l-1.193-13.459c-.049-.551.357-1.035.908-1.084.542-.051 1.036.357 1.084.908l1.193 13.459c.049.551-.357 1.035-.908 1.084-.03.004-.06.004-.089.004z'
            />
          </g>
        </g>
        <g id='LINE_354_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m28.786 56.907c-.029 0-.06 0-.089-.004-.551-.049-.957-.533-.908-1.084l1.193-13.459c.049-.551.543-.959 1.084-.908.551.049.957.533.908 1.084l-1.193 13.459c-.046.522-.482.912-.995.912z'
            />
          </g>
        </g>
        <g id='ARC_214_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m12.549 53.063c-.131 0-.263-.025-.39-.078-1.556-.66-2.534-2.234-2.434-3.92.032-.551.483-.963 1.058-.939.551.033.972.506.938 1.059-.05.842.439 1.629 1.217 1.959.509.215.746.803.531 1.311-.16.38-.531.608-.92.608z'
            />
          </g>
        </g>
        <g id='ARC_213_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m32 56.989c-6.769 0-13.536-1.336-19.841-4.004-.509-.215-.746-.803-.531-1.311.216-.512.807-.744 1.311-.531 12.113 5.129 26.01 5.129 38.123 0 .505-.213 1.095.02 1.311.531.215.508-.022 1.096-.531 1.311-6.306 2.668-13.073 4.004-19.842 4.004z'
            />
          </g>
        </g>
        <g id='ARC_212_'>
          <g>
            <path
              fill={color}
              strokeWidth='1'
              stroke={color}
              d='m51.451 53.063c-.389 0-.76-.229-.921-.609-.215-.508.022-1.096.531-1.311.777-.33 1.267-1.117 1.217-1.959-.033-.553.388-1.025.938-1.059.572-.023 1.025.389 1.058.939.101 1.686-.878 3.26-2.434 3.92-.126.054-.258.079-.389.079z'
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
