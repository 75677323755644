import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import about from 'src/about/redux/reducer';
import customer from 'src/customer/redux/reducer';
import input from 'src/input/redux/reducer';
import layout from 'src/layout/redux/reducer';
import jobs from 'src/jobs/redux/reducer';
import onboarding from 'src/onboarding/redux/reducer';
import payment from 'src/payment/redux/reducer';
import routerReducer from 'src/router/redux/reducer';
import user from 'src/user/redux/reducer';
import locale from 'src/intl/redux/reducer';
import cvBuilder from 'src/cv/redux/reducer';
import articles from 'src/articles/redux/reducer';
import top from 'src/top/redux/reducer';
import admin from 'src/admin/redux/reducer'

export default (history) =>
  combineReducers({
    about,
    customer,
    articles,
    input,
    layout,
    jobs,
    onboarding,
    user,
    payment,
    locale,
    cvBuilder,
    router: routerReducer(connectRouter(history)),
    top,
    admin
  });
