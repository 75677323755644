import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ isActive, color, height = 22, width = 18 }) => (
  <SvgIcon
    viewBox='0 0 18 22'
    style={{
      height,
      width,
    }}
  >
    <g id='shield' transform='translate(0.6601563 0.6601563)'>
      <path
        d='M7.7 19.8Q15.4 16.8474 15.4 12.9105L15.4 3.06842C11.9503 1.75614 7.7 0 7.7 0C7.7 0 3.44973 1.75614 0 3.06842L0 12.9105Q1.19209e-07 16.8474 7.7 19.8ZM12.6445 6.98891L11.7111 6.05553L6.989 10.778L4.23891 8.02771L3.30553 8.96109L6.98891 12.6445L12.6445 6.98891Z'
        id='Combined-Shape'
        fill={color ? color : isActive ? '#82BCFF' : '#FFF'}
        fillRule='evenodd'
        stroke='none'
      />
    </g>
  </SvgIcon>
);
