import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { initiateJobsFetch } from 'src/jobs/redux/actions';
import { toggleLandingModal } from 'src/about/redux/actions';
import { navigateIntl } from 'src/utils/history';

import Searchbar from './Searchbar';

export default compose(
  withRouter,
  connect(
    (state, props) => ({
      isAuthenticated: Boolean(state.user.profile.confirmed),
      query: queryString.parse(props.location.search),
      result: state.jobs.autocomplete?.result,
      bgId: state.layout.bgId,
      langCode: state.locale.language,
      isOnboardingOpen:
        state.onboarding.isLoginClientOpen ||
        state.onboarding.isLoginCustomerOpen ||
        state.onboarding.isRegModalOpen,
      messages: state.locale.messages,
    }),
    {
      toggleLandingModal,
      initiateJobsFetch,
    }
  ),
  injectIntl,
  withFormik({
    mapPropsToValues: ({
      query: {
        title = '',
        companyName = '',
        location = '',
        typeOfEmployment = '',
      },
    }) => ({
      title,
      companyName,
      location,
      typeOfEmployment,
    }),
    displayName: 'SearchForm',
    enableReinitialize: true,
    handleSubmit: async (
      val,
      {
        resetForm,
        props: {
          history: { push },
          enqueueSnackbar,
          initiateJobsFetch,
          location,
          messages,
          langCode,
        },
      }
    ) => {
      const currentParams = queryString.parse(location.search);
      const params = {
        ...val,
        saved: currentParams.saved,
        applied: currentParams.applied,
      };

      // trim string params
      for (const key in params) {
        if (typeof params[key] === 'string')
          params[key] = params[key].trim().toLowerCase();
      }

      initiateJobsFetch({ ...params, langCode });
      navigateIntl(
        `/?${queryString.stringify(params, { skipEmptyString: true })}`
      );
    },
  })
)(Searchbar);
