import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color, size }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    style={{
      height: size || '18px',
      width: size || '18px',
    }}
  >
    <path
      d="M16.403,9H14V7c0-1.032,0.084-1.682,1.563-1.682h0.868c0.552,0,1-0.448,1-1V3.064c0-0.523-0.401-0.97-0.923-1.005 C15.904,2.018,15.299,1.999,14.693,2C11.98,2,10,3.657,10,6.699V9H8c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1l2-0.001V21 c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v-8.003l2.174-0.001c0.508,0,0.935-0.381,0.993-0.886l0.229-1.996 C17.465,9.521,17.001,9,16.403,9z"
      fill={color || '#0041E5'}
    />
  </SvgIcon>
);
