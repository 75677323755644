import React from 'react';
import { FormattedMessage } from 'react-intl';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

import Contact from 'src/img/icons/Contact';
import Close from 'src/img/icons/Close';
import TextInput from 'src/input/components/TextInput';
import PhoneNumberInput from 'src/input/components/PhoneNumberInput';

import useStyles from './styles';

const ContactModal = ({
  open,
  toggleContactModal,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  loading,
  intl,
}) => {
  const classes = useStyles();
  const closeModal = () => {
    toggleContactModal(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.ellipse} />
          <div
            className={classes.closeIcon}
            tabIndex="0"
            onClick={closeModal}
            onKeyDown={(e) => e.keyCode === 13 && closeModal()}
          >
            <Close />
          </div>
          <div className={classes.content}>
            <Contact />
            <p className={classes.title}>
              <FormattedMessage id="about.p6" defaultMessage="Contact us" />
            </p>
            <p className={classes.subTitle}>
              <FormattedMessage
                id="contact.subTitle"
                defaultMessage="Got a question? We are here for you"
              />
            </p>
            <TextInput
              name="name"
              label={intl.formatMessage({
                id: 'inputText.name',
                defaultMessage: 'Name',
              })}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              className={classes.textField}
              intl={intl}
            />
            <TextInput
              name="email"
              label={intl.formatMessage({
                id: 'inputText.email',
                defaultMessage: 'Email',
              })}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              className={classes.textField}
              intl={intl}
            />
            <PhoneNumberInput
              name="phone"
              label={intl.formatMessage({
                id: 'inputText.phone',
                defaultMessage: 'Phone',
              })}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              value={values.phone}
              className={classes.textField}
              error={errors.phone}
              touched={touched.phone}
              intl={intl}
            />
            <TextInput
              name="message"
              label={intl.formatMessage({
                id: 'contact.placeholder',
                defaultMessage: 'Leave us a message',
              })}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              className={classes.textField}
              multiline
              rows="3"
              intl={intl}
            />
            <button
              className={classes.button}
              onClick={handleSubmit}
              type="submit"
            >
              {loading ? (
                <FormattedMessage
                  id="about.btnLoading"
                  defaultMessage="Loading..."
                />
              ) : (
                <FormattedMessage id="about.btnSend" defaultMessage="Send" />
              )}
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ContactModal;
