import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) => ({
  viewMoreSimilar: {
    textAlign: 'center',
    margin: '10px 0',
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
    fontWeight: 400,
    backgroundColor: palette.primary.main,
    color: 'white',
  },
  jobCard: {
    cursor: 'pointer',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 300,
    maxWidth: 500,
    padding: '10px 15px',
  },
  recommendedContainer: {
    border: '2px solid white',
    width: '100%',
    margin: 'auto',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > p': {
      marginTop: 15,
      width: '100%',
      textAlign: 'center',
      padding: 10,
      color: palette.text.secondary,
      fontSize: '20px',
      fontWeight: 'bold',
    },
  },
  containerColumn: {
    height: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    overflowY: 'none',
    '&::-webkit-scrollbar': { width: '9px' },
    '&::-webkit-scrollbar-track': { background: '#f6f6f6' },
    '&::-webkit-scrollbar-thumb': {
      background: '#cecece',
      borderRadius: 2,
    },
    '@media (max-width: 900px)': {
      overflowX: 'hidden',
      maxHeight: 'none',
      height: '100%',
    },
  },

  dNone: {
    display: 'none',
  },
  cvBanner: {
    height: 385,
    width: 248,
    borderRadius: 6,
    '@media (max-width: 540px)': {
      width: '100%',
      height: 'auto',
    },
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    borderRadius: '6px',
    height: '100%',
    backgroundColor: palette.background.paper,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  cvBannerWrapper: {
    width: '100%',
    alignItems: 'end',
    display: 'flex',
    '@media (max-width: 900px)': {
      '&': {
        alignItems: 'center',
      },
    },
  },
  date: {
    color: palette.text.disabled,
    maxWidth: '100%',
    fontWeight: 'normal',
    fontSize: '16px',
    display: 'flex',
  },
  content: {
    height: 690,
    width: '100%',
    padding: '30px 40px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 600px)': {
      '&': {
        height: 'auto',
        padding: '10px 20px',
      },
    },
  },
  shortDescContent: {
    padding: 0,
    height: 'auto',
  },
  heightNone: {
    height: 'auto',
    overflow: 'hidden',
  },
  imageTop: {
    width: '100%',
    borderRadius: '6px 6px 0 0',
    objectFit: 'cover',
    height: 100,
  },
  imageModal: {
    display: 'none',
  },
  descRow: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: 'auto',
    alignItems: 'flex-start',
    '@media (max-width: 1000px)': {
      flexWrap: 'wrap',
    },
  },
  titleCol: {
    marginRight: 10,
    display: 'flex',
    width: 'auto',
    height: 'auto',
    '@media (min-width: 1200px)': {
      minWidth: 350,
    },
  },
  detailsRow: {
    paddingTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '@media (max-width: 600px)': {
      padding: 0,
    },
  },
  detailsWLogo: {
    marginLeft: 95,
    '@media (max-width: 600px)': {
      marginLeft: 0,
    },
  },
  logoDiv: {
    minWidth: '60px',
    height: '60px',
    marginRight: '35px',
  },
  tabsDiv: {
    width: '100%',
  },
  tabsNav: {
    display: 'flex',
    marginBottom: 20,
  },
  tabsBlock: {
    display: 'flex',
    width: 'calc(100% - 248px)',
    paddingRight: 30,
    minWidth: '100px',
    marginBottom: 20,
    width: '100%',
  },
  tabsWLogo: {
    '@media (max-width: 1280px)': {
      width: '100%',
    },
  },
  sharedTabs: {
    width: '100%',
    '@media (max-width: 900px)': {
      width: '100%',
    },
  },
  jobInfo: {
    height: '100%',
    marginRight: 15,
    '& span': {
      display: 'flex',
      marginBottom: 5,
    },
  },
  jobInfoIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
  },
  companyLogo: {
    width: 60,
    height: 60,
  },
  senioritiesContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: '10px 0 0px 4px',
  },
  jobSeniorities: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
  },
  seniorityItem: {
    margin: '0 10px 10px 0',
    background: `rgb(100,112,120)`,
    background: `linear-gradient(90deg, rgba(100,112,120,1) 0%, rgba(121,135,144,1) 100%)`,
    color: '#f3f3f3',
    // color: '#545354',
    border: '1px solid #b0b0b0',
    padding: '2px 6px',
    fontSize: 14,
    fontWeight: 400,
    borderRadius: '.5rem',
    transition: 'all 0.1s ease-in',
  },
  companyName: {
    color: palette.text.secondary,
    fontSize: 22,
    fontWeight: 'normal',
  },
  companyNameLink: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  positionName: {
    display: 'block',
    color: palette.text.secondary,
    fontSize: '22px',
    fontWeight: 'bold',
    // cursor: 'pointer',
    // textDecoration: 'underline',
  },
  additionalInfo: {
    display: 'block',
    color: palette.text.disabled,
    fontSize: '18px',
    maxWidth: '100%',
    fontWeight: 'normal',
  },
  jobId: {
    display: 'block',
    color: palette.text.disabled,
    fontSize: '16px',
    maxWidth: '100%',
    fontWeight: 'normal',
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'end',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    minWidth: 180,
    width: 'auto',
    '@media (max-width: 1000px)': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      // overflowX: 'scroll',
      '& div, & a': {
        fontSize: 13,
        textTransform: 'uppercase',
        fontWeight: 500,
      },
    },
    '& a': {
      fontSize: 15,
      whiteSpace: 'nowrap',
      '@media (max-width: 900px)': {
        fontSize: 12,
      },
    },
  },
  buttonsLogo: {
    '@media (max-width: 900px)': {
      marginLeft: 95,
    },
    '@media (max-width: 500px)': {
      marginLeft: 0,
    },
  },

  displayNone: {
    display: 'none',
  },
  shareButton: {
    minWidth: 100,
    height: 40,
    width: 'auto',
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#00a085',
    backgroundColor: '#CCF3F5',
    margin: ' 5px 5px',
    '& p': {
      margin: 0,
    },
    '&:hover, &:focus': {
      boxShadow: '-3px 3px 7px 0 rgba(25,124,129,0.22)',
    },
  },
  backButton: {
    minWidth: 120,
    height: 40,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid ' + palette.primary.main,
    alignItems: 'center',
    fontWeight: 500,
    margin: ' 5px 5px',
    '& a': {
      margin: 0,
      color: palette.primary.main,
    },
    '&:hover, &:focus': {
      boxShadow: '-3px 3px 7px 0 rgba(25,124,129,0.22)',
    },
  },
  shareButtonPopoverOpen: {
    boxShadow: '-3px 0px 7px 0 rgba(25,124,129,0.22)',
  },
  saveButton: {
    backgroundColor: palette.secondary.light,
    color: palette.secondary.main,
    borderColor: palette.secondary.light,
    margin: '5px 5px',
    '&:hover, &:focus': {
      boxShadow: '-3px 3px 7px 0 rgba(255,110,110,0.22)',
    },
  },
  heartIcon: {
    marginRight: '10px',
    height: 19,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      marginRight: 5,
    },
  },

  button: {
    minWidth: 135,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    cursor: 'pointer',
    padding: 10,
    boxSizing: 'border-box',
    userSelect: 'none',
    textAlign: 'center',
    '@media (max-width: 900px)': {
      minWidth: 110,
      padding: '5px 15px',
      height: 30,
      minWidth: 100,
      '&:first-child': {
        margin: '5px 5px 5px 0',
      },
    },
  },
  buttonWithLogo: {
    marginLeft: 0,
  },
  linkText: {
    textDecoration: 'none',
    color: palette.text.white,
    '&:hover': {
      textDecoration: 'none',
      color: '#fff',
    },
    '&:not([href]), &:not([href]):hover': {
      color: '#fff',
    },
  },
  applyButton: {
    minWidth: 135,
    backgroundColor: palette.primary.main,
    color: palette.text.white,
    '&:hover, &:focus': {
      backgroundColor: '#0033b3',
      color: palette.text.white,
      boxShadow: '-3px 3px 7px 0 rgba(32,40,92,0.22)',
    },
    margin: ' 5px 5px',
  },
  navItem: {
    display: 'flex',
    alignItems: 'flex-end',
    color: palette.primary.dark,
    borderBottom: `2px ${palette.text.white} solid`,
    width: 'auto',
    height: '50px',
    padding: '10px 5px',
    cursor: 'pointer',
    fontWeight: '500',
    marginRight: 20,
    '&:hover': {
      color: palette.primary.main,
    },
    '&:focus': {
      color: palette.primary.main,
    },
  },
  activeNavItem: {
    color: palette.primary.main,
    borderBottom: `2px ${palette.primary.main} solid`,
  },
  disabledButton: {
    backgroundColor: palette.text.disabled,
    border: 'none',
    outline: 'none',
    color: palette.text.white,
    '&:hover, &:focus': {
      backgroundColor: palette.text.disabled,
    },
  },
  descLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      padding: '15px 40px',
      backgroundImage: 'linear-gradient(to right bottom, #2874a6, #32729c)',
      borderRadius: '1000px',
      color: 'white',
      transition: 'all 0.2s',
      border: 'none',
      display: 'inline-block',
      position: 'relative',
      fontWeight: '600',
      letterSpacing: '-1px',
      opacity: '0.8',
      marginTop: '20px',
    },
    '& button:hover': {
      transform: 'translateY(-3px)',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
      letterSpacing: '-1px',
    },
    '& button:active': {
      transform: 'translateY(-1px)',
      boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
      letterSpacing: '-1px',
    },
    '& button::after': {
      content: '',
      height: '100%',
      display: 'inline-block',
      width: '100%',
      borderRadius: '1000px',
      backgroundImage: 'linear-gradient(to right bottom, #2874a6, #32729c)',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: '-1',
      transition: 'all 0.4s',
    },
    '& button:hover::after': {
      transform: 'scaleX(1.4) scaleY(1.6)',
      opacity: 0,
    },
    '& a:hover, a:visited': {
      color: 'white',
      textDecoration: 'none',
    },
  },
  description: {
    alignSelf: 'flex-start',
    width: '100%',
    color: palette.text.primary,
    fontSize: '16px',
    overflowX: 'auto',
    overflowY: 'hidden',
    '& div': {
      maxWidth: '100%',
      // whiteSpace: 'break-spaces',
    },
    '& *': {
      fontSize: '16px !important',
      fontFamily: 'Assistant !important',
      color: palette.primary.dark,
      padding: 'revert',
      margin: 'revert',
    },
    '& *[lang="HE"]': {
      unicodeBidi: 'initial',
    },
  },
  summaryContainer: {
    position: 'relative',
    marginBottom: '20px',
    '&>div': {
      '&:first-child': {
        fontSize: 16,
        height: 60,
        overflow: 'hidden',
        borderRadius: 6,
        '@media (max-width: 600px)': {
          height: 'auto',
          maxHeight: 105,
        },
      },
    },
  },
  summaryShadow: {
    boxShadow: 'inset 0px -25px 20px -10px rgba(255,255,255,1)',
    bottom: 0,
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    height: 84,
  },
  expointJobDesc: {
    position: 'relative',
    color: palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    whiteSpace: 'break-spaces',
    '& ul, ol': {
      marginLeft: 18,
    },
  },
  dirRtl: {
    direction: 'rtl',
  },
  overviewItem: {
    marginBottom: '20px !important',
    '&:nth-last-child(-n+3)': {
      display: 'flex',
      alignItems: 'baseline',
      '& $overviewContent': {
        marginLeft: 5,
      },
    },
    '& a': {
      color: palette.primary.main,
      marginLeft: 5,
      '&:visited': {
        color: palette.text.primary,
      },
      '&:hover': {
        color: palette.primary.main,
      },
    },
  },
  overviewTitle: {
    fontWeight: 'bold',
  },
  overviewContent: {},
  banner: {
    height: 385,
    width: 248,
    borderRadius: 6,
    '@media (max-width: 540px)': {
      width: '100%',
      height: 'auto',
    },
  },
  viewMore: {
    transition: 'all 0.1s ease-in-out',
    maxWidth: 250,
    alignSelf: 'center',
    backgroundColor: '#20285C',
    '&:hover': {
      color: 'white',
      backgroundColor: '#2e3984',
    },
    // fontWeight: 500,
    // border: `2px solid ${palette.primary.main}`,
    '@media (max-width: 600px)': {
      width: '100%',
      height: 35,
      textTransform: 'uppercase',
      fontWeight: 500,
    },
  },
  closeContainer: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 40,
    '& p': {
      marginLeft: 3,
      marginTop: -2,
      color: palette.primary.main,
      fontWeight: '500',
    },
  },
  jobTitleLink: {
    color: 'inherit',
  },
  adsWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
}));
