import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ isSmall }) => (
  <SvgIcon
    viewBox="0 0 712 320"
    style={{
      height: '100%',
      width: '100%',
    }}
    preserveAspectRatio="none"
    transform={isSmall ? 'scale(-1 1)' : 'none'}
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Registration_step00"
        transform="translate(-604.000000, -230.000000)"
        fill="#D9EBFF"
      >
        <g id="Group" transform="translate(604.000000, 230.000000)">
          <path
            d="M337,300 C437,326.336263 543.082441,326.336263 655.247322,300 L712,283.090764 L712,6 C712,2.6862915 709.313708,-2.90304278e-14 706,-2.84217094e-14 L6,-2.84217094e-14 C2.6862915,-2.78129911e-14 -4.05812251e-16,2.6862915 0,6 L0,300 L0,300 C124.666667,273.663737 237,273.663737 337,300 Z"
            id="Path-8"
          ></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);
