import React, { useState } from 'react';
import clsx from 'clsx';
import { Form } from 'formik';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Close from 'src/img/icons/Close';
import Spinner from 'src/layout/Spinner';
import FBLogin from 'src/onboarding/components/FBLogin';
import LILogin from 'src/onboarding/components/LILogin';
import GoogleLogin from 'src/onboarding/components/GoogleLogin';
import BlueWave from 'src/img/icons/BlueWave';

import useStyles from './styles';

export default ({
  handleSubmit,
  steps = [],
  getStepContent,
  setFieldTouched,
  isSubmitting,
  validateForm,
  touched,
  errors,
  loading,
  showSocialLogin,
  type,
  ...rest
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const isBigWidth = useMediaQuery('(min-width:1400px)');
  const elementSize = isBigWidth ? 'medium' : 'small';
  const showSocials = activeStep === 0 && showSocialLogin;

  const classes = useStyles({ activeStep, steps });

  const handleNext = async () => {
    const { fields } = steps[activeStep];
    fields.forEach((f) => setFieldTouched(f, true, true));
    const formErrors = await validateForm();
    const stepErrors = Object.keys(formErrors).some((k) => fields.includes(k));
    if (stepErrors) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0 && rest.returnToLogin) {
      rest.returnToLogin();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const closeModal = () => {
    rest.toggleModal(false);
  };

  return (
    <div className={classes.root}>
      <div
        className={clsx(
          classes.blueWave,
          showSocials && classes.blueWaveSocials
        )}
      >
        <BlueWave isSmall />
      </div>
      <Form className={classes.form}>
        <div
          className={clsx(
            loading ? classes.progressBarLoading : classes.progressBar,
            activeStep === steps.length - 1 && classes.borderTopRadius
          )}
        ></div>
        <div className={classes.closeDiv}>
          <div
            className={classes.closeIcon}
            tabIndex='0'
            onClick={closeModal}
            onKeyDown={(e) => e.keyCode === 13 && closeModal()}
          >
            <Close />
          </div>
        </div>
        <div
          className={clsx(
            classes.iconDiv,
            showSocials && classes.iconDivSocials
          )}
        >
          <img
            alt=''
            className={classes.icon}
            src={require(`src/img/icons/onboarding${activeStep + 1}.svg`)}
          ></img>
        </div>
        {loading ? (
          <div className={classes.loadingDiv}>
            <Spinner isSmall />
          </div>
        ) : (
          <>
            {showSocials ? (
              <div className={classes.socialDiv}>
                <div className={classes.socialButtons}>
                <FBLogin customer={type === 'customer'} size={elementSize} />
                <LILogin customer={type === 'customer'} size={elementSize} />
                <GoogleLogin
                  customer={type === 'customer'}
                  size={elementSize}
                />
                </div>
                <div className={classes.dividerDiv}>
                  <div className={classes.dividerSide}></div>
                  <div className={classes.dividerText}>
                    <FormattedMessage id='mdlLogin.or' defaultMessage='Or' />
                  </div>
                  <div className={classes.dividerSide}></div>
                </div>
              </div>
            ) : null}
            <div
              className={clsx(
                classes.title,
                showSocials && classes.socialTitle
              )}
            >
              <FormattedMessage id={steps[activeStep]?.title} />
            </div>
            <div className={classes.instructions}>
              <div className={classes.w100}>{getStepContent(activeStep)}</div>
            </div>
            <div
              className={clsx(
                classes.buttons,
                activeStep === 2 && classes.cvFormButtons
              )}
            >
              {activeStep === 0 && !rest.returnToLogin ? (
                <div />
              ) : (
                <Button onClick={handleBack} className={classes.button}>
                  <FormattedMessage id='btns.back' defaultMessage='Back' />
                </Button>
              )}
              {activeStep !== steps.length - 1 ? (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleNext}
                  className={classes.button}
                >
                  <FormattedMessage id='btns.next' defaultMessage='Next' />
                </Button>
              ) : null}
              <Button
                variant='contained'
                color={rest.isValid ? 'primary' : 'secondary'}
                disabled={isSubmitting}
                onClick={handleSubmit}
                className={classes.button}
                style={{
                  display: activeStep === steps.length - 1 ? 'block' : 'none',
                }}
              >
                <FormattedMessage
                  id='btns.register'
                  defaultMessage='Register'
                />
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
