import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import CustomerHeader from 'src/layout/components/CustomerHeader';
import CustomerFooter from 'src/layout/components/CustomerFooter';
import Dashboard from 'src/customer/components/Dashboard';

export default ({ children }) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  return (
    <>
      <CustomerHeader />
      <Dashboard history={history}>{children}</Dashboard>
      <CustomerFooter />
    </>
  );
};
