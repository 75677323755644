import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import queryString from 'query-string';

import { navigateIntl } from 'src/utils/history';
import { initiateJobsFetch } from 'src/jobs/redux/actions';
import { getJobTitlesCategories, setJobCategory } from 'src/jobs/redux/actions';

import JobCategoriesDropdown from './JobCategoriesDropdown'

export default compose(
    withRouter,
    withSnackbar,
    connect(
      (state, props) => ({
        jobTitle: queryString.parse(props.location.search)?.title,
        data: state.jobs.autocomplete?.categories,
        selectedCategory: state.jobs.autocomplete.jobCategory,
        langCode: state.locale.language,
        categoriesList: state.jobs.autocomplete?.categories?.categoriesList || [],
      }),
      {
        initiateJobsFetch,
        setJobCategory,
        getJobTitlesCategories,
      },
      (stateProps, dispatchProps, parentProps) => ({
        ...stateProps,
        ...dispatchProps,
        ...parentProps,
        changeSelectedCategory: async (category) => {
          // save on redux the category selected
          dispatchProps.setJobCategory(category);
        },
        fetchCategories: async (title) => {
          dispatchProps.getJobTitlesCategories(title);
        },
        resetJobTitle: async () => {
          const params = queryString.parse(parentProps.location.search);
  
          // reset title and page
          ['page', 'title'].forEach((key) => delete params[key]);
  
          navigateIntl(
            `/?${queryString.stringify(params, {
              skipEmptyString: true,
              arrayFormat: 'comma',
            })}`
          );
          dispatchProps.initiateJobsFetch(params);
        },
      })
    ),
    injectIntl
  )(JobCategoriesDropdown);
  