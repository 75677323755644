import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, shadows }) => ({
  jobTitlesAutocompleteWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  autocomepleteMenu: {
    maxWidth: 310,
    width: '100%',
    position: 'relative',
    top: -5,
    padding: '5px 10px',
    textAlign: 'right',
    backgroundColor: 'gray',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  userTypeContainer: {
    border: '1px solid #CACACA',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginTop: 10,
    maxWidth: 310,
    width: '100%',
    padding: '15px 10px',
    paddingLeft: 15,
    paddingBottom: 10,
    '& label.Mui-focused': {
      color: `#20285C !important`,
    },
    '& span': {
      fontSize: 14,
    },
  },
  blueWave: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100px',
    zIndex: 0,
    minHeight: 130,
    maxHeight: 215,
  },
  error: {
    color: 'red',
    marginTop: 5,
    fontSize: 14,
  },
  typeLabel: {
    marginBottom: 10,
    fontSize: 16,
  },
  formGroup: {
    flexDirection: 'row',
  },
  checkbox: {
    marginLeft: 10,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
      {
        margin: 0,
        WebkitAppearance: 'none',
      },
  },
  title: {
    userSelect: 'none',
    fontSize: 18,
    fontWeight: '500',
    color: palette.primary.dark,
    textAlign: 'center',
    zIndex: 1,
    padding: 20,
    paddingTop: 15,
    paddingBottom: 0,
  },
  iconDiv: {
    userSelect: 'none',
    zIndex: 2,
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    marginTop: -40,
  },
  icon: {
    transform: 'translate(0, 5px)',
    userDrag: 'none',
    width: 130,
    height: 130,
  },
  modalContainer: {
    padding: '30px 15px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    maxWidth: 500,
    width: '90%',
    height: 'auto',
    backgroundColor: palette.background.paper,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 0',
    position: 'relative',
  },
  inputContainer: {
    width: '100%',
    maxWidth: '310px',
    height: '49px',
    marginTop: 20,
    border: `1px dashed ${palette.primary.medium}`,
    borderRadius: '6px',
    '&:focus': {
      border: `1px solid transparent`,
      boxShadow: `0 0 0 2px ${palette.primary.medium}`,
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    cursor: 'pointer',
  },
  errorInputContainer: {
    border: `1px dashed red`,
    marginBottom: 30,
  },
  fileName: {
    width: '230px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 10,
  },
  uploadTitle: {
    margin: 0,
  },
  input: {
    display: 'none',
  },
  size: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  error: {
    color: 'red',
    marginTop: 5,
    fontSize: 14,
  },
  closeModalButtonContainer: {
    transition: 'all .1s ease-in-out',
    position: 'absolute',
    top: 10,
    left: 10,
    cursor: 'pointer',
    borderRadius: 6,
    zIndex: 5,
    width: 34,
    height: 34,
    '&:hover': {
      backgroundColor: palette.primary.medium,
    },
  },
  buttonDiv: {
    width: '100%',
    maxWidth: 310,
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    border: 'none',
    borderRadius: '6px',
    height: 35,
    width: 160,
    backgroundColor: palette.primary.main,
    color: palette.text.white,
    marginTop: 10,
    fontSize: 16,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover, &:focus': {
      backgroundColor: palette.primary.main,
      boxShadow: '0 2px 10px 5px rgba(20,25,60,0.2)',
    },
  },
  textField: {
    width: '100%',
    maxWidth: 310,
    marginTop: 10,
    '& fieldset': {
      borderRadius: 6,
    },
  },
  textFieldErr: {
    marginTop: 35,
  },
  switchContainer: {
    width: '100%',
    maxWidth: 310,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    cursor: 'pointer',
  },
  publicDesc: {
    width: '100%',
    maxWidth: 310,
    fontSize: 14,
    color: palette.grey['500'],
  },
  spinnerDiv: {
    marginTop: 20,
  },
  termsCheckbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '3px',
    color: palette.primary.dark,
    marginTop: 5,
    width: '100%',
    maxWidth: 310,
  },
  termsLabel: {
    '& span': {
      fontWeight: '500',
      color: palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  termsError: {
    color: 'red',
    '& span': {
      color: 'red',
    },
  },
}));
