import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const REGISTER_CUSTOMER =
  createAsyncActionRequestTypes('REGISTER_CUSTOMER');
export const LOGIN_CUSTOMER = createAsyncActionRequestTypes('LOGIN_CUSTOMER');
export const DELETE_CUSTOMER = createAsyncActionRequestTypes('DELETE_CUSTOMER');
export const CHECK_WEBSITE = createAsyncActionRequestTypes('CHECK_WEBSITE');
export const CHECK_CONTACT_EMAIL = createAsyncActionRequestTypes(
  'CHECK_CONTACT_EMAIL'
);
export const CHECK_CONTACT_PHONE = createAsyncActionRequestTypes(
  'CHECK_CONTACT_PHONE'
);
export const CHECK_COMPANY_NAME =
  createAsyncActionRequestTypes('CHECK_COMPANY_NAME');
export const CHECK_LINK = createAsyncActionRequestTypes('CHECK_LINK');
export const LOG_OUT = 'LOG_OUT';

export const register = (d) =>
  createApiAction(
    {
      url: '/customer/register',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    REGISTER_CUSTOMER
  );

export const login = (d) =>
  createApiAction(
    {
      url: '/customer/login',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    LOGIN_CUSTOMER
  );

export const deleteMe = () =>
  createApiAction(
    {
      method: 'DELETE',
      url: '/customer',
    },
    DELETE_CUSTOMER
  );

export const logout = (data) => ({
  type: LOG_OUT,
  payload: data,
});

export const checkWebsite = (d) =>
  createApiAction(
    {
      url: '/customer/check-website',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    CHECK_WEBSITE
  );

export const checkEmail = (d) =>
  createApiAction(
    {
      url: '/customer/check-contact-person-email',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    CHECK_CONTACT_EMAIL
  );

export const checkPhone = (d) =>
  createApiAction(
    {
      url: '/customer/check-phone',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    CHECK_CONTACT_PHONE
  );

export const checkCompanyName = (d) =>
  createApiAction(
    {
      url: '/customer/check-company-name',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    CHECK_COMPANY_NAME
  );

export const checkLink = (d) =>
  createApiAction(
    {
      url: '/customer/check-link',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: d,
    },
    CHECK_LINK
  );
