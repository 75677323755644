import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { states } from 'src/Dashboards/AutoCompleteConst';
import ArrowDown from 'src/img/icons/ArrowDown';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    width: '100%',
    '& fieldset': {
      borderRadius: 6,
    },
  },
}));

export default ({
  name = 'state',
  values,
  errors,
  handleChange,
  setFieldValue,
  touched,
  className,
  variant,
  textInputClassName,
  intl,
  disabled,
}) => {
  const classes = useStyles();

  const onStateChange = (_, value) => {
    if (!value) return;

    setFieldValue(name, value.value);
  };

  const onStateInputChange = (_, value, reason) => {
    if (reason === 'clear') return setFieldValue('state', '');

    if (reason === 'input') return setFieldValue('state', value);
  };

  return values.country === 'United States' ? (
    <Autocomplete
      name={name}
      options={states}
      getOptionLabel={(option) => option.value || option}
      onChange={onStateChange}
      onInputChange={onStateInputChange}
      inputValue={values.state}
      getOptionSelected={({ label }) => {
        return label === values.state;
      }}
      popupIcon={<ArrowDown />}
      openOnFocus
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          error={touched.state && !!errors.state}
          helperText={
            touched.state && errors.state
              ? intl.formatMessage({
                  id: `errors.state.${errors.state}`,
                  defaultMessage: 'Error',
                })
              : ''
          }
          className={`${classes.autocomplete} ${textInputClassName}`}
          label={intl.formatMessage({
            id: 'chooseState',
            defaultMessage: 'State',
          })}
          variant="outlined"
        />
      )}
      className={className}
    />
  ) : (
    <TextField
      name={name}
      value={values.state || ''}
      onChange={handleChange}
      label={intl.formatMessage({ id: 'state', defaultMessage: 'State' })}
      error={touched.state && !!errors.state}
      helperText={
        touched.state && errors.state
          ? intl.formatMessage({
              id: `errors.state.${errors.state}`,
              defaultMessage: 'Error',
            })
          : ''
      }
      className={`${classes.autocomplete} ${className} ${textInputClassName}`}
      variant="outlined"
      fullWidth
      disabled={disabled}
    />
  );
};
