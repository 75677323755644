import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { toggleModal } from 'src/onboarding/redux/actions';
import { initiateJobsFetch } from 'src/jobs/redux/actions';

import CustomerLandingHeader from './CustomerLandingHeader';

export default compose(
  withRouter,
  connect(
    (state, props) => ({
      pathname: state.router.location.pathname,
      isLoginCustomerOpen:
        state.onboarding.isLoginCustomerOpen ||
        state.onboarding.isRegCustomerOpen,
      isCompleteRegModalOpen: queryString.parse(props.location.search)
        .completeRegistration,
    }),
    {
      toggleOnboardingModal: toggleModal,
      initiateJobsFetch,
    }
  )
)(CustomerLandingHeader);
