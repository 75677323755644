import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import { countries } from 'src/Dashboards/AutoCompleteConst';
import ArrowDown from 'src/img/icons/ArrowDown';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    width: '100%',
    '& fieldset': {
      borderRadius: 6,
    },
  },
  errorText: {
    marginLeft: 0,
    fontSize: 14,
  },
}));

export default ({
  name = 'country',
  values,
  errors,
  setFieldValue,
  touched,
  className,
  textInputClassName,
  intl,
  disabled,
}) => {
  const classes = useStyles();

  const onCountryChange = (event, value, reason) => {
    setFieldValue('state', '');
    setFieldValue('city', '');

    if (reason === 'clear') {
      setFieldValue(name, '');
    }
    if (value) {
      setFieldValue(name, value.label);
    }
  };

  return (
    <Autocomplete
      name={name}
      options={countries}
      value={values.country}
      getOptionLabel={(option) => option.label || option}
      getOptionSelected={({ label }) => label === values.country}
      onChange={onCountryChange}
      popupIcon={<ArrowDown color={disabled ? '#9A9DAE' : null} />}
      openOnFocus
      disabled={disabled}
      renderInput={(params) => {
        params.inputProps.autoComplete = 'new-country';
        return (
          <TextField
            {...params}
            error={touched.country && !!errors.country}
            helperText={
              touched.country && errors.country
                ? intl.formatMessage({
                    id: `errors.country.${errors.country}`,
                    defaultMessage: 'Error',
                  })
                : ''
            }
            className={`${classes.autocomplete} ${textInputClassName}`}
            label={intl.formatMessage({
              id: 'chooseCountry',
              defaultMessage: 'Country',
            })}
            variant="outlined"
            FormHelperTextProps={{
              className: classes.errorText,
            }}
          />
        );
      }}
      className={className}
    />
  );
};
