import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { CUSTOMER_DASHBOARD_ROUTE } from 'src/customer/constants';
import { allLanguages } from 'src/intl/utils';

const dev = process.env.NODE_ENV !== 'production';
const useStyles = makeStyles(({ palette }) => ({
  container: {
    height: '48px',
    width: 96,
    border: '1px solid #0041E5',
    borderRadius: '6px',
    backgroundColor: palette.background.paper,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    '&:focus': {
      backgroundColor: palette.primary.light,
    },
  },
  smallContainer: {
    height: 40,
    width: '25%',
  },
}));

export default ({
  customer,
  size,
  setLocalKey = () => {},
  language,
  browserLanguage,
  redirectBackUrl,
  templateName,
}) => {
  const classes = useStyles();

  const href = `${dev ? process.env.SERVER_URL : ''}/api/${
    customer ? 'customer' : 'user'
  }/google?redirectBackUrl=${redirectBackUrl}${
    customer ? `&redirectSuccessUrl=${CUSTOMER_DASHBOARD_ROUTE}` : ''
  }${
    redirectBackUrl.includes('cv-builder') && templateName
      ? '&templateName=' + templateName
      : ''
  }`;

  return (
    <a
      className={clsx(
        classes.container,
        size === 'small' && classes.smallContainer
      )}
      onClick={setLocalKey}
      href={href}
    >
      <img src={require('src/img/icons/google.svg')} alt='Google' />
    </a>
  );
};
