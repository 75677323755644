import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ArrowDown from 'src/img/icons/ArrowDown';
import PrivacyPopup from 'src/about/components/PrivacyPopup';
import UserInfoModal from 'src/users/components/UserInfoModal';
import PasswordChangeModal from 'src/users/components/PasswordChangeModal';
import { navigateIntl, getNavigationUrl } from 'src/utils/history';
import { USER_ROLES_MAP } from 'src/utils/constants';

import links from './dashboardLinks';
import useStyles from './styles';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
  >
    {value === index && <Box>{children}</Box>}
  </div>
);

export default ({
  children,
  history: {
    location: { pathname },
  },
  togglePrivacyPopup,
  intl,
  userRole,
  userLicense,
}) => {
  const classes = useStyles();

  const [candidatesOpen, setCandidatesOpen] = useState(false);
  const [footerOpen, setFooterOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:700px)');
  const tabPanelClass = pathname.includes('candidate')
    ? classes.tabPanelCandidates
    : classes.tabPanel;
  const activeLink =
    links.find((item) => getNavigationUrl(item.path).includes(pathname)) || {};

  const routeRestricted =
    userRole &&
    activeLink.conditionRoles &&
    !activeLink.conditionRoles.includes(userRole);

  const toggleFooter = () => setFooterOpen(!footerOpen);

  const isExpointAdmin = userRole === USER_ROLES_MAP.expointAdmin;

  useEffect(() => {
    pathname.endsWith('candidates') && setCandidatesOpen(true);
  }, [pathname, setCandidatesOpen]);

  const [value, setValue] = useState(
    links.findIndex(
      (e) => e.path.search(pathname) > -1 || pathname.search(e.path) > -1
    )
  );

  // for handling url change from outside this component
  useEffect(() => {
    const foundIndex = links.findIndex(
      (e) => e.path.search(pathname) > -1 || pathname.search(e.path) > -1
    );

    if (foundIndex !== value) {
      setValue(foundIndex);
    }
  }, [pathname, value]);

  const renderRestricted = () => (
    <div className={classes.restrictedContainer}>
      You are not authorized to access this page.
    </div>
  );

  const LabelComponent = ({
    isActive,
    isSubLink,
    renderIcon,
    className,
    label,
  }) => (
    <div
      className={clsx(
        classes.linkItem,
        isActive && classes.activeLink,
        isSubLink && classes.subLink,
        isSubLink && !candidatesOpen && classes.dNone
      )}
    >
      {renderIcon && !isSubLink ? (
        <div className={classes.linkIcon}>{renderIcon(isActive)}</div>
      ) : null}
      <p
        className={clsx(
          classes.linkLabel,
          isActive && classes.activeLinkLabel,
          isSubLink && classes.subLabel,
          classes[className]
        )}
      >
        <FormattedMessage id={label} defaultMessage={label} />
      </p>
    </div>
  );

  return (
    <div
      className={clsx(
        classes.root,
        pathname.includes('my-candidates') && classes.rootCandidates
      )}
    >
      <Tabs
        orientation={isSmallScreen ? 'horizontal' : 'vertical'}
        value={0}
        classes={{ indicator: classes.dNone, root: classes.tabs }}
        scrollButtons={isSmallScreen ? 'on' : 'off'}
        variant={isSmallScreen ? 'scrollable' : 'standard'}
      >
        {links.map((e, i) => {
          const isActive =
            e.path === pathname ||
            // pathname.includes(e.key) ||
            (e.key === 'my-candidates' &&
              pathname.endsWith('imported-candidates')) ||
            (e.key === 'candidates' &&
              pathname.endsWith('imported-candidates')) ||
            (e.key === 'candidates' && pathname.endsWith('candidates')) ||
            (e.key === 'insights' && pathname.endsWith('jobs-statistics')) ||
            (e.key === 'insights' &&
              pathname.endsWith('candidates-statistics'));
          const isSubLink =
            (e.subLink && userLicense === 1 && !isSmallScreen) ||
            (e.subLink && isExpointAdmin);
          if (!isExpointAdmin) {
            if (isSmallScreen && e.key === 'candidates') return null;
            if (
              (e.conditionRoles && !e.conditionRoles.includes(userRole)) ||
              (e.onlyStandard && userLicense !== 1)
            )
              return null;
          }
          return (
            <Tab
              key={i}
              onClick={() => {
                setCandidatesOpen(
                  e.key === 'candidates'
                    ? !candidatesOpen
                    : isSubLink
                    ? candidatesOpen
                    : null
                );
                e.path !== pathname && navigateIntl(e.path);
              }}
              className={clsx(
                classes.linkItem,
                isActive && classes.activeLink,
                isSubLink && classes.subLink,
                isSubLink && !candidatesOpen && classes.dNone
              )}
              icon={
                <LabelComponent
                  isActive={isActive}
                  renderIcon={e.renderIcon}
                  isSubLink={isSubLink}
                  className={e.className}
                  label={e.label}
                />
              }
              disableFocusRipple
              disableRipple
              wrapped
            />
          );
        })}
      </Tabs>
      <TabPanel className={tabPanelClass}>
        {routeRestricted ? renderRestricted() : children}
      </TabPanel>
      <div
        className={clsx(classes.footer, footerOpen && classes.footerOpen)}
        onClick={toggleFooter}
      >
        {!footerOpen ? <div className={classes.footerClosedDiv} /> : null}
        {footerOpen ? (
          <div className={classes.footerActions}>
            <div
              className={classes.footerLink}
              onClick={() => togglePrivacyPopup('customer', 'privacyPolicy')}
            >
              <FormattedMessage
                id='privacy.privacyPolicy'
                defaultMessage='Privacy policy'
              />
            </div>
            <div
              className={classes.footerLink}
              onClick={() => togglePrivacyPopup('customer', 'terms')}
            >
              <FormattedMessage
                id='privacyTerms.title.terms'
                defaultMessage='Terms of use'
              />
            </div>
          </div>
        ) : (
          <FormattedMessage
            id='dashboard.moreActions'
            defaultMessage='More actions'
          />
        )}
        <div
          className={clsx(
            classes.footerArrow,
            !footerOpen && classes.footerArrowClosed,
            footerOpen && classes.footerArrowOpen
          )}
        >
          <ArrowDown color='#fff' />
        </div>
      </div>
      <PrivacyPopup />
      <UserInfoModal />
      <PasswordChangeModal />
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'helmet.expointCustomer',
            defaultMessage: 'Expoint | Customer',
          })}
        </title>
      </Helmet>
    </div>
  );
};
