import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ isActive }) => (
  <SvgIcon
    viewBox="0 0 16 16"
    style={{
      height: '16px',
      width: '16px',
    }}
  >
    <g id="dashboard">
      <path
        d="M15.4 0.11C15.4 0.0492487 15.3508 0 15.29 0L0.11 0C0.0492487 0 0 0.0492487 0 0.11L0 15.29C0 15.3508 0.0492487 15.4 0.11 15.4L15.29 15.4L15.3328 15.3914C15.3723 15.3747 15.4 15.3356 15.4 15.29L15.4 0.11ZM4.1228 3.047L2.7214 3.047L2.7214 4.367L4.1228 4.367L4.1228 3.047ZM5.74991 3.047L12.5699 3.047L12.5699 4.367L5.74991 4.367L5.74991 3.047ZM4.1228 7.117L2.7214 7.117L2.7214 8.437L4.1228 8.437L4.1228 7.117ZM5.74991 7.117L12.5699 7.117L12.5699 8.437L5.74991 8.437L5.74991 7.117ZM4.1228 11.187L2.7214 11.187L2.7214 12.507L4.1228 12.507L4.1228 11.187ZM5.74991 11.187L12.5699 11.187L12.5699 12.507L5.74991 12.507L5.74991 11.187Z"
        id="Shape"
        fill={isActive ? '#82BCFF' : '#FFF'}
        fillRule="evenodd"
        stroke="none"
      />
    </g>
  </SvgIcon>
);
