import createReducer from 'src/utils/createReducer';

import { FETCH_CUSTOMERS_ALL } from 'src/admin/redux/actions';

const INITIAL_STATE = {
  customers: [],
  pages: 0,
  total: 0,
  loading: false,
  updateLoading: false,
  nextId: null,
};

export default createReducer(INITIAL_STATE, {
  [FETCH_CUSTOMERS_ALL.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_CUSTOMERS_ALL.SUCCESS]: (state, { data: { d, length, ...data } }) => ({
    ...state,
    customers: d,
    loading: false,
    ...data,
  }),
  [FETCH_CUSTOMERS_ALL.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
});
