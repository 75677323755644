import createReducer from 'src/utils/createReducer';
import { LOG_OUT } from 'src/customer/redux/actions';
import {
  FETCH_AUTOCOMPLETE_RESULTS,
  FETCH_CUSTOMER_JOBS_CANDIDATES,
  CLEAR_CANDIDATES,
  FETCH_IMPORTED_CANDIDATES,
  REFRESH_IMPORTED_CANDIDATES,
  TOGGLE_IMPORT_CANDIDATES_MODAL,
  UPDATE_UPLOADED_FILES,
  UPDATE_IMPORTED_CANDIDATES,
} from 'src/candidates/redux/actions';
import {
  POST_REVIEW,
  UPDATE_REVIEW,
  SET_CANDIDATE_RANK,
  SET_CANDIDATE_STATUS,
  GET_CANDIDATE,
  DELETE_CANDIDATE,
  // GET_CANDIDATE_CV,
  DELETE_REVIEW,
  UPDATE_IMPORTED_CANDIDATE,
  DELETE_IMPORTED_CANDIDATE,
} from 'src/candidates/redux/actions/candidate';
import { FETCH_JOB_NAMES } from 'src/customerJobs/redux/actions';

const INITIAL_STATE = {
  privateCandidates: {
    list: [],
    pages: 0,
    total: 0,
  },
  publicCandidates: {
    list: [],
    pages: 0,
    total: 0,
  },
  uploadedFiles: [],
  importedCandidates: {
    list: [],
    pages: 0,
    total: 0,
    continueRefreshing: false,
  },
  loading: false,
  reviewLoading: false,
  cvLoading: false,
  candidateLoading: false,
  statusLoading: false,
  autocomplete: {
    jobTitle: [],
    location: [],
    name: [],
    appliedJobTitle: [],
    globalSearch: [],
    loading: false,
  },
  importCandidatesModalOpen: false,
};

export default createReducer(INITIAL_STATE, {
  [FETCH_CUSTOMER_JOBS_CANDIDATES.REQUEST]: (state, { params }) =>
    params.table === 'private'
      ? {
          ...state,
          privateCandidates: {
            list: [],
            pages: 0,
          },
          loading: true,
        }
      : {
          ...state,
          publicCandidates: {
            list: [],
            pages: 0,
          },
          loading: true,
        },
  [FETCH_CUSTOMER_JOBS_CANDIDATES.SUCCESS]: (
    state,
    { params, data: { d, ...data } }
  ) =>
    params.table === 'private'
      ? {
          ...state,
          importedCandidates: {
            ...state.importedCandidates,
            list: d,
            ...data,
          },
          privateCandidates: {
            list: d,
            ...data,
          },
          loading: false,
        }
      : {
          ...state,
          importedCandidates: {
            ...state.importedCandidates,
            list: d,
            ...data,
          },
          publicCandidates: {
            list: d,
            ...data,
          },
          loading: false,
        },
  [FETCH_CUSTOMER_JOBS_CANDIDATES.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [FETCH_IMPORTED_CANDIDATES.REQUEST]: (state, { params }) => ({
    ...state,
    importedCandidates: {
      ...state.importedCandidates,
      list: [],
      pages: 0,
    },
    loading: true,
  }),
  [FETCH_IMPORTED_CANDIDATES.SUCCESS]: (state, { data: { d, ...data } }) => ({
    ...state,
    importedCandidates: {
      ...state.importedCandidates,
      list: d,
      ...data,
    },
    loading: false,
  }),
  [UPDATE_IMPORTED_CANDIDATES]: (state, { payload }) => {
    return {
      ...state,
      importedCandidates: {
        ...state.importedCandidates,
        list: payload,
        total: payload.length,
      },
    };
  },
  [REFRESH_IMPORTED_CANDIDATES.SUCCESS]: (state, { data: { d, ...data } }) => ({
    ...state,
    // loading: false,
    importedCandidates: {
      ...state.importedCandidates,
      list: d,
      total: d.length,
      ...data,
    },
  }),
  [FETCH_CUSTOMER_JOBS_CANDIDATES.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),

  [POST_REVIEW.REQUEST]: (state) => ({
    ...state,
    reviewLoading: true,
  }),
  [POST_REVIEW.SUCCESS]: (state, { data, params }) => ({
    ...state,
    reviewLoading: false,
    privateCandidates: {
      ...state.privateCandidates,
      list: state.privateCandidates.list.map((item) =>
        item.id === params.candidateId
          ? {
              ...item,
              reviews: [data, ...item.reviews],
            }
          : item
      ),
    },
  }),
  [POST_REVIEW.FAILURE]: (state) => ({
    ...state,
    reviewLoading: false,
  }),
  [UPDATE_REVIEW.REQUEST]: (state) => ({
    ...state,
    reviewLoading: true,
  }),
  [UPDATE_REVIEW.SUCCESS]: (
    state,
    { body: { message }, params: { reviewId, candidateId } }
  ) => ({
    ...state,
    reviewLoading: false,
    privateCandidates: {
      ...state.privateCandidates,
      list: state.privateCandidates.list.map((item) =>
        item.id === candidateId
          ? {
              ...item,
              reviews: (item.reviews || []).map((review) =>
                review.id === reviewId
                  ? { ...review, message: message }
                  : review
              ),
            }
          : item
      ),
    },
  }),
  [UPDATE_REVIEW.FAILURE]: (state) => ({
    ...state,
    reviewLoading: false,
  }),
  [SET_CANDIDATE_RANK.SUCCESS]: (
    state,
    { body: { rank }, params: { candidateId } }
  ) => ({
    ...state,
    privateCandidates: {
      ...state.privateCandidates,
      list: state.privateCandidates.list.map((e) =>
        e.id === candidateId
          ? {
              ...e,
              rank,
            }
          : e
      ),
    },
  }),
  [UPDATE_IMPORTED_CANDIDATE.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [UPDATE_IMPORTED_CANDIDATE.SUCCESS]: (
    state,
    { body, params: { candidateId } }
  ) => ({
    ...state,
    loading: false,
  }),
  [UPDATE_IMPORTED_CANDIDATE.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [DELETE_IMPORTED_CANDIDATE.REQUEST]: (state, req) => {
    const removeCandidateId = req?.params?.candidateId;
    return {
      ...state,
      importedCandidates: {
        ...state.importedCandidates,
        list: removeCandidateId
          ? state.importedCandidates?.list.filter(
              (candidate) => candidate.id != req?.params?.candidateId
            )
          : state.importedCandidates?.list,
        total: state.importedCandidates?.total - 1,
      },
    };
  },
  [UPDATE_UPLOADED_FILES]: (state, { payload }) => ({
    ...state,
    uploadedFiles: payload,
  }),
  [LOG_OUT]: () => INITIAL_STATE,
  [GET_CANDIDATE.REQUEST]: (state) => ({
    ...state,
    candidateLoading: true,
  }),
  [GET_CANDIDATE.SUCCESS]: (
    state,
    { data: { d }, params: { candidateId } }
  ) => ({
    ...state,
    privateCandidates: {
      ...state.privateCandidates,
      list: [
        ...state.privateCandidates.list,
        {
          ...d,
          id: candidateId,
        },
      ],
    },
    candidateLoading: false,
  }),
  [GET_CANDIDATE.FAILURE]: (state) => ({
    ...state,
    candidateLoading: false,
  }),
  // [GET_CANDIDATE_CV.REQUEST]: (state) => ({
  //   ...state,
  //   cvLoading: true,
  // }),
  // [GET_CANDIDATE_CV.SUCCESS]: (state, { data, params: { userId } }) => ({
  //   ...state,
  //   privateCandidates: {
  //     ...state.privateCandidates,
  //     list: state.privateCandidates.list.map((item) =>
  //       item.id === userId
  //         ? {
  //             ...item,
  //             cv: data,
  //           }
  //         : item
  //     ),
  //   },
  //   cvLoading: false,
  // }),
  // [GET_CANDIDATE_CV.FAILURE]: (state) => ({
  //   ...state,
  //   cvLoading: false,
  // }),
  [DELETE_CANDIDATE.SUCCESS]: (state, { params: { candidateId } }) => ({
    ...state,
    privateCandidates: {
      ...state.privateCandidates,
      list: state.privateCandidates.list.filter((e) => e.id !== candidateId),
    },
  }),
  [FETCH_AUTOCOMPLETE_RESULTS.REQUEST]: (state) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      loading: true,
    },
  }),
  [FETCH_AUTOCOMPLETE_RESULTS.SUCCESS]: (
    state,
    { data: { d }, params: { field } }
  ) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      loading: false,
      [field]: d,
    },
  }),
  [FETCH_AUTOCOMPLETE_RESULTS.FAILURE]: (state) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      loading: false,
    },
  }),
  [FETCH_JOB_NAMES.REQUEST]: (state) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      loading: true,
    },
  }),
  [FETCH_JOB_NAMES.SUCCESS]: (state, { data: { d } }) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      loading: false,
      appliedJobTitle: d.map((item) => ({
        id: item.id,
        appliedJobTitle: item.title,
      })),
    },
  }),
  [FETCH_JOB_NAMES.FAILURE]: (state) => ({
    ...state,
    autocomplete: {
      ...state.autocomplete,
      loading: false,
    },
  }),
  [SET_CANDIDATE_STATUS.REQUEST]: (state) => ({
    ...state,
    statusLoading: true,
  }),
  [SET_CANDIDATE_STATUS.SUCCESS]: (
    state,
    { body: { status }, params: { candidateId } }
  ) => ({
    ...state,
    statusLoading: false,
    privateCandidates: {
      ...state.privateCandidates,
      list: state.privateCandidates.list.map((e) =>
        e.id === candidateId
          ? {
              ...e,
              status,
            }
          : e
      ),
    },
  }),
  [SET_CANDIDATE_STATUS.FAILURE]: (state) => ({
    ...state,
    statusLoading: false,
  }),

  [DELETE_REVIEW.SUCCESS]: (state, { params }) => ({
    ...state,
    privateCandidates: {
      ...state.privateCandidates,
      list: state.privateCandidates.list.map((item) =>
        item.id === params.candidateId
          ? {
              ...item,
              reviews: (item.reviews || []).filter(
                (review) => review.id !== params.reviewId
              ),
            }
          : item
      ),
    },
  }),
  [CLEAR_CANDIDATES]: () => INITIAL_STATE,
  [TOGGLE_IMPORT_CANDIDATES_MODAL]: (state, { isOpen }) => ({
    ...state,
    importCandidatesModalOpen: isOpen,
  }),
});
