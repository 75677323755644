import createReducer from 'src/utils/createReducer';

import {
  FETCH_CUSTOMERS_AUTOCOMPLETE_RESULTS,
  CLEAR_CUSTOMERS_AUTOCOMPLETE,
} from 'src/admin/redux/actions';

const INITIAL_STATE = {
  customers: [],
};

export default createReducer(INITIAL_STATE, {
  [FETCH_CUSTOMERS_AUTOCOMPLETE_RESULTS.REQUEST]: (state) => ({
    ...state,
    // loading: true,
  }),
  [FETCH_CUSTOMERS_AUTOCOMPLETE_RESULTS.SUCCESS]: (
    state,
    { data: { d, ...data } }
  ) => ({
    ...state,
    customers: d,
    // loading: false,
    // ...d,
  }),
  [FETCH_CUSTOMERS_AUTOCOMPLETE_RESULTS.FAILURE]: (state) => ({
    ...state,
    // loading: false,
  }),
  [CLEAR_CUSTOMERS_AUTOCOMPLETE]: () => ({
    ...INITIAL_STATE,
  }),
});
