import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getNavigationUrl } from 'src/utils/history';
import { initiateJobsFetch } from 'src/jobs/redux/actions';
import { logOut } from 'src/user/redux/actions';
import { toggleInfoModal, togglePasswordModal } from 'src/users/redux/actions';
import { toggleModal } from 'src/onboarding/redux/actions';

// components
import LanguageSwitchButton from '../LanguageSwitchButton/LanguageSwitchButton';
import Fade from '@material-ui/core/Fade';
import OnboardingButtons from 'src/onboarding/components/Buttons';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';

//icons
import Job from 'src/img/icons/menu/Job';
import Departments from 'src/img/icons/menu/Departments';
import userIcon from 'src/img/userIcon.jpg';
import ArrowUp from 'src/img/icons/ArrowUp';
import BackArrow from 'src/img/icons/BackArrow';
import WageInterfaceIcon from 'src/img/icons/WageInterfaceIcon';
import AboutInterfaceIcon from 'src/img/icons/AboutInterfaceIcon';
import ArticleInterfaceIcon from 'src/img/icons/ArticleInterfaceIcon';
import InvestorInterfaceIcon from 'src/img/icons/InvestorInterfaceIcon';
import CVInterfaceIcon from 'src/img/icons/CVInterfaceIcon';
import BriefcaseInterfaceIcon from 'src/img/icons/BriefcaseInterfaceIcon';
import Recommended from 'src/img/icons/Recommended';
import Recruit from 'src/img/icons/Recruit';
import Profile from 'src/img/icons/Profile';
import Settings from 'src/img/icons/Settings';
import LogOut from 'src/img/icons/LogOut';
import Heart from 'src/img/icons/Heart';
import Apply from 'src/img/icons/Apply';
import Articles from 'src/img/icons/menu/Articles';
import License from 'src/img/icons/menu/License';

// css
import useStyles from './styles';
import clsx from 'clsx';

const HeaderModal = ({
  open,
  handleClose,
  LoginEmployerBtn = () => <></>,
  history,
  isAuthenticated,
  imgId = userIcon,
  companyName,
  userName,
  isCustomer,
  logOut,
  user,
  initiateJobsFetch,
  lang,
  isHebrew,
  jobLinks,
  analyticsItems,
  toggleInfoModal,
  togglePasswordModal,
  isCustomerLanding,
  toggleOnboardingModal,
  renderLandingMenu,
  pathname,
  intl,
}) => {
  const classes = useStyles();
  const [jobsOpen, setJobsOpen] = useState(false);
  const [careerOpen, setCareerOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(null);
  const [cvOpen, setCvOpen] = useState(false);
  const showRecommended = user.recommendedJobsCount !== 0;

  const goTo = (params) => {
    initiateJobsFetch(params || {});
    setCareerOpen(false);
    se(false);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    handleClose();
    setJobsOpen(false);
    setCareerOpen(false);
    setCvOpen(false);
    setSubmenuOpen(false);
  };

  const ProfileMenu = () => (
    <div className={classes.profileMenu}>
      <Link
        className={classes.profileMenuItem}
        to={isCustomer ? '#' : getNavigationUrl('/my-profile')}
        onClick={() => {
          isCustomer && toggleInfoModal(true);
          handleCloseModal();
        }}
      >
        <Profile />
        <p>
          <FormattedMessage id='mdlHeader.profile' defaultMessage='Profile' />
        </p>
      </Link>
      <Link
        className={classes.profileMenuItem}
        to={isCustomer ? '#' : getNavigationUrl('/user/settings')}
        onClick={() => {
          isCustomer && togglePasswordModal(true);
          handleCloseModal();
        }}
      >
        <Settings />
        <p>
          {isCustomer ? (
            <FormattedMessage
              id='profileMenu.changePassword'
              defaultMessage='Change password'
            />
          ) : (
            <FormattedMessage
              id='mdlHeader.settings'
              defaultMessage='Settings'
            />
          )}
        </p>
      </Link>
      <div
        className={classes.profileMenuItem}
        onClick={() => {
          logOut({ side: isCustomer ? 'customer' : 'user' });
          handleCloseModal();
        }}
      >
        <LogOut />
        <p>
          <FormattedMessage id='mdlHeader.logout' defaultMessage='Log out' />
        </p>
      </div>
    </div>
  );

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 0,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.topButtons}>
            <div
              className={classes.topButtonWrapper}
              onClick={() => {
                handleCloseModal();
              }}
            >
              <BackArrow
                size={20}
                color={'#011554'}
                style={{
                  ...(lang === 'en' ? { transform: 'rotate(180deg)' } : {}),
                }}
              />
            </div>
            {!isAuthenticated && (
              <div className={classes.topButtonWrapper} onClick={handleClose}>
                <Link
                  className={classes.customerLoginBtn}
                  onClick={() => {
                    setJobsOpen(false);
                    setCvOpen(false);
                    handleCloseModal();
                  }}
                  to={
                    isCustomer || isCustomerLanding
                      ? getNavigationUrl('/')
                      : getNavigationUrl('/customer')
                  }
                >
                  <Recruit
                    color={'white'}
                    size={20}
                    hidePersonIcon={isCustomer || isCustomerLanding}
                  />
                  {isCustomer || isCustomerLanding ? (
                    <FormattedMessage
                      id='customerLanding.findJobs'
                      defaultMessage='Find Jobs'
                    />
                  ) : (
                    <FormattedMessage
                      id='links.recruitment'
                      defaultMessage='Post a job'
                    />
                  )}
                </Link>
              </div>
            )}

            <LanguageSwitchButton />
          </div>

          {/* Profile Info */}
          {isAuthenticated && (
            <>
              <div className={classes.imageDiv}>
                <>
                  {imgId && (
                    <div className={classes.imageContainer}>
                      <img src={imgId} alt='User' className={classes.image} />
                    </div>
                  )}
                  <div className={classes.userName}>
                    {companyName || userName}
                    {user?.jobTitle && (
                      <p className={classes.jobTitle}>{user?.jobTitle}</p>
                    )}
                  </div>
                </>
              </div>
              <ProfileMenu />
            </>
          )}

          <div className={classes.body}>
            {/* MAIN MENU */}
            <div>
              {isCustomer || isCustomerLanding ? null : (
                <>
                  {/* Tech Jobs Dropdown */}
                  <div
                    className={clsx(
                      classes.navItemDropdown,
                      jobsOpen && classes.dropdownOpen
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      setJobsOpen(!jobsOpen);
                      setCvOpen(false);
                      setSubmenuOpen(null);
                    }}
                  >
                    <div
                      className={clsx(
                        classes.navItem,
                        jobsOpen && classes.navItemSelected
                      )}
                    >
                      <div className={classes.navItemIcons}>
                        <div className={classes.dropdownArrow}>
                          <ArrowUp
                            style={{
                              transition: 'all 0.1s ease-in',
                              transform: jobsOpen
                                ? 'rotate(180deg)'
                                : lang === 'en'
                                ? 'rotate(90deg)'
                                : 'rotate(270deg)',
                            }}
                          />
                        </div>
                        <div className={classes.navItemIcon}>
                          <BriefcaseInterfaceIcon color={'#3B71F5'} size={22} />
                        </div>
                      </div>
                      <Link
                        className={classes.underline}
                        to={getNavigationUrl('/')}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCloseModal();
                        }}
                      >
                        <FormattedMessage
                          id='links.hiTechJobs'
                          defaultMessage='Tech Jobs'
                        />
                      </Link>
                    </div>
                    {jobsOpen && (
                      <div className={classes.jobsMenu}>
                        {jobLinks.map((item, i) => (
                          <>
                            <div
                              key={i}
                              className={clsx(
                                classes.jobsMenuItem,
                                submenuOpen === i &&
                                  classes.jobsMenuItemSelected
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (item.children) {
                                  setSubmenuOpen(submenuOpen === i ? null : i);
                                } else {
                                  handleCloseModal();
                                }
                              }}
                            >
                              <div className={classes.jobsMenuIcons}>
                                {item.children ? (
                                  <>
                                    <ArrowUp
                                      style={{
                                        transition: 'all 0.1s ease-in',
                                        transform:
                                          submenuOpen === i
                                            ? 'rotate(180deg)'
                                            : lang === 'en'
                                            ? 'rotate(90deg)'
                                            : 'rotate(270deg)',
                                      }}
                                    />
                                    <p>{item.label}</p>
                                  </>
                                ) : (
                                  <Link
                                    className={clsx(
                                      classes.jobsSubmenuItem,
                                      classes.noMargin
                                    )}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCloseModal();
                                    }}
                                    to={getNavigationUrl(item.pathname)}
                                  >
                                    {item.label}
                                  </Link>
                                )}
                              </div>
                              {submenuOpen === i && (
                                <div className={classes.submenuContainer}>
                                  {item.children.map((child, id) => (
                                    <Link
                                      key={id}
                                      className={clsx(classes.jobsSubmenuItem)}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleCloseModal();
                                      }}
                                      to={
                                        child.pathname
                                          ? getNavigationUrl(child.pathname)
                                          : '#'
                                      }
                                    >
                                      <div className={classes.dot} />
                                      {child.label}
                                    </Link>
                                  ))}
                                </div>
                              )}
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* Wages Article */}
                  {isHebrew && (
                    <Link
                      className={classes.navItem}
                      to={getNavigationUrl('/articles/35')}
                      onClick={handleClose}
                    >
                      <div className={classes.navItemIcons}>
                        <div className={classes.navItemIcon}>
                          <WageInterfaceIcon color={'#3B71F5'} size={22} />
                        </div>
                      </div>
                      טבלאות שכר
                    </Link>
                  )}

                  {/* CV Builder */}
                  <div
                    className={clsx(
                      classes.navItemDropdown,
                      cvOpen && classes.dropdownOpen
                    )}
                    onClick={(e) => {
                      setCvOpen(!cvOpen);
                      setJobsOpen(false);
                    }}
                  >
                    <div
                      className={clsx(
                        classes.navItem,
                        cvOpen && classes.navItemSelected
                      )}
                    >
                      <div className={classes.navItemIcons}>
                        <div className={classes.dropdownArrow}>
                          <ArrowUp
                            style={{
                              transition: 'all 0.1s ease-in',
                              transform: cvOpen
                                ? 'rotate(180deg)'
                                : lang === 'en'
                                ? 'rotate(90deg)'
                                : 'rotate(270deg)',
                            }}
                          />
                        </div>
                        <div className={classes.navItemIcon}>
                          <CVInterfaceIcon color={'#3B71F5'} size={22} />
                        </div>
                      </div>
                      <Link
                        className={classes.underline}
                        to={getNavigationUrl('/cv-builder')}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCloseModal();
                        }}
                      >
                        <FormattedMessage
                          id='links.cvBuilder'
                          defaultMessage='CV Builder'
                        />
                      </Link>
                    </div>
                    {cvOpen && (
                      <div className={classes.jobsMenu}>
                        {isAuthenticated && (
                          <Link
                            className={clsx(
                              classes.jobsMenuItem,
                              classes.underline
                            )}
                            onClick={() => {
                              handleCloseModal();
                              setCvOpen(false);
                            }}
                            to={getNavigationUrl('/cv-builder/library')}
                          >
                            <FormattedMessage
                              id='cvBuilder.myCvLibrary'
                              defaultMessage='My CV library'
                            />
                          </Link>
                        )}
                        <Link
                          className={clsx(
                            classes.jobsMenuItem,
                            classes.underline
                          )}
                          onClick={() => {
                            handleCloseModal();
                            setCvOpen(false);
                          }}
                          to={getNavigationUrl('/cv-builder/cv-examples')}
                        >
                          <FormattedMessage
                            id='links.cvExamples'
                            defaultMessage='CV Examples'
                          />
                        </Link>
                        <Link
                          className={clsx(
                            classes.jobsMenuItem,
                            classes.underline
                          )}
                          onClick={() => {
                            handleCloseModal();
                            setCvOpen(false);
                          }}
                          to={getNavigationUrl('/cv-builder/templates')}
                        >
                          <FormattedMessage
                            id='cvBuilder.templates'
                            defaultMessage='CV Templates'
                          />
                        </Link>
                        <a
                          className={clsx(
                            classes.jobsMenuItem,
                            classes.underline
                          )}
                          onClick={() => {
                            handleCloseModal();
                          }}
                          href={
                            lang === 'en' ? '/he/cv-builder' : `/cv-builder`
                          }
                        >
                          <FormattedMessage
                            id='cvBuilder.langLink'
                            defaultMessage="Hebrew CV's"
                          />
                        </a>
                      </div>
                    )}
                  </div>

                  {/* Career Center */}
                  <div
                    className={clsx(
                      classes.navItemDropdown,
                      careerOpen && classes.dropdownOpen
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCareerOpen(!careerOpen);
                      setCvOpen(false);
                      setJobsOpen(false);
                    }}
                  >
                    <div
                      className={clsx(
                        classes.navItem,
                        careerOpen && classes.navItemSelected
                      )}
                    >
                      <div className={classes.navItemIcons}>
                        <div className={classes.dropdownArrow}>
                          <ArrowUp
                            style={{
                              transition: 'all 0.1s ease-in',
                              transform: careerOpen
                                ? 'rotate(180deg)'
                                : lang === 'en'
                                ? 'rotate(90deg)'
                                : 'rotate(270deg)',
                            }}
                          />
                        </div>
                        <div className={classes.navItemIcon}>
                          <InvestorInterfaceIcon
                            color={'#3B71F5'}
                            size={30}
                            style={{
                              ...(isHebrew
                                ? { transform: 'translateX(4px)' }
                                : { transform: 'translateX(-4px)' }),
                            }}
                          />
                        </div>
                      </div>
                      <Link
                        className={clsx(classes.underline, classes.translateX)}
                        to={getNavigationUrl('/analytics')}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCloseModal();
                        }}
                      >
                        <FormattedMessage
                          id='links.analytics'
                          defaultMessage='Career Center'
                        />
                      </Link>
                    </div>
                    {careerOpen && (
                      <div className={classes.jobsMenu}>
                        {analyticsItems.map((item, i) => (
                          <>
                            <div
                              key={i}
                              className={clsx(classes.jobsMenuItem)}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCloseModal();
                              }}
                            >
                              <div className={classes.jobsMenuIcons}>
                                <Link
                                  className={clsx(
                                    classes.jobsSubmenuItem,
                                    classes.noMargin
                                  )}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCloseModal();
                                  }}
                                  to={getNavigationUrl(item.pathname)}
                                >
                                  {item.label}
                                </Link>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* User logged links  */}
                  {isAuthenticated && (
                    <>
                      {/* Saved Jobs */}
                      <Link
                        className={classes.navItem}
                        to={getNavigationUrl('/?saved=1')}
                        onClick={handleClose}
                      >
                        <div className={classes.navItemIcons}>
                          <div className={classes.navItemIcon}>
                            <Heart strokeWidth={2} color={'#3B71F5'} />
                          </div>
                        </div>
                        <FormattedMessage
                          id='mdlHeader.savedJobs'
                          defaultMessage='Saved jobs'
                        />
                      </Link>

                      {/* Applied Jobs */}
                      <Link
                        className={classes.navItem}
                        onClick={() => goTo({ applied: 1 })}
                        to={getNavigationUrl('/?applied=1')}
                      >
                        <div className={classes.navItemIcons}>
                          <div className={classes.navItemIcon}>
                            <Apply />
                          </div>
                        </div>
                        <FormattedMessage
                          id='userDetails.appliedJobs'
                          defaultMessage='Applied jobs'
                        />
                      </Link>

                      {/* Recommended Jobs */}
                      {showRecommended && (
                        <Link
                          className={classes.navItem}
                          onClick={() => goTo({ recommended: 1 })}
                          to={getNavigationUrl('/?recommended=1')}
                        >
                          <div className={classes.navItemIcons}>
                            <div className={classes.navItemIcon}>
                              <Recommended size={20} />
                            </div>
                          </div>
                          <FormattedMessage
                            id='mdlHeader.recommendedJobs'
                            defaultMessage='Recommended jobs'
                          />
                        </Link>
                      )}
                    </>
                  )}
                </>
              )}

              {/* Customer landing menu */}
              {isCustomerLanding && (
                <>
                  <div
                    className={classes.navItem}
                    onClick={() => {
                      handleCloseModal(false);
                      toggleOnboardingModal(true, 'isRegCustomerOpen');
                    }}
                  >
                    <div className={classes.navItemIcons}>
                      <div className={classes.navItemIcon}>
                        <Job color={'#3B71F5'} size={20} />
                      </div>
                    </div>
                    <FormattedMessage
                      id='links.postJobs'
                      defaultMessage='Post a job'
                    />
                  </div>
                  <Link
                    className={classes.navItem}
                    to={getNavigationUrl('/customer')}
                    onClick={() => {
                      handleCloseModal(false);
                    }}
                  >
                    <div className={classes.navItemIcons}>
                      <div className={classes.navItemIcon}>
                        <Departments color={'#3B71F5'} size={20} />
                      </div>
                    </div>
                    <FormattedMessage
                      id='customerLanding.hub'
                      defaultMessage="Employer's hub"
                    />
                  </Link>
                  <Link
                    className={classes.navItem}
                    to={getNavigationUrl('/customer/plans')}
                    onClick={() => {
                      handleCloseModal(false);
                    }}
                  >
                    <div className={classes.navItemIcons}>
                      <div className={classes.navItemIcon}>
                        <License color={'#3B71F5'} />
                      </div>
                    </div>
                    <FormattedMessage
                      id='billing.plans'
                      defaultMessage='Plans'
                    />
                  </Link>
                </>
              )}

              {/* Articles */}
              <Link
                className={classes.navItem}
                onClick={() => {
                  handleCloseModal();
                }}
                to={getNavigationUrl(
                  isCustomer
                    ? '/customer/dashboard/articles'
                    : isCustomerLanding
                    ? '/customer/articles'
                    : '/articles'
                )}
              >
                <div className={classes.navItemIcons}>
                  <div className={classes.navItemIcon}>
                    {isCustomer || isCustomerLanding ? (
                      <Articles color={'#3B71F5'} size={22} />
                    ) : (
                      <ArticleInterfaceIcon color={'#3B71F5'} size={22} />
                    )}
                  </div>
                </div>
                <p>
                  <FormattedMessage
                    id='links.articles'
                    defaultMessage='Articles'
                  />
                </p>
              </Link>

              {/* About  */}
              {!isCustomer && !isCustomerLanding && (
                <Link
                  className={classes.navItem}
                  onClick={() => {
                    setJobsOpen(false);
                    setCvOpen(false);
                    handleCloseModal();
                  }}
                  to={getNavigationUrl('/about')}
                >
                  <div className={classes.navItemIcons}>
                    <div className={classes.navItemIcon}>
                      <AboutInterfaceIcon color={'#3B71F5'} size={22} />
                    </div>
                  </div>
                  <FormattedMessage id='links.about' defaultMessage='About' />
                </Link>
              )}
            </div>

            {/* User Onboarding Buttons  */}
            {!isAuthenticated &&
              (isCustomer || isCustomerLanding ? (
                <LoginEmployerBtn />
              ) : (
                <OnboardingButtons />
              ))}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default compose(
  connect(
    (state) => ({
      user: state.user.profile,
      userName:
        (state.user.profile?.firstName || '') +
        ' ' +
        (state.user.profile?.lastName || ''),
      companyName: state.customer.user.name,
      lang: state.locale.language,
      isHebrew: state.locale.language === 'he',
      pathname: state.router.location.pathname,
      imgId: state.user.profile.imgId || state.customer.user.logo,
    }),
    {
      logOut,
      initiateJobsFetch,
      toggleInfoModal,
      togglePasswordModal,
      toggleOnboardingModal: toggleModal,
    }
  ),
  withRouter
)(HeaderModal);
