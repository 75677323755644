import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
// import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { numberOfEmployees } from 'src/customer/constants';
import { cities } from 'src/Dashboards/AutoCompleteConst';
// import { TYPES_OF_EMPLOYMENT } from 'src/user/constants';
import Spinner from 'src/layout/Spinner';

import IndustryAutocomplete from 'src/input/components/IndustryAutocomplete';
import CountryAutocomplete from 'src/input/components/CountryAutocomplete';
import StateAutocomplete from 'src/input/components/StateAutocomplete';
import PhoneNumberInput from 'src/input/components/PhoneNumberInput';
import CityAutocomplete from 'src/input/components/CityAutocomplete';
import CheckboxInput from 'src/input/components/CheckboxInput';
import SelectInput from 'src/input/components/SelectInput';
import TextInput from 'src/input/components/TextInput';
import useStyles from './styles';

import BlueWave from 'src/img/icons/BlueWave';
import Close from 'src/img/icons/Close';

const isCompleteRegModal = ({
  closeModalHandler,
  values,
  errors,
  handleChange,
  setFieldValue,
  touched,
  handleBlur,
  handleSubmit,
  loading,
  intl,
  togglePrivacyPopup,
  setFieldTouched,
  // userJobTitles = [],
}) => {
  const classes = useStyles();
  const countryPlaceholder = intl.formatMessage({
    id: 'pleaseSelectCountry',
    defaultMessage: 'Please select a country',
  });
  const [citiesList, setCitiesList] = useState([countryPlaceholder]);

  useEffect(() => {
    setCitiesList(
      values.country ? cities[values.country] || [] : [countryPlaceholder]
    );
  }, [values.country]);

  return (
    <div className={classes.modalContainer}>
      <div className={clsx(classes.iconDiv)}>
        <img
          alt=''
          className={classes.icon}
          src={require(`src/img/icons/onboarding2.svg`)}
        ></img>
      </div>
      <div className={clsx(classes.blueWave)}>
        <BlueWave isSmall />
      </div>
      <div className={classes.title}>
        {loading ? (
          <FormattedMessage
            id='addCVModal.beingUpdated'
            defaultMessage='Data is being updated...'
          />
        ) : (
          <FormattedMessage
            id='completeRegModal.title'
            defaultMessage='Almost there, complete the form to signup'
          />
        )}
      </div>
      {loading ? (
        <div className={classes.spinnerDiv}>
          <Spinner isSmall />
        </div>
      ) : (
        <>
          <TextInput
            name='name'
            errorName='companyName'
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            label={intl.formatMessage({
              id: 'inputText.companyName',
              defaultMessage: 'Company name',
            })}
            touched={touched}
            errors={errors}
            className={classes.textField}
            intl={intl}
          />
          <TextInput
            name='web_site'
            errorName='website'
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            label={intl.formatMessage({
              id: 'inputText.website',
              defaultMessage: 'Website',
            })}
            touched={touched}
            errors={errors}
            className={classes.textField}
            intl={intl}
          />
          <FormControl
            variant='outlined'
            className={clsx(classes.dense, classes.textField)}
          >
            <InputLabel id='num_of_employees'>
              <FormattedMessage
                id='inputText.numOfEmployees'
                defaultMessage='Number of employees'
              />
            </InputLabel>
            <SelectInput
              labelId='num_of_employees'
              name='num_of_employees'
              value={values.num_of_employees}
              onChange={handleChange}
              displayEmpty
              labelWidth={150}
            >
              {numberOfEmployees.map((e, i) => (
                <MenuItem key={i} value={e.value} className={classes.menuItem}>
                  {e.label}
                </MenuItem>
              ))}
            </SelectInput>
          </FormControl>
          <IndustryAutocomplete
            values={values}
            errors={errors}
            touched={touched}
            className={clsx(classes.industryAutocomplete, classes.textField)}
            setFieldValue={setFieldValue}
            intl={intl}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <TextInput
            name='contact_person'
            errorName='contactPerson'
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            label={intl.formatMessage({
              id: 'inputText.contactPerson',
              defaultMessage: 'Your name',
            })}
            touched={touched}
            errors={errors}
            className={classes.textField}
            intl={intl}
          />
          <PhoneNumberInput
            name='contact_person_phone'
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            value={values.contact_person_phone}
            touched={touched.contact_person_phone}
            error={errors.contact_person_phone}
            className={clsx(classes.textField)}
            intl={intl}
          />
          <TextInput
            name='contact_person_job_title'
            errorName='jobTitle'
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            label={intl.formatMessage({
              id: 'inputText.contactPersonJobTitle',
              defaultMessage: 'Job title',
            })}
            touched={touched}
            errors={errors}
            className={classes.textField}
            intl={intl}
          />
          <CountryAutocomplete
            errors={errors}
            values={values}
            touched={touched}
            setFieldValue={setFieldValue}
            setCitiesList={setCitiesList}
            variant='outlined'
            textInputClassName={classes.autocomplete}
            className={clsx(classes.autocomplete, classes.textField)}
            intl={intl}
          />
          <StateAutocomplete
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            errors={errors}
            values={values}
            touched={touched}
            variant='outlined'
            textInputClassName={classes.autocomplete}
            className={clsx(classes.textField, classes.dense)}
            intl={intl}
          />
          <CityAutocomplete
            setFieldValue={setFieldValue}
            errors={errors}
            values={values}
            touched={touched}
            citiesList={citiesList}
            variant='outlined'
            textInputClassName={classes.autocomplete}
            className={clsx(classes.textField, classes.dense)}
            intl={intl}
          />
          <div
            className={clsx(
              classes.termsCheckbox,
              errors?.agreeToTerms &&
                touched?.agreeToTerms &&
                classes.termsError
            )}
          >
            <FormControlLabel
              control={
                <CheckboxInput
                  value={values.agreeToTerms}
                  onChange={handleChange}
                  name='agreeToTerms'
                  error={errors.agreeToTerms && touched.agreeToTerms}
                />
              }
              label={
                <div className={classes.termsLabel}>
                  <FormattedMessage
                    id='payment.iAgreeToTerms'
                    defaultMessage='I agree to '
                  />
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      togglePrivacyPopup('user', 'terms');
                    }}
                  >
                    <FormattedMessage
                      id='payment.termsAndConditions'
                      defaultMessage='Terms and Conditions'
                    />
                  </span>
                </div>
              }
            />
          </div>
          <div className={classes.buttonDiv}>
            <button
              onClick={handleSubmit}
              className={classes.button}
              type='submit'
            >
              <FormattedMessage
                id='btns.saveAndContinue'
                defaultMessage='Save and continue'
              />
            </button>
          </div>
        </>
      )}
      <div
        className={classes.closeModalButtonContainer}
        onClick={closeModalHandler}
      >
        <Close />
      </div>
    </div>
  );
};

export default isCompleteRegModal;
