import { putResolve, select } from 'redux-saga/effects';

import { getCards } from '../actions';

export function* getCardsSaga(params = {}) {
  const selectedCustomerId = yield select(
    (state) => state.admin.selectedCustomer.customerInfo.id
  );
  const role = yield select(
    (state) => state.customer.user?.currentLoggedUser?.role
  );
  if (selectedCustomerId && role === 'EXPOINT_ADMIN') {
    params.selectedCustomerId = selectedCustomerId;
  }
  return yield putResolve(getCards(params));
}
