import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextInput from 'src/input/components/TextInput';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Spinner from 'src/layout/Spinner';
import Close from 'src/img/icons/Close';
import { cities } from 'src/Dashboards/AutoCompleteConst';
import { TYPES_OF_EMPLOYMENT } from 'src/user/constants';
import CountryAutocomplete from 'src/input/components/CountryAutocomplete';
import UserJobTitlesAutocomplete from 'src/input/components/UserJobTitlesAutocomplete';
import BlueWave from 'src/img/icons/BlueWave';
import CityAutocomplete from 'src/input/components/CityAutocomplete';
import CheckboxInput from 'src/input/components/CheckboxInput';
import UserSeniorityLevelDropdown from 'src/input/components/UserSeniorityLevelDropdown';
import useStyles from './styles';

const isCompleteRegModal = ({
  closeModalHandler,
  values,
  errors,
  handleChange,
  setFieldValue,
  touched,
  handleBlur,
  handleSubmit,
  loading,
  fetchUserJobTitlesAutocomplete,
  intl,
  togglePrivacyPopup,
  userJobTitles = [],
}) => {
  const classes = useStyles();
  const countryPlaceholder = intl.formatMessage({
    id: 'pleaseSelectCountry',
    defaultMessage: 'Please select a country',
  });
  const [citiesList, setCitiesList] = useState([countryPlaceholder]);

  useEffect(() => {
    setCitiesList(
      values.country ? cities[values.country] || [] : [countryPlaceholder]
    );
  }, [values.country]);

  return (
    <div className={classes.modalContainer}>
      <div className={clsx(classes.iconDiv)}>
        <img
          alt=''
          className={classes.icon}
          src={require(`src/img/icons/onboarding2.svg`)}
        ></img>
      </div>
      <div className={clsx(classes.blueWave)}>
        <BlueWave isSmall />
      </div>
      <div className={classes.title}>
        {loading ? (
          <FormattedMessage
            id='addCVModal.beingUpdated'
            defaultMessage='Data is being updated...'
          />
        ) : (
          <FormattedMessage
            id='completeRegModal.title'
            defaultMessage='Almost there, complete the form to signup'
          />
        )}
      </div>
      {loading ? (
        <div className={classes.spinnerDiv}>
          <Spinner isSmall />
        </div>
      ) : (
        <>
          <UserJobTitlesAutocomplete
            key='jobTitle'
            updateList={fetchUserJobTitlesAutocomplete}
            options={userJobTitles}
            errors={errors}
            values={values}
            touched={touched}
            className={classes.textField}
            variant='outlined'
            setFieldValue={setFieldValue}
            intl={intl}
          />
          <CountryAutocomplete
            key='country'
            errors={errors}
            values={values}
            touched={touched}
            className={classes.textField}
            variant='outlined'
            setFieldValue={setFieldValue}
            intl={intl}
          />
          <CityAutocomplete
            key='city'
            errors={errors}
            values={values}
            touched={touched}
            citiesList={citiesList}
            className={classes.textField}
            variant='outlined'
            setFieldValue={setFieldValue}
            intl={intl}
          />
          <TextInput
            name='experienceTitle'
            errorName='experienceTitle'
            label={intl.formatMessage({
              id: 'inputText.yearsOfExp',
              defaultMessage: 'Years of experience',
            })}
            className={classes.textField}
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            touched={touched}
            errors={errors}
            intl={intl}
          />
          <UserSeniorityLevelDropdown
            key='seniority'
            errors={errors}
            values={values}
            touched={touched}
            className={classes.textField}
            variant='outlined'
            setFieldValue={setFieldValue}
          />
          <div className={classes.userTypeContainer}>
            <FormControl>
              <FormLabel className={classes.typeLabel}>
                <FormattedMessage
                  id='userDetails.areYou'
                  defaultMessage='Looking for a job as:'
                />
              </FormLabel>
              <FormGroup className={classes.formGroup}>
                {TYPES_OF_EMPLOYMENT.map((item) => (
                  <FormControlLabel
                    name={item.type}
                    key={item.type}
                    control={
                      <CheckboxInput
                        value={values.typeOfEmployment.includes(item.type)}
                        onChange={(e) => {
                          setFieldValue(
                            'typeOfEmployment',
                            values.typeOfEmployment.includes(item.type)
                              ? values.typeOfEmployment.filter(
                                  (i) => i !== item.type
                                )
                              : [...values.typeOfEmployment, item.type]
                          );
                        }}
                        name={item.type}
                      />
                    }
                    label={intl.formatMessage({
                      id: item.labelId,
                      defaultMessage: 'Employment type',
                    })}
                    className={classes.checkbox}
                  />
                ))}
              </FormGroup>
              {errors.typeOfEmployment ? (
                <FormHelperText className={classes.error}>
                  <FormattedMessage
                    id='errors.atLeast1'
                    defaultMessage='You have to choose at least 1'
                  />
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>
          <div
            className={clsx(
              classes.termsCheckbox,
              errors?.agreeToTerms &&
                touched?.agreeToTerms &&
                classes.termsError
            )}
          >
            <FormControlLabel
              control={
                <CheckboxInput
                  value={values?.agreeToTerms}
                  onChange={handleChange}
                  name='agreeToTerms'
                  error={errors.agreeToTerms && touched.agreeToTerms}
                />
              }
              label={
                <div className={classes.termsLabel}>
                  <FormattedMessage
                    id='payment.iAgreeToTerms'
                    defaultMessage='I agree to '
                  />
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      togglePrivacyPopup('user', 'terms');
                    }}
                  >
                    <FormattedMessage
                      id='payment.termsAndConditions'
                      defaultMessage='Terms and Conditions'
                    />
                  </span>
                </div>
              }
            />
          </div>
          <div className={classes.buttonDiv}>
            <button
              onClick={handleSubmit}
              className={classes.button}
              type='submit'
            >
              <FormattedMessage
                id='btns.saveAndContinue'
                defaultMessage='Save and continue'
              />
            </button>
          </div>
        </>
      )}
      <div
        className={classes.closeModalButtonContainer}
        onClick={closeModalHandler}
      >
        <Close />
      </div>
    </div>
  );
};

export default isCompleteRegModal;
