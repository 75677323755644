import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default () => (
  <SvgIcon
    viewBox="0 0 210 210"
    style={{
      // height: '210px',
      // width: '210px',
      height: '150px',
      width: '150px',
    }}
  >
    <defs>
      <rect id="path-1" x="0" y="0" width="712" height="720" rx="6"></rect>
    </defs>
    <g
      id="Builder--DT"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="CV-builder--Contact-us"
        transform="translate(-855.000000, -184.000000)"
      >
        <g id="Group-4" transform="translate(604.000000, 180.000000)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <g id="Illustration-/-sign-up-/-00" mask="url(#mask-2)">
            <g transform="translate(251.000000, 4.000000)">
              <g id="Group-3" transform="translate(29.150097, 31.810878)">
                <g id="Group-2">
                  <g id="Group" transform="translate(12.905903, 10.889122)">
                    <circle
                      id="Oval"
                      fill="#FFFFFF"
                      cx="60.9560003"
                      cy="77.0560003"
                      r="56.0560003"
                    ></circle>
                    <g id="Combined-Shape">
                      <mask id="mask-4" fill="white">
                        <use xlinkHref="#path-3"></use>
                      </mask>
                      <g id="Mask"></g>
                    </g>
                    <circle
                      id="Oval"
                      stroke="#0041E5"
                      strokeWidth="1.6799999"
                      cx="60.9560003"
                      cy="65.8560003"
                      r="55.2160003"
                    ></circle>
                  </g>
                  <g
                    id="Group-7"
                    transform="translate(15.427203, 146.731687) scale(-1, -1) translate(-15.427203, -146.731687) translate(0.000000, 124.569750)"
                  >
                    <polygon
                      id="Rectangle"
                      fill="#82BCFF"
                      transform="translate(14.617300, 38.683904) rotate(-315.000000) translate(-14.617300, -38.683904) "
                      points="10.629239 34.6958429 18.6053609 34.6958429 18.6053609 42.6719649 10.629239 42.6719649"
                    ></polygon>
                    <path
                      d="M3.22283997,0.943947972 C4.48143726,0.943947972 5.50173196,1.96424267 5.50173196,3.22283997 C5.50173196,4.48143726 4.48143726,5.50173196 3.22283997,5.50173196 C1.96424267,5.50173196 0.943947972,4.48143726 0.943947972,3.22283997 C0.943947972,1.96424267 1.96424267,0.943947972 3.22283997,0.943947972 Z"
                      id="Rectangle-Copy-14"
                      fill="#0041E5"
                      transform="translate(3.222840, 3.222840) rotate(-315.000000) translate(-3.222840, -3.222840) "
                    ></path>
                    <line
                      x1="30.8544054"
                      y1="5.92483144"
                      x2="20.0296684"
                      y2="15.6101224"
                      id="Line"
                      stroke="#FF6E7E"
                      strokeWidth="2.35199986"
                      fill="#FF6E7E"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                    <line
                      x1="19.4599454"
                      y1="7.06427744"
                      x2="8.63520846"
                      y2="16.7495684"
                      id="Line-Copy-3"
                      stroke="#FFFFFF"
                      strokeWidth="2.35199986"
                      fill="#FF6E7E"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                  </g>
                  <g
                    id="Group-6-Copy"
                    transform="translate(133.200293, 10.838785) scale(1, -1) rotate(-90.000000) translate(-133.200293, -10.838785) translate(122.361508, -7.000727)"
                  >
                    <path
                      d="M6.96346588,2.37549819 L9.25745041,2.37549819 C11.791315,2.37549819 13.8454181,4.4296013 13.8454181,6.96346588 L13.8454181,9.25745041 C13.8454181,11.791315 11.791315,13.8454181 9.25745041,13.8454181 L6.96346588,13.8454181 C4.4296013,13.8454181 2.37549819,11.791315 2.37549819,9.25745041 L2.37549819,6.96346588 C2.37549819,4.4296013 4.4296013,2.37549819 6.96346588,2.37549819 Z"
                      id="Rectangle-Copy-12"
                      fill="#FFFFFF"
                      transform="translate(8.110458, 8.110458) rotate(-315.000000) translate(-8.110458, -8.110458) "
                    ></path>
                    <path
                      d="M8.11045801,28.756314 L9.25745028,28.756314 C10.5243826,28.756314 11.5514341,29.7833655 11.5514341,31.0502978 L11.5514341,32.1972901 C11.5514341,33.4642224 10.5243826,34.4912739 9.25745028,34.4912739 L8.11045801,34.4912739 C6.84352572,34.4912739 5.81647417,33.4642224 5.81647417,32.1972901 L5.81647417,31.0502978 C5.81647417,29.7833655 6.84352572,28.756314 8.11045801,28.756314 Z"
                      id="Rectangle-Copy-15"
                      fill="#0041E5"
                      transform="translate(8.683954, 31.623794) rotate(-315.000000) translate(-8.683954, -31.623794) "
                    ></path>
                    <path
                      d="M17.9745892,19.5803781 L18.892183,19.5803781 C19.9057288,19.5803781 20.72737,20.4020193 20.72737,21.4155651 L20.72737,22.3331589 C20.72737,23.3467048 19.9057288,24.168346 18.892183,24.168346 L17.9745892,24.168346 C16.9610433,24.168346 16.1394021,23.3467048 16.1394021,22.3331589 L16.1394021,21.4155651 C16.1394021,20.4020193 16.9610433,19.5803781 17.9745892,19.5803781 Z"
                      id="Rectangle-Copy-13"
                      fill="#80F1F9"
                      transform="translate(18.433386, 21.874362) rotate(-315.000000) translate(-18.433386, -21.874362) "
                    ></path>
                  </g>
                </g>
                <path
                  d="M64.973074,132.675615 C65.0323601,133.73816 66.3211154,134.230292 67.0739925,133.477416 L68.0686191,132.482792 C68.5498158,132.001596 70.3103484,131.020993 70.7909695,131.502189 L77.9522068,138.663402 C78.4334035,139.144598 79.2133335,139.144598 79.6939546,138.663402 L86.0241827,132.333189 C86.5053794,131.851994 86.5053794,131.072065 86.0241827,130.591445 L78.8629453,123.430232 C78.3817487,122.949036 79.3623512,121.188505 79.8435479,120.707884 L80.8381745,119.71326 C81.5910516,118.960384 81.0989187,117.672207 80.0363719,117.612345 L65.4554218,116.796154 C64.7221149,116.754711 64.1160143,117.360811 64.1568815,118.094692 L64.973074,132.675615 Z"
                  id="Path"
                  fill="#FFB5B5"
                  fillRule="nonzero"
                  transform="translate(75.270004, 127.909223) rotate(-315.000000) translate(-75.270004, -127.909223) "
                ></path>
              </g>
              <g id="mail" transform="translate(77.000000, 85.400000)">
                <path
                  d="M5.46,0 L49.14,0 C52.143,0 54.6,2.44125 54.6,5.425 L54.6,37.975 C54.6,40.95875 52.143,43.4 49.14,43.4 L5.46,43.4 C2.457,43.4 0,40.95875 0,37.975 L0,5.425 C0,2.44125 2.457,0 5.46,0 Z"
                  id="Path"
                  fill="#82BCFF"
                ></path>
                <path
                  d="M54.6,5.425 L28.8987646,23.3005387 C27.9377179,23.9689591 26.6622821,23.9689591 25.7012354,23.3005387 L0,5.425 L0,5.425 L0,5.0399997 C-3.4088227e-16,2.25648473 2.25648473,5.11323405e-16 5.0399997,0 L49.5600003,0 C52.3435153,-5.11323405e-16 54.6,2.25648473 54.6,5.0399997 L54.6,5.425 L54.6,5.425 Z"
                  id="Path"
                  fill="#80F1F9"
                ></path>
                <path
                  d="M53.9,5.6 L29.2472134,22.4982575 C28.0767077,23.3005808 26.5232923,23.3005808 25.3527866,22.4982575 L0.7,5.6"
                  id="Path"
                  stroke="#0041E5"
                  strokeWidth="1.6799999"
                  fill="#80F1F9"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
