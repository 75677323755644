import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import { VariableSizeList as List } from 'react-window';
import clsx from 'clsx';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import CheckboxInput from 'src/input/components/CheckboxInput';
import FilterItem from '../JobFilters/FilterItem';
import useStyles from './styles';
import { capFirstLetters } from 'src/intl/utils';
import { states } from 'src/Dashboards/AutoCompleteConst';

import {
  DEFAULT_ITEM_HEIGHT,
  DEFAULT_SEARCH_HEIGHT,
  MAX_ITEM_SIZE_STATE,
} from '../../utils/constants';
import {
  measureTextWidth,
  customSort,
  getWindowHeight,
} from '../../utils/functions';

const AsyncTypeahead = withAsync(Typeahead);

export default ({
  handleSubmit,
  intl,
  langCode,
  locationQuery: { state: stateQuery = '', country },
  dropdownMenu = false,
  openFilter,
  setOpenFilter,
}) => {
  const classes = useStyles();
  const listRef = useRef({});
  const rowHeights = useRef({});
  const anchorRef = useRef(null);
  const isMenuOpen = openFilter === 'state';
  const isFilterShown =
    country === 'united states' || (langCode === 'en' && !country);
  // const [filteredStates, setFilteredStates] = useState(states);
  const [searchState, setSearchState] = useState('');
  const [textWidths, setTextWidths] = useState([]);
  const [windowHeight, setWindowHeight] = useState(DEFAULT_SEARCH_HEIGHT);

  const filteredStates = useMemo(() => {
    return states
      .filter((state) =>
        state.value.toLowerCase().includes(searchState.toLowerCase())
      )
      .sort(({ value: a }, { value: b }) => customSort(a, b, stateQuery));
  }, [searchState, stateQuery]);

  const itemSize = useCallback(
    (index) => {
      return textWidths[index] > MAX_ITEM_SIZE_STATE
        ? DEFAULT_ITEM_HEIGHT * 1.5
        : DEFAULT_ITEM_HEIGHT;
    },
    [textWidths]
  );

  useEffect(() => {
    const len = filteredStates.length;
    if (len === 0) {
      setWindowHeight(DEFAULT_SEARCH_HEIGHT);
      return;
    }

    const height = getWindowHeight(rowHeights, len);

    height &&
      setWindowHeight(
        len === 1 ? height + 1 : Math.min(height, DEFAULT_SEARCH_HEIGHT)
      );
  }, [filteredStates]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setTextWidths(filteredStates.map(({ value }) => measureTextWidth(value)));
    }
  }, [filteredStates]);

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const Row = ({ index, style }) => {
    const rowRef = useRef({});
    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [index, rowRef]);

    const { value: state } = filteredStates[index];
    const isChecked = state.toLowerCase() === stateQuery.toLowerCase();
    return (
      <div key={index} className={classes.menuItem} style={style} ref={rowRef}>
        <FormControlLabel
          control={
            <CheckboxInput
              value={isChecked}
              onChange={() => {
                if (isChecked) {
                  handleSubmit('');
                } else {
                  setOpenFilter(null);
                  handleSubmit(state);
                  setSearchState('');
                }
              }}
              name={state}
            />
          }
          label={state}
          className={classes.controlLabel}
        />
      </div>
    );
  };

  // useEffect(() => {
  //   const sortedTitles = states
  //     .filter((state) =>
  //       state.value.toLowerCase().includes(searchState.toLowerCase())
  //     )
  //     .sort(({ value: a }, { value: b }) => customSort(a, b, stateQuery));
  //   setFilteredStates(sortedTitles);
  // }, [searchState, stateQuery]);

  const dropdown = () => (
    <div
      className={clsx(
        classes.container,
        dropdownMenu && classes.dropdownContainer
      )}
    >
      <AsyncTypeahead
        id='state'
        labelKey='state'
        placeholder={'State'}
        isLoading={false}
        options={[]}
        defaultInputValue={''}
        useCache={false}
        defaultSelected={[capFirstLetters(searchState)]}
        onSearch={(text) => {}}
        onInputChange={(text) => {
          setSearchState(text);
        }}
        minLength={1}
        inputProps={{
          className: clsx(classes.autocomplete),
        }}
        renderMenu={() => null}
      />
      <div className={classes.menu}>
        {filteredStates.length > 0 ? (
          <List
            height={windowHeight}
            itemCount={filteredStates.length}
            itemSize={itemSize}
            width={'100%'}
            className={classes.menu}
            ref={listRef}
          >
            {Row}
          </List>
        ) : (
          <p className={classes.noResults}>
            {intl.formatMessage({
              id: 'inputText.noResults',
              defaultMessage: 'No results found',
            })}
          </p>
        )}
      </div>
    </div>
  );

  return (
    isFilterShown && (
      <>
        <FilterItem
          handleClick={() => setOpenFilter(isMenuOpen ? null : 'state')}
          anchorRef={anchorRef}
          open={isMenuOpen}
          placeholder={intl.formatMessage({
            id: 'inputText.state',
            defaultMessage: 'State',
          })}
          value={capFirstLetters(stateQuery)}
          onlyValue
          showResetButton={stateQuery}
          resetFilter={(e) => {
            setSearchState('');
            handleSubmit('');
            e.stopPropagation();
          }}
          dropdown={dropdownMenu && dropdown()}
        />
        {!dropdownMenu && (
          <Popper
            open={isMenuOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{
              zIndex: 100,
            }}
            placement='bottom-start'
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: false,
              },
              arrow: {
                enabled: false,
                element: anchorRef,
              },
              hide: {
                enabled: false,
              },
            }}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} timeout={{ enter: 300, exit: 0 }}>
                <Paper className={classes.paper}>
                  <ClickAwayListener
                    onClickAway={() => {
                      if (isMenuOpen) {
                        setOpenFilter(null);
                        setSearchState('');
                      }
                    }}
                  >
                    {dropdown()}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </>
    )
  );
};
