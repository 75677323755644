import React, { useState, useEffect, useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Menu, withItem } from 'react-bootstrap-typeahead';
import clsx from 'clsx';
import { Form } from 'formik';
import { FormattedMessage } from 'react-intl';

import Autocomplete from 'src/jobs/components/Autocomplete';
import LocationBig from 'src/img/icons/LocationBig';
import { setItem, getItem } from 'src/utils/localStorage';

import useStyles from './styles';

const Item = withItem(Dropdown.Item);
const WAS_SITE_VISITED = 'WAS_SITE_VISITED';
let timeout = null;

export default ({
  isAuthenticated,
  values: { title, location },
  handleChange,
  handleSubmit,
  result = [],
  setFieldValue,
  resetForm,
  toggleLandingModal,
  bgId = 0,
  isOnboardingOpen,
  // headerComponent = 'div',
  headerComponent = 'h2',
  headerComponentProps = {},
  intl,
}) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState({});
  const searchButton = useRef(null);
  // const Header = useMemo(
  //   () => (props) =>
  //     React.createElement(headerComponent, {
  //       ...props,
  //     }),
  //   [headerComponent, headerComponentProps]
  // );

  useEffect(() => {
    timeout = setTimeout(() => {
      if (!isAuthenticated) {
        const visited = getItem(WAS_SITE_VISITED);
        !visited && toggleLandingModal(true) && setItem(WAS_SITE_VISITED, true);
      }
    }, 60000);
    return () => {
      clearTimeout(timeout);
      setItem(WAS_SITE_VISITED, true);
    };
  }, []);

  useEffect(() => {
    isOnboardingOpen && timeout && clearTimeout(timeout);
  }, [isOnboardingOpen]);

  useEffect(() => {
    resetForm();
    isAuthenticated && clearTimeout(timeout);
  }, [isAuthenticated, resetForm]);

  const onMenuToggle = (type, isOpen) => {
    setMenuOpen((p) => ({ ...p, [type]: isOpen }));
  };

  const renderMenu = (results, menuProps) => {
    return results.length ? (
      <Menu
        {...menuProps}
        className={clsx(classes.menu, isAuthenticated && classes.authMenu)}
      >
        {results.map((result, index) => {
          return (
            <Item
              option={result}
              position={index}
              key={index}
              className={classes.menuItem}
            >
              {result[menuProps.id]}
            </Item>
          );
        })}
      </Menu>
    ) : null;
  };

  return (
    <div
      className={clsx(
        classes.searchbar,
        isAuthenticated ? classes.authSearchbar : classes.noAuthSearchbar
      )}
    >
      {isAuthenticated ? null : (
        <picture className={classes.backgroundImage}>
          <source
            srcSet={require(`src/img/landing_bg${bgId}.webp`)}
            type='image/webp'
            alt='Expoint - all jobs in one place'
          />
          <img
            loading='lazy'
            className={classes.backgroundImage}
            src={require(`src/img/landing_bg${bgId}.jpg`)}
            alt='Expoint - all jobs in one place'
          />
        </picture>
      )}
      <div className={classes.innerContainer}>
        {isAuthenticated ? null : (
          <div className={classes.tagline}>
            {bgId < 2 ? (
              <p className={classes.bold}>
                <span className={classes.taglineBlue}>
                  <FormattedMessage
                    id='about.slogen.a'
                    defaultMessage='The point'
                  />
                </span>
                <FormattedMessage
                  id='about.slogen.b'
                  defaultMessage=' where experts'
                />{' '}
                <FormattedMessage
                  id='about.slogen.c'
                  defaultMessage='and best companies meet'
                />
              </p>
            ) : (
              <p className={classes.bold}>
                <FormattedMessage id='slogen.part1' defaultMessage='finding' />{' '}
                <span className={classes.taglineBlue}>
                  <FormattedMessage
                    id='slogen.part2'
                    defaultMessage='the best job'
                  />
                </span>{' '}
                <FormattedMessage
                  id='slogen.part3'
                  defaultMessage='has never been easier'
                />
              </p>
            )}
          </div>
        )}
        <Form>
          <div
            className={clsx(
              classes.inputs,
              isAuthenticated && classes.authInputs
            )}
          >
            <div
              className={clsx(
                classes.inputDiv,
                isAuthenticated && classes.authInputDiv
              )}
            >
              <Autocomplete
                name='title'
                label={intl.formatMessage({
                  id: 'searchbar.jobTitle',
                  defaultMessage: 'Job Title',
                })}
                value={title}
                onChange={handleChange}
                clickSearch={() => searchButton.current.click()}
                onMenuToggle={onMenuToggle.bind(null, 'title')}
                inputProps={{
                  className: clsx(
                    classes.autocomplete,
                    isAuthenticated && classes.authInput,
                    menuOpen.title &&
                      result.title?.length &&
                      classes.autocompleteMenuOpen,
                    title && classes.boldInput
                  ),
                }}
                renderMenu={renderMenu}
              />
            </div>
            <div
              className={clsx(
                classes.inputDiv,
                isAuthenticated && classes.authInputDiv
              )}
            >
              <div className={classes.locationIcon}>
                <LocationBig />
              </div>
              <Autocomplete
                name='location'
                placeholder={intl.formatMessage({
                  id: 'searchbar.location',
                  defaultMessage: 'Location',
                })}
                value={location}
                onChange={(name) => (value, selected) => {
                  // setFieldValue('locationId', selected?.id);
                  handleChange(name)(value);
                }}
                clickSearch={() => searchButton.current.click()}
                onMenuToggle={onMenuToggle.bind(null, 'location')}
                inputProps={{
                  className: clsx(
                    classes.autocomplete,
                    isAuthenticated && classes.authInput,
                    menuOpen.location &&
                      result.location?.length &&
                      classes.autocompleteMenuOpen,
                    classes.locationAutocomplete,
                    location && classes.boldInput
                  ),
                }}
                renderMenu={renderMenu}
              />
            </div>
            <button
              ref={searchButton}
              onClick={handleSubmit}
              className={clsx(
                classes.button,
                isAuthenticated && classes.authButton
              )}
              type='submit'
            >
              <FormattedMessage id='btns.search' defaultMessage='Search' />
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};
