import { all } from 'redux-saga/effects';

import customer from 'src/customer/redux/sagas';
import user from 'src/user/redux/sagas';
import cv from 'src/cv/redux/sagas';
import onboarding from 'src/onboarding/redux/sagas';
import jobs from 'src/jobs/redux/sagas';
import layout from 'src/layout/redux/sagas';
import intl from 'src/intl/redux/sagas';
// import errorHandling from 'src/api/redux/sagas/errorHandling';
import analytics from 'src/analytics/redux/sagas';
import articles from 'src/articles/redux/sagas';

export default (store) =>
  function* () {
    yield all([
      customer(store),
      cv(store),
      user(store),
      // errorHandling(store),
      onboarding(store),
      jobs(store),
      layout(store),
      analytics(store),
      intl(store),
      articles(store),
    ]);
  };
