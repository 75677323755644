import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ArrowDown from 'src/img/icons/ArrowDown';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    width: '100%',
    '& fieldset': {
      borderRadius: 6,
    },
  },
  errorText: {
    marginLeft: 0,
    fontSize: 14,
  },
}));

const UserSeniorityLevelDropdown = ({
  name = 'seniority',
  values,
  errors,
  setFieldValue,
  touched,
  className,
  textInputClassName,
  intl,
  disabled,
  langCode,
  defaultLevels = [
    { label: 'entry', minYears: 0 },
    { label: 'junior', minYears: 1 },
    { label: 'mid', minYears: 3 },
    { label: 'senior', minYears: 6 },
    { label: 'executive' },
  ],
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  useEffect(() => {
    // translate all seniority levels to the correct options
    setOptions(
      defaultLevels.map(({ label, minYears }) => {
        return {
          label: intl.formatMessage({
            id: `inputText.seniority.${label}`,
          }),
          seniority: label,
          minYears,
        };
      })
    );
  }, [langCode]);

  const onChange = (event, value, reason) => {
    if (reason === 'clear') {
      setFieldValue('suggestedSeniority', '');
      setFieldValue(name, '');
    }
    if (value) {
      setFieldValue(name, value);
    }
  };
  const onInputChange = (e) => {};

  useEffect(() => {
    // if user entered years of exp, and didn't entered seniority level
    // insert the default seniority level
    if (!values.seniority && values.experienceTitle) {
      let yearsOfExp = parseInt(values.experienceTitle);
      for (let i = defaultLevels.length - 1; i >= 0; i--) {
        if (
          'minYears' in defaultLevels[i] &&
          yearsOfExp >= defaultLevels[i].minYears
        )
          return setFieldValue('suggestedSeniority', options[i]);
      }
    }
  }, [values.experienceTitle, values.suggestedSeniority]);

  return (
    <Autocomplete
      name={name}
      options={options}
      onKeyPress={(e) => {
        // disable text input
        e.preventDefault();
      }}
      value={values.seniority || values.suggestedSeniority}
      onFocus={onInputChange}
      getOptionLabel={(option) => option.label || option}
      getOptionSelected={({ label }) => label === values.suggestedSeniority}
      onChange={onChange}
      popupIcon={<ArrowDown color={disabled ? '#9A9DAE' : null} />}
      openOnFocus
      freeSolo
      disabled={disabled}
      onInput={onInputChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={touched.seniority && !!errors.seniority}
            className={`${classes.autocomplete} ${textInputClassName}`}
            label={intl.formatMessage({
              id: 'inputText.seniority',
              defaultMessage: 'Seniority',
            })}
            variant='outlined'
            FormHelperTextProps={{
              className: classes.errorText,
            }}
          />
        );
      }}
      className={className}
    />
  );
};

export default compose(
  injectIntl,
  connect(
    (state, props) => ({
      langCode: state.locale.language,
    }),
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
    })
  )
)(UserSeniorityLevelDropdown);
