import { select, put, take, putResolve, all } from 'redux-saga/effects';
import { fetchCvSaga } from 'src/cv/redux/sagas';
import { USER_PAGES_ARRAY } from 'src/user/constants';
import { redirect } from 'src/router/redux/actions';

import { getMe } from 'src/user/redux/actions';

/**
 * @param {Boolean} userOnlyAccess redirect if user isn't logged?
 */
export function* getMeSaga(userOnlyAccess = false) {
  const {
    locale: { language = 'en' },
    user: { profile },
    router: {
      location: { query = {}, pathname, search = '' },
    },
    onboarding: { token },
  } = yield select((state) => state);
  if (!profile.email && token) {
    yield all([putResolve(getMe()), fetchCvSaga()]);
  } else {
    // user isn't logged!
    // check if the guest trying to access user pages
    if (Object.keys(query)?.length > 0 || userOnlyAccess === true) {
      const isUserPage =
        userOnlyAccess === true ||
        USER_PAGES_ARRAY.find((page) => Object.hasOwn(query, page));
      if (isUserPage) {
        // redirect to homepage, and show login modal
        yield put(
          redirect(
            `${
              language != 'en' ? `/${language}` : ''
            }/?showLogin=1&redirectUrl=${pathname + search}`
          )
        );
      }
    }
  }
}
