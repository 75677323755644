import React from 'react';
import { useSelector } from 'react-redux';
import LanguageFlag from 'src/img/icons/LanguageFlag';
import LanguageMenu from './LanguageMenu';
import useStyles from './styles';

const LanguageSwitchButton = () => {
  const classes = useStyles();
  const langCode = useSelector((state) => state.locale.language);

  return (
    <div className={classes.languageButton}>
      <div className={classes.button}>
        <div className={classes.flagsContainer}>
          <LanguageFlag size={35} type={langCode} />
        </div>
      </div>
      <LanguageMenu />
    </div>
  );
};

export default LanguageSwitchButton;
