import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ color = '#0041E5', size = 16, strokeWidth = 1, style }) => (
  <SvgIcon
    viewBox='0 0 15 16'
    style={{
      height: size,
      width: size,
      ...style,
    }}
  >
    <g id='settings' transform='translate(0.5 0.5)'>
      <path
        d='M1.4488 7.68639C1.43043 7.50275 1.42103 7.31647 1.42103 7.128C1.42103 6.93953 1.43043 6.75325 1.4488 6.56961L0 4.93578L1.584 2.19222L3.72335 2.63C4.02456 2.41254 4.34849 2.22459 4.6909 2.07039L5.38103 0L8.54903 0L9.23916 2.07039C9.58156 2.22459 9.90549 2.41254 10.2067 2.63L12.3461 2.19222L13.9301 4.93578L12.4813 6.56961C12.4996 6.75325 12.509 6.93953 12.509 7.128C12.509 7.31647 12.4996 7.50275 12.4813 7.68639L13.9301 9.32022L12.3461 12.0638L10.2067 11.626C9.90549 11.8435 9.58156 12.0314 9.23916 12.1856L8.54903 14.256L5.38103 14.256L4.6909 12.1856C4.34849 12.0314 4.02456 11.8435 3.72335 11.626L1.584 12.0638L0 9.32022L1.4488 7.68639Z'
        id='Path'
        fill='none'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='square'
      />
      <path
        d='M1.692 3.384C2.62647 3.384 3.384 2.62647 3.384 1.692C3.384 0.757534 2.62647 0 1.692 0C0.757534 0 0 0.757534 0 1.692C0 2.62647 0.757534 3.384 1.692 3.384Z'
        transform='translate(5.4 5.4)'
        id='Oval'
        fill='none'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='square'
      />
    </g>
  </SvgIcon>
);
