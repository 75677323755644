import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import clsx from 'clsx';

import ArrowDown from 'src/img/icons/ArrowDown';

const useStyles = makeStyles(({ palette }) => ({
  dropdownIcon: {
    position: 'absolute',
    right: 12,
    display: 'inline-block',
    color: '#c0c0c0',
    width: '12px',
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  openIcon: {
    transform: 'rotate(180deg) translate(-2px, -3px)',
  },
  selected: {
    backgroundColor: `${palette.text.white} !important`,
  },
  textInput: {
    '& fieldset': {
      borderRadius: 6,
    },
    '& .MuiSelect-root': {
      borderRadius: 6,
    },
  },
}));

export default ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Select
      {...props}
      classes={{ root: classes.selected, ...props.classes }}
      className={classes.textInput}
      IconComponent={({ className = '' }) => (
        <div
          className={clsx(
            classes.dropdownIcon,
            props.dropdownStyle,
            className.includes('iconOpen') && classes.openIcon
          )}
        >
          <ArrowDown color={props.disabled ? '#9A9DAE' : null} />
        </div>
      )}
    >
      {children}
    </Select>
  );
};
