import {
  putResolve,
  put,
  takeLatest,
  takeEvery,
  all,
  select,
} from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import queryString from 'query-string';
import {
  AUTOCOMPLETE_INITITATED,
  autocompleteJobsByField,
  SAVE_JOB_IN_FAVORITE,
  GET_JOBS,
  getJobsAction,
  fetchSimilarJobs,
  INITIATE_JOBS_FETCH,
  getJob,
  initiateJobsFetch,
  SET_CURRENT_JOB_INDEX,
  getDefaultCompanies,
  // setSeoInfo,
} from 'src/jobs/redux/actions';
import { showSnackbar } from 'src/layout/redux/actions';
import { CUSTOMER_DASHBOARD_ROUTE } from 'src/customer/constants';
import { execute } from 'src/utils/captcha';
import { redirect } from 'src/router/redux/actions';
import { getArticleSaga } from 'src/articles/redux/sagas';
// import { toggleModal } from 'src/onboarding/redux/actions';

function* autocomplete({ field, value, langCode }) {
  yield putResolve(autocompleteJobsByField(field, value, langCode));
}

export function* getJobsSaga() {
  const {
    customer: { user },
    router: { location },
    locale: { language: langCode },
    jobs: {
      jobs: { resolvedOnSSR, loading },
      query,
    },
  } = yield select();

  if (query == location.pathname + location.search || resolvedOnSSR || loading)
    return;

  if (user.id) {
    yield put(push(CUSTOMER_DASHBOARD_ROUTE));
    return;
  }

  return yield sendJobsRequest({
    ...queryString.parse(location.search),
    langCode,
  });
}

export function* getDefaultCompaniesSaga() {
  return yield putResolve(getDefaultCompanies());
}

export function* getSharedJobSaga(props) {
  const {
    locale: { language },
    jobs: {
      jobs: { currentJob },
    },
    router: {
      location: { query = {}, pathname },
    },
  } = yield select((state) => state);

  if (pathname?.includes('/job/')) {
    // singleJobPage
    const requestedJobId = pathname.split('/job/')[1].split('/')[0];

    if (
      requestedJobId != currentJob?.id ||
      currentJob.pageMetaData.langCode != language
    ) {
      // ensure there are no query params!
      const validKeys = ['completeRegistration', 'loginType', 'accessToken'];
      const queryKeys = Object.keys(query);

      let validRequest = true;

      for (const key of queryKeys) {
        if (!validKeys.includes(key)) {
          validRequest = false;
          break;
        }
      }

      const result = yield putResolve(
        getJob(requestedJobId, language, validRequest)
      );

      if (result?.error) {
        return yield put(
          redirect(`${language !== 'en' ? `/${language}` : '/'}`, 301)
        );
      }
      if (result?.redirectURL)
        return yield put(redirect(result.redirectURL, 301));

      if (query?.token && result?.link) {
        yield put(redirect(result.link));
      }
    }
  }
}

function* sendJobsRequest(params) {
  const {
    router: { location },
    locale: { language = 'en' },
    onboarding: { sessionId },
  } = yield select();

  const captchaToken = yield execute({
    action: 'submit',
  });
  const timestamp = +new Date();
  console.log(
    '\x1b[34m%s\x1b[0m',
    'get_jobs_user',
    'Timestamp: ' + timestamp,
    '\nSession ID: ' + sessionId
  );
  return yield putResolve(
    getJobsAction({
      ...params,
      size: 7,
      captchaToken,
      url: location.pathname + location.search,
      langCode: language,
    })
  );
}

function* failToSaveJobSaga({ error }) {
  if (error?.error === 'ALREADY_SAVED')
    yield put(showSnackbar('This job has already been saved', 'error'));
}

function* getJobs({
  payload: {
    location: { pathname, search },
  },
}) {
  const {
    locale: { language },
    jobs: {
      jobs: { resolvedOnSSR },
      query,
    },
  } = yield select((state) => state);
  //check if the page is homepage
  let trimmedPath = pathname.replace('/' + language, '');

  if (trimmedPath === '/' || trimmedPath === '') {
    // homepage!
    if (query != pathname + search && !resolvedOnSSR) {
      return yield all([
        putResolve(initiateJobsFetch(queryString.parse(search))),
        getArticleSaga(),
      ]);
    }
  }
}

function* getSimilarJobs() {
  const {
    locale: { language },
    jobs: {
      jobs: { currentJob },
    },
  } = yield select((state) => state);
  if (
    currentJob &&
    currentJob?.id &&
    !(currentJob?.similarJobs && currentJob?.similarJobs?.length)
  ) {
    return yield putResolve(fetchSimilarJobs(currentJob, language));
  }
}

function* jobsFetchFail({
  error: { status, error, seoInfo = {}, redirectURL } = {},
}) {
  switch (status) {
    case 301:
      if (error === 'UNAUTHTORIZED' || 'COMPANY_REBRANDED') {
        // yield all([
        //   put(setSeoInfo(seoInfo)),
        //   put(toggleModal(true, 'isRegModalOpen')),
        // ]);
        return yield put(redirect(redirectURL, 301));
      }
      break;
    case 404:
      if (error === 'PAGE_NOT_FOUND' && redirectURL)
        return yield put(redirect(redirectURL, 301));
      break;

    default:
      break;
  }
}

export default function* () {
  yield all([
    takeLatest(AUTOCOMPLETE_INITITATED, autocomplete),
    takeLatest(SAVE_JOB_IN_FAVORITE.FAILURE, failToSaveJobSaga),
    takeLatest(GET_JOBS.FAILURE, jobsFetchFail),
    takeLatest(INITIATE_JOBS_FETCH, function* ({ params }) {
      return yield sendJobsRequest(params);
    }),
    takeEvery(LOCATION_CHANGE, getJobs),
    takeEvery(LOCATION_CHANGE, getSharedJobSaga),
    takeEvery(SET_CURRENT_JOB_INDEX, getSimilarJobs),
  ]);
}
