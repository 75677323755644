import loadable from '@loadable/component';

import { loadableConfig } from 'src/router/config';
import { langRegex } from 'src/intl/utils';
import wrapIntoLayout from 'src/utils/wrapIntoLayout';
import LandingLayout from 'src/customer/components/LandingLayout';
import Layout from 'src/customer/components/Layout';

import { JOBS_LIST_ROUTE_NAME } from './constants';

const DetailForm = loadable(
  () => import('src/customer/components/DetailForm'),
  loadableConfig.customer
);
const Jobs = loadable(
  () => import('src/customerJobs/components/Jobs'),
  loadableConfig.customer
);
const Candidates = loadable(
  () => import('src/candidates/components/CandidatesPage'),
  loadableConfig.customer
);
const JobEdit = loadable(
  () => import('src/customer/components/JobEdit'),
  loadableConfig.customer
);
const PostJob = loadable(
  () => import('src/customer/components/PostJob'),
  loadableConfig.customer
);
const UsersPage = loadable(
  () => import('src/users/components/UsersPage'),
  loadableConfig.customer
);
const Billing = loadable(
  () => import('src/customer/components/BillingPage'),
  loadableConfig.customer
);
const DepartmentsPage = loadable(
  () => import('src/department/components/DepartmentsPage'),
  loadableConfig.customer
);
const MyCandidates = loadable(
  () => import('src/candidates/components/MyCandidatesPage'),
  loadableConfig.customer
);
const Candidate = loadable(
  () => import('src/candidates/components/CandidatePage'),
  loadableConfig.customer
);
const Landing = loadable(() => import('src/customer/components/Landing'));
const Articles = loadable(
  () => import('src/articles/components/ArticlesPage'),
  loadableConfig.customer
);
const Article = loadable(
  () => import('src/articles/components/ArticlePage'),
  loadableConfig.customer
);
const Plans = loadable(
  () => import('src/customer/components/LandingPlans'),
  loadableConfig.customer
);

const AdminPage = loadable(
  () => import('src/admin/components/AdminPage'),
  loadableConfig.customer
);

// const InsightsPage = loadable(
//   () => import('src/insights/components/InsightsPage'),
//   loadableConfig.customer
// );
const InsightsPage = loadable(
  () => import('src/insights/components/InsightsPageStart'),
  loadableConfig.customer
);

const routes = [
  {
    path: `${langRegex}/customer/dashboard/details`,
    component: wrapIntoLayout(Layout)(DetailForm),
  },
  {
    path: `${langRegex}/customer/dashboard/postjob`,
    component: wrapIntoLayout(Layout)(PostJob),
  },
  {
    path: `${langRegex}/customer/dashboard/billing`,
    component: wrapIntoLayout(Layout)(Billing),
  },
  {
    path: `${langRegex}/customer/dashboard/candidates`,
    exact: true,
    component: wrapIntoLayout(Layout)(Candidates),
  },
  {
    path: `${langRegex}/customer/dashboard/my-candidates`,
    exact: true,
    component: wrapIntoLayout(Layout)(MyCandidates),
  },
  {
    path: `${langRegex}/customer/dashboard/imported-candidates`,
    exact: true,
    component: wrapIntoLayout(Layout)(MyCandidates),
  },
  {
    path: `${langRegex}/customer/dashboard/my-candidates/:id`,
    exact: true,
    component: wrapIntoLayout(Layout)(Candidate),
  },
  {
    path: `${langRegex}/customer/dashboard/departments`,
    exact: true,
    component: wrapIntoLayout(Layout)(DepartmentsPage),
  },
  {
    path: `${langRegex}/customer/dashboard/users`,
    exact: true,
    component: wrapIntoLayout(Layout)(UsersPage),
  },
  {
    path: JOBS_LIST_ROUTE_NAME,
    exact: true,
    component: wrapIntoLayout(Layout)(Jobs),
  },
  {
    path: `${langRegex}/customer/dashboard/jobs/:id`,
    component: wrapIntoLayout(Layout)(JobEdit),
  },
  {
    path: `${langRegex}/customer/dashboard/articles`,
    component: wrapIntoLayout(Layout)(Articles),
    exact: true,
  },
  {
    path: `${langRegex}/customer/dashboard/articles/:id`,
    component: wrapIntoLayout(Layout)(Article),
    exact: true,
  },
  {
    path: `${langRegex}/customer`,
    component: wrapIntoLayout(LandingLayout)(Landing),
    exact: true,
  },
  {
    path: `${langRegex}/customer/articles`,
    component: wrapIntoLayout(LandingLayout)(Articles),
    exact: true,
  },
  {
    path: `${langRegex}/customer/articles/:id`,
    component: wrapIntoLayout(LandingLayout)(Article),
    exact: true,
  },
  {
    path: `${langRegex}/customer/plans`,
    component: wrapIntoLayout(LandingLayout)(Plans),
    exact: true,
  },
  {
    path: `${langRegex}/customer/dashboard/admin`,
    component: wrapIntoLayout(Layout)(AdminPage),
    exact: true,
  },
  // {
  //   path: `${langRegex}/customer/dashboard/insights`,
  //   component: wrapIntoLayout(Layout)(InsightsPage),
  //   exact: true,
  // },
  {
    path: `${langRegex}/customer/dashboard/jobs-statistics`,
    component: wrapIntoLayout(Layout)(InsightsPage),
    exact: true,
  },
  {
    path: `${langRegex}/customer/dashboard/candidates-statistics`,
    component: wrapIntoLayout(Layout)(InsightsPage),
    exact: true,
  },
  {
    path: `${langRegex}/customer/dashboard/job-statistics`,
    component: wrapIntoLayout(Layout)(InsightsPage),
    exact: true,
  },
];

export default routes;
