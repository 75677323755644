export const TOGGLE_PROFILE_MODAL = 'TOGGLE_PROFILE_MODAL';

export const toggleModal = (isOpen, modalType, showEmpty, itemId) => ({
  type: TOGGLE_PROFILE_MODAL,
  isOpen,
  modalType,
  showEmpty,
  itemId,
});

export const TOGGLE_CV_MODAL = 'TOGGLE_CV_MODAL';

export const toggleCVModal = (isOpen, cb, oneField) => ({
  type: TOGGLE_CV_MODAL,
  isOpen,
  cb,
  oneField,
});

export const TOGGLE_COMPLETE_REG_MODAL = 'TOGGLE_COMPLETE_REG_MODAL';

export const toggleCompleteRegModal = (isOpen) => ({
  type: TOGGLE_COMPLETE_REG_MODAL,
  isOpen,
});

export const TOGGLE_MODAL_LOADING = 'TOGGLE_MODAL_LOADING';

export const toggleModalLoading = (isLoading) => ({
  type: TOGGLE_MODAL_LOADING,
  isLoading,
});
