import { createApiAction, createAsyncActionRequestTypes } from 'src/api';

export const UPLOAD_FILE = createAsyncActionRequestTypes('UPLOAD_FILE');

export const uploadFile = (body, headers) =>
  createApiAction(
    {
      method: 'POST',
      url: '/file',
      body,
      headers,
    },
    UPLOAD_FILE
  );
