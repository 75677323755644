import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ size, color, strokeWidth, style = {} }) => (
  <SvgIcon
    viewBox='0 0 34 34'
    style={{
      height: size || '34px',
      width: size || '34px',
      ...style,
    }}
  >
    <g id='Icon-/-X'>
      <path
        d='M12 0L12 24M24 12L0 12'
        transform='matrix(0.7071068 0.7071068 -0.7071068 0.7071068 17 0.02943659)'
        id='Combined-Shape'
        fill='none'
        stroke={color || '#20285C'}
        strokeWidth={strokeWidth || '2'}
        strokeLinecap='round'
      />
    </g>
  </SvgIcon>
);
