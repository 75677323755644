import createReducer from 'src/utils/createReducer';
import { LOG_OUT } from 'src/customer/redux/actions';

import {
  FETCH_CUSTOMER_JOBS,
  CREATE_JOB,
  EDIT_JOB,
  FETCH_CUSTOMER_JOB_INFO,
  GET_NEXT_ID,
  CLEAR_CUSTOMER_JOBS,
  PROMOTE_JOB,
} from 'src/customerJobs/redux/actions';

const INITIAL_STATE = {
  list: [],
  pages: 0,
  total: 0,
  loading: false,
  updateLoading: false,
  nextId: null,
};

export default createReducer(INITIAL_STATE, {
  [FETCH_CUSTOMER_JOBS.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [FETCH_CUSTOMER_JOBS.SUCCESS]: (state, { data: { d, ...data } }) => ({
    ...state,
    list: d,
    loading: false,
    ...data,
  }),
  [FETCH_CUSTOMER_JOBS.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [CREATE_JOB.REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [CREATE_JOB.SUCCESS]: (state, { body, data: { id } }) => ({
    ...state,
    loading: false,
    list: state.list.concat({ ...body, id }),
  }),
  [CREATE_JOB.FAILURE]: (state) => ({
    ...state,
    loading: false,
  }),
  [EDIT_JOB.REQUEST]: (state) => ({
    ...state,
    updateLoading: true,
  }),
  [EDIT_JOB.SUCCESS]: (state, { body, data }) => ({
    ...state,
    list: state.list.map((e) =>
      e.id === data.editedJobID
        ? { ...body, lastPromotedDate: data.lastPromotedDate }
        : e
    ),
    updateLoading: false,
  }),
  [EDIT_JOB.FAILURE]: (state) => ({
    ...state,
    updateLoading: false,
  }),
  [GET_NEXT_ID.SUCCESS]: (state, { data }) => ({
    ...state,
    nextId: data.id,
  }),
  [FETCH_CUSTOMER_JOB_INFO.SUCCESS]: (state, { data }) => ({
    ...state,
    list: state.list.concat(data),
  }),
  [PROMOTE_JOB.SUCCESS]: (state, { data: { jobObject } }) => {
    const newJobsList = state.list.map((job) => {
      if (job.jobId === jobObject.jobID) {
        jobObject.id = jobObject.jobID;
        jobObject.jobId = jobObject.jobID;
        return jobObject;
      }
      return job;
    });
    return { ...state, list: newJobsList };
  },
  [LOG_OUT]: () => INITIAL_STATE,
  [CLEAR_CUSTOMER_JOBS]: () => INITIAL_STATE,
});
