import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { toggleSucccessBar } from 'src/layout/redux/actions';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  paper: {
    backgroundColor: '#00D3AF',
    width: '100vw',
    minHeight: 40,
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: 500,
    padding: '5px 20px',
  },
  button: {
    backgroundColor: '#00D3AF',
    borderRadius: 6,
    border: '1px #fff solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 76,
    minWidth: 76,
    height: 24,
    marginLeft: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#00ba9a',
      border: '1px #f2f2f2 solid',
      color: '#f2f2f2',
    },
  },
}));

const SuccessBar = ({ open, toggleSucccessBar, isCustomer }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      keepMounted
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          {isCustomer ? (
            <FormattedMessage
              id="successBar.checkMail"
              defaultMessage="Thank you for registering. To start posting jobs (create vacancies), please check your mailbox first and confirm your email."
            />
          ) : (
            <FormattedMessage
              id="successBar.confirmMail"
              defaultMessage="All set up! In order to start looking for a job, please check your mail box first and confirm our mail."
            />
          )}
          <div
            className={classes.button}
            onClick={() => toggleSucccessBar(false)}
          >
            <FormattedMessage id="btns.gotIt" defaultMessage="Got it" />
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
export default compose(
  connect(
    (state) => ({
      open: !!state.layout.isSuccessBarShown,
      isCustomer: state.layout.isSuccessBarCustomer,
    }),
    { toggleSucccessBar }
  )
)(SuccessBar);
