import ae from 'src/img/cc-icons/ae.png';
import dinersclub from 'src/img/cc-icons/dinersclub.png';
import discover from 'src/img/cc-icons/discover.png';
import jcb from 'src/img/cc-icons/jcb.png';
import mastercard from 'src/img/cc-icons/mastercard.png';
import visa from 'src/img/cc-icons/visa.png';
import { langRegex } from 'src/intl/utils';

export const numberOfEmployees = [
  {
    value: 1,
    label: '1 - 10',
  },
  {
    value: 10,
    label: '10 - 100',
  },
  {
    value: 100,
    label: '100 - 500',
  },
  {
    value: 500,
    label: '> 500',
  },
];

export const JOBS_LIST_ROUTE_NAME = `${langRegex}/customer/dashboard/jobs`;
export const CUSTOMERS_LIST_ROUTE_NAME = `${langRegex}/customer/dashboard/admin`;

export const CANDIDATES_STATUSES = [
  {
    id: 'CONTACTED',
    label: 'candidates.status.contacted',
  },
  {
    id: 'INTERVIEW_SCHEDULED',
    label: 'candidates.status.interviewScheduled',
  },
  {
    id: 'INTERVIEWED',
    label: 'candidates.status.interviewed',
  },
  {
    id: 'HIRED',
    label: 'candidates.status.hired',
  },
  {
    id: 'REJECTED',
    label: 'candidates.status.rejected',
  },
];

export const JOBS_STATUSES = [
  {
    id: 'opened',
    label: 'jobs.status.opened',
  },
  {
    id: 'closed',
    label: 'jobs.status.closed',
  },
];

export const CANDIDATES_STATUSES_MAP = {
  CONTACTED: {
    id: 'CONTACTED',
    label: 'candidates.status.contacted',
  },
  INTERVIEW_SCHEDULED: {
    id: 'INTERVIEW_SCHEDULED',
    label: 'candidates.status.interviewScheduled',
  },
  INTERVIEWED: {
    id: 'INTERVIEWED',
    label: 'candidates.status.interviewed',
  },
  HIRED: {
    id: 'HIRED',
    label: 'candidates.status.hired',
  },
  REJECTED: {
    id: 'REJECTED',
    label: 'candidates.status.rejected',
  },
  APPLICABLE: {
    id: 'APPLICABLE',
    label: 'candidates.status.applicable',
  },
  NOT_APPLICABLE: {
    id: 'NOT_APPLICABLE',
    label: 'candidates.status.notApplicable',
  },
  INVITED: {
    id: 'INVITED',
    label: 'candidates.status.invitedToInterview',
  },
  Rejected: {
    id: 'Rejected',
    label: 'candidates.status.rejected',
  },
  NOT_INTERESTED: {
    id: 'NOT_INTERESTED',
    label: 'candidates.status.notInterested',
  },
};

export const INTERVALS = [
  {
    id: 'day',
    label: 'intervals.daily',
  },
  {
    id: 'week',
    label: 'intervals.weekly',
  },
  {
    id: 'month',
    label: 'intervals.monthly',
  },
  {
    id: 'year',
    label: 'intervals.yearly',
  },
];

export const PERIODS = [
  {
    id: 'days7',
    label: 'period.days7',
  },
  {
    id: 'days30',
    label: 'period.days30',
  },
  {
    id: 'days60',
    label: 'period.days60',
  },
  {
    id: 'days90',
    label: 'period.days90',
  },
  {
    id: 'month6',
    label: 'period.month6',
  },
  {
    id: 'year1',
    label: 'period.year1',
  },
  {
    id: 'year2',
    label: 'period.year2',
  },
  {
    id: 'year5',
    label: 'period.year5',
  },
];

export const PERIODS_MAP = {
  days_7: {
    id: 'days7',
    label: 'period.days7',
  },
  days_30: {
    id: 'days30',
    label: 'period.days30',
  },
  days_60: {
    id: 'days60',
    label: 'period.days60',
  },
  days_90: {
    id: 'days90',
    label: 'period.days90',
  },
  month_6: {
    id: 'month6',
    label: 'period.month6',
  },
  year_1: {
    id: 'year1',
    label: 'period.year1',
  },
  year_2: {
    id: 'year2',
    label: 'period.year2',
  },
  year_5: {
    id: 'year5',
    label: 'period.year5',
  },
};

export const CREDIT_CARD_ICONS = {
  ae,
  dinersclub,
  discover,
  jcb,
  mastercard,
  visa,
};

export const USER_ROLES = [
  { value: 'ADMIN', name: 'userRoles.admin' },
  { value: 'EDITOR', name: 'userRoles.editor' },
  { value: 'DEPARTMENT_ADMIN', name: 'userRoles.departmentAdmin' },
  { value: 'DEPARTMENT_EDITOR', name: 'userRoles.depEditor' },
  { value: 'VIEWER', name: 'userRoles.viewer' },
  { value: 'DEPARTMENT_VIEWER', name: 'userRoles.depViewer' },
  // { value: 'RECRUITMENT_MANAGER', name: 'userRoles.recruitmentManager' },
  // { value: 'RECRUITER', name: 'userRoles.recruiter' },
];

export const USER_ROLES_MAP = {
  ADMIN: 'userRoles.admin',
  DEPARTMENT_ADMIN: 'userRoles.departmentAdmin',
  EDITOR: 'userRoles.editor',
  DEPARTMENT_EDITOR: 'userRoles.depEditor',
  VIEWER: 'userRoles.viewer',
  DEPARTMENT_VIEWER: 'userRoles.depViewer',
  // RECRUITMENT_MANAGER: 'userRoles.recruitmentManager',
  // RECRUITER: 'userRoles.recruiter',
};

export const LOCAL_STORE_TOKEN = 'expoint@customer:token';

export const CUSTOMER_DASHBOARD_ROUTE = '/customer/dashboard/jobs-statistics';

export const SENIORTY_LEVELS = [
  { id: 'entry', label: 'inputText.seniority.entry' },
  { id: 'junior', label: 'inputText.seniority.junior' },
  { id: 'mid', label: 'inputText.seniority.mid' },
  { id: 'senior', label: 'inputText.seniority.senior' },
  { id: 'executive', label: 'inputText.seniority.executive' },
];

export const TYPES_OF_EMPLOYMENT = [
  {
    id: 'fulltime',
    label: 'typeOfEmp.fullTime',
  },
  {
    id: 'parttime',
    label: 'typeOfEmp.partTime',
  },
  {
    id: 'contract',
    label: 'typeOfEmp.contract',
  },
  {
    id: 'temporary',
    label: 'typeOfEmp.temporary',
  },
  {
    id: 'relocation',
    label: 'typeOfEmp.relocation',
  },
  {
    id: 'freelance',
    label: 'typeOfEmp.freelance',
  },
  {
    id: 'remote',
    label: 'typeOfEmp.remote',
  },
  {
    id: 'student',
    label: 'typeOfEmp.student',
  },
];

export const CURRENCIES = [
  {
    value: 'ILS',
    label: 'Israeli shekel',
    symbol: '₪',
  },
  {
    value: 'USD',
    label: 'US dollar',
    symbol: '$',
  },
  {
    value: 'EUR',
    label: 'Euro',
    symbol: '€',
  },
  {
    value: 'JPY',
    label: 'Japanese yen',
    symbol: '¥',
  },
  {
    value: 'GBP',
    label: 'Pound sterling',
    symbol: '£',
  },
  {
    value: 'AUD',
    label: 'Australian dollar',
    symbol: '$',
  },
  {
    value: 'CAD',
    label: 'Canadian dollar',
    symbol: '$',
  },
  {
    value: 'CHF',
    label: 'Swiss franc',
    symbol: 'CHF',
  },
  {
    value: 'CNY',
    label: 'China yuan renminbi',
    symbol: '¥',
  },
  {
    value: 'HKD',
    label: 'Hong Kong dollar',
    symbol: '$',
  },
  {
    value: 'NZD',
    label: 'New Zealand dollar',
    symbol: '$',
  },
];

export const errorsMap = {
  INTERNAL_ERROR: 'snackbar.error.refusal',
  CAN_REMOVE_ONLY_YOUR_DEPARTMENT_USER: 'snackbar.error.onlyYourDep',
  CANNOT_REMOVE_YOUR_OWN_USER: 'snackbar.error.yourUser',
  NOT_FOUND: 'snackbar.error.userNotFound',
  USER_HAS_AVAILABLE_JOBS: 'snackbar.error.userHasJobs',
  CANNOT_REMOVE_LAST_ADMIN: 'snackbar.error.lastAdmin',
  CANNOT_REMOVE_LAST_DEPARTMENT_ADMIN: 'snackbar.error.lastDepAdmin',
};
