const KEY = process.env.GOOGLE_API_KEY;
let isCaptchaReady = false;

const sleep = (t) => new Promise(setTimeout, t);

const checkIfReady = async () => {
  if (!window.grecaptcha || !window.grecaptcha.render) {
    await sleep(1000);
    return checkIfReady();
  }

  return Promise.resolve(true);
};

const runWhenReady = (cb) => {
  if (!process.env.BROWSER) return null;

  return new Promise(async (res) => {
    await checkIfReady();

    window.grecaptcha.ready(() => {
      console.log('CAPTCHA API READY');
      return res(cb());
    });
  });
};

runWhenReady(() => {});

export const execute = (config) => {
  if (!process.env.BROWSER) return null;

  try {
    return runWhenReady(() => window.grecaptcha.execute(KEY, config));
  } catch (e) {
    console.error(e);
  }
};
