import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { putResolve } from 'redux-saga/effects';
import queryString from 'query-string';

import { getCountriesAutocomplete } from 'src/jobs/redux/actions';
import { initiateJobsFetch } from 'src/jobs/redux/actions';
import { navigateIntl } from 'src/utils/history';
import resolveRoute from 'src/utils/resolveRoute';

import CountryDropdown from './CountryDropdown';

export default compose(
  withRouter,
  withSnackbar,
  resolveRoute(function* () {
    yield putResolve(getCountriesAutocomplete());
  }),
  connect(
    (state, props) => ({
      countryQuery: queryString
        .parse(props.location.search)
        ?.location?.split(',')[0],
      countriesList: state.jobs.autocomplete?.countries,
      langCode: state.locale.language,
    }),
    { initiateJobsFetch },
    (stateProps, dispatchProps, parentProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...parentProps,
      handleSubmit: async (country) => {
        const currentParams = queryString.parse(parentProps.location.search);
        const { location, ...rest } = currentParams;

        const params = {
          ...rest,
          location: country?.trim()?.toLowerCase(),
        };

        // reset page
        delete params.page;

        navigateIntl(
          `/?${queryString.stringify(params, {
            skipEmptyString: true,
            arrayFormat: 'comma',
          })}`
        );
        dispatchProps.initiateJobsFetch(params);
      },
    })
  ),
  injectIntl
)(CountryDropdown);
